import { subscribe } from './subscribe'
import { commonError } from './commonError'
import type { UserContentType } from '@components/NovaBoxUserTopHeader/NovaBoxUserTopHeader.types'
import type {
  DonationsOrderBy,
  MyCommentOrderBy,
  UserContentsOrderBy,
  MyRecommendPostType,
  RewardsOrderBy,
  ScrapPostsOrderBy,
  SetRecommendsOrderBy,
  ActivityFilter,
  CreatorContentsItemType,
  MyCollectionsFilter,
} from '@store'

export const mypage = {
  profile: {
    title: '프로필 정보',
    placeholder: {
      nickname: '사용할 닉네임을 입력해주세요.',
      content: '프로필내용',
    },
    button: {
      cancel: '취소',
      save: '저장',
    },
    dialog: {
      title: '알림',
      initImage:
        '이미지 삭제 시 기본 설정 아바타로 이미지가 변경됩니다. 삭제하시겠습니까?',
      doubleNickname:
        '동일한 닉네임이 사용중입니다. 다른 닉네임을 사용해 주세요.',
      noImage: '사용자 프로필이 없습니다.',
    },
    toastMessage: {
      unavailableNickname:
        '사용할 수 없는 문자가 포함되어 있습니다. 다른 닉네임을 사용해 주세요.',
      unavailableSimpleIntroduce:
        '사용할 수 없는 문자가 포함되어 있습니다. 다른 한줄소개를 사용해 주세요.',
      imgError2005: '등록할 수 없는 확장자 입니다.',
      imgError: '이미지 업로드에 실패했습니다. 잠시후 다시 시도해주세요.',
    },
  },
  tabMenu: {
    contents: '포스트',
    reward: '리워드',
    donation: '후원',
    activity: '내 활동',
    collections: '마이 컬렉션',
  } as { [Property in UserContentType]: string },
  sort: {
    reward: {
      newest: '최신순',
      oldest: '과거순',
      rewardQty: '리워드크기순',
    } as { [Property in RewardsOrderBy]: string },
    rewardWeekly: {
      like: '좋아요',
      follow: '팔로우',
    },
    rewardChart: {
      month: '최근 1달',
      threeMonths: '최근 3달',
      all: '전체',
    },
    rewardChartActivity: {
      month: '최근 1달',
      threeMonths: '최근 3달',
      all: '전체',
    },
    donation: {
      newest: '최신순',
      oldest: '과거순',
      donationQty: '크기순',
    } as { [Property in DonationsOrderBy]: string },
    post: {
      newest: '최신순',
      oldest: '과거순',
      hot: '인기순',
    } as { [Property in UserContentsOrderBy]: string },
    recommend: {
      newest: '최신순',
      oldest: '과거순',
    } as { [Property in SetRecommendsOrderBy]: string },
    comment: {
      newest: '최신순',
      oldest: '과거순',
      // hot: '인기순',
    } as { [Property in MyCommentOrderBy]: string },
    scrap: {
      newest: '최신순',
      oldest: '과거순',
      // hot: '인기순',
    } as { [Property in ScrapPostsOrderBy]: string },
  },
  postTypes: {
    content: '콘텐츠',
    free: '자유',
    notice: '공지사항',
  } as { [Property in CreatorContentsItemType]: string },
  filter: {
    donation: {
      received: '받은 내역',
      sent: '보낸 내역',
    },
    activity: {
      recommend: '좋아요',
      comment: '댓글',
      scrap: '스크랩',
    } as { [Property in ActivityFilter]: string },
    myCollections: {
      recentlyDesc: '최신 발행 순',
      rarityDesc: '희귀도 높은 순',
      rarityAsc: '희귀도 낮은 순',
    } as { [Property in MyCollectionsFilter]: string },
  },
  panel: {
    reward: {
      title: '총 리워드',
      empty: '리워드 지급 금액이 없습니다.',
    },
    donationQty: {
      received: '받은 후원',
      sent: '보낸 후원',
    },
    post: {
      public: '공개',
      private: '비공개',
    },
    comment: '총 댓글',
    recommend: {
      get: '좋아요 받은 포스트',
      set: '좋아요 한 포스트',
    } as { [Property in MyRecommendPostType]: string },
    scrap: '총 스크랩',
  },
  reward: {
    active: '활동내역',
    messages: {
      activity: '주간 활동 리워드가 지급되었습니다.',
      post: '주간 게시글 리워드가 지급되었습니다.',
      fixed: '월간 고정 리워드가 지급되었습니다.',
      incentive: '주간 인센티브 리워드가 지급되었습니다.',
      monthly: '월간 보너스 리워드가 지급되었습니다.',
      referral: '레퍼럴 리워드가 지급되었습니다.',
      novaPlus: `${subscribe.subscribeGrade.novaPlus} 리워드가 지급되었습니다.`,
      nft: '주간 생태계활성화 활동 리워드가 지급되었습니다.',
      superCreator: '특별 월간 리워드가 지급되었습니다.',
      megaCreator: '특별 월간 리워드가 지급되었습니다.',
      weeklyReward: '주간 리워드가 지급되었습니다.',
      missionReward: '미션 리워드를 받았습니다.',
    },
    zeroGuide:
      '리워드가 0LM으로 표시되는 것은 활동점수가 서로 상쇄되어 0점이 된 경우입니다.',
    title: {
      weekly: '주간 예상 리워드',
      total: '총 리워드',
      statistics: '통계',
    },
    modal: {
      recommend: '회원님의 포스트를 좋아합니다.',
      follow: '회원님을 팔로우했습니다.',
    },
    chart: {
      creatorActivity: {
        label: {
          follow: '팔로우',
          recommend: '좋아요',
        },
        title: '크리에이터 활동',
      },
      totalReward: {
        label: {
          reward: '주간 리워드',
        },
        title: '주간 리워드',
      },
    },
  },
  donation: {
    message: {
      received: '<strong>{userNcnm} 님</strong>에게 후원받았습니다.',
      sent: '<strong>{userNcnm} 님</strong>에게 후원하였습니다.',
      withdrawUser: '탈퇴한 사용자',
      donateMessage: '응원 메시지',
      receivedTotal:
        '<div>지금까지 총 <strong style="color: #ff6934">{formattedQty} LM</strong> 후원 받았어요.</div>',
      sentTotal:
        '<div>지금까지 총 <strong style="color: #347ae2">{formattedQty} LM</strong> 후원했어요.</div>',
      empty: {
        received: {
          title: '후원 받은 내역이 없습니다.',
          message: '다양한 활동을 통해 팬으로부터 후원을 받아보세요.',
        },
        sent: {
          title: '후원 하신 내역이 없습니다.',
          message: '크리에이터에게 따듯한 응원을 보내 보세요.',
        },
      },
      error: {
        received:
          '후원 받은 내역을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
        sent: '후원한 내역을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
        donationQty:
          '후원 정보를 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
      },
    },
  },
  myFollow: {
    tabs: {
      follower: '팔로워',
      block: '차단목록',
    },
    title: {
      follower: '팔로워',
      following: '팔로잉',
      block: '차단목록',
    },
    follower: {
      noDate: {
        title: '팔로워를 찾으세요?',
        message:
          '이 계정에 아직 팔로워가 없습니다.<br />포스트를 작성하고 사람들과 소통하면 팔로워를 만들 수 있습니다.',
      },
      error: '팔로워 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    following: {
      state: '나를 팔로우합니다.',
      noDate: {
        title: '아직 팔로우한 사람이 없습니다.',
        button: '팔로우 할 사람 찾기',
      },
      backFlag: '나를 팔로우합니다',
      error: '팔로잉 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },

    block: {
      title: '차단목록',
      noDate: {
        title: '차단한 사람이 없습니다.',
      },
      error: '차단 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    button: {
      follow: '팔로우',
      following: '팔로잉',
      delete: '팔로워 삭제하기',
      search: '팔로우 할 사람 찾기',
      cancel: '차단 해제',
    },
    message: {
      follow: {
        error: {
          2022: '이미 팔로우하였습니다.',
          2034: '{userNcnm}님을 팔로우 할 수 없습니다.',
          2037: '자동화봇으로 인식되어 처리에 실패했습니다. 새로고침 후 다시 시도해 주세요.',
          4002: '가입되지 않은 회원입니다.',
          4013: '가입되지 않은 회원입니다.',
          unknown: commonError.network.message,
        },
      },
      unfollow: {
        error: {
          2023: '팔로워가 존재하지 않습니다.',
          2034: '{userNcnm}님을 언팔로우 할 수 없습니다.',
          4002: '가입되지 않은 회원입니다.',
          4013: '가입되지 않은 회원입니다.',
          unknown: commonError.network.message,
        },
      },
      followerRemoveConfirmMessage: '팔로워를 삭제하시겠습니까?',
      delSuccess: '팔로워를 삭제하였습니다.',
      delError: '팔로워를 삭제할 수 없습니다. 잠시후 다시 시도해주세요.',
      followerError: '팔로우 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
      unfollowError: '언팔로우 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
  },
  myCollection: {
    message: {
      empty: {
        message: '보유한 NFT가 없습니다.',
      },
    },
    button: {
      showMore: '더보기',
      showLess: '접기',
    },
    detail: {
      owner: '소유자',
      rarity: '희귀도',
      issueDate: '발행일자',
      reveal: '리빌',
    },
    reveal: {
      success: 'NFT 리빌이 완료되었습니다.',
      error: 'NFT 리빌에 실패하였습니다. <br />잠시 후 다시 시도해 주세요.',
    },
  },
}
