<script setup lang="ts">
import type {
  ShowCreatorNoticeDetailModalParams,
  NovaBoxCreatorNoticeListItemProps,
} from './NovaBoxCreatorNoticeListItem.types'

const props = defineProps<NovaBoxCreatorNoticeListItemProps>()

const { gtEvent } = useGoogleTag()
const creatorStore = useCreatorStore()
const userStore = useUserStore()
const { show: modalShow } = useModal()

// 크리에이터 공지사항 상세 모달 열기
const handleOnOpenNoticeDetail = () => {
  if (props.viewType === 'list') {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '공지사항 상세보기',
      eventLabel: '',
      eventSlot: '',
      eventI18nAddr: '',
      eventComponent: 'Modal',
      crtrNttSn: props.source.crtrNttSn,
      crtrNttSj: props.source.crtrNttSj,
      crtrNttCn: props.source.crtrNttCn,
      crtrUserSn: props.source.crtrUserSn,
      sysRegisterSn: props.source.sysRegisterSn,
    })
    modalShow(modalsName.MODAL_CREATOR_NOTICE_DETAIL, {
      creatorNotice: props.source,
    } as ShowCreatorNoticeDetailModalParams)
  }
}
</script>

<template>
  <article
    :class="['post', `view-type-${viewType}`]"
    @click="handleOnOpenNoticeDetail"
  >
    <div class="header">
      <div class="info-bar">
        <div class="left-wrap creator-wrap">
          <NovaCreatorCell
            :user-name="creatorStore.profile?.userNcnm"
            :portrait="creatorStore.profile?.userProflUrl"
            :post-create-at="source?.sysRegistDt"
            :post-view-count="source?.ntbdRdcnt"
          />
        </div>
        <NovaBoxCreatorNoticeAction
          v-if="
            viewType !== 'detail' &&
            userStore.user?.userSn === creatorStore.profile?.userSn
          "
          :popover-append-target="null"
          :post-sn="source?.crtrNttSn"
          :post-writer-sn="creatorStore.profile?.userSn"
        />
      </div>
    </div>

    <div class="contents">
      <h4 v-dompurify-html="source.crtrNttSj" class="post-title" />

      <NovaBoxPostContentsSnapshotContents
        :contents="source?.crtrNttCn"
        :view-type="viewType"
        class="post-contents"
      />
    </div>
  </article>
</template>

<style lang="scss" scoped>
.post {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  //max-height: 634px;
  padding: 20px;
  border-radius: $radius-10;
  background-color: $color-bg-3;
  box-shadow: 5px 5px 10px 0 hex-to-rgba($color-bg-custom-4, 0.05);
  @include transition(box-shadow 0.2s);

  &.view-type {
    &-list {
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          box-shadow: 0 5px 10px 10px hex-to-rgba($color-bg-custom-4, 0.05);
        }
      }
    }

    &-detail {
      //max-height: 100%;
      //.contents .post-contents { height: auto; }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .info-bar {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;

      .left-wrap {
        display: flex;
        align-items: center;

        .creator-badge {
          margin-left: 2px;
        }

        .create-at {
          margin-left: 20px;
          @include text-style($text-body-11-medium);
          color: $color-text-3;
        }

        > .follow {
          flex-shrink: 0;
          padding: 0;
          margin-left: 20px;
          @include text-style($text-body-11-medium);

          &.status {
            &-required-signin {
              color: $color-black;
            }

            &-follow {
              color: $color-sky-blue;
            }

            &-following {
              color: $color-text-3;
            }
          }
        }
      }

      .meta-info {
        display: flex;
        gap: 20px;

        .meta {
          @include text-style($text-body-14-regular);
          color: $color-text-3;
        }
      }
    }
  }

  .contents {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;

    .post-title {
      flex-shrink: 0;
      @include text-style($text-heading2-bold);
      color: $color-text-2;
      @include ellipsis(2);
    }

    .post-contents {
      flex-grow: 1;
    }

    > .extras {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }
  }
}
</style>
