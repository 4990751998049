<script setup lang="ts">
import dayjs from 'dayjs'
import { AxiosError } from 'axios'
import { Tippy } from 'vue-tippy'
import type { NovaBoxFundProjectDetailProps } from './NovaBoxFundProjectDetail.types'
import { HOUR } from '@configs'
import { NovaModalDialog } from '#components'

const props = withDefaults(defineProps<NovaBoxFundProjectDetailProps>(), {
  isDetail: false,
  showVoteButton: true,
})

const { t, locale } = useI18n()
const fundStore = useFundStore()
const layoutStore = useLayoutStore()
const { show: modalShow, hide: modalHide } = useModal()
const { userStore, showSignInDialog } = useMembershipProcess()
const tippyRef = ref()
const isKo = computed(() => locale.value === 'ko')
const projectSeason = computed(() => {
  if (props.source.suptPrjctSlctnDt) {
    const forceKoDate = dayjs(
      new Date(props.source.suptPrjctSlctnDt).getTime() + HOUR * 9
    )
    return t('fund.project.season', {
      year: forceKoDate.format('YYYY'),
      month: forceKoDate.format(isKo.value ? 'MM' : 'MMMM'),
    })
  } else {
    return ''
  }
})

// 공유 패널 닫기
const handleOnSharePanelClose = () => {
  // tippyRef.value?.hide()
}

// 투표하기
const handleOnVote = async () => {
  // 1. 비 로그인 상태일때 로그인 확인 다이얼로그 호출
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }

  // 2. 로그인 상태일때 투표 컨펌 다이얼로그 호출
  await modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_DIALOG,
      btns: [
        {
          label: t('fund.voteDialog.actions.negative'),
          theme: 'secondary-gray-200',
          size: 32,
          onClick: async () => {
            await modalHide(modalsName.MODAL_DIALOG)
          },
        },
        {
          label: t('fund.voteDialog.actions.positive'),
          theme: 'primary-blue-dark',
          size: 32,
          onClick: async () => {
            await modalHide(modalsName.MODAL_DIALOG)
            await reqFundProjectVote()
          },
        },
      ],
    },
    slots: {
      title: t('fund.voteDialog.title', {
        creatorNickname: props.source.crtrNcnm,
      }),
      content: t('fund.voteDialog.message'),
    },
  })
}

const reqFundProjectVote = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    await fundStore.reqFundProjectVote({
      crfdSurveyPrjctSn: props.source.crfdSurveyPrjctSn,
      crfdSurveySn: props.source.crfdSurveySn,
      svcActCode: 'CVTA',
    })
    useToast(t('fund.voteRequest.success'))
  } catch (err) {
    const _err = err as AxiosError
    if (_err.response?.status) {
      switch (_err.response.status) {
        case 8045:
        case 8046:
        case 8047:
          useToast(t(`fund.voteRequest.error.${_err.response.status}`))
          break
        default:
          useToast(t('fund.voteRequest.error.default'))
          break
      }
    }
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}
</script>

<template>
  <div :class="['project-detail', { 'on-detail': isDetail }]">
    <div class="header">
      <div v-if="projectSeason" class="project-season">
        {{ t('fund.histories.fundNo', { fundNo: source.crfdSuptCycleOdr }) }}
        {{ projectSeason }}
      </div>

      <div class="apply-user">
        <NovaImageContainer :ratio="'1:1'" :image-url="source.proflFileUrl" />
        <div class="creator-name">{{ source.crtrNcnm }}</div>
      </div>
    </div>

    <div class="body">
      <h4 class="title">
        {{
          useFindLocaleByNamespace({
            nameSpace: 'prjctSjNm',
            model: source,
          })
        }}
      </h4>

      <div class="contents">
        <NovaMediaContainer
          :image="
            source.prjctDcFileMediaTyCode === 'I'
              ? { source: source.prjctDcFileUrl }
              : undefined
          "
          :video="
            source.prjctDcFileMediaTyCode === 'V'
              ? {
                  source: source.prjctDcFileUrl,
                  poster: source.prjctThumbFileUrl,
                }
              : undefined
          "
          :auto-play="true"
          :preload="'auto'"
        />

        <div
          v-dompurify-html="
            useFindLocaleByNamespace({
              nameSpace: 'prjctDc',
              model: source,
            })
          "
          class="description"
        />
      </div>

      <hr />

      <div class="contents">
        <NovaMediaContainer
          :image="
            source.crtrFileMediaTyCode === 'I'
              ? { source: source.crtrFileUrl }
              : undefined
          "
        />

        <div
          v-dompurify-html="
            useFindLocaleByNamespace({
              nameSpace: 'crtrDc',
              model: source,
            })
          "
          class="description"
        />
      </div>

      <NovaDivider />

      <div :class="['actions', { 'align-end': !showVoteButton }]">
        <NovaButtonSupport
          v-if="showVoteButton"
          :label="$t('fund.project.support')"
          :disabled="source.lastSvcActCode === 'CVTA'"
          @click.stop="handleOnVote"
        />

        <Tippy
          ref="tippyRef"
          :append-to="'parent'"
          :interactive="true"
          :placement="'bottom-end'"
          :theme="'popover'"
          @click.stop="handleOnSharePanelClose"
        >
          <NovaBoxPostAction
            :icon="{ type: 'outline', name: 'more-horizontal' }"
            :display-number="-1"
            :icon-size="16"
            :action-type="'share'"
          />
          <template #content>
            <NovaBoxPostActionSharePanel
              :contents-type="'fundProject'"
              :show-navigation="false"
              :cmty-ntt-sn="source.crfdSurveyPrjctSn"
              :post-title="source.crtrNcnm"
              :post-contents="source.prjctDc"
              :post-image="source.proflFileUrl"
              :share-count="0"
            />
          </template>
        </Tippy>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.project-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: $color-bg-3;

  &.on-detail {
    border-radius: $radius-10;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .project-season {
      @include text-style($text-display-bold);
    }

    .apply-user {
      @include text-style($text-heading3-bold);
      color: $color-text-2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      .creator-name {
        word-break: break-all;
      }
      .image-container {
        width: 50px;
        border-radius: 25px;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      @include text-style($text-heading2-bold);
      color: $color-text-2;
    }

    .contents {
      display: flex;
      flex-direction: column;

      .description {
        word-break: break-all;
        :deep(h2) {
          @include text-style($text-heading3-bold);
          padding-top: 30px;
          padding-bottom: 10px;
        }
        :deep(h3) {
          @include text-style($text-body-14-bold);
          padding-bottom: 10px;
        }
        .small-title {
          font-size: 18px;
          font-weight: 700;
          padding-bottom: 10px;
        }
        * {
          @include text-style($text-body-14-regular);
          color: $color-text-2;
        }
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    &.align-end {
      justify-content: end;
    }

    .project-support-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 112px;
      height: 32px;
      background: #ffed00;
      border: 2px solid #ffed00;
      overflow: hidden;
      border-radius: $radius-10;
      .label {
        display: block;
        width: 98%;
        padding: 0 5px;
        border: 2px solid rgba(0, 0, 0, 0.6);
        color: #000;
        text-shadow: 1px 1px 1px #fff;
        border-radius: 12px;
      }
    }
  }
}
</style>
