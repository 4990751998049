export const mainTop = {
  mainTopLink: {
    guide: 'เกี่ยวกับ I LIKE LM',
    reward: 'คู่มือรางวัล',
    event: 'กิจกรรม',
    support: 'สนับสนุนกองทุน',
  },
  topBanner: {
    titleSub: 'แพลตฟอร์ม Web3 Creator FAM',
    title: 'ที่ซึ่งผู้สร้างและแฟน ๆ เชื่อมต่อ เติบโต และอยู่ร่วมกัน',
  },
}
