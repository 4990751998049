<script setup lang="ts">
import type { NovaLayoutMainProps } from './NovaLayoutDefault.types'

const props = withDefaults(defineProps<NovaLayoutMainProps>(), {
  fixWidth: false,
  pageMode: false,
  mobileFlexColumn: false,
  mobileFlexColumnNoPd: false,
})

const slots = useSlots()
const layoutStore = useLayoutStore()
const mainRef = ref<Element | null>(null)
const leftRef = ref<Element | null>(null)
const rightRef = ref<Element | null>(null)
const scrollLock = ref(false)

onMounted(() => {
  if (!props.pageMode) return
  setSideBarHeight()
  window.addEventListener('resize', setSideBarHeight)
})

onUnmounted(() => {
  window.removeEventListener('resize', setSideBarHeight)
})

useListen('main:scrollLock', (_scrollLock) => {
  scrollLock.value = _scrollLock
})

const setSideBarHeight = () => {
  const winHeight = window.innerHeight
  const headerHeight = layoutStore.headerRect?.height || 0

  leftRef.value?.setAttribute(
    'style',
    `height: ${winHeight - headerHeight}px; top: ${headerHeight}px`
  )
  rightRef.value?.setAttribute(
    'style',
    `height: ${winHeight - headerHeight}px; top: ${headerHeight}px`
  )
}
</script>

<template>
  <main
    ref="mainRef"
    :class="[
      'main',
      { 'page-mode': pageMode },
      { 'mobile-flex-column': mobileFlexColumn },
      { 'mobile-flex-column-no-pd': mobileFlexColumnNoPd },
      { 'bg-white': layoutStore.mainBgWhite },
      { 'scroll-lock': scrollLock },
    ]"
  >
    <div :class="['inner', { fixed: fixWidth }]">
      <div
        v-if="slots.left"
        ref="leftRef"
        class="left"
        :style="pageMode && { top: `${layoutStore.headerRect?.height || 0}px` }"
      >
        <slot name="left" />
      </div>

      <div class="center">
        <slot name="header" />
        <slot name="center" class="page" />
      </div>

      <div
        v-if="slots.right"
        ref="rightRef"
        class="right"
        :style="{ top: `${layoutStore.headerRect?.height || 0}px` }"
      >
        <slot name="right" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.main {
  display: flex;
  justify-content: center;
  background-color: $color-bg-2;

  &.bg-white {
    background-color: $color-bg-3;
  }

  &.page-mode {
    > .inner {
      height: fit-content;

      .left {
        position: sticky;
        top: 0;
      }

      > .center {
        height: auto;
        //overflow: visible;
      }

      .right {
        position: sticky;
        top: 0;
      }
    }
  }

  &.scroll-lock {
    overflow: hidden !important;
  }

  > .inner {
    @include inner-base;

    &.fixed {
      max-width: calc(900px + 16px + 16px);
    }

    .left {
      @include inner-left-base;
    }

    .center {
      @include inner-center-base;
    }

    .right {
      @include inner-right-base;
    }
  }

  @include tablet {
    .inner {
      max-width: $base-tablet-main-max-width;

      .right {
        display: none;
      }
    }
  }

  @include mobile {
    .inner {
      padding: 0;

      .left {
        display: none;
      }
    }

    &.mobile-flex-column {
      &.mobile-flex-column-no-pd {
        > .inner .left {
          padding: 0;
        }
      }

      > .inner {
        flex-direction: column;
        gap: 5px;

        .left {
          position: relative;
          display: flex;
          width: 100%;
          height: auto !important;
          top: auto !important;
          padding: 15px 20px 0;

          :deep(.left-side-bar) {
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
