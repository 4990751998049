<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { ProfileByReferral, ProfileByReferralPayload } from '@store'

defineOptions({
  inheritAttrs: false,
})

const router = useRouter()
const { hide: modalHide } = useModal()
const { userStore, openMembershipServicePopup } = useMembershipProcess()
const show = ref(false)
const isLoading = ref(false)
const isFail = ref(false)
const profile = ref<ProfileByReferral | null>(null)
const referralCode = ref<ProfileByReferralPayload['referralCode'] | null>(null)

// 팝업 열릴때 호출하는 쪽에서 파라미터 받아 레퍼럴 코드로 사용자 프로필 정보 조회
const handleOnGetParams = async (evt: RouteParams) => {
  referralCode.value =
    evt.value as unknown as ProfileByReferralPayload['referralCode']

  try {
    isLoading.value = true
    isFail.value = false
    profile.value = await userStore.fetchProfileByReferral({
      referralCode: referralCode.value,
    })
  } catch {
    isFail.value = true
  } finally {
    isLoading.value = false
  }
}

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_REFERRAL)
}

// 팝업 닫은 후 초기화
const handleOnClosed = async () => {
  await router.replace({ query: undefined })
}

// 레퍼럴 회원가입 프로세스 시작
const handleOnGoReferralSignup = async () => {
  await openMembershipServicePopup.signup(referralCode.value!)
  await handleOnClose()
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_REFERRAL"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
    @closed="handleOnClosed"
  >
    <ClientOnly>
      <NovaLoadingIndicator
        v-if="isLoading"
        :fill="true"
        :bg-bright="'dark'"
        class="async-status-message"
      />

      <NovaBoxBase v-if="!isLoading && show" class="modal-box">
        <div class="modal-header">
          <h4 class="title">
            {{ $t('referral.signUp.dialog.referralAble.title') }}
          </h4>

          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="20"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <div class="modal-body">
          <div v-if="profile && !isFail" class="referral-signup-guide">
            <div class="contents">
              <NovaUserCell
                :user-name="profile.userNcnm"
                :portrait="profile.userProflUrl"
              />

              <p
                v-dompurify-html="
                  $t('referral.signUp.dialog.referralAble.description')
                "
                class="description"
              />
            </div>

            <NovaDivider />

            <p class="mobile-access-guide">
              {{ $t('referral.signUp.dialog.referralAble.mobileGuide') }}
            </p>
          </div>

          <NovaBoxEmptyMessage
            v-if="isFail"
            :title="$t('referral.signUp.error.title')"
            :message="$t('referral.signUp.error.message')"
            :fill="true"
            class="async-status-message"
          />
        </div>

        <div v-if="profile && !isFail" class="modal-actions">
          <NovaButtonText
            :label="$t('cancel')"
            :theme="'secondary-gray-200'"
            :size="32"
            :full-width="true"
            @click="handleOnClose"
          />
          <NovaButtonText
            :label="$t('confirm')"
            :theme="'primary-blue-dark'"
            :size="32"
            :full-width="true"
            @click="handleOnGoReferralSignup"
          />
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 240px;
  max-width: 350px;
  pointer-events: none;

  .modal-box {
    width: 100%;
    max-width: 420px;
    max-height: calc(100% - 80px);
    padding: 0;
    pointer-events: auto;
    overflow: hidden;

    .modal-header {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 20px;
      color: $color-text-2;
      background-color: $color-bg-3;
      border-bottom: 1px solid $border-1;

      .title {
        @include text-style($text-display-bold);
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
      padding: 24px;

      .referral-signup-guide {
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: $color-text-2;

        > .contents {
          display: flex;
          flex-direction: column;
          gap: 6px;

          > .description {
            @include text-style($text-body-14-regular);
            word-break: break-all;
          }
        }

        > .mobile-access-guide {
          @include text-style($text-body-13-regular);
          color: $color-primary-blue;
        }
      }
    }

    .modal-actions {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      gap: 4px;
      padding: 24px;
    }
  }
}
</style>
