<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import {
  BlockAccessType,
  type BlockAccessModalParam,
} from './NovaModalBlockAccess.types'

defineOptions({
  inheritAttrs: false,
})

const { t, locale } = useI18n()
const show = ref(false)
const { hide: modalHide } = useModal()
const { gmt } = useDateFormat()
const userStore = useUserStore()
const blockAccessType = ref<BlockAccessType>(BlockAccessType.ACTIVITY)
const onClickConfirm = ref<() => void>()
const blockPeriod = computed(() => {
  if (userStore.user?.svcActSanctnsAt !== 'Y') return ''

  // 영구제재
  if (userStore.user?.svcActPrmanentSanctnsAt === 'Y')
    return t('blockAccess.eternalBlocked')

  const blockDateFormat = gmt({
    date: userStore.user?.svcActSanctnsRelisDt || '',
    prefixFormat:
      locale.value === 'ko' ? 'YYYY년 MM월 DD일 HH:mm' : 'MMMM.DD-YYYY HH:mm',
  })

  return (userStore.user.svcActSanctnsDaycnt || 0) > 0
    ? `~${blockDateFormat} (${userStore.user.svcActSanctnsDaycnt}${t(
        'units.days'
      )})`
    : `~${blockDateFormat}`
})
const message = computed(() => {
  switch (blockAccessType.value) {
    case BlockAccessType.ACTIVITY:
      return t('blockAccess.modal.contents.activity')
    case BlockAccessType.SIGNIN:
      return t('blockAccess.modal.contents.signin', {
        period: blockPeriod.value,
      })
    default:
      return ''
  }
})

const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_BLOCK_ACCESS)
}

const handleOnGetParams = (evt: RouteParams) => {
  const params = evt.value as unknown as BlockAccessModalParam

  blockAccessType.value = params.blockAccessType
  onClickConfirm.value = params.onClickConfirm
}

const handleOnClickConfirm = () => {
  onClickConfirm.value?.()
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_BLOCK_ACCESS"
    :content-class="'modal-content'"
    :click-to-close="false"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" :class="['modal-box', `${blockAccessType}`]">
        <div class="modal-header">
          <div class="title">
            <span v-dompurify-html="t('blockAccess.modal.title')" />
          </div>
          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="24"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>

        <div class="modal-body">
          <div v-dompurify-html="message" class="message" />
        </div>

        <div class="modal-actions">
          <NovaButtonText
            :label="t('confirm')"
            :theme="'primary-blue-dark'"
            :size="40"
            :width="198"
            :full-width="false"
            @click="handleOnClickConfirm"
          />
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 538px;
    min-height: 260px;
    border-radius: 20px !important;
    pointer-events: auto;

    @include mobile {
      min-height: auto;
    }

    &.activity {
      max-width: 505px;
    }

    &.signin {
      max-width: 740px;
    }

    .modal-header {
      position: relative;
      padding: 24px;
      @include mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
      }

      .title {
        text-align: center;
        @include text-style($text-heading2-bold);
      }

      .btn-close {
        position: absolute;
        top: 30px;
        right: 30px;
        color: $color-black;
        z-index: 50;
        @include mobile {
          top: 14px;
          right: 20px;
        }
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: hidden;
      padding: 24px;
      gap: 5px;

      .message {
        text-align: center;
        @include text-style($text-heading3-regular);
        color: $color-black;
      }
    }
    .modal-actions {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      gap: 30px;
      padding: 24px;
    }
  }
}
</style>
