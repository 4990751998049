import { corporationInfo } from './corporationInfo'
import { kyc } from './kyc'
import { profileControl } from './profile-control'
import { donateAction } from './donate-action'
import { creatorGrade } from './creatorGrade'
import { applyCreator } from './applyCreator'
import { applyKpopCoverDance } from './applyKpopCoverDance'
import { commonError } from './commonError'
import { referral } from './referral'
import { creatorHomeLink } from './creatorHomeLink'
import { creatorGuestBook } from './creatorGuestBook'
import { creatorNotice } from './creatorNotice'
import { creatorHome } from './creator'
import { share } from './share'
import { mypage } from './mypage'
import { dialog } from './dialog'
import { userSummery } from './user-summery'
import { creatorMyPage } from './creatorMyPage'
import { home } from './home'
import { navigations } from './navigations'
import { w3cf } from './w3cf'
import { subscribe } from './subscribe'
import { pay } from './pay'
import { accountPanel } from './account-panel'
import { notificationsPanel } from './notificationsPanel'
import { fund } from './fund'
import { timeLimitPopup } from './time-limit-popup'
import { sponsors } from './sponsors'
import { auction } from './auction'
import { terminateApp } from './terminate-app'
import { requestAppPermission } from './request-app-permission'
import { pullToRefresh } from './pullToRefresh'
import { notificationPermission } from './notification-permission'
import { sendbird } from './sendbird'
import { notificationSetting } from './notification-setting'
import { blockMessage } from './block-message'
import { homeActions } from './home-actions'
import { dailyCheck } from './daily-check'
import { blockAccess } from './block-access'
import { postCreate } from './postCreate'
import { serviceBenefits } from './serviceBenefits'
import { rewardPopup } from './rewardPopup'
import { mainTop } from './main-top'
import { aiContents } from './aiContents'
import { menus } from './menus'
import { onboarding } from './onboarding'
import { dao } from './dao'
import type {
  CommunityMenu,
  FeedMenu,
} from '@components/NovaHeaderMenuPanelMobileView/NovaHeaderMenuPanelMobileView.types'

import type {
  PostsOrderBy,
  PostPrivateAction,
  CommentsAndRepliesOrderBy,
  CommentAndReplyPrivateActionType,
  SearchAllItemType,
  NoticeAction,
  DaoPostPrivateAction,
} from '@store'

export default {
  message: '안녕',
  confirm: '확인',
  cancel: '취소',
  save: '저장',
  delete: '삭제',
  reg: '등록',
  modify: '수정',
  goHome: '홈으로 가기',
  goMyHome: '마이홈 가기',
  goCreatorHome: '크리에이터홈 가기',
  myTotalRewards: '나의 총 리워드',
  rewardsGate: '나의 총 리워드',
  useReward: '리워드 사용하기',
  kycBeforeUseReward: 'KYC하고 리워드 사용하기',
  helpCenter: '{appName} Help Center',
  attendanceCheck: '출석 체크',
  displayCount: '조회수',
  category: '카테고리',
  allView: '전체보기',
  sign: {
    in: '로그인',
    up: '회원가입',
    out: '로그아웃',
    off: '탈퇴하기',
  },
  myRewards: '내 활동 및 리워드',
  linkMyHome: '마이 홈',
  settings: '설정',
  servicesAndPolicies: '서비스 및 정책',
  servicesAndPoliciesMenus: {
    servicesMenu: '서비스',
    termsMenu: '정책',
  },
  servicesMenu: {
    leisureMetaVerse: '레저메타버스',
    theMoonLabs: '더문랩스',
  },
  service: '서비스',
  profile: '프로필',
  services: {
    leisureMetaVerse: '레저메타버스',
    theMoonLabs: '더문랩스',
  },
  cs: '고객문의',
  accountInfo: '프로필 수정',
  account: '프로필 수정',
  defaultSettings: '기본설정',
  notification: '알림',
  notifications: '알림',
  notificationAndSetting: '알림 설정',
  selectNotificationSound: '알림음 선택',
  language: '언어',
  languageSettings: '언어 설정',
  korean: 'KOR',
  english: 'ENG',
  thai: 'THA',
  spanish: 'ESP',
  policy: '정책',
  termsAndPolicies: '이용약관 및 정책',
  name: '이름',
  email: '이메일',
  nickName: '닉네임',
  birthday: '생년월일',
  simpleIntroduce: '한줄소개',
  recommenderEmail: '추천인 이메일',
  changePassword: {
    title: '비밀번호',
    change: '변경하기',
  },
  referralLabel: '추천인',
  checkDuplicate: '중복 체크',
  referralEmail: '추천인 이메일',
  referralReg: '추천인 등록',
  membershipOff: '회원탈퇴',
  suggestSignUpCopy: '챌린지로 코인 부자 되기!',
  apply: '신청',
  comments: '댓글',
  commentsExpand: '댓글 펼치기',
  commentsCollapse: '댓글 접기',
  commentStatus: {
    hide: '숨겨진 댓글입니다.',
    report: '신고된 댓글입니다.',
    delete: '삭제된 댓글입니다.',
    signOff: '삭제된 댓글입니다.',
  } as { [Property in CommentAndReplyPrivateActionType]: string },
  replies: '답글',
  postAction: '포스트 액션',
  createPost: '포스트 작성',
  editPost: '포스트 수정',
  createNotice: '공지사항 작성',
  modifyNotice: '공지사항 수정',
  viewRelatedPosts: '관련 포스트 보기',
  event: '이벤트',
  notice: '공지사항',
  answNotifyDescription: '댓글 및 댓글 액션에 대한 알림',
  evntNotifyDescription: '이벤트 알림',
  ntceNotifyDescription: '공지사항 알림',
  postNotifyDescription: '일반 포스트 액션에 대해 알림',
  dntnNotifyDescription: '일반, {subscribeGrade} 포스트 후원에 대한 알림',
  cprpNotifyDescription: '{subscribeGrade} 포스트 액션에 대한 알림',
  cppsNotifyDescription: '{subscribeGrade}에 가입한 후원자에 대한 알림',
  faq: '자주묻는 질문',
  hashtag: '해시태그',
  hashtagRanking: '해시태그 랭킹',
  searchHashtag: '검색',
  searchPlaceholder: '검색어를 입력해 주세요',
  seeMore: '더보기',
  creator: '크리에이터',
  linkCreatorHome: '크리에이터 홈',
  creatorHomeLink,
  creatorGuestBook,
  creatorNotice,
  creatorGrade,
  creatorHome,
  follower: '팔로워',
  follow: '팔로우',
  following: '팔로잉',
  donate: '후원하기',
  donateAction,
  gapTime: {
    underHour: '방금전',
    underDay: '시간전',
    underWeek: '일전',
  },
  showTermDetail: '보기',
  agreeAllTerms: '전체동의',
  essentialTerm: '[필수]',
  optionalTerm: '[선택]',
  rewards: {
    title: '일일 챌린지로 리워드받기',
    description: '목표를 달성하고 리워드를 듬뿍 받아가세요!',
    goRewards: '나의 리워드 보러가기',
    certificationKyc: 'KYC하고 리워드 사용하기',
    useRewards: '리워드 사용하기',
    rewardsCopy: {
      article:
        '글 1개 작성 시<br />평균 <span class="text-color-primary-blue력 text-bold">{lm} LM</span>을 리워드로 받았습니다.',
      comment:
        '댓글 1개 작성 시<br />평균 <span class="text-color-primary-blue text-bold">{lm} LM</span>을 리워드로 받았습니다.',
      post: '포스트 1개 작성 시<br />평균 <span class="text-color-primary-blue text-bold">{lm} LM</span>을 리워드로 받았습니다.',
    },
    lastWeekAverageLM:
      '※ 지난주 평균 LM 가격 <span class="text-color-2 text-medium">{price}</span>원',
  },
  postRewards: {
    expected: '예상리워드',
    total: '총 리워드',
  },
  postRecommend: {
    like: 'Like',
    disLike: 'Dislike',
  },
  postPrivateAction: {
    share: '공유하기',
    scrap: '스크랩하기',
    cancelScrap: '스크랩 취소',
    // hide: '숨기기',
    report: '포스트 신고',
    edit: '수정',
    remove: '삭제',
    donate: '후원하기',
  } as { [Property in PostPrivateAction]: string },
  daoPostPrivateAction: {
    share: '공유하기',
    // report: '포스트 신고',
    edit: '수정',
    remove: '삭제',
  } as { [Property in DaoPostPrivateAction]: string },
  postRemoveConfirmMessage: '포스트를 삭제하시겠습니까?',
  noticeAction: {
    modify: '수정',
    remove: '삭제',
  } as { [Property in NoticeAction]: string },
  noticeRemoveConfirmMessage: '공지사항을 삭제하시겠습니까?',
  searchMenu: '메뉴검색',
  feeds: '피드',
  feedsMenu: {
    forYouPost: '추천 포스트',
    recentPost: '최근 포스트',
    followingPost: '팔로잉 포스트',
  } as { [Property in FeedMenu]: string },
  community: '커뮤니티',
  communityMenus: {
    // best: 'BEST',
    // basicGuide: '기본 가이드',
    // insight: '인사이트',
    // allTemplate: '모든 템플릿',
    categories: '카테고리',
  } as { [Property in CommunityMenu]: string },
  etc: '기타',
  etcMenus: {
    event: '이벤트',
    notice: '공지사항',
    faq: '자주묻는 질문',
  },
  report: {
    inappropriate: '부적절한 포스트 (불쾌하거나 폭력 등의 문제)',
    false: '허위 내용',
    spam: '스팸',
    etc: '기타',
  },
  reportForm: {
    title: '포스트 신고',
    dropdownPlaceholder: '신고분류',
    textareaPlaceholder: '내용을 입력하세요.',
  },
  reportAction: {
    cancel: '취소',
    send: '신고',
  },
  statusMessages: {
    searchKeyword: {
      empty: '검색어를 입력해 주세요.',
    },
    search: {
      success: "<strong>'{keyword}'</strong> 검색 결과",
      empty: '{keyword} 검색 결과가 없습니다.',
      error: '{keyword}를 검색할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    menus: {
      empty: '{menu} 메뉴가 없습니다.',
    },
    postsByHashtagSimple: {
      empty: '{hashtag} 포스트가 없습니다.',
      error:
        '{hashtag} 포스트를 조회할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    categories: {
      empty: '조회 가능한 카테고리가 없습니다.',
    },
    hashtagRanking: {
      empty: '조회 가능한 해시태그가 없습니다.',
    },
    tempList: {
      empty: '임시저장 목록이 없습니다.',
      error: '임시저장 목록을 조회할 수 없습니다. 다시 시도해 주세요.',
    },
    recommendAndUnRecommend: {
      recommend: {
        success: '좋아요 하였습니다.',
        error: '좋아요 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
        2037: '자동화봇으로 인식되어 처리에 실패했습니다. 새로고침 후 다시 시도해 주세요.',
        8048: '일정 시간 내에 동일 활동을 반복할 수 없습니다.',
        8050: '내가 작성한 포스트에는 ‘좋아요’를 할 수 없습니다.',
      },
      recommendCancel:
        '좋아요를 취소할 수 없습니다. 잠시 후 다시 시도해 주세요.',
      unRecommend: '싫어요 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
      unRecommendCancel:
        '싫어요를 취소할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    postScrap: {
      success: '스크랩 되었습니다.',
      error: '스크랩 할수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    postScrapCancel: {
      success: '스크랩을 취소하였습니다.',
      error: '스크랩을 취소할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    postHide: {
      error: '포스트를 숨길 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    postReport: {
      success: '포스트 신고가 정상적으로 접수되었습니다.',
      errors: {
        2000: '없는 포스트 입니다.',
        2006: '신고분류를 선택 해주세요.',
        2010: '이미 신고한 포스트 입니다.',
        2012: '본인은 신고가 불가합니다.',
        4002: '가입되지 않은 회원입니다.',
        2020: '이미 제재된 게시글 입니다.',
        unknown: 'Cannot report the post. Please try again later.',
      },
    },
    postRemove: {
      error: '포스트를 삭제할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    tempPostRemove: {
      error:
        '임시저장된 포스트를 삭제할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    mypageList: {
      reward: {
        empty: '리워드 내역이 없습니다',
        error: '리워드 내역을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
      },
      post: {
        empty: '등록된 포스트가 없습니다',
        error: '포스트를 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
      },
      comment: {
        empty: '댓글이 달린 포스트가 없습니다.',
        error:
          '댓글이 달린 포스트를 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
      },
      recommend: {
        get: {
          empty: '좋아요 받은 포스트가 없습니다',
          error:
            '좋아요 받은 포스트를 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
        },
        set: {
          empty: '좋아요 한 포스트가 없습니다',
          error:
            '좋아요 한 포스트를 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
        },
      },
      scrap: {
        empty: '스크랩한 포스트가 없습니다',
        error: '해당리스트를 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
      },
    },
    creatorList: {
      guest: {
        empty: '오픈 예정',
      },
      notice: {
        empty: '오픈 예정',
      },
    },
    creators: {
      empty: '등록된 크리에이터가 없습니다',
      error:
        '크리에이터 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    users: {
      empty: '등록된 유저가 없습니다',
      error: '유저 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    creatorFollow: {
      error: '크리에이터를 팔로우 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    creatorUnfollow: {
      error:
        '크리에이터를 언팔로우 할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    creatorHome: {
      invalidUrl: '크리에이터 주소를 다시 확인해주세요.',
    },
    recentSearches: {
      empty: '최근 검색어가 없습니다.',
    },
    postDetail: {
      error: {
        notFound: '삭제되었거나 찾을 수 없는 포스트입니다.',
        drop: '제제된 포스트입니다.',
        notSubscribed: '{subscribeGrade} 포스트에 대한 권한이 없습니다.',
        blocked: '호스트가 나를 차단하여 포스트에 접근할 수 없습니다.',
      },
    },
    comments: {
      empty: '등록된 댓글이 없습니다.<br />최초로 댓글을 등록해 주세요.',
      error: '댓글을 조회할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    commentsAdd: {
      empty: '{type} 내용이 없습니다.',
      errors: {
        8048: '일정 시간 내에 동일 활동을 반복할 수 없습니다.',
        default: '{type}을 수정할 수 없습니다. 잠시 후 다시 시도해 주세요.',
      },
    },
    commentsEdit: {
      empty: '{type} 내용이 없습니다.',
      error: '{type}을 등록할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    commentsDelete: {
      denied: '이미 삭제된 댓글입니다.',
      error: '댓글을 삭제할 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    commentsReport: {
      success: '댓글 신고가 정상적으로 접수되었습니다.',
      errors: {
        2000: '해당 댓글을 찾을 수 없습니다.',
        2006: '신고분류를 선택 해주세요.',
        2010: '이미 신고한 댓글 입니다.',
        2012: '본인은 신고가 불가합니다.',
        2020: '이미 제재 처리된 댓글입니다.',
        4002: '가입되지 않은 회원입니다.',
        unknown: '댓글을 신고할 수 없습니다. 잠시 후 다시 시도해 주세요.',
      },
    },
    commentsHide: {
      denied: '이미 숨겨진 댓글입니다.',
      error: '댓글을 숨길수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    boardList: {
      empty: '등록된 글이 없습니다.',
      error: '해당 리스트를 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    tableList: {
      empty: '데이터가 없습니다.',
      error: '해당 리스트를 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    retire: {
      loading:
        '회원탈퇴를 위한 자산조회에 시간이 소요될 수 있으니 잠시만 기다려주세요',
      error: '데이터를 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    notification: {
      empty: '알림이 없습니다.',
      error: '알림을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    network: {
      error: commonError.network.message,
    },
  },
  postCreate,
  noticeCreate: {
    placeholder: {
      public: '전체공개',
      tempSave: '임시저장 불러오기',
      title: '제목을 입력해주세요.',
      content: '내용을 입력해주세요.(필수)',
      hashtag: '#해시태그입력',
    },
    button: {
      tempSave: '임시저장',
      preview: '미리보기',
      save: '저장',
      confirm: '확인',
    },
    toastMessage: {
      tempSave: '임시저장이 되었습니다.',
      noLinkSelect: '링크할 텍스트를 선택해주세요.',
      havingLink: '이미 링크지정이 되었습니다.',
      validateTitle: '제목을 2자 이상 써주세요.',
      validateContents: '내용을 10자 이상 써주세요.',
      validateHashtagCharacter: '한글, 영문, 숫자만 입력 가능합니다.',
      validateHashtagCheckByte: '최대 30자까지 입력 가능합니다.',
      validateHashtagCount: '태그는 최대 5개까지 입력 가능합니다.',
      validateHashtagDuplicate: '중복된 태그가 있습니다.',
      imgError2005: '등록할 수 없는 확장자 입니다.',
      imgError: '이미지 업로드에 실패했습니다. 잠시후 다시 시도해주세요.',
      error: '요청 처리중 오류가 발생하였습니다. 잠시후 다시 시도해주세요.',
    },
    modal: {
      tempTitle: '임시저장 목록',
      previewTitle: '미리보기',
      dialogTitle: '삭제 확인',
      dialogContent:
        '임시 저장되지 않은 상태에서 닫을 경우 입력하신 정보가 삭제됩니다. 저장하지 않고 삭제할까요?',
    },
  },
  qna: {
    title: '고객문의',
    button: {
      cancel: '취소',
      confirm: '접수',
    },
    reportMessage: '*답변은 가입하신 이메일로 발송됩니다.',
    placeholder: {
      content: '내용을 입력하세요.',
      dropdown: '문의분류',
    },
    error: '문의 요청이 실패했습니다. 잠시 후 다시 요청해주세요.',
    dialog: {
      title: '문의 접수',
      success:
        '문의가 정상적으로 접수되었습니다.빠른시간 내에 메일 답변 드리겠습니다.',
    },
  },
  creatorMyPage,
  mypage,
  board: {
    sideTitle: '게시판',
    title: {
      notice: '공지사항',
      event: '이벤트',
      /* news: '소식', */
      faq: '자주 묻는 질문',
      terms: '서비스 및 정책',
    },
    label: {
      notice: '공지',
      list: '목록',
      prev: '이전글',
      next: '다음글',
      search: '검색',
      total: '전체',
    },
  },
  dialog,
  retire: {
    title: '회원탈퇴',
    password: {
      title: '비밀번호',
      content: {
        text: '회원 탈퇴를 하시려면 안내 및 동의가 필요합니다.',
        warning: '정말로 회원탈퇴를 진행하시겠습니까?',
      },
      validate:
        '비밀번호를 확인 후 다시 로그인해주세요 (5회이상 오류시 로그인 차단)',
    },
    guide: {
      type1: {
        subTitle: '회원 탈퇴하시기 전에 확인해 주세요!',
        list: [
          '탈퇴 후 발생하는 문제에 대한 모든 책임은 사용자에게 있습니다.',
          'I LIKE LM 서비스 탈퇴 시 연계 서비스인 LM Wallet 서비스에 가입되어 있으면 즉시 재가입할 수 있습니다',
          '기존에 사용하시던 동일 아이디로 재가입해도 이전 기록은 복구되지 않습니다. 이용시 주소를 반드시 확인하신 후 사용해주시기 바랍니다.',
          'I LIKE LM을 탈퇴할 때, LM Wallet 모두 탈퇴했으면, I LIKE LM 탈퇴 후 동일 아이디로 30일 동안 재가입을 할 수 없습니다',
          '30일 후 기존에 사용하시던 동일 아이디로 재가입하셔도 이전 기록(디지털 자산 포함)은 복구되지 않습니다. 이용시 주소를 반드시 확인하신 후 사용해주시기 바랍니다.',
        ],
        text: '회원 탈퇴를 하시려면 개인 정보 및 이용기록 삭제에 동의를 하셔야 합니다.',
        warning: '탈퇴하시겠습니까?',
        agree: '동의합니다',
        complete: '회원탈퇴 처리가 완료 되었습니다',
      },
      type2: {
        content: {
          text1:
            '회원님께서는 잔고 및 디지털 자산이 있어 즉시 탈퇴처리가 불가합니다.',
          text2:
            '탈퇴를 원하시면 고객센터(<em class="text bule">cs&#64;likelm.com</em>)을 통해 문의해주세요.',
        },
        button: {
          inquire: '문의글 남기기',
          home: '홈가기',
        },
        modal: {
          title: '회원탈퇴 문의',
        },
      },
    },
    inquire: {
      text: {
        title: '문의 내용',
        validate: '문의내용을 입력해주세요.',
        placeholder: '문의내용을 입력해주세요.',
      },
      email: {
        title: '이메일 주소',
        validate: {
          text: '답변이 이메일로 발송되니 정확하게 입력하세요',
          error: '답변 받을 이메일을 입력해주세요.',
        },
      },
      term: {
        title: '개인정보 수집, 이용 동의',
        content: {
          text1:
            '(주)플레이놈은 고객문이 처리를 위해 아래와 같은 목적으로 개인정보를 처리합니다.',
          text2:
            '더 자세한 내용은 I LIKE LM <em>개인정보처리방침</em>을 참고하시기 바랍니다.',
        },
        list: [
          {
            title: '수집항목',
            detail: '이메일 주소',
          },
          {
            title: '목적',
            detail:
              '고객 문의 및 답변 회신, 문의 처리를 위한 서비스 이용기록 조회',
          },
          {
            title: '보유기간',
            detail: '문의 접수 후 2년간 보관',
          },
        ],
        checkbox: '개인정보 수집 및 이용에 동의합니다.',
      },
      button: '문의하기',
      modal: '문의가 접수되었습니다.',
    },
  },
  sort: {
    'for-you': '추천순',
    newest: '최신순',
    'follow-newest': '팔로잉',
  } as { [Property in PostsOrderBy]: string },
  copyToClipboard: '클립보드에 링크가 복사되었습니다.',
  recentSearches: '최근 검색어',
  seeAllResult: '결과 모두 보기',
  searchResultItemType: {
    user: '유저',
    // TODO: 앱 버전 릴리즈를 위해 기능 숨김
    // premium: subscribe.premiumBrand,
    post: '포스트',
    comment: '댓글',
    hash: '해시태그',
  } as { [Property in SearchAllItemType]: string },
  writeComment: '댓글을 입력하세요.',
  doComment: '댓글달기',
  editComment: '댓글수정',
  cancelComment: '댓글취소',
  doReply: '답글달기',
  editReply: '답글수정',
  cancelReply: '답글취소',
  foldComment: '댓글접기',
  commentsCount: '댓글 {count}개',
  commentsAndRepliesSort: {
    newest: '최신순',
    oldest: '과거순',
    hot: '인기순',
  } as { [Property in CommentsAndRepliesOrderBy]: string },
  recommendOthersCount: '그외 {count}명',
  commentAndReplyPrivateAction: {
    // hide: '숨기기',
    report: '댓글 신고',
    delete: '삭제',
    edit: '수정',
  } as { [Property in CommentAndReplyPrivateActionType]: string },
  videoAddPanel: {
    videoTypeLabel: '동영상',
    urlLabel: '링크',
    urlPlaceholder: '링크를 입력해 주세요.',
    addBtn: '확인',
    descriptions: {
      youtube: [
        'https://www.youtube.com/watch?v=xxxxxxxxxxx',
        'https://www.youtu.be/xxxxxxxxxxx',
        'https://www.youtube.com/embed/xxxxxxxxxxx',
        '위 샘플 주소와 같은 형태의 링크를 입력해 주세요.',
      ],
      youtubeShorts: [
        'https://www.youtube.com/shorts/xxxxxxxxxxx',
        'https://www.youtube.com/embed/xxxxxxxxxxx',
        '위 샘플 주소와 같은 형태의 링크를 입력해 주세요.',
      ],
      vimeo: [
        'https://vimeo.com/xxxxxxxxx',
        'https://player.vimeo.com/video/xxxxxxxxx',
        '위 샘플 주소와 같은 형태의 링크를 입력해 주세요.',
      ],
    },
  },
  colorPickerPanel: {
    textColorLabel: '글자색',
    highlightColorLabel: '하이라이트',
  },
  linkAddPanel: {
    label: '링크',
    placeholder: '링크를 입력해 주세요.',
    addBtn: '확인',
  },
  corporationInfo,
  kyc,
  profileControl,
  events: {
    recommender: {
      title: '2차! 오픈 이벤트',
      recommenders: {
        empty: '랭킹 정보가 없습니다.',
        error:
          '랭킹 정보를 불러 올 수 없습니다. 잠시 후 다시 확인 부탁드립니다.',
      },
      rank: '랭킹',
      email: '이메일 주소',
      recommendCount: '좋아요 받은 수',
      recommendCountUnit: '명',
      myRank: '나의 랭킹',
      myRankStatus: {
        error:
          '나의 랭킹 정보를 불러 올 수 없습니다. 잠시 후 다시 시도해 주세요.',
      },
      currentTopRank: '현재 상위 랭크',
      rankCondition:
        '※ 동점자 발생시, <span class="highlight">포스팅수+댓글수</span> 에 대한 점수를 반영하여 순위가 결정됩니다.',
      guide: '당첨자 안내',
      guideDescription:
        '이벤트 당첨자에게는 이메일로 별도 안내가 있을 예정이며, 발표일자를 기준으로 <span class="highlight">[공지사항]</span>을 반드시 확인 부탁 드립니다.',
    },
  },
  accountSetting: {
    introduction: {
      placeholder: '계정 소개를 입력해주세요.',
    },
  },
  bottomSheet: {
    title: '클릭 한 번으로 편하게 접속하세요!',
    button: '앱으로 보기',
  },
  units: {
    days: '일',
  },
  required: '필수',
  optional: '선택',
  postReportNotification:
    '*악의적인 허위신고로 판단 시, -30점의 불이익이 적용되며 허위신고 횟수가 누적되면 활동이 제재될 수 있습니다.',
  applyCreator,
  applyKpopCoverDance,
  commonError,
  referral,
  share,
  userSummery,
  home,
  navigations,
  w3cf,
  subscribe,
  pay,
  accountPanel,
  notificationsPanel,
  fund,
  timeLimitPopup,
  sponsors,
  auction,
  terminateApp,
  requestAppPermission,
  pullToRefresh,
  notificationPermission,
  sendbird,
  notificationSetting,
  blockMessage,
  homeActions,
  dailyCheck,
  blockAccess,
  serviceBenefits,
  rewardPopup,
  mainTop,
  aiContents,
  menus,
  onboarding,
  dao,
} as { [key in string]: any }
