import env, { type Envs } from '../env'

const mode = (process.env.MODE || 'development') as Envs

interface MetaDefaultItem {
  title: string
  description: string
  keywords: string
}

/**
 * 절대 환경변수를 필요로 하는 메타 정보를 선언하지 말것
 */
export const metaDefault: { [Key: string]: MetaDefaultItem } = {
  ko: {
    title: 'I LIKE LM : K-POP 팬덤 커뮤니티',
    description:
      '창작의 열정을 보상으로, 응원의 힘을 보상으로 돌려드립니다. K-POP 팬덤 커뮤니티 l LIKE LM에서 당신의 가능성을 펼치고, 함께 성장해보세요. 지금 바로 참여하세요! 새로운 시대의 아티스트들을 기다립니다',
    keywords:
      'I LIKE LM, 아이라이크엘엠, DAO, Web3 아티스트, 레저메타, LeisureMeta, KPOP, 팬 커뮤니티, 팬덤, 창작, 콘텐츠, 수익화, LM, 더문랩스, The Moon Labs, 앱테크, N잡, AI 창작, 보상형 플랫폼, 활동 보상, k-culture, 토큰, K-POP, 한류, FAM, 레저메타코인, LM코인, 빗썸',
  },
  en: {
    title: 'I LIKE LM : K-POP Fandom Community',
    description:
      "Unlock your creative potential and join the  K-POP Fandom Community! With I LIKE LM, you'll find the reward, support and enthusiasm you need to thrive. Embrace the new era for Artists and become part of a dynamic, innovative community. Join us now and let's succeed together!",
    keywords:
      'I LIKE LM, 아이라이크엘엠, DAO, Web3 아티스트, 레저메타, LeisureMeta, KPOP, 팬 커뮤니티, 팬덤, 창작, 콘텐츠, 수익화, LM, 더문랩스, The Moon Labs, 앱테크, N잡, AI 창작, 보상형 플랫폼, 활동 보상, k-culture, 토큰, K-POP, 한류, FAM, 레저메타코인, LM코인, 빗썸',
  },
  th: {
    title: 'I LIKE LM : K-POP Fandom Community',
    description:
      "Unlock your creative potential and join the  K-POP Fandom Community! With I LIKE LM, you'll find the reward, support and enthusiasm you need to thrive. Embrace the new era for Artists and become part of a dynamic, innovative community. Join us now and let's succeed together!",
    keywords:
      'I LIKE LM, 아이라이크엘엠, DAO, Web3 아티스트, 레저메타, LeisureMeta, KPOP, 팬 커뮤니티, 팬덤, 창작, 콘텐츠, 수익화, LM, 더문랩스, The Moon Labs, 앱테크, N잡, AI 창작, 보상형 플랫폼, 활동 보상, k-culture, 토큰, K-POP, 한류, FAM, 레저메타코인, LM코인, 빗썸',
  },
  es: {
    title: 'I LIKE LM : K-POP Fandom Community',
    description:
      "Unlock your creative potential and join the  K-POP Fandom Community! With I LIKE LM, you'll find the reward, support and enthusiasm you need to thrive. Embrace the new era for Artists and become part of a dynamic, innovative community. Join us now and let's succeed together!",
    keywords:
      'I LIKE LM, 아이라이크엘엠, DAO, Web3 아티스트, 레저메타, LeisureMeta, KPOP, 팬 커뮤니티, 팬덤, 창작, 콘텐츠, 수익화, LM, 더문랩스, The Moon Labs, 앱테크, N잡, AI 창작, 보상형 플랫폼, 활동 보상, k-culture, 토큰, K-POP, 한류, FAM, 레저메타코인, LM코인, 빗썸',
  },
}

/**
 * nuxt config meta
 */
export const nuxtMetas = [
  {
    name: 'viewport',
    content:
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
  },
  {
    name: 'description',
    content: metaDefault.ko.description,
  },
  {
    name: 'keywords',
    content: metaDefault.ko.keywords,
  },
  {
    name: 'msapplication-TileImage',
    content: `${env[mode].APP_URL}/favicon-on-light/ms-icon-144x144.png`,
  },
  {
    name: 'naver-site-verification',
    content: 'e38bf22bc2a250125f65ac91464e8163e563f107',
  },
  { property: 'og:title', content: metaDefault.ko.title },
  { property: 'og:url', content: `${env[mode].APP_URL}` },
  {
    property: 'og:description',
    content: metaDefault.ko.description,
  },
  {
    property: 'og:image',
    content: `${env[mode].APP_URL}/ilikelm-og.png`,
  },
  { property: 'og:type', content: 'website' },
]
