<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { ShowPostDetailModalParams } from '@components/NovaBoxPost/NovaBoxPost.types'
import { NovaLayoutScrollContainer } from '#components'

defineOptions({
  inheritAttrs: false,
})

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const postStore = usePostStore()
const { hide: modalHide } = useModal()
const show = ref(false)
const postDetailRef = ref<InstanceType<
  typeof NovaLayoutScrollContainer
> | null>(null)
const isLoading = ref(false)
const isFail = ref(false)
const errorMessage = ref('')
const onClose = ref<ShowPostDetailModalParams['onClose']>()

watch(
  () => postStore.post,
  (cur) => {
    if (!cur && show.value) {
      handleOnClose()
    }
  }
)

// 팝업 열릴때 호출하는 쪽에서 파라미터 받아 포스트 상세 및 댓글 & 답글 조회
const handleOnGetParams = async (evt: RouteParams) => {
  const post = evt.value as unknown as ShowPostDetailModalParams
  onClose.value = post.onClose

  // post 조회
  try {
    isLoading.value = true
    isFail.value = false

    // 모달 호출 부모로 부터 포스트 모델을 주입받지 않았다면 조회
    if (!post.ssrPostData) {
      await postStore.fetchPost({ cmtyNttSn: post.postId })
    }
    // 모달 호출 부모로 부터 포스트 모델을 주입받았다면 스토어 업데이트
    else {
      postStore.updatePost(post.ssrPostData)
    }
    await postStore.fetchCommentsAndReplies(
      { ...postStore.commentsAndReplies.payload, cmtyNttSn: post.postId },
      true
    )
  } catch (err) {
    switch ((err as any).response.status) {
      case 2030:
        errorMessage.value = t(
          'statusMessages.postDetail.error.notSubscribed',
          { subscribeGrade: t('subscribe.subscribeGrade.novaPlus') }
        )
        break
      case 2020:
        errorMessage.value = t('statusMessages.postDetail.error.drop')
        break
      case 2034:
        errorMessage.value = t('statusMessages.postDetail.error.blocked')
        break
      default:
        errorMessage.value = t('statusMessages.postDetail.error.notFound')
        break
    }
    isFail.value = true
  } finally {
    isLoading.value = false
  }
}

/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '포스트 상세보기 닫기',
    eventLabel: 'close-extend',
    eventSlot: '포스트 상세보기 모달 닫기',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
  })

  onClose.value
    ? onClose.value()
    : await modalHide(modalsName.MODAL_POST_DETAIL)
}

/**
 * 팝업 닫은 후 스토어에서 포스트 상세 및 댓글 목록 초기화
 */
const handleOnClosed = () => {
  postStore.initPost()
  postStore.initCommentsAndReplies()
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_POST_DETAIL"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @closed="handleOnClosed"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaLoadingIndicator
        v-if="isLoading"
        :fill="true"
        :bg-bright="'dark'"
        class="async-status-message"
      />

      <NovaBoxBase v-if="!isLoading && show" class="modal-box">
        <div class="modal-header">
          <div class="on-mobile">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'arrow-left' }"
              :theme="'transparent'"
              :size="24"
              class="btn-close-on-mobile"
              @click="handleOnClose"
            />
          </div>

          <div class="on-desktop">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'close-extend' }"
              :theme="'transparent'"
              :size="24"
              :is-modal="true"
              class="btn-close"
              @click="handleOnClose"
            />
          </div>
        </div>

        <NovaLayoutScrollContainer
          ref="postDetailRef"
          class="modal-body post-detail"
        >
          <NovaBoxPost
            v-if="postStore.post && !isFail && !isLoading"
            :view-type="PostContentsType.DETAIL"
            :source="postStore.post"
            class="post"
          />

          <NovaBoxEmptyMessage
            v-if="isFail"
            :message="errorMessage"
            :fill="true"
            class="async-status-message"
          />

          <NovaScrollToTopButton
            :target="postDetailRef?.$el || null"
            :bottom-position="postStore.commentsAndReplies.last ? 16 : 64"
          />
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;

    .modal-header {
      flex-shrink: 0;
      height: 0;
      padding: 0;
      background-color: $color-bg-3;

      .on-mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        .btn-close-on-mobile {
        }
      }

      .on-desktop {
        .btn-close {
          position: absolute;
          top: 30px;
          right: 30px;
          color: $color-bg-3;
        }
      }
    }

    .modal-body {
      border-radius: $radius-20;

      > .post {
        border-radius: 0;
        box-shadow: none;
      }

      .async-status-message {
        min-height: 200px;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-header {
        flex-shrink: 0;
        height: 56px;
        padding: 0 20px;

        .on-mobile {
          display: flex;
        }

        .on-desktop {
          .btn-close {
            display: none;
          }
        }
      }

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
