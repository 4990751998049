import { AxiosError } from 'axios'

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore()
  const creatorStore = useCreatorStore()

  let creatorLink = ''

  try {
    if (
      !creatorStore.profile ||
      creatorStore.profile.creatorLink !== String(to.params.id).replace('@', '')
    ) {
      console.log('SERVER MIDDLEWARE')
      const { creatorLink: newCreatorLink } =
        await creatorStore.fetchProfileLink({
          creatorLink: String(to.params.id).replace('@', ''),
        })
      creatorLink = newCreatorLink
    } else {
      creatorLink = creatorStore.profile.creatorLink
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      throw showError({
        statusCode: err.response?.status,
        statusMessage: err.message,
      })
    } else {
      throw showError({
        statusCode: 500,
        statusMessage: 'creator page access fail',
      })
    }
  }

  if (process.client) {
    const accessAble =
      userStore.user && userStore.user?.creatorLink === creatorLink
    if (!accessAble) {
      throw showError({
        statusCode: 403,
        statusMessage: 'creator page access require login',
      })
    }
  }
})
