/**
 * KPOP Cover Dance 신청하기
 */
export const applyKpopCoverDance = {
  entry: 'Application Form',
  message: {
    err: 'This field is required.',
    invalidUrl: 'Invalid URL. Please enter a valid link.',
    representative:
      '* If under 18, a parent/guardian consent form is required.',
    errEmail: 'Invalid email format.',
    deleteMember: {
      title: 'Notice',
      contents: 'Are you sure you want <strong>to delete?</strong>',
      btn: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
    },
    confirmApply: {
      title: 'Notice',
      contents:
        '<strong style="font-size: 16px">Would you like to complete<br /> the application submission?</strong>' +
        '<br /><br />*Guidance will be sent to the representative email address.',
      btn: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
    },
    applicationCloses: {
      title: 'Notice',
      content: 'The registration period has ended.',
    },
  },
  member: {
    top: 'Member',
    name: {
      label: 'Name',
      placeholder: 'Name',
    },
    email: {
      label: 'Email',
      placeholder: 'Email',
    },
    phone: {
      label: 'Mobile Number',
      placeholder: '000-000-0000',
    },
    age: {
      label: 'Age',
      placeholder: 'Age',
      message: '* If under 18, a parent/guardian consent form is required.',
    },
    wallet: {
      label: 'Bitkub Wallet address',
      message: '* Please enter your wallet address to receive the airdrop.',
      message2: '*  Airdrop amount: LM token worth 100THB.',
    },
  },
  addMember: '+ Add Team Members',
  team: {
    label: 'Team Name',
    placeHolder: 'Name',
  },
  representative: {
    label: 'Representative',
  },
  introduce: {
    label: 'Introduction',
    video: {
      label: 'Introduction Video',
      placeHolder: 'Instagram, YouTube, TikTok',
      descriptions: [
        '<strong>Please provide the URL of your K-POP dance cover video.</strong>',
        'Please submit a cover dance video URL between 30 seconds to 1 minute.',
        'This video will be used for preliminary screening.',
      ],
    },
    motivation: {
      label: 'Motivation for application',
    },
  },
  applyBtn: 'Submit',
  eventDetail: {
    btn: {
      apply: 'Apply',
      complete: 'Complete',
    },
  },
  terms: {
    agreeAll: 'Full agreement of terms and conditions',
    view: 'View',
    confirm: 'Confirm',
    err: {
      title: 'Notice',
      content: 'There are terms and conditions that you did not agree with.',
    },
  },
}
