<script lang="ts" setup>
import type { RouteParams } from 'vue-router'
import type { ModalDaoVoteDetailParams } from './NovaModalDaoVoteDetail.types'
import type { Theme } from '@components/NovaButtonText/NovaButtonText.types'
import { NovaLayoutScrollContainer } from '#components'
import { ROUTES } from '@configs'

const route = useRoute()
//
defineOptions({
  inheritAttrs: false,
})

// const { gtEvent } = useGoogleTag()
const { hide: modalHide } = useModal()
const show = ref(false)
const myCollectionRef = ref<InstanceType<
  typeof NovaLayoutScrollContainer
> | null>(null)
const daoStore = useDaoStore()

const badgeThemeClass = ref<Theme>()
const statusClass = ref<string>('')
const badgeLabel = ref<string>('')

// 팝업 열릴때 호출하는 쪽에서 파라미터 받아 모델 업데이트
const handleOnGetParams = (evt: RouteParams) => {
  const params = evt.value as unknown as ModalDaoVoteDetailParams
  badgeThemeClass.value = params.badgeThemeClass
  statusClass.value = params.statusClass
  badgeLabel.value = params.badgeLabel
}

/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_DAO_VOTE_DETAIL)
  await useNavigations({
    url: useRoutePathIdChange(ROUTES.CREATOR_DAO_VOTE.path, {
      id: String(route.params.id),
    }),
    type: 'replace',
  })
}

/**
 * 팝업 닫은 후 스토어에서 DAO 투표 상세 초기화
 */
const handleOnClosed = () => {
  daoStore.refreshVoteDetail()
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :content-class="'modal-content'"
    :name="modalsName.MODAL_DAO_VOTE_DETAIL"
    classes="modal-container"
    @closed="handleOnClosed"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-desktop">
            <h4 class="title">투표</h4>
            <div class="close-wrap" @click="handleOnClose">
              <NovaButtonIcon
                :icon="{ type: 'outline', name: 'close-extend' }"
                class="btn-close"
              />
            </div>
          </div>
        </div>
        <NovaLayoutScrollContainer ref="myCollectionRef" class="modal-body">
          <NovaBoxDaoVoteDetail
            :badge-label="badgeLabel"
            :badge-theme-class="badgeThemeClass"
            :status-class="statusClass"
          />
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;

    .modal-header {
      .on-desktop {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        flex-grow: 1;
        background-color: $color-white;
        border-radius: 20px 20px 0 0;
        @include text-style($text-display-medium);

        > .title {
          flex-grow: 1;
          color: $color-black;
        }

        .close-wrap {
          .btn-close {
            color: $color-text-2;
          }
        }
      }
    }

    .modal-body {
      border-top: 1px solid $color-gray-1;
      border-radius: 0 0 20px 20px;

      > .post {
        border-radius: 0;
        box-shadow: none;
      }

      .async-status-message {
        min-height: 200px;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
