import type { SigninPayload } from '@store'
import { ROUTES } from '@configs'

export default defineNuxtRouteMiddleware(async () => {
  const mypageStore = useMyPageStore()
  const daoStore = useDaoStore()
  const { cookieNames, getCookie } = useCookies()

  // 쿠키에서 사용자 정보를 가져옴
  const userInfoInCookie: SigninPayload | null = process.server
    ? getCookie<SigninPayload>(
        cookieNames.USER_INFO,
        useRequestHeaders(['cookie']).cookie || ''
      )
    : getCookie<SigninPayload>(cookieNames.USER_INFO)

  // 사용자 정보 없으면 리다이렉트
  if (!userInfoInCookie) {
    return await useNavigations({ url: ROUTES.APPLY_DAO.path, type: 'replace' })
  }

  // DAO 상태 및 프로필 데이터를 가져옴
  const [daoStatus, profile] = await Promise.all([
    daoStore.fetchDaoStatus(),
    mypageStore.fetchProfile(),
  ])

  if (!profile) {
    return await useNavigations({ url: ROUTES.APPLY_DAO.path, type: 'replace' })
  }

  // DAO 상태 코드에 따라 리다이렉트
  if (
    daoStatus &&
    ['P', 'A', 'L', 'D', 'T30'].includes(daoStatus.daoSttusCode)
  ) {
    return await useNavigations({ url: ROUTES.APPLY_DAO.path, type: 'replace' })
  }

  // 유효하지 않은 상태 코드 처리
  if (
    daoStatus?.daoSttusCode &&
    !['R', 'T', null].includes(daoStatus.daoSttusCode)
  ) {
    throw showError({
      statusCode: 500,
      statusMessage: 'check conditions error in middleware rent guard',
    })
  }
})

// import type { Profile, SigninPayload, DaoStatus } from '@store'
// import { ROUTES } from '@configs'
// export default defineNuxtRouteMiddleware(async () => {
//   // const userStore = useUserStore()
//   const mypageStore = useMyPageStore()
//   const daoStore = useDaoStore()
//   const { cookieNames, getCookie } = useCookies()
//   let userInfoInCookie: SigninPayload | null = null
//   let profile: Profile | null = null
//   let daoStatus: DaoStatus | null = null
//   // let userCommonInfo: UserCommonInfo | null = null
//   // 1. 사용자 조건을 파악하기 위해 쿠키 접근(SERVER, CLIENT)
//   if (process.server) {
//     const { cookie } = useRequestHeaders(['cookie'])
//     userInfoInCookie = getCookie<SigninPayload>(
//       cookieNames.USER_INFO,
//       cookie || ''
//     )
//   } else {
//     userInfoInCookie = getCookie<SigninPayload>(cookieNames.USER_INFO)
//   }
//   // 2. 쿠키에 로그인 정보가 존재 한다면 사용자 기본 정보(userCommonInfo) 및 daoStatus 조회
//   if (userInfoInCookie) {
//     const { data: _daoStatus } = await useAsyncData('daoStatus', () =>
//       daoStore.fetchDaoStatus()
//     )
//     daoStatus = _daoStatus.value
//     const { data: _profile } = await useAsyncData('profile', () =>
//       mypageStore.fetchProfile()
//     )
//     profile = _profile.value
//   }
//   // 로그인 안했으면 팅겨냄
//   if (!profile) {
//     await useNavigations({ url: ROUTES.APPLY_DAO.path, type: 'replace' })
//   } else {
//     if (daoStatus) {
//       switch (daoStatus.daoSttusCode) {
//         case 'P':
//         case 'A':
//         case 'D':
//         case 'L':
//         case 'T30':
//           await useNavigations({ url: ROUTES.APPLY_DAO.path, type: 'replace' })
//           break
//         case 'R':
//         case 'T':
//         case null:
//           break
//         default:
//           throw showError({
//             statusCode: 500,
//             statusMessage: 'check conditions error in middleware rent guard',
//           })
//       }
//     }
//   }
// })
