<script setup lang="ts">
const { isLoading } = useLoadingIndicator()
const layoutStore = useLayoutStore()

watch(
  () => isLoading.value,
  (cur) => {
    layoutStore.updateLoadingIndicatorGlobal({
      show: cur,
    })
  }
)
</script>

<template>
  <slot />
</template>
