<script setup lang="ts">
import type { ModalDaoRejectDialogParams } from '@components/NovaModalDaoRejectDialog/NovaModalDaoRejectDialog.types'

const { hide: modalHide } = useModal()
const { t } = useI18n()

const show = ref(false)
const rejectMsg = ref('')
const handleOnGetParams = (evt: any) => {
  const payload = evt.value as unknown as ModalDaoRejectDialogParams
  rejectMsg.value = payload.daoCrtfcResultCn
}
const confirm = async () => {
  await modalHide(modalsName.MODAL_DAO_REJECT_DIALOG)
}

const close = () => {
  modalHide(modalsName.MODAL_DAO_REJECT_DIALOG)
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_DAO_REJECT_DIALOG"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="close"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-desktop">
            <h4 class="title">{{ t('dao.approve.dialog.title') }}</h4>
            <div class="close-wrap" @click="close">
              <NovaButtonIcon
                :icon="{ type: 'outline', name: 'close-extend' }"
                class="btn-close"
                @click="close"
              />
            </div>
          </div>
        </div>
        <div class="modal-body">
          <section class="form-field-wrap">
            <div class="section-title-wrap">
              <h4
                v-dompurify-html="t('dao.approve.dialog.companionMessage')"
                class="title"
              ></h4>
            </div>
            <div class="form-field-wrap">
              <div class="field">
                <p>{{ t('dao.approve.dialog.companionReasonTitle') }}</p>
                <p>{{ rejectMsg }}</p>
              </div>
            </div>
          </section>
          <section>
            <div class="form-field-wrap-btns">
              <NovaButtonText
                :label="t('dao.member.management.dialog.actions.positive')"
                :width="200"
                :theme="'primary-blue-dark'"
                :full-width="true"
                @click="confirm"
              />
            </div>
          </section>
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 12px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 360px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;
    background-color: $color-border-1;

    .modal-header {
      .on-desktop {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        flex-grow: 1;
        background-color: $color-white;
        border-radius: 20px 20px 0 0;
        @include text-style($text-display-medium);

        > .title {
          flex-grow: 1;
          color: $color-black;
        }

        .close-wrap {
          .btn-close {
            color: $color-text-2;
          }
        }
      }
    }

    .modal-body {
      display: flex;
      gap: 12px;
      padding: 0px 20px 32px 20px;
      border-radius: 0 0 20px 20px;
      flex-direction: column;
      background-color: $color-white;
      .section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 35px;
      }
      .section-title-wrap {
        text-align: center;
      }

      .form-field-wrap {
        display: flex;
        flex-direction: column;
        gap: 6px;
        .field {
          display: block;
          margin: 10px 0 12px;
          padding: 10px;
          color: $color-warning;
          background: $color-gray-100;
          border-radius: 5px;
          @include text-style($text-body-14-regular);
        }
        .error-message {
          display: flex;
          align-items: center;
          gap: 6px;
          @include text-style($text-body-12-regular);
          color: $color-primary-red;

          &:before {
            @include error-icon;
          }
        }
      }
      .form-field-wrap-btns {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 10px;
      }
    }
  }
}
/*@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}*/
</style>
