<script setup lang="ts">
import { AxiosError } from 'axios'
import type { NuxtError } from '#app'
import type { ErrorMessage, NovaBoxErrorProps } from './NovaBoxError.types'
import { ErrorStatus } from '@configs'
import Error400 from 'assets/images/error400.svg?url'
import Error500 from 'assets/images/error500.svg?url'

const props = withDefaults(defineProps<NovaBoxErrorProps>(), {
  instance: false,
})

// const isProduction = process.env.NODE_ENV === 'production'
const runtimeConfig = useRuntimeConfig()
const router = useRouter()
const { t, locale } = useI18n()
const isAxiosError = computed(() => props.error instanceof AxiosError)

// 에러 메세지 분기
const errorMessage = computed(() => {
  switch (
    isAxiosError.value
      ? (props.error as AxiosError).response?.status || 0
      : (props.error as NuxtError).statusCode
  ) {
    case 401:
      return message(
        t('commonError.401.title'),
        t('commonError.401.message'),
        Error400
      )
    case 403:
      return message(
        t('commonError.403.title'),
        t('commonError.403.message'),
        Error400
      )
    case 404:
      return message(
        t('commonError.404.title'),
        t('commonError.404.message'),
        Error400
      )
    case 500 || 502 || 505:
      return message(
        t('commonError.500.title'),
        t('commonError.500.message'),
        Error500
      )
    // 레퍼럴 회원가입 랜딩페이지에서 추천인 정보를 조회할 수 없음(4010)
    case ErrorStatus.REFERRAL_USER_NOT_FOUND:
      return message(
        t('referral.signUp.error.title'),
        t('referral.signUp.error.message'),
        Error400
      )
    default:
      return message(
        t('commonError.default.title'),
        t('commonError.default.message'),
        Error500
      )
  }
})

// 메세지 만들기
const message = (
  title: string,
  productionMessage: string,
  imgPath: string
): ErrorMessage => ({
  code: isAxiosError.value
    ? (props.error as AxiosError).response?.status || 0
    : (props.error as NuxtError).statusCode,
  message: productionMessage,
  title,
  imagePath: imgPath,
})

// 홈 가기
const handleOnGoHome = () => {
  router.replace({
    path: locale.value === 'ko' ? '/' : `/${locale.value}`,
  })
}
</script>

<template>
  <div :class="['page-error', { instance }]">
    <div class="inner">
      <div class="contents">
        <NovaLogo class="logo" :is-go-home="false" />

        <img class="error-icon" :src="errorMessage.imagePath" alt="error" />

        <div class="message-wrap">
          <div class="messages">
            <h4
              v-if="
                ['development', 'local'].includes(runtimeConfig.public.MODE)
              "
              class="error-code"
            >
              {{ errorMessage.code }}
            </h4>
            <h4 class="title">{{ errorMessage.title }}</h4>
            <p v-dompurify-html="errorMessage.message" class="description" />
          </div>

          <div class="actions">
            <NovaButtonText
              :label="$t('goHome')"
              :size="40"
              :theme="'primary-blue-dark'"
              class="btn-action"
              @click="handleOnGoHome"
            />
          </div>
        </div>
      </div>
      <img class="error-icon" :src="errorMessage.imagePath" alt="error" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-error {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;

  &.instance {
    position: relative;
    top: auto;
    left: auto;
    min-height: 600px;
  }

  > .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    max-width: 760px;

    .error-icon {
      width: auto;
      height: 320px;
    }

    .contents {
      display: flex;
      flex-direction: column;
      gap: 60px;

      > .logo {
        display: inline-block;
      }

      .error-icon {
        display: none;
      }

      .message-wrap {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .messages {
          display: flex;
          flex-direction: column;
          gap: 20px;
          color: $color-text-2;

          > .error-code,
          > .title {
            @include text-style($text-custom-40-bold);
          }

          > .description {
            @include text-style($text-body-14-regular);
          }
        }

        .actions {
          .btn-action {
            display: inline-flex;
            width: auto;
          }
        }
      }
    }
  }

  @include mobile {
    > .inner {
      flex-direction: column;

      .error-icon {
        display: none;
        width: 160px;
        max-width: 100%;
        height: auto;
      }

      .contents {
        align-items: center;
        gap: 30px;

        > .logo {
          display: flex;
        }

        .error-icon {
          display: block;
        }

        .message-wrap {
          .messages {
            align-items: center;

            > .error-code,
            > .title {
              text-align: center;
              @include text-style($text-custom-22-bold);
            }

            > .description {
              text-align: center;
              @include text-style($text-body-13-regular);
            }
          }
        }

        .actions {
          text-align: center;
        }
      }
    }

    .logo {
      :deep(.logo-button) {
        width: 70px;
      }
    }
  }
}
</style>
