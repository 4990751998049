<script setup lang="ts">
// import { AxiosError } from 'axios/index'
import type { RouteParams } from 'vue-router'
import { v4 } from 'uuid'
import { Tippy } from 'vue-tippy'
import { AxiosError } from 'axios'
import type { ModalDaoJoinParams } from '@components/NovaModalDaoInfo/NovaModalDaoInfo.types'
import type { DeleteDaoDisbandPayload, DaoMemberSecessionPayload } from '@store'
import { NovaModalDialog } from '#components'
import { ROUTES } from '@configs'
const { show: modalShow, hide: modalHide } = useModal()
const { gtEvent } = useGoogleTag()
const { t, messages, locale } = useI18n()
const route = useRoute()

const daoStore = useDaoStore()
const creatorStore = useCreatorStore()
const layoutStore = useLayoutStore()

const show = ref(false)
const handleOnGetParams = (evt: RouteParams) => {
  const payload = evt.value as unknown as ModalDaoJoinParams
  daoInfoSource.value = payload.source
  isDisbandable.value = payload.isDisbandable === 'Y'
  privateKey.value = payload.privateKey
  isMaster.value =
    daoStore.daoStatus?.daoSn === creatorStore.profile?.creatorDaoSn
}

const daoInfoSource = ref()
const isMaster = ref<boolean>(false)
const isDisbandable = ref<boolean>(false)
const privateKey = ref<string>('')

// 해산 액션
const disbandDao = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const payload = {
      daoMbrSn: daoInfoSource.value.daoMbrSn,
      daoSn: daoInfoSource.value.daoSn,
      privateKey: privateKey.value,
    } as DeleteDaoDisbandPayload
    await daoStore.deleteDaoDisband(payload)
    await modalHide(modalsName.MODAL_DAO_INFO)
    await useToast(t('dao.member.management.dialog.disbandSuccess'))
    await useNavigations({
      url: useRoutePathIdChange(ROUTES.CREATOR.path, {
        id: String(route.params.id),
      }),
    })
  } catch (err) {
    // if (err instanceof AxiosError) {
    if (err instanceof AxiosError) {
      const errCode = err.response.status.toString()
      const errCodes = Object.keys(
        (messages.value[locale.value].dao as any).member.management.dialog
          .errorMessage
      )
      if (errCodes.includes(errCode)) {
        await useToast(
          t(`dao.member.management.dialog.errorMessage.${errCode}`)
        )
        return
      }
      await useToast(t('dao.member.management.dialog.errorMessage.default'))
    }
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

// 탈퇴 액션
const leaveDao = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const payload = {
      fndrUserSn: creatorStore.profile?.userSn,
      privateKey: privateKey.value,
    } as DaoMemberSecessionPayload
    await daoStore.editDaoMemberSecession(payload)
    await useToast(t('dao.member.management.dialog.leaveSuccess'))
    await modalHide(modalsName.MODAL_DAO_INFO)
    await useNavigations({ url: ROUTES.HOME.path })
  } catch (err) {
    if (err instanceof AxiosError) {
      const errCode = err.response.status.toString()
      const errCodes = Object.keys(
        (messages.value[locale.value].dao as any).member.management.dialog
          .errorMessage
      )
      if (errCodes.includes(errCode)) {
        await useToast(
          t(`dao.member.management.dialog.errorMessage.${errCode}`)
        )
        return
      }
      await useToast(t('dao.member.management.dialog.errorMessage.default'))
    }
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}
const confirm = async () => {
  if (isMaster.value) {
    // 해산 신청 확인 모달
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: t('dao.dissolution.dialog.actions.negative'),
            theme: 'secondary-gray-200',
            size: 32,
            onClick: async () => {
              await gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '취소 버튼 클릭',
                eventLabel: t('dao.dissolution.dialog.actions.negative'),
                eventSlot: 'DAO 홈 > DAO 정보 모달 > 해산 > 취소 ',
                eventI18nAddr: useKoreanTranslation(
                  'dao.dissolution.dialog.actions.negative'
                ),
                eventComponent: 'Button',
              })
              await modalHide(modalsName.MODAL_DIALOG)
            },
          },
          {
            label: t('dao.dissolution.dialog.actions.positive'),
            theme: 'primary-blue-dark',
            size: 32,
            onClick: async () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '확인 버튼 클릭',
                eventLabel: t('dao.dissolution.dialog.actions.positive'),
                eventSlot: 'DAO 홈 > DAO 정보 모달 > 해산 > 확인 ',
                eventI18nAddr: useKoreanTranslation(
                  'dao.dissolution.dialog.actions.positive'
                ),
                eventComponent: 'Button',
              })
              await disbandDao()
              await modalHide(modalsName.MODAL_DIALOG)
            },
          },
        ],
      },
      slots: {
        title: t('dao.dissolution.dialog.title'),
        content: t('dao.dissolution.dialog.message'),
      },
    })
  } else {
    // 탈퇴
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: t('dao.leave.model.actions.negative'),
            theme: 'secondary-gray-200',
            size: 32,
            onClick: async () => {
              await gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '취소 버튼 클릭',
                eventLabel: t('dao.leave.model.actions.negative'),
                eventSlot: 'DAO 홈 > DAO 정보 모달 > 탈퇴 > 취소 ',
                eventI18nAddr: useKoreanTranslation(
                  'dao.leave.model.actions.negative'
                ),
                eventComponent: 'Button',
              })
              await modalHide(modalsName.MODAL_DIALOG)
            },
          },
          {
            label: t('dao.leave.model.actions.positive'),
            theme: 'primary-blue-dark',
            size: 32,
            onClick: async () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '확인 버튼 클릭',
                eventLabel: t('dao.leave.model.actions.positive'),
                eventSlot: 'DAO 홈 > DAO 정보 모달 > 탈퇴 > 확인 ',
                eventI18nAddr: useKoreanTranslation(
                  'dao.leave.model.actions.positive'
                ),
                eventComponent: 'Button',
              })
              await leaveDao()
              await modalHide(modalsName.MODAL_DIALOG)
            },
          },
        ],
      },
      slots: {
        title: t('dao.leave.model.title'),
        content: t('dao.member.management.dialog.leaveMessage'),
      },
    })
  }
}

const close = () => {
  modalHide(modalsName.MODAL_DAO_INFO)
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: 'DAO 정보 모달 닫기',
    eventLabel: 'close-extend',
    eventSlot: '',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
  })
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_DAO_INFO"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="close"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-desktop">
            <h4 class="title">
              {{ t('dao.dissolution.dissolutionModel.title') }}
            </h4>
            <div class="close-wrap" @click="close">
              <NovaButtonIcon
                :icon="{ type: 'outline', name: 'close-extend' }"
                class="btn-close"
              />
            </div>
          </div>
        </div>
        <div class="modal-body">
          <section class="form-field-wrap">
            <div class="form-field-box jc-fs">
              <div class="section-title-wrap">
                <h4
                  v-dompurify-html="t('dao.dissolution.dissolutionModel.name')"
                  class="title"
                />
              </div>
              <div class="section-cont-wrap">{{ daoInfoSource.daoNm }}</div>
            </div>
            <div class="form-field-box jc-fs">
              <div class="section-title-wrap">
                <h4
                  v-dompurify-html="
                    t('dao.dissolution.dissolutionModel.introduction')
                  "
                  class="title"
                />
              </div>
              <div
                v-dompurify-html="daoInfoSource.daoDc"
                class="section-cont-wrap"
              ></div>
            </div>
            <div class="form-field-box">
              <div class="section-title-wrap">
                <h4
                  v-dompurify-html="
                    t('dao.dissolution.dissolutionModel.membershipNFT')
                  "
                  class="title"
                />
              </div>
              <div class="section-cont-wrap-img">
                <NovaImageContainer
                  :key="daoInfoSource.daoImg || v4()"
                  :image-url="daoInfoSource.daoImg"
                  :ratio="'1:1'"
                  :empty-type="'none'"
                  class="user-portrait toggle-panel-button"
                />
              </div>
            </div>
            <div class="form-field-box jc-sb">
              <div v-if="isMaster" class="section-title-wrap">
                <h4
                  v-dompurify-html="
                    t('dao.dissolution.dissolutionModel.dissolutionBox.title')
                  "
                  class="title"
                />
                <div class="dao-info-tooltip">
                  <Tippy
                    :interactive="true"
                    :theme="'black'"
                    :placement="'bottom'"
                  >
                    <span class="tooltip-label" />

                    <template #content>
                      <p
                        v-dompurify-html="
                          t(
                            'dao.dissolution.dissolutionModel.dissolutionBox.message'
                          )
                        "
                        class=""
                      />
                    </template>
                  </Tippy>
                </div>
              </div>
              <div v-else class="section-title-wrap">
                <h4
                  v-dompurify-html="
                    t('dao.dissolution.dissolutionModel.dissolutionBox.leave')
                  "
                  class="title"
                />
              </div>
              <div class="section-cont-wrap">
                <NovaButtonText
                  :label="
                    t('dao.dissolution.dissolutionModel.dissolutionBox.btn')
                  "
                  :size="28"
                  :theme="'secondary-gray-200'"
                  :full-width="true"
                  :disabled="!isDisbandable && isMaster"
                  @click="confirm"
                />
              </div>
            </div>
          </section>
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;
    background-color: $color-border-1;

    .modal-header {
      .on-desktop {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        flex-grow: 1;
        background-color: $color-white;
        border-radius: 20px 20px 0 0;
        @include text-style($text-display-medium);
        @include mobile {
          border-radius: 0 0 0 0;
        }
        > .title {
          flex-grow: 1;
          color: $color-black;
        }

        .close-wrap {
          .btn-close {
            color: $color-text-2;
          }
        }
      }
    }

    .modal-body {
      display: flex;
      gap: 12px;
      padding: 24px 20px 32px 20px;
      border-radius: 20px;
      flex-direction: column;

      .section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 35px;
      }

      .form-field-wrap {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 24px 16px;
        background-color: $color-white;
        border-radius: 10px;
        .form-field-box {
          display: flex;
          flex-direction: column;
          gap: 12px;
          &.jc-fs {
            flex-direction: row;
            align-items: flex-start;
          }
          &.jc-sb {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
          .section-title-wrap {
            display: flex;
            flex-direction: row;
            min-width: 120px;
            gap: 5px;
            .title {
              @include text-style($text-display-medium);
              color: $color-text-1;
            }
            .dao-info-tooltip {
              display: flex;
              align-items: center;
              .label {
                color: #97989d;
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.7px;
                margin-left: 3px;
                margin-right: 6px;
              }
            }
          }
          .tooltip-label {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $color-dark-1;
            &:after {
              content: '?';
              color: $color-bg-3;
              font-size: 18px;
              font-weight: 500;
              line-height: 1;
            }
          }
          .section-cont-wrap {
            @include text-style($text-display-medium);
            color: $color-text-12;
            word-break: break-all;
          }
          .section-cont-wrap-img {
            width: 70px;
          }
        }
      }
    }
  }
}
@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-body {
        border-radius: 0;
      }
    }
  }
}
</style>
