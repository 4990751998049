<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import { Tippy } from 'vue-tippy'
import { decode } from 'html-entities'
import { AxiosError } from 'axios'
import type { ModalDaoBoardParams } from './NovaModalDaoBoardCreate.types'
import {
  NovaLayoutScrollContainer,
  NovaModalCommon,
  NovaModalDialog,
  NovaModalPostCreatePreview,
} from '#components'
import {
  type CreateDaoBoardPostPayload,
  type CreateDaoBoardTempPostPayload,
  type DaoBoardPost,
  type DaoPostScopeCode,
  type editDaoBoardPostPayload,
  type EditorImage,
  PostContentsType,
  useAppStore,
} from '@store'

defineOptions({
  inheritAttrs: false,
})

const config = useRuntimeConfig()
const { t, messages, locale } = useI18n()
const { gtEvent } = useGoogleTag()
const { show: modalShow, hide: modalHide } = useModal()
const appStore = useAppStore()
const userStore = useUserStore()
const layoutStore = useLayoutStore()
const daoStore = useDaoStore()

const show = ref(false)
const editorLoading = ref(false)

// 모달 닫을때 변경내용이 있는지 검사 하기 위한 값
const init = ref('')
const editInit = ref('')

const prevNoticeAt = ref('')
const daoMasterUserSn = ref()

const checkAuthorLevel = computed(() => {
  return daoStore.daoInfo && daoStore.daoInfo?.daoAuthorLevel < 3
})

// models: 카테고리 목록
const scopeMenus = [
  {
    label: t('dao.board.create.dropdown.readPermission.follower'),
    value: 'PTFW',
  },
  {
    label: t('dao.board.create.dropdown.readPermission.onlyMember'),
    value: 'DALL',
  },
]
const hashtags = ref<Array<string>>([])
const defaultState: CreateDaoBoardPostPayload = {
  daoTmprNttSn: -1, // DAO 임시 게시물 일련번호
  fndrUserSn: -1, // 창업자 사용자 일련번호
  hashTagList: [], // 해시태그 목록
  nttAiPrcuseAt: 'N', // AI 컨텐츠 여부
  nttAnswerPermAt: 'N', // 댓글 허용 여부
  nttCn: '', // 게시물 내용
  nttNoticeAt: 'N', // 게시물 공지 여부 Y/N
  nttOthbcScopeCode: '', // 게시물 공개 범위 코드
  nttSj: '', // 게시물 제목
  orignlNttCn: '', // 게시물 원본 내용
  tempImgUrlList: [], // 이미지 파일 리스트
}

const editDefaultState: editDaoBoardPostPayload = {
  daoNttSn: -1, // DAO 게시물 일련번호
  fileUrlList: [], // 창업자 사용자 일련번호
  hashTagList: [], // 해시태그 목록
  nttAiPrcuseAt: 'N', // AI 컨텐츠 여부
  nttAnswerPermAt: 'N', // 댓글 허용 여부
  nttCn: '', // 게시물 내용
  nttNoticeAt: 'N', // 게시물 공지 여부 Y/N
  nttOthbcScopeCode: '', // 게시물 공개 범위 코드
  nttSj: '', // 게시물 제목
  orignlNttCn: '', // 게시물 원본 내용
}
// 모델
const state = ref<CreateDaoBoardPostPayload>(defaultState)
const editState = ref<editDaoBoardPostPayload>(editDefaultState)
const isCreateAvailable = computed(
  () => state.value.nttOthbcScopeCode !== '' && !!state.value.nttOthbcScopeCode
)
const isEditMode = ref(false)
const isNotice = computed(() => {
  return editState.value.nttNoticeAt === 'Y' || state.value.nttNoticeAt === 'Y'
})
const handleOnGetParams = (evt: RouteParams) => {
  const modalData = evt.value as unknown as ModalDaoBoardParams
  isEditMode.value = modalData.daoBoardData.type === 'edit'
  daoMasterUserSn.value = modalData.daoBoardData.fndrUserSn
  if (isEditMode.value) {
    setInit(modalData.daoBoardData.source)
  } else {
    // 신규 작성 시 모델 초기화
    state.value = {
      daoTmprNttSn: -1,
      fndrUserSn: -1,
      hashTagList: [],
      nttAiPrcuseAt: 'N',
      nttAnswerPermAt: 'N',
      nttCn: '',
      nttNoticeAt: 'N',
      nttOthbcScopeCode: '',
      nttSj: '',
      orignlNttCn: '',
      tempImgUrlList: [],
    }
    init.value = JSON.stringify({
      ...state.value,
    })
  }
}

const setInit = (data: DaoBoardPost | null) => {
  if (data) {
    if (data.hashTagList?.length) {
      hashtags.value = data.hashTagList.map((tag) => tag.hashtagNm)
    }
    editState.value = {
      daoNttSn: data.daoNttSn, // DAO 게시물 일련번호
      fileUrlList: [], // 창업자 사용자 일련번호
      hashTagList: hashtags.value || [], // 해시태그 목록
      nttAiPrcuseAt: data.nttAiPrcuseAt || 'N',
      nttAnswerPermAt: data.nttAnswerPermAt || 'N',
      nttCn: data.orignlNttCn || '',
      nttNoticeAt: data.nttNoticeAt || 'N',
      nttOthbcScopeCode: data.nttOthbcScopeCode || '',
      nttSj: decode(data.nttSj) || '',
      orignlNttCn: data.orignlNttCn || '',
    }
    prevNoticeAt.value = editState.value.nttNoticeAt
  }
  editInit.value = JSON.stringify({
    ...editState.value,
  })
}
/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  // 게시글 작성 모달 닫기 전에 변경된 사항 있는지 확인 하고 모달 닫기
  const isChanged = isEditMode.value
    ? editInit.value !== JSON.stringify(editState.value)
    : init.value !== JSON.stringify(state.value)
  if (isChanged) {
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: t('cancel'),
            theme: 'secondary-gray-200',
            size: 32,
            onClick: async () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction:
                  'DAO 포스트 작성 모달 닫기 > 작성중인 내용 삭제 확인 > 취소',
                eventLabel: t('cancel'),
                eventSlot: '모달 닫기',
                eventI18nAddr: useKoreanTranslation('createPost'),
                eventComponent: 'Button',
              })
              await modalHide(modalsName.MODAL_DIALOG)
            },
          },
          {
            label: t('confirm'),
            theme: 'primary-blue-dark',
            size: 32,
            onClick: async () => {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction:
                  'DAO 포스트 작성 모달 닫기 > 작성중인 내용 삭제 확인 > 확인',
                eventLabel: t('confirm'),
                eventSlot: '포스트 작성 모달 닫기 > 닫기 확인',
                eventI18nAddr: useKoreanTranslation('confirm'),
                eventComponent: 'Button',
              })
              await modalHide(modalsName.MODAL_DIALOG)
              await modalHide(modalsName.MODAL_REG_DAO_BOARD)
            },
          },
        ],
      },
      slots: {
        title: t('postCreate.modal.dialogTitle'),
        content: t('postCreate.modal.dialogContent'),
      },
    })
  } else {
    await modalHide(modalsName.MODAL_DIALOG)
    await modalHide(modalsName.MODAL_REG_DAO_BOARD)
  }
}

const handleOnUpdateScope = (value: DaoPostScopeCode) => {
  isEditMode.value
    ? (editState.value.nttOthbcScopeCode = value)
    : (state.value.nttOthbcScopeCode = value)
}

const handleOnTipTapLoading = (loading: boolean) => {
  editorLoading.value = loading
}
const handleOnChangeAIContents = (value: boolean) => {
  isEditMode.value
    ? (editState.value.nttAiPrcuseAt = value ? 'Y' : 'N')
    : (state.value.nttAiPrcuseAt = value ? 'Y' : 'N')
}
const handleOnChangeNotice = (value: boolean) => {
  isEditMode.value
    ? (editState.value.nttNoticeAt = value ? 'Y' : 'N')
    : (state.value.nttNoticeAt = value ? 'Y' : 'N')
}
const handleOnChangeReply = (value: boolean) => {
  isEditMode.value
    ? (editState.value.nttAnswerPermAt = value ? 'Y' : 'N')
    : (state.value.nttAnswerPermAt = value ? 'Y' : 'N')
}
const updateTags = (tags: string[]) => {
  isEditMode.value
    ? (editState.value.hashTagList = tags)
    : (state.value.hashTagList = tags)
}
// methods: 포스트 발행 전 유효성 검사
const onValidate = () => {
  if (!isCreateAvailable.value && !isEditMode) {
    useToast(t('postCreate.toastMessage.validateScope'))
    return false
  } else if (state.value.nttSj.length < 2) {
    useToast(t('postCreate.toastMessage.validateTitle'))
    return false
  } else if (state.value.nttCn.replace(/<[^>]+>/g, '').length < 15) {
    useToast(t('postCreate.toastMessage.validateContents'))
    return false
  } else {
    return true
  }
}
const openTempList = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: 'DAO 게시판 작성 > 임시저장 불러오기 클릭',
    eventLabel: t('postCreate.placeholder.tempSave'),
    eventSlot: 'Modal',
    eventI18nAddr: useKoreanTranslation('postCreate.placeholder.tempSave'),
    eventComponent: 'Button',
  })
  modalShow({
    component: NovaModalCommon,
    bind: {
      name: modalsName.MODAL_POST_TEMP_LIST,
      showCloseBtn: true,
      hasInnerScroll: true,
    },
    on: {
      close: () => {
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '임시저장 목록 모달 > 닫기',
          eventLabel: t('noticeCreate.modal.tempTitle'),
          eventSlot: 'Modal',
          eventI18nAddr: useKoreanTranslation('noticeCreate.modal.tempTitle'),
          eventComponent: 'Button',
          creatorUserSn: userStore.user!.userSn,
        })
        modalHide(modalsName.MODAL_POST_TEMP_LIST)
      },
    },
    slots: {
      title: t('noticeCreate.modal.tempTitle'),
      noScrollContents: {
        component: NovaModalDaoPostTempSaveList,
        on: {
          selectItem(id: number) {
            selectTempItem(id)
            modalHide(modalsName.MODAL_POST_TEMP_LIST)
          },
          close() {
            modalHide(modalsName.MODAL_POST_TEMP_LIST)
          },
        },
      },
    },
  })
}

const selectTempItem = async (tempSN: number) => {
  try {
    const data = await daoStore.fetchDaoBoardTempPost({ daoTmprNttSn: tempSN })
    state.value = {
      fndrUserSn: -1,
      daoTmprNttSn: data.daoTmprNttSn,
      hashTagList: data.hashTagList,
      nttAiPrcuseAt: data.nttAiPrcuseAt,
      nttAnswerPermAt: data.nttAnswerPermAt,
      nttCn: data.nttCn,
      orignlNttCn: '',
      nttNoticeAt: data.nttNoticeAt,
      nttOthbcScopeCode: data.nttOthbcScopeCode,
      nttSj: data.nttSj,
      tempImgUrlList: [],
    }

    init.value = JSON.stringify({
      ...state.value,
    })
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: 'DAO > 임시저장 된 내용 선택',
      eventLabel: '',
      eventSlot: 'Modal',
      eventI18nAddr: '',
      eventComponent: 'Button',
      daoTmprNttSn: data.daoTmprNttSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      const errCode = err.response.status.toString()
      const errCodes = Object.keys(
        (messages.value[locale.value].dao as any).member.errorMessage
      )
      if (errCodes.includes(errCode)) {
        t(`dao.board.errorMessage.${errCode}`)
        return
      }
      t('dao.board.errorMessage.default')
    }
  }
}
const handleOnTempSave = async () => {
  if (onValidate()) {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: 'Dao 포스트 임시 저장',
      eventLabel: t('postCreate.button.tempSave'),
      eventSlot: 'Modal',
      eventI18nAddr: useKoreanTranslation('postCreate.button.tempSave'),
      eventComponent: 'Button',
    })
    try {
      layoutStore.updateLoadingIndicatorGlobal({ show: true })
      const payload: CreateDaoBoardTempPostPayload = {
        fndrUserSn: daoMasterUserSn.value,
        hashTagList: isEditMode.value
          ? editState.value.hashTagList
          : state.value.hashTagList,
        nttAiPrcuseAt: isEditMode.value
          ? editState.value.nttAiPrcuseAt
          : state.value.nttAiPrcuseAt,
        nttAnswerPermAt: isEditMode.value
          ? editState.value.nttAnswerPermAt
          : state.value.nttAnswerPermAt,
        nttCn: isEditMode.value ? editState.value.nttCn : state.value.nttCn,
        nttNoticeAt: isEditMode.value
          ? editState.value.nttNoticeAt
          : state.value.nttNoticeAt,
        nttOthbcScopeCode: isEditMode.value
          ? editState.value.nttOthbcScopeCode
          : state.value.nttOthbcScopeCode,
        nttSj: isEditMode.value ? editState.value.nttSj : state.value.nttSj,
      }
      await daoStore.createDaoBoardTempPost(payload)
      isEditMode.value
        ? (init.value = JSON.stringify({
            ...state.value,
          }))
        : (editInit.value = JSON.stringify({
            ...state.value,
          }))
      await useToast(t('postCreate.toastMessage.tempSave'))
    } catch (err) {
      await useToast(t('postCreate.toastMessage.errors.default'))
    } finally {
      init.value = JSON.stringify({
        ...state.value,
      })
      layoutStore.updateLoadingIndicatorGlobal({ show: false })
    }
  }
}
const handleOnPreview = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const contents = await useReplacerPostContents(
      isEditMode.value ? editState.value.nttCn : state.value.nttCn,
      PostContentsType.DETAIL,
      true
    )
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: 'DAO 게시물 작성 > 미리보기 버튼 클릭',
      eventLabel: t('postCreate.button.preview'),
      eventSlot: 'Modal',
      eventI18nAddr: useKoreanTranslation('postCreate.button.preview'),
      eventComponent: 'Button',
    })
    await modalShow({
      component: NovaModalCommon,
      bind: {
        name: modalsName.MODAL_POST_PREVIEW,
        showCloseBtn: true,
        maxHeight: 500,
      },
      on: {
        close: () => {
          gtEvent('clickEvent', {
            eventCategory: '클릭',
            eventAction: 'DAO 게시물 작성 > 미리보기 모달 > 닫기 버튼',
            eventLabel: t('postCreate.modal.previewTitle'),
            eventSlot: 'Modal',
            eventI18nAddr: useKoreanTranslation(
              'postCreate.modal.previewTitle'
            ),
            eventComponent: 'Button',
          })
          modalHide(modalsName.MODAL_POST_PREVIEW)
        },
      },
      slots: {
        title: t('postCreate.modal.previewTitle'),
        contents: {
          component: NovaModalPostCreatePreview,
          bind: {
            title: isEditMode.value ? editState.value.nttSj : state.value.nttSj,
            contents,
            hashtags: isEditMode.value
              ? editState.value.hashTagList?.map((item, index) => ({
                  hashtagNm: item,
                  hashtagSn: index,
                }))
              : state.value.hashTagList?.map((item, index) => ({
                  hashtagNm: item,
                  hashtagSn: index,
                })),
          },
          on: {
            close() {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: 'DAO 게시물 작성 > 미리보기 모달 > 확인 버튼',
                eventLabel: t('postCreate.button.confirm'),
                eventSlot: 'Modal',
                eventI18nAddr: useKoreanTranslation(
                  'postCreate.button.confirm'
                ),
                eventComponent: 'Button',
              })
              modalHide(modalsName.MODAL_POST_PREVIEW)
            },
          },
        },
      },
    })
  } catch (err) {
    await useToast(t('postCreate.toastMessage.errors.default'))
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

const handleOnSave = async () => {
  const isEdit = isEditMode.value
  const currentState = isEdit ? editState.value : state.value

  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `DAO 포스트 ${isEdit ? '수정' : '작성'} > 게시 버튼 클릭`,
    eventLabel: t('postCreate.button.save'),
    eventSlot: 'Modal',
    eventI18nAddr: useKoreanTranslation('postCreate.button.save'),
    eventComponent: 'Button',
    nttAnswerPermAt: 'N', // 댓글 허용 여부
    nttSj: currentState.nttSj,
    nttCn: currentState.nttCn,
    nttNoticeAt: currentState.nttNoticeAt,
  })

  if (currentState.nttNoticeAt === 'Y') {
    handleOnChangeAIContents(false)
    handleOnChangeReply(false)
    updateTags([])
  }

  await (isEdit ? editPost() : savePost())
}

const savePost = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })

    state.value.fndrUserSn = daoMasterUserSn.value
    // 웹에디터 내 이미지 src 변경
    const { updatedHtml, replaceImgs } =
      await useReplacePostContentImgsToTempImgs(state.value.nttCn)

    const payload = {
      daoTmprNttSn: 0,
      fndrUserSn: state.value.fndrUserSn,
      hashTagList: state.value.hashTagList,
      nttCn: updatedHtml,
      nttNoticeAt: state.value.nttNoticeAt,
      nttOthbcScopeCode: state.value.nttOthbcScopeCode,
      nttSj: state.value.nttSj,
      tempImgUrlList: replaceImgs
        .filter((img) => img.tempSrc)
        .map((img) => img.tempSrc),
      nttAiPrcuseAt: state.value.nttAiPrcuseAt,
      nttAnswerPermAt: state.value.nttAnswerPermAt,
      orignlNttCn: state.value.nttCn,
    }

    payload.nttCn = await useReplacerPostContents(
      updatedHtml,
      PostContentsType.DETAIL,
      true
    )
    await daoStore.createDaoBoardPost(payload)
  } catch (err) {
    if (err instanceof AxiosError) {
      const errCode = err.response.status.toString()
      const errCodes = Object.keys(
        (messages.value[locale.value].dao as any).board.errorMessage
      )
      if (errCodes.includes(errCode)) {
        await useToast(t(`dao.board.errorMessage.${errCode}`))
        return
      }
      await useToast(t('dao.board.errorMessage.default'))
    }
  } finally {
    state.value = defaultState
    editState.value = editDefaultState
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
    await modalHide(modalsName.MODAL_REG_DAO_BOARD)
  }
}
const getImagesFromContents = (contents: string, submit = false) => {
  // 1. 콘텐츠에서 이미지 추출(외부 이미지 제외)
  const container = document.createElement('div')
  container.innerHTML = contents
  const imageEls = container.querySelectorAll('img')
  const nextImages: EditorImage[] = Array.prototype.slice
    .call(imageEls)
    .filter((img) => {
      const fileUrl = img.getAttribute('src')
      return (
        fileUrl.includes(config.public.TEMP_IMAGE_URL) ||
        fileUrl.includes(config.public.POST_RELEASED_IMAGE_URL)
      )
    })
    .map((img) => {
      const fileUrl = img.getAttribute('src')

      return {
        deleteAt:
          !fileUrl.includes(config.public.TEMP_IMAGE_URL) &&
          editState.value.fileUrlList.findIndex(
            (item) => item.fileUrl === fileUrl
          ) === -1
            ? 'Y'
            : 'N',
        fileUrl,
        tempAt: fileUrl.includes(config.public.TEMP_IMAGE_URL) ? 'Y' : 'N',
      }
    })

  // 2. 수정 전 이미지와 비교하여 삭제된 이미지 추출
  let deletedImages: EditorImage[] = []
  deletedImages = editState.value.fileUrlList.filter(
    (prevImg) =>
      nextImages.findIndex((nextImg) => nextImg.fileUrl === prevImg.fileUrl) ===
      -1
  )

  // 3. 수정 하기 위한 페이로드 생성시 변경 사항 없는 이미지는 제외
  if (submit) {
    return [...nextImages, ...deletedImages].filter(
      (img) => !(img.deleteAt === 'N' && img.tempAt === 'N')
    )
  }

  return [...nextImages, ...deletedImages]
}

const editPost = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    // 수정 할때 nttNoticeAt 변경 된지 확인
    const isChangingNoticeAt =
      editState.value.nttNoticeAt !== prevNoticeAt.value
    // 웹에디터 내 이미지 src 변경
    const { updatedHtml } = await useReplacePostContentImgsToTempImgs(
      editState.value.nttCn
    )

    const payload: editDaoBoardPostPayload = {
      daoNttSn: editState.value.daoNttSn,
      fileUrlList: getImagesFromContents(updatedHtml, true),
      hashTagList: editState.value.hashTagList,
      nttAiPrcuseAt: editState.value.nttAiPrcuseAt,
      nttAnswerPermAt: editState.value.nttAnswerPermAt,
      nttCn: updatedHtml,
      nttNoticeAt: editState.value.nttNoticeAt,
      nttOthbcScopeCode: editState.value.nttOthbcScopeCode,
      nttSj: editState.value.nttSj,
      orignlNttCn: editState.value.nttCn,
    }

    payload.nttCn = await useReplacerPostContents(
      updatedHtml,
      PostContentsType.DETAIL,
      true
    )
    await daoStore.editDaoBoardPost(payload, isChangingNoticeAt)
  } catch (err) {
    if (err instanceof AxiosError) {
      const errCode = err.response.status.toString()
      const errCodes = Object.keys(
        (messages.value[locale.value].dao as any).board.errorMessage
      )
      if (errCodes.includes(errCode)) {
        await useToast(t(`dao.board.errorMessage.${errCode}`))
        return
      }
      await useToast(t('dao.board.errorMessage.default'))
    }
  } finally {
    state.value = defaultState
    editState.value = editDefaultState
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
    await modalHide(modalsName.MODAL_REG_DAO_BOARD)
  }
}
const handleScroll = () => {
  if (appStore.isApp && window.scrollY === 0) {
    window.scrollTo({ top: 1 })
  }
}

onMounted(() => {
  if (appStore.isApp && window.scrollY === 0) {
    window.scrollTo({ top: 1 })
    window.addEventListener('scroll', handleScroll)
  }
})

onBeforeUnmount(() => {
  if (appStore.isApp) {
    window.removeEventListener('scroll', handleScroll)
  }
})
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_REG_DAO_BOARD"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <NovaBoxBase v-if="show" class="modal-box">
      <div class="modal-header">
        <div class="on-desktop">
          <h4 v-dompurify-html="t('dao.board.create.header')" class="title" />
          <div class="close-wrap" @click="handleOnClose">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'close-extend' }"
              class="btn-close"
            />
          </div>
        </div>
      </div>
      <!--        작성일떄-->
      <NovaLayoutScrollContainer v-if="!isEditMode" class="modal-body">
        <div class="content-top">
          <NovaUserCell
            :portrait="userStore.user?.userProflUrl"
            :user-name="userStore.user?.userNcnm"
            :user-sn="userStore.user?.userSn"
            :creator-link="userStore.user?.creatorLink"
          />

          <div v-if="isCreateAvailable && !isNotice" class="extra">
            <div class="ai-content-switch">
              <Tippy :interactive="true" :theme="'gray'" :placement="'top'">
                <span class="tooltip-label" />

                <template #content>
                  <p v-dompurify-html="t('aiContents.regGuide')" />
                </template>
              </Tippy>

              <span class="label">{{ t('aiContents.isAIContents') }}</span>

              <NovaSwitch
                :model-value="state.nttAiPrcuseAt === 'Y'"
                :theme="'IOS'"
                :disabled="!isCreateAvailable"
                @update="handleOnChangeAIContents"
              />
            </div>
          </div>
        </div>
        <div class="select-box grid-wrapper gap-12 grid-2">
          <NovaDropdown
            :placeholder="t('dao.board.create.dropdown.tempSave')"
            :menus="[]"
            @click="openTempList"
          />
          <NovaDropdown
            :placeholder="
              t('dao.board.create.dropdown.readPermission.placeHolder')
            "
            :menus="scopeMenus"
            :active-value="
              isEditMode ? editState.nttOthbcScopeCode : state.nttOthbcScopeCode
            "
            @update="(value) => {
                handleOnUpdateScope(value as DaoPostScopeCode)
              }"
          />
        </div>
        <div class="content-title">
          <NovaInput
            v-model="state.nttSj"
            class="input-title"
            :disabled="!isCreateAvailable"
            :max-byte="200"
            :placeholder="t('dao.board.create.title.placeHolder')"
          />
        </div>
        <div class="editor-section">
          <NovaTipTap
            v-model="state.nttCn"
            :placeholder="t('postCreate.placeholder.content')"
            :disabled="!isCreateAvailable"
            :is-loading="editorLoading"
            @on-loading="handleOnTipTapLoading"
          ></NovaTipTap>
        </div>
        <div class="content-options">
          <div v-if="!isNotice" class="content-option">
            <span v-dompurify-html="t('dao.board.create.option.cmments')" />
            <NovaSwitch
              :model-value="state.nttAnswerPermAt === 'Y'"
              :theme="'IOS'"
              :disabled="!isCreateAvailable"
              @update="handleOnChangeReply"
            />
          </div>
          <div v-if="checkAuthorLevel" class="content-option">
            <span v-dompurify-html="t('dao.board.create.option.notice')" />
            <NovaSwitch
              :model-value="state.nttNoticeAt === 'Y'"
              :theme="'IOS'"
              :disabled="!isCreateAvailable"
              @update="handleOnChangeNotice"
            />
          </div>
        </div>

        <div v-if="!isNotice" class="hashtag-wrap">
          <NovaCreateHashtag
            :hashtag-set="[]"
            :tags="state.hashTagList"
            @update-tags="updateTags"
          />
          <p class="hashtag-message">
            <NovaIcon
              class="icon"
              :icon="{ type: 'fill', name: 'lightning' }"
              :size="22"
            />
            <span v-dompurify-html="t(`postCreate.hashtagMessage`)" />
          </p>
        </div>
      </NovaLayoutScrollContainer>
      <!--        수정일떄-->
      <NovaLayoutScrollContainer v-else class="modal-body">
        <div class="content-top">
          <NovaUserCell
            :portrait="userStore.user?.userProflUrl"
            :user-name="userStore.user?.userNcnm"
            :user-sn="userStore.user?.userSn"
            :creator-link="userStore.user?.creatorLink"
          />

          <div v-if="!isNotice" class="extra">
            <div class="ai-content-switch">
              <Tippy :interactive="true" :theme="'gray'" :placement="'top'">
                <span class="tooltip-label" />

                <template #content>
                  <p v-dompurify-html="t('aiContents.regGuide')" />
                </template>
              </Tippy>

              <span class="label">{{ t('aiContents.isAIContents') }}</span>

              <NovaSwitch
                :model-value="editState.nttAiPrcuseAt === 'Y'"
                :theme="'IOS'"
                @update="handleOnChangeAIContents"
              />
            </div>
          </div>
        </div>
        <div class="select-box grid-wrapper gap-12 grid-1">
          <NovaDropdown
            :placeholder="
              t('dao.board.create.dropdown.readPermission.placeHolder')
            "
            :menus="scopeMenus"
            :active-value="editState.nttOthbcScopeCode"
            @update="(value: DaoPostScopeCode) => {
                        handleOnUpdateScope(value as DaoPostScopeCode)
                      }"
          />
        </div>
        <div class="content-title">
          <NovaInput
            v-model="editState.nttSj"
            class="input-title"
            :max-byte="200"
            :placeholder="t('dao.board.create.title.placeHolder')"
          />
        </div>
        <div class="editor-section">
          <NovaTipTap
            v-model="editState.nttCn"
            :placeholder="t('postCreate.placeholder.content')"
            :is-loading="editorLoading"
            @on-loading="handleOnTipTapLoading"
          ></NovaTipTap>
        </div>
        <div class="content-options">
          <div v-if="!isNotice" class="content-option">
            <span v-dompurify-html="t('dao.board.create.option.cmments')" />
            <NovaSwitch
              :model-value="editState.nttAnswerPermAt === 'Y'"
              :theme="'IOS'"
              @update="handleOnChangeReply"
            />
          </div>
          <div class="content-option">
            <span v-dompurify-html="t('dao.board.create.option.notice')" />
            <NovaSwitch
              :model-value="editState.nttNoticeAt === 'Y'"
              :theme="'IOS'"
              @update="handleOnChangeNotice"
            />
          </div>
        </div>

        <div v-if="!isNotice" class="hashtag-wrap">
          <NovaCreateHashtag
            :hashtag-set="[]"
            :tags="editState.hashTagList"
            @update-tags="updateTags"
          />
          <p class="hashtag-message">
            <NovaIcon
              class="icon"
              :icon="{ type: 'fill', name: 'lightning' }"
              :size="22"
            />
            <span v-dompurify-html="t(`postCreate.hashtagMessage`)" />
          </p>
        </div>
      </NovaLayoutScrollContainer>
      <div class="content-action">
        <NovaButtonText
          :label="t('postCreate.button.tempSave')"
          :theme="'secondary-gray'"
          :size="32"
          :full-width="true"
          class="btn-action"
          @click="handleOnTempSave"
        />

        <NovaButtonText
          :label="t('postCreate.button.preview')"
          :theme="'secondary-gray'"
          :size="32"
          :full-width="true"
          class="btn-action"
          @click="handleOnPreview"
        />

        <NovaButtonText
          :label="t('postCreate.button.save')"
          :theme="'primary-blue-dark'"
          :size="32"
          :full-width="true"
          class="btn-action"
          @click="handleOnSave"
        />
      </div>
    </NovaBoxBase>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;
    background-color: $color-bg-3;
    .modal-header {
      .on-desktop {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        flex-grow: 1;
        background-color: $color-white;
        border-radius: 20px 20px 0 0;
        @include text-style($text-display-medium);
        > .title {
          flex-grow: 1;
          color: $color-black;
        }
        .close-wrap {
          .btn-close {
            color: $color-text-2;
          }
        }
      }
    }

    .modal-body {
      display: flex;
      padding: 0 20px;
      border-radius: 20px;
      min-height: 396px;

      .content-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        .extra {
          flex-shrink: 0;
          display: inline-flex;
          align-items: center;
          gap: 6px;
        }
        .ai-content-switch {
          display: flex;
          align-items: center;
          .label {
            color: #97989d;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.7px;
            margin-left: 3px;
            margin-right: 6px;
          }
        }
      }
      .select-box {
        display: flex;
        margin-top: 20px;
        width: 100%;
      }
      .editor-section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        border: 1px solid $color-bg-custom-2;
        border-radius: 8px;
      }
      .content-title,
      .editor-section,
      .hashtag-wrap,
      .content-options {
        margin-top: 12px;
      }
      .content-options {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 12px;
        .content-option {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    }
  }
}
.hashtag-message {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10px;
  color: $color-text-3;
  .icon {
    color: $color-highlight;
  }
}
.tooltip-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-bg-custom-11;
  &:after {
    content: '?';
    color: $color-bg-3;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
  }
}

.content-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 20px 20px 51px;

  > .btn-action {
    width: 100px;
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
  .content-action {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-bottom: 24px;

    :deep(.btn) {
      width: 100%;
    }
  }
}
</style>
