<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import { $vfm } from 'vue-final-modal'
import type {
  NovaBoxDaoNoticeActionProps,
  PrivateAction,
} from './NovaBoxDaoNoticeAction.types'
import type {
  NoticeAction,
  ShowOptionMenu,
  ShowOptionMenuInfo,
  deleteDaoBoardPostPayload,
} from '@store'
import {
  // NovaModalCommon,
  NovaModalDialog,
  // NovaModalNoticeCreate,
} from '#components'

const props = withDefaults(defineProps<NovaBoxDaoNoticeActionProps>(), {
  popoverAppendTarget: null,
})

const isHide = ref(true)

const { t, locale, messages } = useI18n()
const { gtEvent } = useGoogleTag()
const creatorStore = useCreatorStore()
const daoStore = useDaoStore()
const userStore = useUserStore()
const layoutStore = useLayoutStore()
const { show: modalShow, hide: modalHide } = useModal()
const privateActions = computed(() => {
  return Object.keys(messages.value[locale.value].noticeAction).map((key) => ({
    id: key,
    name: t(`noticeAction.${key}`),
  })) as PrivateAction[]
})
// const authToken = getCookie<AuthToken>(cookieNames.AUTH_TOKEN)

// private action wrapper
const handleOnPrivateAction = async (id: NoticeAction) => {
  switch (id) {
    case 'modify':
      await modify()
      break
    case 'remove':
      remove()
      break
    default:
      throw new Error('post private action type is not match')
  }
}

// 포스트 삭제하기(팝업 호출)
const remove = () => {
  $vfm.show({
    component: NovaModalDialog,
    bind: {
      name: 'ConfirmPostRemove',
      btn: {
        grid: 2,
        isCancel: true,
      },
    },
    on: {
      async confirm() {
        await handleOnPostRemove()
        await $vfm.hide('ConfirmPostRemove')
      },
      cancel() {
        $vfm.hide('ConfirmPostRemove')
      },
    },
    slots: {
      title: t('confirm'),
      content: t('noticeRemoveConfirmMessage'),
    },
  })
}

const modify = async () => {
  await daoStore.fetchDaoBoardPost({
    daoNttSn: props.daoNttSn,
  })
  await modalHide(modalsName.MODAL_OPTION_MENU)
  await modalShow(modalsName.MODAL_REG_DAO_BOARD, {
    daoBoardData: {
      type: 'edit',
      fndrUserSn: creatorStore.profile?.userSn,
      source: daoStore.daoPost,
    },
  })
}

const handleOnPostRemove = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const payload: deleteDaoBoardPostPayload = {
      daoNttSn: props.daoNttSn,
    }
    await daoStore.deleteDaoBoardPost(payload, true)
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: 'Dao 공지사항 삭제',
      eventLabel: t('noticeRemoveConfirmMessage'),
      eventSlot: '',
      eventI18nAddr: useKoreanTranslation('noticeRemoveConfirmMessage'),
      eventComponent: 'Button',
      daoNttSn: props.daoNttSn,
    })
  } catch {
    await useToast(t('statusMessages.postRemove.error'))
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
    await modalHide(modalsName.MODAL_OPTION_MENU)
  }
}

const clickHandler = async () => {
  const isMobile = useCheckMobile()
  if (isMobile) {
    isHide.value = false
    const menuList: ShowOptionMenuInfo[] = privateActions.value.map((item) => {
      const menu: ShowOptionMenuInfo = {
        id: item.id,
        name: item.name,
        class: '',
        fn: async () => await handleOnPrivateAction(item.id),
      }
      return menu
    })

    await modalShow(modalsName.MODAL_OPTION_MENU, {
      menuType: 'btn',
      menuList,
    } as ShowOptionMenu)
  }
}
</script>

<template>
  <ClientOnly>
    <Tippy
      v-if="userStore.isSignIn"
      :append-to="popoverAppendTarget || 'parent'"
      :interactive="true"
      :placement="'bottom-end'"
      :theme="'popover'"
      @click.stop="clickHandler"
    >
      <NovaBoxCreatorNoticeActionButton
        :icon="{ type: 'outline', name: 'more-horizontal' }"
        :action-type="'privateActions'"
        :display-number="-1"
      />

      <template #content>
        <NovaBoxDaoNoticeActionPanel v-if="isHide" :dao-ntt-sn="daoNttSn" />
      </template>
    </Tippy>
  </ClientOnly>
</template>
