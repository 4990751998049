<script setup lang="ts">
import { $vfm } from 'vue-final-modal'
import type { NovaBoxDaoNoticeActionPanelProps } from './NovaBoxDaoNoticeActionPanel.types'
import type {
  NoticeAction,
  deleteDaoBoardPostPayload,
  useCreatorStore,
} from '@store'
import {
  // NovaModalCommon,
  NovaModalDialog,
  // NovaModalNoticeCreate,
} from '#components'

const props = defineProps<NovaBoxDaoNoticeActionPanelProps>()
const { gtEvent } = useGoogleTag()
const { t, locale, messages } = useI18n()
const daoStore = useDaoStore()
const layoutStore = useLayoutStore()
const creatorStore = useCreatorStore()
const { show: modalShow, hide: modalHide } = useModal()
const privateActions = computed(() => {
  return Object.keys(messages.value[locale.value].noticeAction).map((key) => ({
    id: key,
    name: t(`noticeAction.${key}`),
  })) as { id: NoticeAction; name: string }[]
})

// private action wrapper
const handleOnPrivateAction = async (id: NoticeAction) => {
  switch (id) {
    case 'modify':
      await modify(id)
      break
    case 'remove':
      remove(id)
      break
    default:
      throw new Error('post private action type is not match')
  }
}

// 포스트 삭제하기(팝업 호출)
const remove = (id: NoticeAction) => {
  const label: string | undefined = privateActions.value.find(
    (item) => item.id === id
  )?.name
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: 'Dao 공지사항 > 패널 > 삭제',
    eventLabel: label!,
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  $vfm.show({
    component: NovaModalDialog,
    bind: {
      name: 'ConfirmPostRemove',
      btn: {
        grid: 2,
        isCancel: true,
      },
    },
    on: {
      async confirm() {
        await handleOnPostRemove()
        await $vfm.hide('ConfirmPostRemove')
      },
      cancel() {
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '공지사항 > 패널 > 삭제 > 취소',
          eventLabel: label!,
          eventSlot: '',
          eventI18nAddr: '',
          eventComponent: 'Button',
        })
        $vfm.hide('ConfirmPostRemove')
      },
    },
    slots: {
      title: t('confirm'),
      content: t('noticeRemoveConfirmMessage'),
    },
  })
}

const modify = async () => {
  await daoStore.fetchDaoBoardPost({
    daoNttSn: props.daoNttSn,
  })
  await modalHide(modalsName.MODAL_OPTION_MENU)
  await modalShow(modalsName.MODAL_REG_DAO_BOARD, {
    daoBoardData: {
      type: 'edit',
      fndrUserSn: creatorStore.profile?.userSn,
      source: daoStore.daoPost,
    },
  })
  // const label: string | undefined = privateActions.value.find(
  //   (item) => item.id === id
  // )?.name
  // gtEvent('clickEvent', {
  //   eventCategory: '클릭',
  //   eventAction: '공지사항 > 패널 > 수정',
  //   eventLabel: label!,
  //   eventSlot: '',
  //   eventI18nAddr: '',
  //   eventComponent: 'Button',
  // })
  // const payload: CreatorNoticePayload = {
  //   crtrNttSn: props.cmtyNttSn,
  //   creatorUserSn: props.userSn,
  // }
  // await creatorStore.fetchCreatorNotice(payload)
  // await modalShow(
  //   {
  //     component: NovaModalCommon,
  //     bind: {
  //       name: modalsName.MODAL_REG_CREATOR_NOTICE,
  //       showCloseBtn: true,
  //       mobileFull: true,
  //       hasInnerScroll: true,
  //       type: 'create',
  //     },
  //     on: {
  //       customClose: () => {
  //         gtEvent('clickEvent', {
  //           eventCategory: '클릭',
  //           eventAction: '공지사항 수정 닫기',
  //           eventLabel: '',
  //           eventSlot: 'Modal',
  //           eventI18nAddr: '',
  //           eventComponent: 'Button',
  //         })
  //         useEvent('notice:createClose')
  //       },
  //     },
  //     slots: {
  //       title: t('modifyNotice'),
  //       contents: {
  //         component: NovaModalNoticeCreate,
  //         on: {
  //           close: async () => {
  //             await modalHide(modalsName.MODAL_REG_CREATOR_NOTICE)
  //             await fetchCreatorNoticeList(true)
  //           },
  //         },
  //       },
  //     },
  //   },
  //   { cmtyNttSn: props.cmtyNttSn }
  // )
}

// // fetch my comments
// const fetchCreatorNoticeList = async (refresh: boolean) => {
//   try {
//     isLoading.value = true
//     isError.value = false
//     await creatorStore.fetchCreatorNoticeList(
//       {
//         ...creatorStore.creatorNoticeList.payload,
//         creatorUserSn: creatorStore.profile!.userSn ,
//       },
//       refresh
//     )
//   } catch {
//     isError.value = true
//   } finally {
//     isLoading.value = false
//   }
// }

const handleOnPostRemove = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const payload: deleteDaoBoardPostPayload = {
      daoNttSn: props.daoNttSn,
    }
    await daoStore.deleteDaoBoardPost(payload, true)
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: 'Dao 공지사항 삭제',
      eventLabel: t('noticeRemoveConfirmMessage'),
      eventSlot: '',
      eventI18nAddr: useKoreanTranslation('noticeRemoveConfirmMessage'),
      eventComponent: 'Button',
      daoNttSn: props.daoNttSn,
    })
  } catch {
    await useToast(t('statusMessages.postRemove.error'))
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}
</script>

<template>
  <NovaBoxBase class="private-panel" :floating="true">
    <button
      v-for="item in privateActions"
      :key="item.id"
      type="button"
      class="action-item"
      @click.stop="handleOnPrivateAction(item.id)"
    >
      <span class="label">{{ item.name }}</span>
      <NovaIcon
        :icon="{ type: 'outline', name: 'chev-compact-right' }"
        class="chev"
      />
    </button>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.private-panel {
  width: 200px !important;
  padding: 10px 0 !important;

  .action-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    height: 30px;
    padding: 0 15px 0 20px;

    > .label {
      @include text-style($text-body-14-regular);
      line-height: 18px;
      color: $color-text-2;
    }

    > .chev {
      color: $color-text-3;
    }
  }
}
</style>
