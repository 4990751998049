import axios, { type AxiosInstance, type AxiosResponse } from 'axios'
import { type AuthToken } from '~/store/user/type'
import UserUrl from '@/store/user/url'
import { createBaseUrl } from '~/api/interceptors/baseUrl'
import { ErrorStatus } from '~/configs/error'

/**
 * set token to request header
 * @param instance
 */
export const setTokenToRequestHeader = (instance: AxiosInstance) => {
  instance.interceptors.request.use((config) => {
    const { cookieNames, getCookie } = useCookies()

    if (process.client) {
      const authToken = getCookie<AuthToken>(cookieNames.AUTH_TOKEN)

      authToken
        ? (config.headers.Authorization = `${authToken.grantType} ${authToken.accessToken}`)
        : delete config.headers.Authorization
    }
    return config
  })
}

/**
 * set multiple request refreshing
 * @param instance
 */
export const setMultipleRequestRefreshing = (instance: AxiosInstance) => {
  const { cookieNames, getCookie, setCookie } = useCookies()
  const requireRefreshingStatus = [ErrorStatus.ACCESS_TOKEN_EXPIRE]
  let refreshPromise: Promise<AxiosResponse<AuthToken>> | null = null
  const clearPromise = () => (refreshPromise = null)

  // create new axios instance & refresh access token
  const refreshToken = async (authToken: AuthToken) => {
    const baseUrl = createBaseUrl()

    const response = await axios.post<AuthToken>(
      `${baseUrl}${UserUrl.POST_ACCESS_TOKEN_REFRESH}`,
      null,
      {
        headers: {
          Authorization: `${authToken!.grantType} ${authToken!.refreshToken}`,
          'Auth-Status': 'REFRESH',
        },
      }
    )
    return response
  }
  instance.interceptors.response.use(undefined, async (error) => {
    if (process.server) return Promise.reject(error)
    const originalRequest = error.config

    if (
      requireRefreshingStatus.includes(error.response.status) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true

      console.info(
        `%c JWT REFRESH PROCESS 1. [${originalRequest.url}]: jwt token error: ${error.response.status}`,
        'background: #222; color: #bada55',
        error
      )

      if (!refreshPromise) {
        const authToken = getCookie<AuthToken>(cookieNames.AUTH_TOKEN)!
        refreshPromise = refreshToken(authToken).finally(clearPromise)
      }

      try {
        const { data } = await refreshPromise
        setCookie(cookieNames.AUTH_TOKEN, data, 30, 'days')
        instance.defaults.headers.common.Authorization = `${data.grantType} ${data.accessToken}`
        originalRequest.headers.Authorization = `${data.grantType} ${data.accessToken}`

        console.info(
          `%c JWT REFRESH PROCESS 2-1. [${originalRequest.url}]: jwt refresh access token success: `,
          'background: #222; color: #bada55',
          data
        )
      } catch (err) {
        console.info(
          `%c JWT REFRESH PROCESS 2-2. [${originalRequest.url}]: jwt refresh access token fail: `,
          'background: #222; color: #bada55',
          err
        )
        return Promise.reject(err)
      }

      return instance(originalRequest)
    }

    return Promise.reject(error)
  })
}

/**
 * OLD
 */
// export const setMultipleRequestRefreshing = (instance: AxiosInstance) => {
//   const { cookieNames, getCookie, setCookie } = useCookies()
//   const jwtStatus = [403]
//   const refreshAxiosInstance = axios.create({ baseURL: createBaseUrl() })
//   let isRefreshing = false
//   let failedQueue: {
//     resolve: (value: unknown) => void
//     reject: (reason?: any) => void
//   }[] = []
//
//   const processQueue = (error: any, authToken: AuthToken | null = null) => {
//     failedQueue.forEach((prom) => {
//       if (error) {
//         prom.reject(error)
//       } else {
//         prom.resolve(authToken)
//       }
//     })
//
//     failedQueue = []
//   }
//
//   instance.interceptors.response.use(undefined, (error) => {
//     if (process.server) return Promise.reject(error)
//     const originalRequest = error.config
//     console.info(
//       '%c JWT PROCESS 2-1. start response error start ',
//       'background: #222; color: red',
//       error
//     )
//
//     console.info(
//       '%c JWT PROCESS 2-2. set original request: error.config ',
//       'background: #222; color: red',
//       originalRequest,
//       error.response.status
//     )
//
//     if (jwtStatus.includes(error.response.status) && !originalRequest._retry) {
//       console.info(
//         '%c JWT PROCESS 3-1. request refresh access token: start',
//         'background: #222; color: green',
//         error.response.status
//       )
//       const authToken = getCookie<AuthToken>(cookieNames.AUTH_TOKEN)
//
//       if (isRefreshing) {
//         return new Promise(function (resolve, reject) {
//           failedQueue.push({ resolve, reject })
//         })
//           .then((token) => {
//             console.info(
//               '%c JWT PROCESS. push failed queue ',
//               'background: #222; color: red',
//               token
//             )
//             originalRequest.headers.Authorization = `${
//               authToken!.grantType
//             } ${token}`
//             return instance(originalRequest)
//           })
//           .catch((err) => {
//             return Promise.reject(err)
//           })
//       }
//
//       originalRequest._retry = true
//       isRefreshing = true
//
//       return new Promise((resolve, reject) => {
//         refreshAxiosInstance
//           .post<AuthToken>(UserUrl.POST_ACCESS_TOKEN_REFRESH, null, {
//             headers: {
//               Authorization: `${authToken!.grantType} ${
//                 authToken!.refreshToken
//               }`,
//               'Auth-Status': 'REFRESH',
//             },
//           })
//           .then(({ data }) => {
//             setCookie(cookieNames.AUTH_TOKEN, data, 30, 'days')
//             instance.defaults.headers.common.Authorization = `${data.grantType} ${data.accessToken}`
//             originalRequest.headers.Authorization = `${data.grantType} ${data.accessToken}`
//             processQueue(null, data)
//             resolve(instance(originalRequest))
//           })
//           .catch((err) => {
//             processQueue(err, null)
//             reject(err)
//           })
//           .finally(() => {
//             isRefreshing = false
//           })
//       })
//     }
//
//     console.info(
//       '%c JWT PROCESS 2-3. jwt refreshing queue end ______________________________________________________________________________________________________________________________________________________________________________________________ ',
//       'background: #222; color: red'
//     )
//
//     return Promise.reject(error)
//   })
// }
