<script setup lang="ts">
import type {
  NovaHashtagExtenderEmits,
  NovaHashtagExtenderProps,
} from './NovaHashtagExtender.types'
import type { Hashtag } from '@store'

const emit = defineEmits<NovaHashtagExtenderEmits>()
const props = withDefaults(defineProps<NovaHashtagExtenderProps>(), {
  minHashtags: 5,
  maxHashtags: 10,
})

const { show: modalShow } = useModal()
const showMore = ref(false)
const extendAble = computed(() => props.hashtags.length > props.minHashtags)
const showTagsList = computed(() =>
  showMore.value
    ? props.hashtags.slice(0, props.maxHashtags)
    : props.hashtags.slice(0, props.minHashtags)
)

const handleOnChangeActiveHashtag = (hashtag: Hashtag) => {
  emit('onChangeActiveHashtag', hashtag)
}

const handleOnToggleExtend = () => {
  showMore.value = !showMore.value
}

const handleOnOpenHashtagAll = () => {
  modalShow(modalsName.MODAL_ALL_HASHTAGS)
}
</script>

<template>
  <div :class="['hashtag-extender', { extended: showMore }]">
    <div class="hashtags">
      <NovaHashtag
        v-for="hashtag in showTagsList"
        :key="hashtag.hashtagSn"
        :hashtag="hashtag"
        :able-action="true"
        :on="activeHashtag.hashtagSn === hashtag.hashtagSn"
        class="hashtag-item"
        @click="() => handleOnChangeActiveHashtag(hashtag)"
      />

      <div v-if="extendAble" class="indicator-wrap">
        <NovaButtonIcon
          :icon="{ type: 'outline', name: 'carrot-bottom' }"
          class="indicator"
          @click="handleOnToggleExtend"
        />
      </div>
    </div>

    <div v-if="showMore" class="show-all-hashtag-wrap">
      <NovaButtonText
        :label="$t('allView')"
        :theme="'primary-blue-dark'"
        :size="32"
        @click="handleOnOpenHashtagAll"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hashtag-extender {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  &.extended {
    .indicator-wrap > .indicator {
      transform: rotate(180deg);
    }
  }

  .hashtags {
    flex-wrap: wrap;
    display: flex;
    column-gap: 8px;
    row-gap: 10px;
    width: 100%;
  }

  .indicator-wrap {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > .indicator {
      color: $color-text-4;
      @include transition(transform 0.2s ease-in-out);
    }
  }

  .show-all-hashtag-wrap {
    display: none;
    align-content: center;
    justify-content: center;
  }

  @include mobile {
    .show-all-hashtag-wrap {
      display: flex;
    }
  }
}
</style>
