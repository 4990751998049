<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { PremiumSponsorDetail, PremiumSponsorDetailPayload } from '@store'
import { NovaLayoutScrollContainer } from '#components'

defineOptions({
  inheritAttrs: false,
})

const { t } = useI18n()
const mypageStore = useMyPageStore()
const { hide: modalHide } = useModal()
const show = ref(false)
const premiumSponsorDetailRef = ref<InstanceType<
  typeof NovaLayoutScrollContainer
> | null>(null)
const premiumSponsorDetail = ref<PremiumSponsorDetail>()
const isLoading = ref(false)
const isFail = ref(false)
const errorMessage = ref('')

// 팝업 열릴때 호출하는 쪽에서 파라미터 받아 후원 상세 조회
const handleOnGetParams = async (evt: RouteParams) => {
  const payload = evt.value as unknown as PremiumSponsorDetailPayload

  try {
    isLoading.value = true
    isFail.value = false
    const data = await mypageStore.fetchSponsorDetail(payload)
    premiumSponsorDetail.value = data
  } catch (err) {
    errorMessage.value = t('sponsors.index.error')
    isFail.value = true
  } finally {
    isLoading.value = false
  }
}

/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_SPONSOR_DETAIL)
}

/**
 * 팝업 닫은 후 스토어에서 w3cf 상세 초기화
 */
const handleOnClosed = () => {
  premiumSponsorDetail.value = undefined
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_SPONSOR_DETAIL"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @closed="handleOnClosed"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaLoadingIndicator
        v-if="isLoading"
        :fill="true"
        :bg-bright="'dark'"
        class="async-status-message"
      />

      <NovaBoxBase v-if="!isLoading && show" class="modal-box">
        <div class="modal-header">
          <div class="on-mobile">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'arrow-left' }"
              :theme="'transparent'"
              :size="24"
              class="btn-close-on-mobile"
              @click="handleOnClose"
            />
          </div>

          <div class="on-desktop">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'close-extend' }"
              :theme="'transparent'"
              :size="24"
              class="btn-close"
              @click="handleOnClose"
            />
          </div>
        </div>

        <NovaLayoutScrollContainer
          ref="premiumSponsorDetailRef"
          class="modal-body"
        >
          <NovaBoxPremiumSponsor
            v-if="premiumSponsorDetail && !isFail && !isLoading"
            :source="premiumSponsorDetail!"
          />

          <NovaBoxEmptyMessage
            v-if="isFail"
            :message="errorMessage"
            :fill="true"
            class="async-status-message"
          />

          <NovaScrollToTopButton
            :target="premiumSponsorDetailRef?.$el || null"
            :bottom-position="64"
          />
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;

    .modal-header {
      flex-shrink: 0;
      height: 0;
      padding: 0;
      background-color: $color-bg-3;

      .on-mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        .btn-close-on-mobile {
        }
      }

      .on-desktop {
        .btn-close {
          position: absolute;
          top: 30px;
          right: 30px;
          color: $color-bg-3;
        }
      }
    }

    .modal-body {
      border-radius: $radius-10;

      > .post {
        border-radius: 0;
        box-shadow: none;
      }

      .async-status-message {
        min-height: 200px;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-header {
        flex-shrink: 0;
        height: 56px;
        padding: 0 20px;

        .on-mobile {
          display: flex;
        }

        .on-desktop {
          .btn-close {
            display: none;
          }
        }
      }

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
