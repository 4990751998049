<script setup lang="ts">
import { AxiosError } from 'axios'
import type {
  NovaBoxPostCommentPrivateActionPanelEmits,
  NovaBoxPostCommentPrivateActionPanelProps,
} from './NovaBoxPostCommentPrivateActionPanel.types'
import type { CommentAndReplyPrivateActionType } from '@store'
import type {
  DetailType,
  MenuSetType,
} from '@components/NovaModalReport/NovaModalReport.types'
import { NovaModalCommon, NovaModalDialog, NovaModalReport } from '#components'

const emit = defineEmits<NovaBoxPostCommentPrivateActionPanelEmits>()
const props = defineProps<NovaBoxPostCommentPrivateActionPanelProps>()

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { show: modalShow, hide: modalHide } = useModal()
const postStore = usePostStore()
const etcStore = useEtcStore()
const reportCode = ref<string | null>(null)
const reportContents = ref('')
const reportMenuSet = computed<MenuSetType[]>(() =>
  etcStore.reportCodes.map((item) => ({
    label: item.stdCodeNm,
    value: item.stdCode,
    contentRequiredAt: item.contentRequiredAt,
  }))
)
const reportForm = computed<DetailType>(() => ({
  dropdownPlaceholder: t('reportForm.dropdownPlaceholder'),
  textareaPlaceholder: t('reportForm.textareaPlaceholder'),
  textareaMax: 4000,
  btnLabel: [t('reportAction.cancel'), t('reportAction.send')],
}))

// 댓글 & 답글 액션 게이트
const handleOnPrivateAction = (id: CommentAndReplyPrivateActionType) => {
  switch (id) {
    case 'hide':
      hideComment()
      break
    case 'report':
      reportComment()
      break
    case 'delete':
      deleteComment()
      break
    case 'edit':
      editComment()
      break
    default:
      throw new Error('comment private action type is not match')
  }
}

// 댓글 & 답글 숨기기
const hideComment = async () => {
  if (props.source.bkFlag === '1') {
    useToast(t('statusMessages.commentsHide.denied'))
    return
  }

  try {
    emit('onLoading', true)
    await postStore.reqCommentHide({
      cmtyNttSn: postStore.post!.cmtyNttSn,
      cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
    })
  } catch {
    t('statusMessages.commentsHide.error')
  } finally {
    emit('onLoading', false)
  }
}

// 댓글 & 답글 신고(신고 하기 다이얼로그 모달 호출)
const reportComment = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '댓글 신고 클릭',
    eventLabel: t('commentAndReplyPrivateAction.report'),
    eventSlot: '',
    eventI18nAddr: useKoreanTranslation('commentAndReplyPrivateAction.report'),
    eventComponent: 'Button',
    cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
    nttAnswerCn: props.source.nttAnswerCn,
    userNcnm: props.source.userNcnm,
  })
  await etcStore.fetchReportCodes({ nttTyCode: 'A' })
  await modalShow({
    component: NovaModalCommon,
    bind: {
      name: modalsName.MODAL_REPORT_COMMENT,
      showCloseBtn: true,
    },
    on: {
      close: async () => {
        await modalHide(modalsName.MODAL_REPORT_COMMENT)
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: `${useKoreanTranslation(
            'commentAndReplyPrivateAction.report'
          )} 모달 닫기`,
          eventLabel: '',
          eventSlot: '',
          eventI18nAddr: '',
          eventComponent: 'NovaModalCommon',
        })
        reportCode.value = null
      },
    },
    slots: {
      title: t('commentAndReplyPrivateAction.report'),
      contents: {
        component: NovaModalReport,
        bind: {
          detail: reportForm.value,
          menuSet: reportMenuSet.value,
          where: t('commentAndReplyPrivateAction.report'),
          cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
          title: useKoreanTranslation('commentAndReplyPrivateAction.report'),
        },
        on: {
          close: async () => {
            await modalHide(modalsName.MODAL_REPORT_COMMENT)
            reportCode.value = null
          },
          updateMenu: (value: string) => {
            reportCode.value = value
          },
          updateText: (value: string) => {
            reportContents.value = value
          },
          confirm: async () => {
            await handleOnReportSend()
          },
        },
      },
    },
  })
}

// 댓글 & 답글 신고하기
const handleOnReportSend = async () => {
  const payload = {
    cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
    rptCn: reportContents.value,
    sanctnsRptClCode: reportCode.value!,
  }

  try {
    emit('onLoading', true)
    await postStore.reqCommentReport(payload)
    useToast(t('statusMessages.commentsReport.success'))
    await modalHide(modalsName.MODAL_REPORT_COMMENT)
  } catch (err) {
    if (err instanceof AxiosError) {
      const status = err.response?.status
      switch (status) {
        case 2000:
        case 2006:
        case 2010:
        case 2012:
        case 4002:
          useToast(t(`statusMessages.commentsReport.errors.${status}`))
          break
        case 2020:
          await modalHide(modalsName.MODAL_REPORT_COMMENT)
          useToast(t(`statusMessages.commentsReport.errors.${status}`))
          postStore.deleteComment(
            {
              cmtyNttAnswerSn: payload.cmtyNttAnswerSn,
            },
            false,
            { cmtyNttSn: props.cmtyNttSn, answerCount: props.answerCount }
          )
          break
        default:
          useToast(t('statusMessages.commentsReport.errors.unknown'))
          break
      }
    }
  } finally {
    emit('onLoading', false)
  }
}

// 댓글 & 답글 삭제(삭제하기 다이얼로그 모달 호출)
const deleteComment = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '댓글 삭제 클릭',
    eventLabel: '',
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  if (props.source.deleteAt === 'Y') {
    useToast(t('statusMessages.commentsDelete.denied'))
    return
  }
  await modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_CONFIRM_DELETE_COMMENT,
      btns: [
        {
          label: t('dialog.confirmCommentDelete.action.negative'),
          theme: 'secondary-gray-200',
          size: 32,
          onClick: async () => {
            gtEvent('replyAction', {
              eventCategory: '댓글',
              eventAction: '댓글 삭제 취소',
              eventLabel: t('dialog.confirmCommentDelete.action.negative'),
              eventSlot: '',
              eventI18nAddr: useKoreanTranslation(
                'dialog.confirmCommentDelete.action.negative'
              ),
              eventComponent: 'Button',
            })
            await modalHide(modalsName.MODAL_CONFIRM_DELETE_COMMENT)
          },
        },
        {
          label: t('dialog.confirmCommentDelete.action.positive'),
          theme: 'primary-blue-dark',
          size: 32,
          onClick: async () => {
            await handleOnDeleteComment()
            await modalHide(modalsName.MODAL_CONFIRM_DELETE_COMMENT)
          },
        },
      ],
    },
    slots: {
      title: t('dialog.confirmCommentDelete.title'),
      content: t('dialog.confirmCommentDelete.description'),
    },
  })
}

// 댓글 & 답글 삭제
const handleOnDeleteComment = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '삭제 버튼 클릭',
    eventLabel: t('dialog.confirmCommentDelete.action.positive'),
    eventSlot: '댓글 삭제 모달',
    eventI18nAddr: useKoreanTranslation(
      'dialog.confirmCommentDelete.action.positive'
    ),
    eventComponent: 'Button',
    cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
  })
  try {
    await postStore.deleteComment(
      {
        cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
      },
      true,
      { cmtyNttSn: props.cmtyNttSn, answerCount: props.answerCount }
    )
    gtEvent('replyAction', {
      eventCategory: '댓글',
      eventAction: '댓글 삭제',
      eventLabel: t('dialog.confirmCommentDelete.action.positive'),
      eventSlot: '',
      eventI18nAddr: useKoreanTranslation(
        'dialog.confirmCommentDelete.action.positive'
      ),
      eventComponent: 'Button',
      cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
    })
  } catch {
    useToast(t('statusMessages.commentsDelete.error'))
  }
  // finally {
  //   emit('onLoading', false)
  // }
}

// 댓글 & 답글 수정
const editComment = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '댓글 수정 클릭',
    eventLabel: '',
    eventSlot: '액션 패널',
    eventI18nAddr: '수정',
    eventComponent: 'Button',
    cmtyNttAnswerSn: props.source.cmtyNttAnswerSn,
  })
  emit('onEditMode', true)
}
</script>

<template>
  <NovaBoxBase class="comment-private-action-panel" :floating="true">
    <button
      v-for="item in actions"
      :key="item.id"
      type="button"
      class="action-item"
      @click.stop="handleOnPrivateAction(item.id)"
    >
      <span class="label">{{ item.name }}</span>
      <NovaIcon
        :icon="{ type: 'outline', name: 'chev-compact-right' }"
        class="chev"
      />
    </button>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.comment-private-action-panel {
  width: 200px !important;
  padding: 10px 0 !important;

  .action-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    height: 30px;
    padding: 0 15px 0 20px;

    > .label {
      @include text-style($text-body-14-regular);
      line-height: 18px;
      color: $color-text-2;
    }

    > .chev {
      color: $color-text-3;
    }
  }
}
</style>
