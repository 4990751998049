import * as KpopFestivalType from './type'
import KpopFestivalService from './service'
import { initKpopFestivalState } from './init'

export * from './init'
export * from './type'
export const useKpopFestivalStore = defineStore('kpopfestival', () => {
  const kpopFestivalTerms = ref(initKpopFestivalState.kpopFestivalTerms)
  const kpopFestivalApplyStatus = ref(
    initKpopFestivalState.kpopFestivalApplyStatus
  )

  /**
   * 커버댄스 이벤트 참가 신청
   */
  const reqKpopFestivalApply = async (
    payload: KpopFestivalType.KpopFestivalFormPayload
  ) => {
    const { data } = await KpopFestivalService.reqKpopFestivalApply(payload)
    return data
  }

  /**
   * 커버댄스 이벤트 약관 목록 조회
   */
  const fetchKpopFestivalTerms = async () => {
    const { data } = await KpopFestivalService.fetchKpopFestivalTerms()
    kpopFestivalTerms.value = data
    return data
  }

  /**
   * 커버댄스 이벤트 약관 상세 조회
   */
  const fetchKpopFestivalTerm = async (
    payload: KpopFestivalType.KpopFestivalTermPayload
  ) => {
    const { data } = await KpopFestivalService.fetchKpopFestivalTerm(payload)
    return data
  }

  /**
   * 커버댄스 이벤트 참가 신청 현황 조회
   */
  const fetchKpopFestivalApplyStatus = async () => {
    const { data } = await KpopFestivalService.fetchKpopFestivalApplyStatus()
    kpopFestivalApplyStatus.value = data
    return data
  }

  return {
    kpopFestivalTerms,
    kpopFestivalApplyStatus,
    reqKpopFestivalApply,
    fetchKpopFestivalTerms,
    fetchKpopFestivalTerm,
    fetchKpopFestivalApplyStatus,
  }
})
