import * as KpopFestivalType from '@store/kpopfestival/type'
import KpopFestivalApi from '@store/kpopfestival/url'
import { api } from '@api'

export default {
  /**
   * 커버댄스 이벤트 신청
   */
  reqKpopFestivalApply(payload: KpopFestivalType.KpopFestivalFormPayload) {
    return api.post<{}>(KpopFestivalApi.REQ_KPOP_FESTIVAL_APPLY, payload)
  },

  /**
   * 커버댄스 이벤트 약관 상세 조회
   */
  fetchKpopFestivalTerm(payload: KpopFestivalType.KpopFestivalTermPayload) {
    return api.get<Array<KpopFestivalType.KpopFestivalTerm>>(
      KpopFestivalApi.FETCH_KPOP_FESTIVAL_TERM,
      { params: payload }
    )
  },

  /**
   * 커버댄스 이벤트 약관 목록 조회
   */
  fetchKpopFestivalTerms() {
    return api.get<Array<KpopFestivalType.KpopFestivalTerms>>(
      KpopFestivalApi.FETCH_KPOP_FESTIVAL_TERMS
    )
  },

  /**
   * 커버댄스 이벤트 참가 신청 현황 조회
   */
  fetchKpopFestivalApplyStatus() {
    return api.get<KpopFestivalType.KpopFestivalApplyStatus>(
      KpopFestivalApi.FETCH_KPOP_FESTIVAL_STATUS
    )
  },
}
