import type { DaoMemberFilter, DaoPostsOrderBy } from '@store/dao/type'
import { commonError } from '@locales/ko/commonError'
import type { DaoCreatorsFilter } from '@store'
/**
 * DAO
 */
export const dao = {
  opening: {
    dialog: {
      title: 'Notice',
      creatorMessage:
        'FAM applications can only be made by Artists. Please <strong>apply for the Artist</strong> and use it.',
      followMessage:
        'Only artists with more than 1,000 followers can apply for FAM.',
      dissolutionMessage:
        '<strong>You can apply after 30 days based on the FAM dissolution date.</strong>',
      cancelMessage: 'Are you going to cancel the FAM application?',
      completeMessage:
        'FAM join request has been completed. Please wait for the results.',
      btnBox: {
        checkBtn: 'OK',
        cancelBtn: 'Cancel',
        applyBtn: 'Applying for a creator',
      },
    },
  },
  entry: {
    possible: 'Create a FAM',
  },
  form: {
    title: 'Create a FAM',
    name: {
      title: 'Please write down the FAM name',
      placeHolder: 'Please enter it in English.',
      button: 'ID duplicate check',
      message: {
        possible: 'Available.',
        impossible: 'Already exists.',
      },
    },
    desc: {
      title: 'Please briefly write about the FAM',
      placeHolder: 'Please briefly write about the FAM',
    },
    nft: {
      title: 'Please register the image to be used as a membership NFT.',
      placeHolder: 'Please upload a image.',
      button: {
        file: 'Find a file',
        add: 'Attachment',
        delete: 'Delete',
      },
      message: {
        fileError: 'File has not been uploaded.',
        volume: 'Please upload the file size of 15MB or less.',
        size: 'The width and length of the image must be at least 500px.',
        guide: 'The width and length of the image must be the same.',
        imgError: 'Invalid image file.',
        imgSuccess: 'Your image has been uploaded.',
      },
      description: [
        'Image capacity and format: <strong>JPG, PNG files over 15MB</strong>',
        'Image size: <strong>over 500*500px</strong>',
      ],
    },
    caution: {
      title: 'Precautions',
      description: [
        '- Requirements for application: More than 1,000 followers.',
        "- It is difficult to change FAM's information, so please write it carefully.",
        '- Please check the NFT image guide and upload the file that fits the form.',
        '- Approval of the application will be completed within three to seven business days from the application date.',
      ],
      label: {
        duplication: 'ID duplicate check',
      },
      message: {
        guide: 'Please check the precautions.',
      },
      check: 'checked the precautions.',
    },
    dialog: {
      title: 'Notifications',
      content: 'Your FAM application has been completed.',
      btnBox: {
        checkBtn: 'OK',
      },
    },
    message: {
      available: 'Available.',
      notAvailable: 'Already exists.',
      notWritten: 'You have not created one.',
      overSize: 'Images can be attached up to 15MB.',
      caution: 'Please check the precautions.',
      check: 'You need to double check.',
    },
    errorMessage: {
      1999: 'It is not possible because users are restricted from use.',
      2006: commonError.network.message, // 필수값이 누락되었습니다.,
      2024: 'An error occurred in wallet creation.',
      2033: commonError.network.message, // 파라미터 패턴 오류
      2038: 'Capacity exceeded.',
      2043: 'FAM application is not possible.',
      2901: 'Please check the image format again.',
      2902: 'Please check the image size again.',
      default: commonError.network.message,
    },
  },
  approve: {
    dialog: {
      title: 'Notice',
      companionMessage:
        'FAM application has been rejected. Please re-apply after confirming the reason.',
      companionReasonTitle: '*Reasons for FAM rejection:',
      approvalMessage:
        'FAM participation has been completed. Switch to FAM in My Home now.',
      btnBox: {
        checkBtn: 'OK',
      },
    },
  },
  accession: {
    btn: {
      apply: 'Join',
      pending: 'Reviewing',
    },
    applyModel: {
      title: '[{daoNm}] Application to join FAM',
      message: 'Write down the reason for the application',
      placeholder: 'Write down the reason for the application',
      errorMessage: 'You have not created one.',
      btnBox: {
        applyBtn: 'Create',
        participating: 'Joined',
        pending: 'Reviewing',
      },
    },
    dialog: {
      title: 'Notice',
      companionMessage:
        'FAM application has been rejected. Please re-apply after confirming the reason.',
      companionReasonTitle: '*Reasons for FAM rejection:',
      approvalMessage:
        'FAM participation has been completed. Switch to FAM in My Home now.',
      applyMessage: '<strong>[{daoNm}]</strong> FAM join has been completed.',
      beforeMessage:
        "You don't have the right to vote. Please join the FAM and use it.",
      btnBox: {
        checkBtn: 'OK',
      },
    },
  },
  vote: {
    message: {
      empty: 'No voting agenda',
    },
    create: {
      header: 'Create a Vote',
      conditions: {
        title: 'conditions for participation',
        errMsg: "'Please select 'NFT'.",
        placeHolder: 'Select NFT',
      },
      name: {
        title: 'Title',
        placeHolder: 'Please enter the voting title.',
        errMsg: 'You have not created one.',
      },
      contents: {
        title: 'Options',
        add: 'Add',
        placeHolder: 'Options {index}',
        over: 'The maximum number of voting items is 10.',
        error: {
          select: 'At least two items are required.',
          value: 'There are items you have not entered.',
        },
      },
      period: {
        title: 'Period',
        start: 'Start date',
        errStartMsg: 'Please select the start date first.',
        end: 'End date',
        errMsg: 'You have not selected a voting period.',
      },
      button: {
        create: 'Create',
      },
    },
    modify: {
      header: 'Edit Voting',
      button: {
        delete: 'Delete',
        modify: 'Modify',
      },
    },
    list: {
      badge: 'Participation',
      message: "It's an ongoing vote.",
    },
    createButton: 'Create',
    sort: {
      all: 'All',
      inProgress: 'Ongoing',
      expected: 'Coming Soon',
      end: 'Completion',
    },
    dialog: {
      title: 'Notice',
      deleteMessage: 'Do you want to delete the vote?',
      modifyMessage: 'Do you want to modify the vote?',
      actions: {
        negative: 'Cancel',
        positive: 'OK',
      },
    },
    detail: {
      badge: {
        noVote: 'Non-participation',
      },
      count: 'votes',
      button: {
        votesAvailable: 'Voting',
        votesUnavailable: 'You are not eligible to vote.',
        completeVote: 'You are not eligible to vote.',
      },
    },
    success: 'The vote has been completed.',
    errorMessage: {
      1999: 'It is not possible because users are restricted from use.',
      2006: commonError.network.message, // '필수값이 누락되었습니다.',
      2039: 'You can register up to 2 to 10 voting questions.',
      2040: 'FAM Voting Period entered incorrectly. Please re-enter.',
      2041: 'Vote modification is not possible.',
      2042: "You can't vote.",
      2049: commonError.network.message, // 'NFT 스냅샷 자산 조회 에러',
      2050: commonError.network.message, // '스냅샷 상태 조회 에러',
      2990: 'Request is being processed, please try again in a moment.',
      5400: 'You do not have permission.',
      default: commonError.network.message,
    },
    // } as { [Property in CommentsAndRepliesOrderBy]: string },
  },
  board: {
    title: 'Board',
    createButton: 'Post',
    list: {
      title: 'Notice',
      message: 'Please check the post after signing up for FAM.',
    },
    create: {
      header: 'posting',
      dropdown: {
        tempSave: 'Load Draft',
        readPermission: {
          placeHolder: 'Select View Permissions',
          follower: 'I LIKE LM user',
          onlyMember: 'FAM members only',
        },
      },
      title: {
        placeHolder: 'Write a title',
      },
      option: {
        cmments: 'Allow comments',
        notice: 'Registration of notice',
      },
      contents: {
        placeHolder: 'Select View permissions first.',
      },
    },
    sorter: {
      commentAndReply: {
        newest: 'newest',
        oldest: 'oldest',
      } as { [Property in DaoPostsOrderBy]: string },
    },
    errorMessage: {
      1999: 'It is not possible because users are restricted from use.',
      2000: 'The article could not be found.',
      2006: commonError.network.message, // '필수값이 누락되었습니다.',
      2009: commonError.network.message, // '지정되지 않은 타입 코드 입니다.',
      2010: 'You have been duplicated.',
      2013: 'You cannot delete posts written by other users.',
      2015: 'The number of hashtags allowed has been exceeded. Please try again.',
      2018: 'You have exceeded the allowable hashtag character count. Please try again.',
      2041: 'Vote modification is not possible.',
      2990: 'Request is being processed, please try again in a moment.',
      4002: 'You are an unregistered member.',
      5400: 'You do not have permission.',
      5401: 'You do not have permission.',
      8050: "You can't like yourself.", // 활동 대상 오류 - 본인에게 활동 한 경우
      default: commonError.network.message,
    },
  },
  member: {
    approval: {
      pending: 'Request',
      approval: 'Approve',
      refusal: 'Reject',
    },
    memberList: {
      empty: 'No list data.',
      error: 'Could not get that list, please try again in a moment.',
    },
    management: {
      member: 'Member',
      dialog: {
        title: 'Notice',
        approveMessage: '<strong>Do you want to approve it?</strong>',
        refuseMessage: 'Are you going to refuse?',
        refusePlaceholder: 'Enter reason for rejection',
        deportMessage: 'Do you want to <strong>expel this user</strong>?',
        deportPlaceholder: 'Please enter a reason for Expulsion',
        appointMessage:
          'Do you want to appoint <strong>{userName}</strong> as <strong>manager</strong>?',
        appointErrorMessage:
          'The manager capacity is <strong>up to five people</strong>.',
        dismissMessage:
          'Are you sure you want to <strong>dismiss a manager</strong>?',
        leaveMessage:
          'Are you no longer active in FAM? <strong>Membership NFTs you own will be deleted</strong>. FAM who has withdrawn can rejoin <strong>after 30 days</strong> as of the withdrawal date.',
        leaveSuccess: 'Your withdrawal has been processed.',
        disbandSuccess: 'FAM has been dissolved.',
        actions: {
          negative: 'Cancel',
          positive: 'OK',
        },
        successMessage: {
          approval: 'It has been approved.',
          refuse: 'The approval has been rejected.',
          accession: 'You are already joined to FAM.',
          appoint: 'You have been appointed as an FAM Manager.',
          dismiss: 'You have turned off the FAM Manager.',
          deport: 'The member has been expelled.',
        },
        errorMessage: {
          1999: 'It is not possible because users are restricted from use.',
          2006: commonError.network.message, // 필수값이 누락되었습니다.,
          2010: 'You have been duplicated.',
          2011: 'User information not found.',
          2044: 'FAM dissolution is not possible.',
          2990: 'Request is being processed, please try again in a moment.',
          5400: commonError.network.message, // '개설자는 탈퇴 불가능 합니다.(en)'
          default: commonError.network.message,
        },
      },
      btnBox: {
        deportBtn: 'Expulsion',
        appointBtn: 'Appoint',
        dismissBtn: 'Dismiss',
      },
    },
    errorMessage: {
      1999: 'It is not possible because users are restricted from use.',
      2006: commonError.network.message, // 필수값이 누락되었습니다.,
      2009: commonError.network.message, // 지정되지 않은 타입 코드 입니다.
      2010: 'You have been duplicated.',
      2011: 'Member does not exist.',
      2024: 'An error occurred in wallet creation.',
      2041: 'Vote modification is not possible.',
      2046: 'You are already joined to FAM.',
      2048: 'You cannot sign up with a refusal (7 days) or withdrawal (30 days).',
      2051: 'You have been deported from FAM and cannot join permanently.',
      2990: 'Request is being processed, please try again in a moment.',
      5400: 'You do not have permission.',
      5402: 'The maximum number of Managers has been exceeded.',
      default: commonError.network.message,
    },
  },
  dissolution: {
    dissolutionModel: {
      title: 'FAM Information',
      name: 'FAM name',
      introduction: 'Introduction',
      membershipNFT: 'Membership NFT image',
      dissolutionBox: {
        title: 'Dissolution',
        leave: 'To leave',
        message:
          'Application for dissolution of FAM is only possible when the last member leaves and there are no waiting people to join. If there are waiting people to join, approval will be made Please refuse.',
        btn: 'Request',
      },
    },
    dialog: {
      title: 'Notice',
      message: "Are you sure you're not running a FAM?",
      actions: {
        negative: 'Cancel',
        positive: 'OK',
      },
    },
  },
  filter: {
    member: {
      apply: 'Join',
      management: 'Manage',
    } as { [Property in DaoMemberFilter]: string },
    board: {
      all: 'ALL',
      notice: 'Notice',
    },
    creator: {
      all: 'ALL',
      dao: 'FAM',
    } as { [Property in DaoCreatorsFilter]: string },
  },
  leave: {
    model: {
      title: 'Withdrawal',
      message:
        'You are currently a FAM member. <br />Upon withdrawal, the FAM Membership NFT you own will be deleted, and the FAM will also be processed.',
      importantMessage:
        'Are you sure you want to proceed with the membership withdrawal?',
      form: {
        title: 'Password',
        placeHolder: 'Please enter your password.',
      },
      actions: {
        negative: 'Cancel',
        positive: 'OK',
      },
    },
    dialog: {
      title: 'Notice',
      message:
        'You can apply for withdrawal after dissolving the FAM as a FAM master.',
      actions: {
        checkBtn: 'OK',
      },
    },
    successMessage: 'You have been withdrawn.',
  },
  createCard: {
    sttusCode: {
      pCode: 'Reviewing',
      sCode: 'Joined',
    },
  },
  index: {
    daoItem01: {
      textBox: {
        text01: 'Together We Create, Together We Thrive',
        text02: 'FAM',
      },
      btnBox: {
        listOfDAO: 'List of FAM',
        create: 'Create',
        reviewing: 'Reviewing',
      },
    },
    daoItem02: {
      textBox: {
        title: '<span>What</span> is a FAM?',
        message:
          'FAM is composed of fans and artists. Through the mission, you achieve your goal. It is a participatory community where you can receive rewards.',
      },
      governanceBox: {
        title: '<span>FAM</span> Governance',
        nft: 'Digital Card',
        voting: 'Voting',
        community: 'Community',
      },
    },
    daoItem03: {
      title: '<span>HOW</span> to use FAM?',
      daoCreator: {
        title: 'Artist',
        creative: {
          title: 'Creative',
          message:
            'Communicate with fans and create content by reflecting new ideas.',
        },
        interaction: {
          title: 'Interaction',
          message:
            'Through direct communication with fans, they grow together and form a deep bond.',
        },
      },
      daoMember: {
        title: 'Member',
        cooperation: {
          title: 'Voting & Proposing',
          message:
            "Be active in the community by voting for an artist's project or suggesting an idea",
        },
        communication: {
          title: 'Communication',
          message: 'Collaborate with other fans to enrich the FAM community.',
        },
      },
    },
    daoItem04: {
      title: '<span>FAM</span> Join and create rules',
      textBox: {
        creation: {
          title: 'FAM Creation :',
          message: 'Artists with over 1,000 followers.',
        },
        participation: {
          title: 'FAM Participation :',
          message: 'I LIKE LM members and fans of the Artist.',
        },
      },
    },
  },
  home: {
    topNotice: 'Check out the FAM.',
  },
}
