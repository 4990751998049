<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const { t } = useI18n()
const show = ref(false)
</script>

<template>
  <vue-final-modal
    v-model="show"
    classes="modal-container"
    content-class="modal-content"
    :name="modalsName.MODAL_FEED_FOR_YOU_REFRESH"
    :click-to-close="false"
  >
    <div class="modal__content">
      <div class="loading-wrap">
        <NovaLoadingIndicator
          class="account-loading-indicator"
          :bg-bright="'spinner-blue'"
          :fill="false"
          :size="100"
          :overlay="true"
        />
      </div>
      {{ t('commonError.1998.message') }}
    </div>
  </vue-final-modal>
</template>

<style lang="scss" scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 240px;
  max-width: 350px;
  padding: 34px 24px;
  box-shadow: 5px 5px 10px hex-to-rgba($color-bg-custom-4, 0.05);
  border-radius: $radius-10;
  background: $color-white;
}
.modal__title {
  text-align: center;
  @include text-style($text-heading2-bold);
}
.modal__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  overflow-y: overlay;
  text-align: center;

  color: $color-black;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.4px;
}

.loading-wrap {
  position: relative;
  width: 30px;
  height: 30px;
}
</style>
