<script setup lang="ts">
import type { CreatorCategory } from '@store'
import { NovaModalDialog } from '#components'
import { ROUTES } from '@configs'

definePageMeta({
  layout: 'main',
})

useSetSEO()

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const layoutStore = useLayoutStore()
const { show: modalShow, hide: modalHide } = useModal()
const { showPostDropDialog, showPostBlockedDialog } = useShowPostErrorMessage()
const creatorStore = useCreatorStore()
const userStore = useUserStore()
const { gtEvent } = useGoogleTag()
const { cookieNames, getCookie } = useCookies()
const rewardToken = getCookie(cookieNames.REWARD_MONTHLY)

// model: 크리에이터 카테고리
const creatorCategories = ref<CreatorCategory[]>([])
const allCreatorCategories = computed<CreatorCategory[]>(() =>
  [
    {
      crtrCount: 0,
      crtrCtgrySn: -1,
      ctgryDc: '',
      ctgryNm: 'All',
      mainCrtrCount: 0,
    },
  ].concat(creatorCategories.value)
)

const myCreatorStatus = reactive({
  isLoading: false,
  isError: false,
})
const myCreators = computed(() =>
  creatorStore.myCreators.items.creatorList.slice(
    0,
    layoutStore.responsive === 'mobile' ? 3 : 5
  )
)

const showMyCreatorsMore = computed(() => {
  switch (layoutStore.responsive) {
    case 'mobile':
      return creatorStore.myCreators.items.creatorList.length > 3
    case 'tablet':
    case 'desktop':
    default:
      return creatorStore.myCreators.items.creatorList.length > 5
  }
})

const creatorsStatus = reactive({
  isLoading: false,
  isError: false,
})
const creators = computed(() =>
  creatorStore.creators.items.creatorList.slice(
    0,
    layoutStore.responsive === 'mobile' ? 9 : 15
  )
)
const categoryAllCreatorCount = ref(0)
const showCreatorsMore = computed(() => {
  switch (layoutStore.responsive) {
    case 'mobile':
      return categoryAllCreatorCount.value > 9
    case 'tablet':
    case 'desktop':
    default:
      return categoryAllCreatorCount.value > 15
  }
})

const creatorCategoriesStatus = reactive({
  isLoading: false,
  isError: false,
})
const selectCreatorCategorySn = ref<CreatorCategory['crtrCtgrySn']>(-1)

// 노출할 팝업이 있는지 조회
const checkPopupList = async () => {
  if (
    rewardToken &&
    rewardToken.popupData &&
    userStore.userCommonInfo?.userSn === rewardToken.userSn
  ) {
    await modalShow(modalsName.MODAL_BONUS_REWARD_MONTHLY)
  }
}

// 크리에이터 카테고리 목록 조회
const fetchCreatorCategories = async () => {
  try {
    creatorCategoriesStatus.isLoading = true
    creatorCategoriesStatus.isError = false
    creatorCategories.value = await creatorStore.fetchCreatorCategories({
      crtrOrgnztTyCode: 'ALL',
    })
  } catch {
    creatorCategoriesStatus.isError = true
  } finally {
    creatorCategoriesStatus.isLoading = false
  }
}

/**
 * 크리에이터 카테고리 변경
 * @param crtrCtgrySn
 */
const handleOnUpdateCreatorCategory = async (
  crtrCtgrySn: CreatorCategory['crtrCtgrySn']
) => {
  selectCreatorCategorySn.value = crtrCtgrySn
  await fetchCreators()
}

/**
 * 라우터 이동: 크리에이터 더보기(나의 크리에이터)
 */
const handleOnGoMyCreators = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '나의 크리에이터 더보기 클릭',
    eventLabel: t('home.seeMore'),
    eventSlot: '',
    eventI18nAddr: useKoreanTranslation('home.seeMore'),
    eventComponent: 'NovaButtonSimpleText',
  })
  useNavigations({
    url: ROUTES.CREATORS_MY.path,
  })
}

/**
 * 라우터 이동: 크리에이터 더보기(모든 크리에이터)
 */
const handleOnGoCreators = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '크리에이터 더보기 클릭',
    eventLabel: t('home.seeMore'),
    eventSlot: '',
    eventI18nAddr: useKoreanTranslation('home.seeMore'),
    eventComponent: 'NovaButtonSimpleText',
  })

  useNavigations({
    url: ROUTES.CREATORS_ALL.path,
  })
}

/**
 * 나의 크리에이터 목록 불러오기
 */
const fetchMyCreators = async () => {
  try {
    myCreatorStatus.isLoading = true
    myCreatorStatus.isError = false

    await creatorStore.fetchMyCreators({
      payload: { pageSize: 10, crtrCtgrySn: -1 },
      refresh: true,
    })
  } catch {
    myCreatorStatus.isError = true
  } finally {
    myCreatorStatus.isLoading = false
  }
}

/**
 * 크리에이터 목록 불러오기
 */
const fetchCreators = async () => {
  try {
    creatorsStatus.isLoading = true
    creatorsStatus.isError = false

    await creatorStore.fetchCreators({
      payload: {
        pageSize: 20,
        crtrCtgrySn: selectCreatorCategorySn.value,
      },
      refresh: true,
    })

    if (selectCreatorCategorySn.value === -1) {
      categoryAllCreatorCount.value =
        creatorStore.creators.items.creatorList.length
    }
  } catch {
    creatorsStatus.isError = true
  } finally {
    creatorsStatus.isLoading = false
  }
}

// 레퍼럴 페이지로 부터 리다이렉트 후 다이얼로그 호출
const showReferralSignup = () => {
  // 1. 로그인 상태이면 에러 메세지 호출
  if (userStore.isSignIn) {
    modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_REFERRAL_NOT_ABLE,
        btns: [
          {
            label: t('referral.signUp.dialog.referralNotAble.action.positive'),
            theme: 'primary-blue-dark',
            size: 32,
            onClick: async () => {
              await modalHide(modalsName.MODAL_REFERRAL_NOT_ABLE)
              await router.replace({ query: undefined })
            },
          },
        ],
      },
      slots: {
        title: t('referral.signUp.dialog.referralNotAble.title'),
        content: t('referral.signUp.dialog.referralNotAble.description'),
      },
    })
  }

  // 2. 로그인 상태가 아니면 레퍼럴 회원가입 안내 메세지 호출
  else {
    modalShow(modalsName.MODAL_REFERRAL, route.query.referralCode)
  }
}

onBeforeMount(async () => {
  await fetchCreatorCategories()
  if (userStore.isSignIn) {
    await fetchMyCreators()
  }
  await fetchCreators()
  await checkPopupList()
})

onMounted(async () => {
  // 1. 포스트 상세로 부터 리다이렉트: 포스트 상세를 찾을 수 없음
  if (String(route.query.redirect) === 'POST_DETAIL_DROP')
    await showPostDropDialog('', () => router.replace({ query: undefined }))
  // 1-1. 포스트 상세로 부터 차단되어서 리다이렉트: 포스트 상세를 찾을 수 없음
  if (String(route.query.redirect) === 'POST_DETAIL_BLOCKED')
    await showPostBlockedDialog('', () => router.replace({ query: undefined }))

  // 2. 레퍼럴 페이지로 부터 리다이렉트: 레퍼럴 회원가입 다이얼로그 호출
  if (String(route.query.redirect) === 'REFERRAL_SIGNUP') showReferralSignup()
})
</script>

<template>
  <div class="page-home">
    <!--
    <NovaLayoutTopLink />
    -->

    <!-- 크리에이터 목록 -->
    <div class="creators">
      <NovaBoxMainSection
        v-if="myCreators.length"
        :title="$t('home.myCreator')"
      >
        <template #tools>
          <NovaButtonSimpleText
            v-if="showMyCreatorsMore"
            :label="$t('home.seeMore')"
            @click="handleOnGoMyCreators()"
          />
        </template>

        <template #default>
          <NovaBoxCreators
            :creator-count="myCreators.length"
            :is-loading="myCreatorStatus.isLoading"
            :is-error="myCreatorStatus.isError"
            :ga-slot="$t('home.myCreator')"
          >
            <template #default>
              <NovaCreatorProfileCardTiny
                v-for="creator in myCreators"
                :key="creator.userSn"
                :type="'MY'"
                :creator="creator"
              />
            </template>
          </NovaBoxCreators>
        </template>
      </NovaBoxMainSection>

      <NovaBoxMainSection :title="$t('home.creator')">
        <template #tools>
          <NovaButtonSimpleText
            v-if="showCreatorsMore"
            :label="$t('home.seeMore')"
            @click="handleOnGoCreators()"
          />
        </template>

        <template #default>
          <NovaBoxCreatorCategories
            :creator-categories="allCreatorCategories"
            :select-creator-category-sn="selectCreatorCategorySn"
            :is-loading="creatorCategoriesStatus.isLoading"
            :is-error="creatorCategoriesStatus.isError"
            :ga-slot="$t('home.creator')"
            :show-navigation="true"
            @update:category-change="handleOnUpdateCreatorCategory"
          />

          <NovaBoxCreators
            :creator-count="creators.length"
            :is-loading="creatorsStatus.isLoading"
            :is-error="creatorsStatus.isError"
            :ga-slot="$t('home.creator')"
          >
            <template #default>
              <NovaCreatorProfileCardTiny
                v-for="creator in creators"
                :key="creator.userSn"
                :type="'MY'"
                :creator="creator"
              />
            </template>
          </NovaBoxCreators>
        </template>
      </NovaBoxMainSection>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-home {
  position: relative;
  padding-top: 0;
  padding-bottom: 100px;
  width: 100%;
  max-width: $base-desktop-main-max-width;
  min-width: 320px;
  margin: 0 auto;

  @include mobile {
    padding-top: 0;
    padding-bottom: 60px;
  }
}
.main-top-banner {
  background-color: $color-white;
}

.main-content {
  padding-top: 10px;
}

.creators {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0;

  @include mobile {
  }
}
</style>
