<script setup lang="ts">
import type {
  NovaBoxPostActionSharePanelEmits,
  NovaBoxPostActionSharePanelProps,
  Share,
} from './NovaBoxPostActionSharePanel.types'
import type { ShareType } from '@store'

const emit = defineEmits<NovaBoxPostActionSharePanelEmits>()
const props = withDefaults(defineProps<NovaBoxPostActionSharePanelProps>(), {
  contentsType: 'post',
  postThumbnail: '',
  showNavigation: false,
})
const { gtEvent } = useGoogleTag()
const config = useRuntimeConfig()
const postStore = usePostStore()
const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const isLoading = ref(false)
const shares = ref<Share[]>([
  {
    id: 'clipboard',
    icon: { type: 'outline', name: 'link' },
    label: t('share.actions.clipboard'),
  },
  {
    id: 'facebook',
    icon: { type: 'outline', name: 'social-facebook' },
    label: t('share.actions.facebook'),
  },
  {
    id: 'twitter',
    icon: { type: 'outline', name: 'social-twitter' },
    label: t('share.actions.twitter'),
  },
  {
    id: 'kakao',
    icon: { type: 'outline', name: 'social-kakao' },
    label: t('share.actions.kakao'),
  },
])

const openLink = async (url: string) => {
  await useNavigations({ external: true, url })
}

const handleOnGoBack = () => {
  emit('goBack')
}

const handleOnShare = async (id: ShareType, data: any) => {
  const meta = getLandingMeta()
  // const currentURL = window.location.href
  switch (id) {
    case 'clipboard':
      // useCopyToClipboard(useDeeplinkEntry(meta.landingUrl))
      useCopyToClipboard(meta.landingUrl)
      await useToast(t('copyToClipboard'))
      break
    case 'facebook':
      await openLink(
        `https://www.facebook.com/sharer/sharer.php?u=${useDeeplinkEntry(
          meta.landingUrl
        )}`
      )
      break
    case 'twitter':
      await openLink(
        `https://twitter.com/share?url=${useDeeplinkEntry(meta.landingUrl)}`
      )
      break
    case 'kakao':
      useSendKakaoShare({
        objectType: 'feed',
        content: {
          title: props.postTitle,
          description: meta.description,
          imageUrl: meta.imageUrl,
          // link: {
          //   mobileWebUrl: useDeeplinkEntry(meta.landingUrl),
          //   webUrl: useDeeplinkEntry(meta.landingUrl),
          // },
          link: {
            mobileWebUrl: meta.landingUrl,
            webUrl: meta.landingUrl,
          },
        },
      })
      break
    case 'discord':
      break
    default:
      throw new Error('share type is not match')
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `${data.label} 클릭`,
    eventLabel: data.label,
    eventSlot: '포스트 액션 패널',
    eventI18nAddr: data.label,
    eventComponent: 'Button',
    cmtyNttSn: props.cmtyNttSn,
  })
  if (props.contentsType === 'post') {
    try {
      isLoading.value = true
      await Promise.all([
        await useSleeper(),
        await postStore.reqPostShare(
          { cmtyNttSn: props.cmtyNttSn },
          props.shareCount + 1
        ),
      ])
      emit('close')
    } catch {
    } finally {
      isLoading.value = false
    }
  }
}

const createLandingUrl = (path: string, id: string) => {
  return path.includes(id) ? path : `${path}/${id}`
}

const getLandingMeta = () => {
  const currentPath = route.path
  const meta = {
    landingUrl: '',
    title: '',
    description: '',
    imageUrl: '',
  }

  // 1. set landing url
  switch (props.contentsType) {
    case 'post':
      meta.landingUrl = `${config.public.APP_URL}${
        router.resolve({
          path: createLandingUrl(currentPath, String(props.cmtyNttSn)),
          query: route.query,
        }).href
      }`
      break
    case 'w3cf':
      meta.landingUrl = `${config.public.APP_URL}${
        router.resolve({
          path: createLandingUrl(currentPath, String(props.cmtyNttSn)),
          query: route.query,
        }).href
      }`
      break
    case 'fundProject':
      meta.landingUrl = `${config.public.APP_URL}${
        router.resolve({
          path: createLandingUrl(currentPath, String(props.cmtyNttSn)),
          query: route.query,
        }).href
      }`
      break
  }

  // 2. set title
  meta.title = props.postTitle

  // 3. set description & imageUrl
  if (props.contentsType === 'post') {
    const { description, thumbnail } = useExtractionPostContents(
      props.postContents
    )
    meta.description = description
    meta.imageUrl = thumbnail
  } else if (props.contentsType === 'w3cf') {
    meta.description = props.postContents
    meta.imageUrl = props.postThumbnail
  } else if (props.contentsType === 'fundProject') {
    meta.description = useGetMaxThreeParagraphTexts(props.postContents)
    meta.imageUrl = props.postThumbnail
  }

  return meta
}
</script>

<template>
  <div
    :class="[
      'share-panel',
      { 'is-loading': isLoading, 'is-instance': contentsType !== 'post' },
    ]"
  >
    <div v-if="showNavigation" class="panel-header">
      <NovaButtonIcon
        :icon="{ type: 'outline', name: 'arrow-left' }"
        class="btn-go-back"
        @click.stop="handleOnGoBack"
      />
    </div>

    <div class="actions">
      <button
        v-for="share in shares"
        :key="share.id"
        class="share-item"
        type="button"
        @click.stop="handleOnShare(share.id, share)"
      >
        <NovaIcon :icon="share.icon" :class="['icon', share.id]" />

        <span class="label">{{ share.label }}</span>
      </button>

      <div v-if="isLoading" class="loading-indicator-wrap">
        <NovaLoadingIndicator :bg-bright="'light'" :size="60" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.share-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px 15px 10px 20px;

  &.is-instance {
    width: 200px !important;
    padding: 20px;
    border-radius: $radius-10;
    background-color: $color-bg-3;
    box-shadow: 0 4px 20px hex-to-rgba($color-black, 0.08);
  }

  &.is-loading {
    pointer-events: none;

    .actions {
      opacity: 0.3;
      pointer-events: none;
    }

    .loading-indicator-wrap {
      display: flex;
    }
  }

  .actions {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .share-item {
      display: flex;
      align-items: center;
      gap: 8px;

      @media (hover: hover) {
        &:hover {
          .label {
            color: $color-black;
          }
        }
      }

      > .icon {
        &.clipboard {
          color: $color-text-2;
        }
        &.facebook {
          color: $color-facebook;
        }
        &.twitter {
          color: $color-twitter;
        }
        &.kakao {
          color: $color-kakao;
        }
        //&.discord {
        //  color: $color-discord;
        //}
      }

      .label {
        @include text-style($text-body-14-regular);
        color: $color-text-2;
        transition: color 0.2s ease-in-out;
      }
    }

    .loading-indicator-wrap {
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
