import { AxiosError } from 'axios'
import type { Post } from '@store'
import { NovaModalDialog } from '#components'
import { ROUTES } from '@configs'

type UseShowPostErrorMessageErrType = AxiosError<unknown> | null

export const useShowPostErrorMessage = () => {
  const nuxtApp = useNuxtApp()
  const { t } = nuxtApp.$i18n
  const { show: modalShow, hide: modalHide } = useModal()

  /**
   * 동적 다이얼로그 호출
   *
   * @param {Object} - 대화 상자의 속성입니다.
   * @param {string} title - 대화 상자의 제목입니다.
   * @param {string} content - 대화 상자의 내용 본문입니다.
   */
  const dialog = ({
    title,
    content,
    onConfirm,
    landingPath,
  }: {
    title: string
    content: string
    onConfirm?: () => any
    landingPath: string
  }) =>
    modalShow(
      {
        component: NovaModalDialog,
        bind: {
          name: modalsName.MODAL_DIALOG,
          contentsTextAlign: 'center',
          btns: [
            {
              label: t('confirm'),
              theme: 'primary-blue-dark',
              size: 32,
              onClick: async () => {
                await modalHide(
                  modalsName.MODAL_DIALOG,
                  true,
                  false,
                  landingPath
                )
                onConfirm?.()
              },
            },
          ],
        },
        slots: {
          title,
          content,
        },
      },
      undefined,
      landingPath
    )

  /**
   * 드롭된 포스트 상세 다이얼로그 호출
   * @param onConfirm
   * @param landingPath
   */
  const showPostDropDialog = (landingPath: string, onConfirm?: () => any) =>
    modalShow(
      {
        component: NovaModalDialog,
        bind: {
          name: modalsName.MODAL_NOTIFY_POST_DETAIL_ERROR,
          btns: [
            {
              label: t('dialog.postDetailDrop.action.positive'),
              theme: 'primary-blue-dark',
              size: 32,
              onClick: async () => {
                await modalHide(
                  modalsName.MODAL_NOTIFY_POST_DETAIL_ERROR,
                  true,
                  false,
                  landingPath
                )
                onConfirm?.()
              },
            },
          ],
        },
        slots: {
          title: t('dialog.postDetailDrop.title'),
          content: t('dialog.postDetailDrop.description'),
        },
      },
      undefined,
      landingPath
    )

  /**
   * 차단된 포스트 접근 안내 다이얼로그 호출
   * @param onConfirm
   * @param landingPath
   */
  const showPostBlockedDialog = (landingPath: string, onConfirm?: () => any) =>
    modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_NOTIFY_POST_DETAIL_BLOCKED_ERROR,
        btns: [
          {
            label: t('dialog.postDetailBlocked.action.positive'),
            theme: 'primary-blue-dark',
            size: 32,
            onClick: async () => {
              await modalHide(
                modalsName.MODAL_NOTIFY_POST_DETAIL_BLOCKED_ERROR,
                true,
                false,
                landingPath
              )
              onConfirm?.()
            },
          },
        ],
      },
      slots: {
        title: t('dialog.postDetailBlocked.title'),
        content: t('dialog.postDetailBlocked.description'),
      },
      undefined,
      landingPath,
    })

  /**
   * 구독하지 않은 포스트 상세를 접근 불가 다이얼로그 호출
   * @param landingPath
   * @param onConfirm
   */
  const showPostUnsubscribedDialog = (landingPath: string, onConfirm?: any) =>
    modalShow(
      {
        component: NovaModalDialog,
        bind: {
          name: modalsName.MODAL_UN_SUBSCRIBED_POST_DETAIL_ERROR,
          btns: [
            {
              label: t('dialog.postUnSubscribed.action.positive'),
              theme: 'primary-blue-dark',
              size: 32,
              onClick: async () => {
                await modalHide(
                  modalsName.MODAL_UN_SUBSCRIBED_POST_DETAIL_ERROR,
                  true,
                  false,
                  landingPath
                )
                onConfirm?.()
              },
            },
          ],
        },
        slots: {
          title: t('dialog.postUnSubscribed.title'),
          content: t('dialog.postUnSubscribed.description', {
            subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
          }),
        },
      },
      undefined,
      landingPath
    )

  const routeBackReplace = async () => {
    const route = useRoute()
    const currentRoute = Object.values(ROUTES).find(
      (_route) => _route.name === useGetRouteName(String(route.name))
    )
    if (currentRoute?.isModalPage) {
      const parrentRoute = route.matched[route.matched.length - 2]
      await useNavigations({
        url: parrentRoute.path,
        query: route.query,
        type: 'replace',
      })
      return parrentRoute.path
    }
  }

  /**
   * 포스트 상세 조회 에러 발생시 페이지 에러 메세지 생성
   * 오류가 발생하고 그것이 AxiosError인 경우, 다른 HTTP 응답 상태 코드를 적절하게 처리
   *
   * @param {string} cmtyNttSn - 커뮤니티 공지사항의 일련 번호입니다.
   * @param {AxiosError<unknown> | null} err - 에러 객체
   *
   * @throws {Error} - 오류 응답 상태 코드가 처리되지 않은 경우 오류를 발생
   */
  const onPage = async (
    cmtyNttSn: Post['cmtyNttSn'],
    err: UseShowPostErrorMessageErrType
  ) => {
    if (err && err instanceof AxiosError) {
      switch (err.response?.status) {
        // 해당 글을 찾을 수 없습니다.
        case 2000:
          throw showError({
            statusCode: 404,
            statusMessage: `post ${cmtyNttSn} is not find`,
          })
        // 제제된 게시물
        case 2020:
          await useNavigations({
            url: '/',
            query: { redirect: 'POST_DETAIL_DROP' },
            type: 'replace',
          })
          break
        // 사용자 차단에 의한 권한 오류 게시물
        case 2034:
          await useNavigations({
            url: '/',
            query: { redirect: 'POST_DETAIL_BLOCKED' },
            type: 'replace',
          })
          break
        // 프리미엄 게시물에 대한 권한이 없습니다.
        case 2030:
          await useNavigations({
            url: '/nova-plus',
            query: { redirect: 'UN_SUBSCRIBED_POST' },
            type: 'replace',
          })
          break
        default:
          throw showError({
            statusCode: err.response?.status,
            statusMessage: `post ${cmtyNttSn} is error`,
          })
      }
    }
  }

  const onLayer = (
    err: UseShowPostErrorMessageErrType,
    landingPath: string
  ) => {
    if (err && err instanceof AxiosError) {
      switch (err.response?.status) {
        // 해당 글을 찾을 수 없습니다.
        case 2000:
          dialog({
            title: t('commonError.404.title'),
            content: t('commonError.404.message'),
            landingPath,
          })
          break
        // 제제된 게시물
        case 2020:
          showPostDropDialog(landingPath)
          break
        // 사용자 차단에 의한 권한 오류 게시물
        case 2034:
          showPostBlockedDialog(landingPath)
          break
        // 프리미엄 게시물에 대한 권한이 없습니다.
        case 2030:
          showPostUnsubscribedDialog(landingPath)
          break
        default:
          dialog({
            title: t('commonError.default.title'),
            content: t('commonError.default.message'),
            landingPath,
          })
          break
      }
    } else {
      dialog({
        title: t('commonError.default.title'),
        content: t('commonError.default.message'),
        landingPath,
      })
    }
  }

  return {
    onPage,
    onLayer,
    showPostDropDialog,
    showPostBlockedDialog,
    showPostUnsubscribedDialog,
    routeBackReplace,
  }
}
