<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { NovaModalKycUnAuthEmits } from './NovaModalKycUnAuth.types'
import type { KycAuthStatus, KycAuthPurpose, KycProcessParams } from '@mixins'
import {
  NovaKycContentDuplicate,
  NovaKycContentPending,
  NovaKycContentReject,
  NovaKycContentTryYet,
} from '#components'

defineOptions({
  inheritAttrs: false,
})
defineEmits<NovaModalKycUnAuthEmits>()

const { t } = useI18n()
const { hide: modalHide } = useModal()
const { openMembershipServicePopup } = useMembershipProcess()
const show = ref(false)
const purpose = ref<KycAuthPurpose | null>(null)
const kycStatus = ref<KycAuthStatus | null>(null)
const processItems = ref([
  {
    id: 'tryYet',
    component: shallowRef(NovaKycContentTryYet),
    actions: [
      {
        label: computed(() => t('kyc.unAuthKyc.tryYet.actions.negative')),
        theme: 'secondary-gray-200',
        size: 32,
        onClick: async () => {
          await modalHide(modalsName.MODAL_KYC_PROCESS)
        },
      },
      {
        label: computed(() => t('kyc.unAuthKyc.tryYet.actions.positive')),
        theme: 'primary-blue-dark',
        size: 32,
        onClick: async () => {
          openMembershipServicePopup.kyc()
          await modalHide(modalsName.MODAL_KYC_PROCESS)
        },
      },
    ],
  },
  {
    id: 'reject',
    component: shallowRef(NovaKycContentReject),
    actions: [
      {
        label: computed(() => t('kyc.unAuthKyc.reject.actions.negative')),
        theme: 'secondary-gray-200',
        size: 32,
        onClick: async () => {
          await modalHide(modalsName.MODAL_KYC_PROCESS)
        },
      },
      {
        label: computed(() => t('kyc.unAuthKyc.reject.actions.positive')),
        theme: 'primary-blue-dark',
        size: 32,
        onClick: async () => {
          openMembershipServicePopup.kyc()
          await modalHide(modalsName.MODAL_KYC_PROCESS)
        },
      },
    ],
  },
  {
    id: 'pending',
    component: shallowRef(NovaKycContentPending),
    actions: [
      {
        label: computed(() => t('kyc.unAuthKyc.pending.confirm')),
        theme: 'primary-blue-dark',
        size: 32,
        onClick: async () => {
          await modalHide(modalsName.MODAL_KYC_PROCESS)
        },
      },
    ],
  },
  {
    id: 'duplicate',
    component: shallowRef(NovaKycContentDuplicate),
    actions: [
      {
        label: computed(() => t('kyc.unAuthKyc.duplicate.confirm')),
        theme: 'primary-blue-dark',
        size: 32,
        onClick: async () => {
          await modalHide(modalsName.MODAL_KYC_PROCESS)
        },
      },
    ],
  },
])
const currentProcess = computed(() =>
  processItems.value.find((item) => item.id === kycStatus.value)
)

const handleOnGetParams = (evt: RouteParams) => {
  const params = evt.value as unknown as KycProcessParams
  purpose.value = params.purpose
  kycStatus.value = params.kycStatus
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_KYC_PROCESS"
    :click-to-close="false"
    classes="modal-container"
    content-class="modal-content"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
  >
    <span class="title">{{ $t('kyc.unAuthKyc.title') }}</span>

    <div v-if="currentProcess && purpose && show" class="content">
      <component :is="currentProcess.component" :purpose="purpose" />
    </div>

    <div
      v-if="currentProcess && show"
      :class="[
        'actions',
        'grid-wrapper',
        `grid-${currentProcess.actions.length}`,
        'gap-4',
      ]"
    >
      <NovaButtonText
        v-for="(item, index) in currentProcess.actions || []"
        :key="index"
        :label="item.label"
        :theme="item.theme"
        :size="item.size"
        :full-width="true"
        @click="item.onClick"
      />
    </div>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 380px;
  width: 100%;
  padding: 30px 20px;
  box-shadow: 5px 5px 10px hex-to-rgba($color-bg-custom-4, 0.05);
  border-radius: $radius-10;
  background: $color-white;
}

.title {
  text-align: center;
  @include text-style($text-heading2-bold);
}

.content {
  margin-top: 20px;
  flex-grow: 1;
  overflow-y: overlay;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@include mobile {
  ::v-deep(.modal-content) {
    padding: 20px;
  }
  .title {
    text-align: center;
    @include text-style($text-heading3-bold);
  }
}
</style>
