import * as DaoType from './type'
import DaoApi from './url'
import { api } from '@api'

export default {
  /**
   * DAO 해산 신청
   * @param payload
   */
  deleteDaoDisband(payload: DaoType.DeleteDaoDisbandPayload) {
    return api.delete(DaoApi.DELETE_DAO_DISBAND, {
      data: payload,
    })
  },

  /**
   * DAO 정보 조회
   * @param payload
   */
  fetchDaoInfo(payload: DaoType.DaoInfoPayload) {
    return api.get<DaoType.DaoInfo>(DaoApi.FETCH_DAO_INFO, { params: payload })
  },

  /**
   * DAO 가입 신청
   */
  createDaoJoin(payload: DaoType.DaoJoinPayload) {
    return api.post<DaoType.DaoJoin>(DaoApi.CREATE_DAO_JOIN, null, {
      params: payload,
    })
  },

  /**
   * DAO 가입 여부 조회
   * @param payload
   */
  fetchDaoJoinStatus(payload: DaoType.DaoJoinStatusPayload) {
    return api.get<DaoType.DaoJoinStatus>(DaoApi.FETCH_DAO_JOIN_STATUS, {
      params: payload,
    })
  },

  /**
   * DAO 개설 신청
   */
  createDaoOpening(payload: DaoType.DaoOpeningPayloadFormData) {
    return api.post<DaoType.DaoOpening>(DaoApi.CREATE_DAO_OPENING, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  /**
   * DAO 개설 가능 여부 조회
   */
  fetchDaoOpeningAvailable() {
    return api.get<DaoType.DaoOpeningAvailable>(
      DaoApi.FETCH_DAO_OPENING_AVAILABLE
    )
  },

  /**
   * DAO 상태 조회
   */
  fetchDaoStatus() {
    return api.get<DaoType.DaoStatus>(DaoApi.FETCH_DAO_STATUS)
  },

  /**
   * DAO 명 중복 확인
   * @param payload
   */
  fetchDaoValidateDuplicate(payload: DaoType.DaoValidateDuplicatePayload) {
    return api.get<DaoType.DaoValidateDuplicate>(
      DaoApi.FETCH_DAO_VALIDATE_DUPLICATE,
      {
        params: payload,
      }
    )
  },

  // -------------- 게시판 -------------------

  /**
   * DAO 게시판 게시물 상세 조회
   */
  fetchDaoBoardPost(payload: DaoType.DaoBoardPostPayload) {
    return api.get<DaoType.DaoBoardPost>(DaoApi.FETCH_DAO_BOARD_POST, {
      params: payload,
    })
  },
  /**
   * DAO 게시판 게시물 등록
   */
  createDaoBoardPost(payload: DaoType.CreateDaoBoardPostPayload) {
    return api.post<DaoType.DaoBoardPost>(DaoApi.CREATE_DAO_BOARD_POST, payload)
  },
  /**
   * DAO 게시판 게시물 수정
   */
  editDaoBoardPost(payload: DaoType.editDaoBoardPostPayload) {
    return api.put<DaoType.DaoBoardPost>(DaoApi.EDIT_DAO_BOARD_POST, payload)
  },
  /**
   * DAO 게시판 게시물 삭제
   */
  deleteDaoBoardPost(payload: DaoType.deleteDaoBoardPostPayload) {
    return api.delete(DaoApi.DELETE_DAO_BOARD_POST, {
      data: payload,
    })
  },

  /**
   * DAO 게시판 게시물 좋아요
   */
  createDaoBoardPostRecommend(payload: DaoType.DaoBoardPostActionPayload) {
    return api.post(DaoApi.CREATE_DAO_BOARD_POST_RECOMMEND, payload)
  },

  /**
   * DAO 게시판 게시물 좋아요 취소
   */
  editDaoBoardPostRecommend(payload: DaoType.DaoBoardPostActionPayload) {
    return api.put(DaoApi.EDIT_DAO_BOARD_POST_RECOMMEND, payload)
  },

  /**
   * DAO 게시판 게시물 댓글/대댓글 목록 조회
   */
  fetchDaoBoardPostReply(payload: DaoType.DaoBoardPostReplyPayload) {
    return api.get<DaoType.DaoBoardPostReply[]>(
      DaoApi.FETCH_DAO_BOARD_POST_REPLY,
      {
        params: payload,
      }
    )
  },

  /**
   * DAO 게시판 게시물 목록 조회
   */
  fetchDaoBoardPosts(payload: DaoType.DaoBoardPostsPayload) {
    return api.get<DaoType.DaoBoardPosts[]>(DaoApi.FETCH_DAO_BOARD_POSTS, {
      params: payload,
    })
  },

  /**
   * DAO 게시판 게시물 목록 조회 - SIMPLE
   */
  fetchDaoBoardPostsSimple(payload: DaoType.DaoBoardPostsPayload) {
    return api.get<DaoType.DaoBoardPostsSimple[]>(
      DaoApi.FETCH_DAO_BOARD_POSTS_SIMPLE,
      {
        params: payload,
      }
    )
  },

  /**
   * DAO 포스트 댓글/대댓글 작성
   */
  createDaoBoardPostReply(payload: DaoType.CreateDaoBoardPostReplyPayload) {
    return api.post<DaoType.DaoBoardPostReply>(
      DaoApi.CREATE_DAO_BOARD_POST_REPLY,
      payload
    )
  },

  /**
   * DAO 포스트 댓글/대댓글 수정
   */
  editDaoBoardPostReply(payload: DaoType.EditDaoBoardPostReplyPayload) {
    return api.put<DaoType.DaoBoardPostReply>(
      DaoApi.EDIT_DAO_BOARD_POST_REPLY,
      payload
    )
  },

  /**
   * DAO 포스트 댓글/대댓글 삭제
   */
  deleteDaoBoardPostReply(payload: DaoType.DeleteDaoBoardPostReplyPayload) {
    return api.delete<DaoType.DaoBoardPostReply>(
      DaoApi.DELETE_DAO_BOARD_POST_REPLY,
      {
        data: payload,
      }
    )
  },

  /**
   * DAO 포스트 댓글/대댓글 좋아요
   */
  createDaoBoardPostReplyRecommend(
    payload: DaoType.CreateDaoBoardPostReplyRecommendPayload
  ) {
    return api.post(DaoApi.CREATE_DAO_BOARD_POST_REPLY_RECOMMEND, payload)
  },

  /**
   * DAO 포스트 댓글/대댓글 좋아요 취소
   */
  editDaoBoardPostReplyRecommend(
    payload: DaoType.CreateDaoBoardPostReplyRecommendPayload
  ) {
    return api.put(DaoApi.EDIT_DAO_BOARD_POST_REPLY_RECOMMEND, payload)
  },

  /**
   * DAO 임시 게시물 상세 조회
   */
  fetchDaoBoardTempPost(payload: DaoType.DaoBoardTempPostPayload) {
    return api.get<DaoType.DaoBoardTempPost>(DaoApi.FETCH_DAO_BOARD_TEMP_POST, {
      params: payload,
    })
  },

  /**
   * DAO 임시 게시물 상세 저장
   */
  createDaoBoardTempPost(payload: DaoType.CreateDaoBoardTempPostPayload) {
    return api.post(DaoApi.CREATE_DAO_BOARD_TEMP_POST, payload)
  },

  /**
   * DAO 임시 게시물 상세 삭제
   */
  deleteDaoBoardTempPost(payload: DaoType.DaoBoardTempPostPayload) {
    return api.delete<DaoType.DaoBoardTempPost>(
      DaoApi.DELETE_DAO_BOARD_TEMP_POST,
      {
        data: payload,
      }
    )
  },

  /**
   * DAO 임시 게시물 목록 조회
   */
  fetchDaoBoardTempPostList(payload: DaoType.DaoBoardTempPostListPayload) {
    return api.get<DaoType.DaoBoardTempPostListItem[]>(
      DaoApi.FETCH_DAO_BOARD_TEMP_POST_LIST,
      {
        params: payload,
      }
    )
  },
  // -------------- 멤버 -------------------
  /**
   * DAO 가입 승인
   */
  createDaoMemberJoinApproval(payload: DaoType.DaoMemberApprovalPayload) {
    return api.post<DaoType.DaoMemberApprovalPayload>(
      DaoApi.CREATE_DAO_MEMBER_JOIN_APPROVAL,
      payload
    )
  },
  /**
   * DAO 가입 거절
   */
  createDaoMemberJoinReject(payload: DaoType.DaoMemberRejectPayload) {
    return api.post<DaoType.DaoMemberRejectPayload>(
      DaoApi.CREATE_DAO_MEMBER_JOIN_REJECT,
      payload
    )
  },

  /**
   * DAO 가입 요청 목록 조회
   */
  fetchDaoMemberJoinRequestList(
    payload: DaoType.DaoMemberJoinRequestListPayload
  ) {
    return api.get<DaoType.DaoMemberJoinResponseList>(
      DaoApi.FETCH_DAO_MEMBER_JOIN_REQUESTS,
      {
        params: payload,
      }
    )
  },
  /**
   * DAO 멤버 목록 조회
   */
  fetchDaoMemberManagementList(
    payload: DaoType.DaoMemberManagementListPayload
  ) {
    return api.get<DaoType.DaoMemberManagementResponseList>(
      DaoApi.FETCH_DAO_MEMBER_MANAGEMENT_LIST,
      {
        params: payload,
      }
    )
  },
  /**
   * DAO 멤버 추방
   * @param payload
   */
  editDaoMemberDeport(payload: DaoType.DaoMemberDeport) {
    return api.put(DaoApi.EDIT_DAO_MEMBER_MANAGEMENT_DEPORT, payload)
  },
  /**
   * DAO 관리자 임명/해제
   */
  editDaoMemberModerator(payload: DaoType.DaoMemberModerator) {
    return api.put<DaoType.DaoMemberManagementListItem>(
      DaoApi.EDIT_DAO_MEMBER_MANAGEMENT_MODERATOR,
      payload
    )
  },
  /**
   * DAO 멤버 탈퇴
   * @param payload
   */
  editDaoMemberSecession(payload: DaoType.DaoMemberSecession) {
    return api.put(DaoApi.EDIT_DAO_MEMBER_MANAGEMENT_SECESSION, payload)
  },

  // -------------- 투표 -------------------

  /**
   * DAO 투표 생성
   * @param payload
   */
  createDaoVote(payload: DaoType.DaoVoteCreatePayload) {
    return api.post<DaoType.DaoVoteListItem>(DaoApi.CREATE_DAO_VOTE, payload)
  },

  /**
   * DAO 투표 삭제
   * @param payload
   */
  deleteDaoVote(payload: DaoType.DaoVoteDeletePayload) {
    return api.delete<DaoType.DaoVoteListItem>(DaoApi.DELETE_DAO_VOTE, {
      params: payload,
    })
  },

  /**
   * DAO 투표 상세 조회
   * @param payload
   */
  fetchDaoVote(payload: DaoType.DaoVoteDetailPayload) {
    return api.get<DaoType.DaoVoteDetail>(DaoApi.FETCH_DAO_VOTE_DETAIL, {
      params: payload,
    })
  },

  /**
   * DAO 투표 참여 조건 조회
   * @param payload
   */
  fetchDaoVoteEligibility(payload: DaoType.DaoVoteEligibilityPayload) {
    return api.get<DaoType.DaoVoteEligibility[]>(
      DaoApi.FETCH_DAO_VOTE_ELIGIBILITY,
      {
        params: payload,
      }
    )
  },

  /**
   * DAO 투표 목록 조회
   * @param payload
   */
  fetchDaoVoteList(payload: DaoType.DaoVoteListPayload) {
    return api.get<DaoType.DaoVoteList>(DaoApi.FETCH_DAO_VOTE_LIST, {
      params: payload,
    })
  },

  /**
   * DAO 투표 수정
   * @param payload
   */
  editDaoVote(payload: DaoType.DaoVoteEditPayload) {
    return api.put<DaoType.DaoVoteListItem>(DaoApi.EDIT_DAO_VOTE, payload)
  },

  /**
   * DAO 투표 하기
   * @param payload
   */
  createDaoVoting(payload: DaoType.DaoVotingPayload) {
    return api.post<DaoType.DaoVotingResData>(DaoApi.CREATE_DAO_VOTING, null, {
      params: payload,
    })
  },
}
