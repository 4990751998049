<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import { bridgeToApp } from '@bridge'

const { t, locale, locales, setLocale } = useI18n()
const { gtEvent } = useGoogleTag()
const appStore = useAppStore()
const etcStore = useEtcStore()

const route = useRoute()
const tippyRef = ref<typeof Tippy>()
const currentLocale = computed(() => ({
  label: locales.value.find((item) => item.code === locale.value),
  value: locale.value,
}))
const availableLocales = computed(() =>
  locales.value.map((item) => ({
    label: t(item.name!),
    value: item.code,
  }))
)

const handleOnChangeLocale = async (_value: string | number) => {
  const value = String(_value)
  const clearedRoutePath = useClearRoutePathLocale(route.fullPath)
  setLocale(value)
  tippyRef.value?.hide()
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '언어 변경',
    eventLabel: value,
    eventSlot: '헤더',
    eventI18nAddr: value,
    eventComponent: 'DropDown',
  })
  if (appStore.isApp) {
    await bridgeToApp.changeLocale(value)
  }
  await useNavigations({
    url: `/${clearedRoutePath}`,
    type: 'replace',
    localeCode: value,
  })
  await etcStore.fetchMainBanners()
}
</script>

<template>
  <div class="locale-change">
    <Tippy
      ref="tippyRef"
      :append-to="'parent'"
      :interactive="true"
      :placement="'bottom'"
      :theme="'popover'"
      :trigger="'click'"
      :role="'button'"
    >
      <NovaButtonGNB :icon="{ type: 'outline', name: 'globe' }" />

      <template #content>
        <div class="drop-down-menu">
          <NovaDropdownItem
            v-for="(item, index) in availableLocales"
            :key="index"
            :item="item"
            :active-value="currentLocale.value"
            class="drop-down-menu-item"
            @update="handleOnChangeLocale"
          />
        </div>
      </template>
    </Tippy>
  </div>
</template>

<style lang="scss" scoped>
.locale-change {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;

  .drop-down-menu {
    display: inline-flex;
    flex-direction: column;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    overflow: hidden;
  }
}
</style>
