import * as DaoType from './type'
import DaoService from './service'
import { initDaoState } from './init'

export * from './init'
export * from './type'

export const useDaoStore = defineStore('dao', () => {
  const daoOpeningAvailable = ref(initDaoState.daoOpeningAvailable)
  const daoJoinStatus = ref(initDaoState.daoJoinStatus)
  const daoInfo = ref(initDaoState.daoInfo)
  const daoStatus = ref(initDaoState.daoStatus)
  const daoPost = ref(initDaoState.daoPost)
  const daoCommentsAndReplies = ref(initDaoState.daoCommentsAndReplies)
  const daoPostsList = ref(initDaoState.daoPostsList)
  const daoNoticeList = ref(initDaoState.daoNoticeList)
  const daoNoticeListSimple = ref(initDaoState.daoNoticeListSimple)
  const daoTempPostsList = ref(initDaoState.daoTempPostsList)
  const daoBoardActiveFilter = ref(initDaoState.daoBoardActiveFilter)
  const daoMemberJoinRequestList = ref(initDaoState.daoMemberJoinRequestList)
  const daoMemberManagementList = ref(initDaoState.daoMemberManagementList)
  const daoVote = ref(initDaoState.daoVote)
  const daoVoteList = ref(initDaoState.daoVoteList)
  const daoVoteEligibility = ref(initDaoState.daoVoteEligibility)

  // DAO 해산 신청
  const deleteDaoDisband = async (payload: DaoType.DeleteDaoDisbandPayload) => {
    await DaoService.deleteDaoDisband(payload)
  }

  // DAO 정보 조회
  const fetchDaoInfo = async (payload: DaoType.DaoInfoPayload) => {
    const { data } = await DaoService.fetchDaoInfo(payload)
    daoInfo.value = data
    return data
  }

  // DAO 가입 신청
  const createDaoJoin = async (payload: DaoType.DaoJoinPayload) => {
    const { data } = await DaoService.createDaoJoin(payload)
    if (daoJoinStatus.value) {
      daoJoinStatus.value.sbscrbSttusCode = data.sbscrbSttusCode
    }
    return data
  }
  // DAO 가입 여부 조회
  const fetchDaoJoinStatus = async (payload: DaoType.DaoJoinStatusPayload) => {
    try {
      daoJoinStatus.value.loading = true
      const { data } = await DaoService.fetchDaoJoinStatus(payload)
      daoJoinStatus.value = data
      return data
    } finally {
      daoJoinStatus.value.loading = false
    }
  }

  // DAO 개설 신청
  const createDaoOpening = async (payload: DaoType.DaoOpeningPayload) => {
    const daoFormData = new FormData()
    Object.entries(payload).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (value instanceof File) {
          daoFormData.append(key, value) // File은 그대로 추가
        } else {
          daoFormData.append(key, String(value)) // 다른 값은 문자열로 변환하여 추가
        }
      }
    })
    await DaoService.createDaoOpening(daoFormData)
  }
  // DAO 개설 가능 여부 조회
  const fetchDaoOpeningAvailable = async () => {
    const { data } = await DaoService.fetchDaoOpeningAvailable()
    daoOpeningAvailable.value = data
    return data
  }

  // DAO 상태 조회
  const fetchDaoStatus = async () => {
    const { data } = await DaoService.fetchDaoStatus()
    daoStatus.value = data
    return data
  }

  // DAO 명 중복 확인
  const fetchDaoValidateDuplicate = async (
    payload: DaoType.DaoValidateDuplicatePayload
  ) => {
    const { data } = await DaoService.fetchDaoValidateDuplicate(payload)
    return data
  }
  /**
   * DAO 게시판 전체 목록 조회
   * @param payload
   * @param refresh
   * @param isSimple
   */
  const fetchDaoBoardPosts = async (
    payload: DaoType.DaoBoardPostsPayload,
    refresh = false,
    isSimple = false
  ) => {
    console.log(isSimple)
    const _payload = {
      ...daoPostsList.value.payload,
      ...payload,
    }

    if (refresh) {
      delete _payload.lastDaoNttSn
    }
    const { data, headers } = await DaoService.fetchDaoBoardPosts(_payload)
    if (isSimple) {
      const items = refresh
        ? data
        : daoNoticeListSimple.value.items.concat(data)
      daoNoticeListSimple.value = {
        items,
        payload: {
          ..._payload,
        },
        refresh,
        last: typeof headers['pagination-location'] === 'undefined',
      }
    } else {
      const items = refresh ? data : daoPostsList.value.items.concat(data)
      daoPostsList.value = {
        items,
        payload: {
          ..._payload,
          lastDaoNttSn: items.length ? items[items.length - 1].daoNttSn : 0,
        },
        refresh,
        last: typeof headers['pagination-location'] === 'undefined',
      }
    }
  }

  /**
   * DAO 게시판 필터 변경
   * @param id
   */
  const changeBoardActiveFilter = (id: DaoType.DaoBoardFilter) => {
    daoBoardActiveFilter.value = id
  }

  /**
   * DAO 게시판 게시물 상세 조회
   */
  const fetchDaoBoardPost = async (payload: DaoType.DaoBoardPostPayload) => {
    const { data } = await DaoService.fetchDaoBoardPost(payload)
    daoPost.value = data

    return data
  }
  /**
   * DAO 게시판 게시물 등록
   */
  const createDaoBoardPost = async (
    payload: DaoType.CreateDaoBoardPostPayload
  ) => {
    const { data } = await DaoService.createDaoBoardPost(payload)
    data.isUpdateBefore = true
    data.secessionAt = 'N'
    //
    // 게시물 리스트가 일반 게시물인지 공지인지 파악해서 리스트에 추가
    // 공지사항일떄
    if (payload.nttNoticeAt === 'Y') {
      daoNoticeList.value.items.unshift(data)
    } else {
      daoPostsList.value.items.unshift(data)
    }
  }

  /**
   * 해당 되는 게시판을 찾아서 삭제
   * @param nttNoticeAt
   * @param index
   */
  const sliceInPostList = (nttNoticeAt: boolean, index: number) => {
    if (nttNoticeAt) {
      if (index !== -1) {
        daoNoticeList.value.items.splice(index, 1)
      }
    } else if (index !== -1) {
      daoPostsList.value.items.splice(index, 1)
    }
  }

  /**
   * DAO 게시판 수정
   * @param payload
   * @param isChangingNoticeAt
   */
  const editDaoBoardPost = async (
    payload: DaoType.editDaoBoardPostPayload,
    isChangingNoticeAt: boolean
  ) => {
    const { data } = await DaoService.editDaoBoardPost(payload)
    data.isUpdateBefore = true // 이미지 리사이즈를 기다리기 위한 상태 설정

    const isNotice = payload.nttNoticeAt === 'Y'
    let targetList
    if (isChangingNoticeAt) {
      targetList = isNotice ? daoPostsList.value : daoNoticeList.value
    } else {
      targetList = isNotice ? daoNoticeList.value : daoPostsList.value
    }
    const targetItemIndex = targetList.items.findIndex(
      (item) => item.daoNttSn === payload.daoNttSn
    )
    // 속성이 변경된 경우: 공지 ⇔ 일반 게시물일 때 현재 목록에서 삭제
    if (isChangingNoticeAt) {
      sliceInPostList(!isNotice, targetItemIndex)
    } else {
      // 게시물 속성 변경 없이 업데이트할 경우
      if (targetItemIndex > -1) {
        targetList.items[targetItemIndex] = data
      }

      // 상세보기에서 수정된 내용으로 업데이트
      if (daoPost.value?.daoNttSn === payload.daoNttSn) {
        daoPost.value = {
          ...daoPost.value,
          ...data,
        } as DaoType.DaoBoardPost
      }
    }
  }

  /**
   * DAO 게시판 게시물 삭제
   * @param payload
   * @param nttNoticeAt
   */
  const deleteDaoBoardPost = async (
    payload: DaoType.deleteDaoBoardPostPayload,
    nttNoticeAt: boolean
  ) => {
    await DaoService.deleteDaoBoardPost(payload)
    // 삭제한 게시물이 공지 사항 일때
    if (nttNoticeAt) {
      const noticeItemIndex = daoNoticeList.value.items.findIndex(
        (item) => item.daoNttSn === payload.daoNttSn
      )
      sliceInPostList(nttNoticeAt, noticeItemIndex)
    } else {
      const postItemIndex = daoPostsList.value.items.findIndex(
        (item) => item.daoNttSn === payload.daoNttSn
      )
      sliceInPostList(nttNoticeAt, postItemIndex)
    }
  }

  /**
   * DAO store model update
   * @param payload
   */
  const updateDaoPostModel = (payload: {
    daoNttSn: DaoType.DaoBoardPost['daoNttSn']
    replace: any
  }) => {
    if (payload.replace.nttNoticeAt === 'Y') {
      // 공지 사항 일떄!
      // daoNoticeList
      // 1. 공지 사항 목록에서 해당 게시물 찾아서 업데이트
      const daoNoticeItemIndex = daoNoticeList.value.items.findIndex(
        (item) => item.daoNttSn === payload.daoNttSn
      )
      if (daoNoticeItemIndex > -1) {
        daoNoticeList.value.items[daoNoticeItemIndex] = {
          ...daoNoticeList.value.items[daoNoticeItemIndex],
          ...payload.replace,
        }
      }
    } else {
      // 일반 게시물 일떄!
      // daoPostsList
      // 1. 일반 게사물 목록에서 해당 게시물 찾아서 업데이트
      const daoPostsListItemIndex = daoPostsList.value.items.findIndex(
        (item) => item.daoNttSn === payload.daoNttSn
      )
      if (daoPostsListItemIndex > -1) {
        daoPostsList.value.items[daoPostsListItemIndex] = {
          ...daoPostsList.value.items[daoPostsListItemIndex],
          ...payload.replace,
        }
      }
    }
    // 2. DAO 포스트 상세 업데이트
    if (daoPost.value && daoPost.value.daoNttSn === payload.daoNttSn) {
      daoPost.value = {
        ...daoPost.value,
        ...payload.replace,
      }
    }
  }

  /**
   * DAO 게시판 게시물 좋아요
   * @param payload
   * @param successPostRecommend
   */
  const createDaoBoardPostRecommend = async (
    payload: DaoType.DaoBoardPostActionPayload,
    successPostRecommend: DaoType.DaoRecommendAndUnRecommendSuccess
  ) => {
    const { data } = await DaoService.createDaoBoardPostRecommend(payload)
    updateDaoPostModel({
      daoNttSn: payload.daoNttSn,
      replace: successPostRecommend,
    })
    return data
  }

  /**
   * DAO 게시판 게시물 좋아요 취소
   * @param payload
   * @param successPostRecommend
   */
  const editDaoBoardPostRecommend = async (
    payload: DaoType.DaoBoardPostActionPayload,
    successPostRecommend: DaoType.DaoRecommendAndUnRecommendSuccess
  ) => {
    const { data } = await DaoService.editDaoBoardPostRecommend(payload)
    updateDaoPostModel({
      daoNttSn: payload.daoNttSn,
      replace: successPostRecommend,
    })
    return data
  }

  /**
   * DAO 게시판 공지 사항 목록 조회
   */
  const fetchDaoBoardNotices = async (
    payload: DaoType.DaoBoardPostsPayload,
    refresh = false
  ) => {
    const _payload = {
      ...daoNoticeList.value.payload,
      ...payload,
    }

    if (refresh) {
      delete _payload.lastDaoNttSn
    }
    const { data, headers } = await DaoService.fetchDaoBoardPosts(_payload)
    const items = refresh ? data : daoNoticeList.value.items.concat(data)
    daoNoticeList.value = {
      items,
      payload: {
        ..._payload,
        lastDaoNttSn: items.length ? items[items.length - 1].daoNttSn : 0,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
    return data
  }

  /**
   * DAO 게시판 목록 조회 - Simple
   */
  // const fetchDaoBoardPostsSimple = async (
  //   payload: DaoType.DaoBoardPostsPayload,
  //   refresh = false
  // ) => {
  //   const _payload = {
  //     ...daoNoticeListSimple.value.payload,
  //     ...payload,
  //   }
  //
  //   if (refresh) {
  //     delete _payload.lastDaoNttSn
  //   }
  //
  //   const { data, headers } = await DaoService.fetchDaoBoardPostsSimple(
  //     _payload
  //   )
  //   const items = refresh ? data : daoNoticeListSimple.value.items.concat(data)
  //   daoNoticeListSimple.value = {
  //     items,
  //     payload: {
  //       ..._payload,
  //     },
  //     refresh,
  //     last: typeof headers['pagination-location'] === 'undefined',
  //   }
  // }

  /**
   * DAO 게시판 게시물 댓글/대댓글 목록 조회
   * @param payload
   * @param refresh
   */
  const fetchDaoBoardPostReply = async (
    payload: DaoType.DaoBoardPostReplyPayload,
    refresh = false
  ) => {
    const { data, headers } = await DaoService.fetchDaoBoardPostReply({
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    })
    // 댓글 & 답글 순서별로 필요한 렌더링 조건 추가
    let replace: DaoType.DaoBoardPostReply[] = []
    if (data.length) {
      replace = calcCommentsAndReplies(
        refresh ? data : daoCommentsAndReplies.value.items.concat(data)
      )
    }

    // 댓글 & 답글 목록 업데이트
    daoCommentsAndReplies.value = {
      items: replace,
      payload: { ...payload, pageNum: refresh ? 1 : payload.pageNum + 1 },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
    return replace
  }

  /**
   * DAO 게시판 게시물 댓글/대댓글 작성
   * @param payload
   */
  const createDaoBoardPostReply = async (
    payload: DaoType.CreateDaoBoardPostReplyPayload
  ) => {
    const { data } = await DaoService.createDaoBoardPostReply(payload)
    // 받은 값을 store에 추가 해야 함
    const replace = [...daoCommentsAndReplies.value.items]
    // 1. 댓글일때 배열 최상위 추가 및 카운터 변경
    if (!payload.nttParntsAnswerSn) {
      replace.unshift(data)

      updateDaoPostModel({
        daoNttSn: payload.daoNttSn,
        replace: { answerCount: daoPost.value!.answerCount + 1 },
      })
    }

    // 2. 부모 댓글의 답글 맨 마지막에 추가 혹은 답글일때 부모 댓글의 다음
    else {
      const replyLastIdx = replace
        .map((item) => item.nttParntsAnswerSn)
        .lastIndexOf(payload.nttParntsAnswerSn!)

      // 2-1. 부모 댓글의 답글들이 존재할때 맨 마지막에 추가
      if (replyLastIdx !== -1) {
        replace[replyLastIdx].replyreAt = 'Y'
        replace.splice(replyLastIdx + 1, 0, data)
      }

      // 2-2. 부모 댓글의 답글이 존재하지 않을때 부모 댓글
      else {
        const commentIdx = replace.findIndex(
          (item) => item.daoNttAnswerSn === payload.nttParntsAnswerSn
        )
        replace.splice(commentIdx + 1, 0, data)
      }
    }

    // 3. 최종 순서 변경한 후 업데이트
    daoCommentsAndReplies.value.items = calcCommentsAndReplies(replace)
    return data
  }

  /**
   * DAO 게시판 게시물 댓글/대댓글 수정
   * @param payload
   */
  const editDaoBoardPostReply = async (
    payload: DaoType.EditDaoBoardPostReplyPayload
  ) => {
    const { data } = await DaoService.editDaoBoardPostReply(payload)
    // 받은 값을 store에 추가 해야 함
    const replaceTargetIdx = daoCommentsAndReplies.value.items.findIndex(
      (item) => item.daoNttAnswerSn === data.daoNttAnswerSn
    )
    if (replaceTargetIdx !== -1) {
      daoCommentsAndReplies.value.items[replaceTargetIdx] = {
        ...daoCommentsAndReplies.value.items[replaceTargetIdx],
        ...data,
      }
    }
    return data
  }

  /**
   * DAO 게시판 게시물 댓글/대댓글 삭제
   * @param payload
   * @param updatePayload
   */
  const deleteDaoBoardPostReply = async (
    payload: DaoType.DeleteDaoBoardPostReplyPayload,
    updatePayload: DaoType.DaoReplyRemoveUpdatePayload
  ) => {
    const { data } = await DaoService.deleteDaoBoardPostReply(payload)

    daoCommentsAndReplies.value.items.some((item) => {
      if (item.daoNttAnswerSn === payload.daoNttAnswerSn) {
        item.deleteAt = 'Y'
        return true
      }
      return false
    })

    updateDaoPostModel({
      daoNttSn: updatePayload.daoNttSn,
      replace: { answerCount: updatePayload.answerCount - 1 },
    })
    return data
  }

  /**
   * 댓글 & 답글 리스트에서 동적으로 추가가 이뤄지면 순서별 렌더링 타입을 위한 모델 계산
   * @param daoComments
   */
  const calcCommentsAndReplies = (daoComments: DaoType.DaoBoardPostReply[]) => {
    // 댓글 & 답글 중 답글이 존재하는지 여부를 판단하고 답글 여부 추가
    const hasRepliesItemSns = [
      ...new Set(
        daoComments
          .filter((item) => item.nttParntsAnswerSn)
          .map((item) => item.nttParntsAnswerSn)
      ),
    ]
    daoComments.forEach((item) => {
      item.replyreAt = hasRepliesItemSns.includes(item.daoNttAnswerSn)
        ? 'Y'
        : 'N'
    })

    // 답글중 마지막인지 판단하고 마지막 답글 여부 추가(배열의 뒤에서 부터 검사)
    daoComments.reduce((acc, cur, index) => {
      if (
        daoComments.length !== index + 1 &&
        acc.nttParntsAnswerSn &&
        !cur.nttParntsAnswerSn
      ) {
        acc.lastReply = true
      }

      if (daoComments.length === index + 1 && cur.nttParntsAnswerSn) {
        cur.lastReply = true
      }

      return cur
    })
    return daoComments
  }

  /**
   * DAO store daoCommentsAndReplies model update
   * @param daoNttAnswerSn
   * @param updateValue
   */
  const updateDaoReplyRecommend = (
    daoNttAnswerSn: DaoType.DaoBoardPostReply['daoNttAnswerSn'],
    updateValue: DaoType.DaoReplyRecommendAndUnRecommendSuccess
  ) => {
    const replaceIdx = daoCommentsAndReplies.value.items.findIndex(
      (item) => item.daoNttAnswerSn === daoNttAnswerSn
    )
    const replaceTarget = daoCommentsAndReplies.value.items[replaceIdx]

    daoCommentsAndReplies.value.items[replaceIdx] = {
      ...replaceTarget,
      ...updateValue,
      recommendCount: updateValue.rcCount,
    }
  }

  /**
   * DAO 게시판 게시물 댓글/대댓글 좋아요
   * @param payload
   * @param successCommentRecommend
   */
  const createDaoBoardPostReplyRecommend = async (
    payload: DaoType.CreateDaoBoardPostReplyRecommendPayload,
    successCommentRecommend: DaoType.DaoReplyRecommendAndUnRecommendSuccess
  ) => {
    const { data } = await DaoService.createDaoBoardPostReplyRecommend(payload)
    updateDaoReplyRecommend(payload.daoNttAnswerSn, successCommentRecommend)
    return data
  }

  /**
   * DAO 게시판 게시물 댓글/대댓글 좋아요 취소
   * @param payload
   * @param successCommentRecommend
   */
  const editDaoBoardPostReplyRecommend = async (
    payload: DaoType.CreateDaoBoardPostReplyRecommendPayload,
    successCommentRecommend: DaoType.DaoReplyRecommendAndUnRecommendSuccess
  ) => {
    const { data } = await DaoService.editDaoBoardPostReplyRecommend(payload)
    updateDaoReplyRecommend(payload.daoNttAnswerSn, successCommentRecommend)
    return data
  }

  /**
   * DAO 임시 저장 상세
   * @param payload
   */
  const fetchDaoBoardTempPost = async (
    payload: DaoType.DaoBoardTempPostPayload
  ) => {
    const { data } = await DaoService.fetchDaoBoardTempPost(payload)
    return data
  }

  /**
   * DAO 게시물 임시 저장
   * @param payload
   */
  const createDaoBoardTempPost = async (
    payload: DaoType.CreateDaoBoardTempPostPayload
  ) => {
    const { data } = await DaoService.createDaoBoardTempPost(payload)
    // Store 에서 생성한 임시저장 데이터 추가
    daoTempPostsList.value.items.unshift(data)
    return data
  }

  /**
   * DAO 게시물 임시 저장 삭제
   * @param payload
   */
  const deleteDaoBoardTempPost = async (
    payload: DaoType.DaoBoardTempPostPayload
  ) => {
    await DaoService.deleteDaoBoardTempPost(payload)
    // Store 에서 삭제한 임시저장 데이터 찾아서 제거
    const tempPostItemIndex = daoTempPostsList.value.items.findIndex(
      (item) => item.daoTmprNttSn === payload.daoTmprNttSn
    )
    daoTempPostsList.value.items.splice(tempPostItemIndex, 1)
  }

  /**
   * DAO 임시 저장 목록 불러오기
   * @param payload
   * @param refresh
   */
  const fetchDaoBoardTempPostList = async (
    payload: DaoType.DaoBoardTempPostListPayload,
    refresh = false
  ) => {
    const _payload = {
      ...daoTempPostsList.value.payload,
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    }
    const { data, headers } = await DaoService.fetchDaoBoardTempPostList(
      _payload
    )
    const items = refresh ? data : daoTempPostsList.value.items.concat(data)
    daoTempPostsList.value = {
      items,
      payload: {
        ..._payload,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
    return data
  }
  /**
   * DAO 가입 승인
   */
  const createDaoMemberJoinApproval = async (
    payload: DaoType.DaoMemberApprovalPayload
  ) => {
    const { data } = await DaoService.createDaoMemberJoinApproval(payload)
    const daoMemberJoinRequestItemIndex =
      daoMemberJoinRequestList.value.items.findIndex(
        (item) => item.daoMbrSn === payload.daoMbrSn
      )
    if (daoMemberJoinRequestItemIndex > -1) {
      daoMemberJoinRequestList.value.items.splice(
        daoMemberJoinRequestItemIndex,
        1
      )
      daoMemberJoinRequestList.value.count =
        daoMemberJoinRequestList.value.count - 1
    }
    return data
  }

  /**
   * DAO 가입 거절
   */
  const createDaoMemberJoinReject = async (
    payload: DaoType.DaoMemberRejectPayload
  ) => {
    const { data } = await DaoService.createDaoMemberJoinReject(payload)
    const daoMemberJoinRequestItemIndex =
      daoMemberJoinRequestList.value.items.findIndex(
        (item) => item.daoMbrSn === payload.daoMbrSn
      )
    if (daoMemberJoinRequestItemIndex > -1) {
      daoMemberJoinRequestList.value.items.splice(
        daoMemberJoinRequestItemIndex,
        1
      )
      daoMemberJoinRequestList.value.count =
        daoMemberJoinRequestList.value.count - 1
    }
    return data
  }

  /**
   * DAO 가입 요청 목록 조회
   */
  const fetchDaoMemberJoinRequestList = async (
    payload: DaoType.DaoMemberJoinRequestListPayload,
    refresh = false
  ) => {
    const _payload = {
      ...daoMemberJoinRequestList.value.payload,
      ...payload,
      pageNum: refresh ? 1 : daoMemberJoinRequestList.value.payload.pageNum + 1,
    }
    const { data, headers } = await DaoService.fetchDaoMemberJoinRequestList(
      _payload
    )
    const items = refresh
      ? data.list
      : daoMemberJoinRequestList.value.items.concat(data.list)

    daoMemberJoinRequestList.value = {
      count: data.count,
      items,
      payload: {
        ..._payload,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }
  /**
   * DAO 멤버 목록 조회
   */
  const fetchDaoMemberManagementList = async (
    payload: DaoType.DaoMemberManagementListPayload,
    refresh = false
  ) => {
    const _payload = {
      ...daoMemberManagementList.value.payload,
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
    }

    const { data, headers } = await DaoService.fetchDaoMemberManagementList(
      _payload
    )
    const items = refresh
      ? data.list
      : daoMemberManagementList.value.items.concat(data.list)
    const count = data.count
    daoMemberManagementList.value = {
      count,
      items,
      payload: {
        ..._payload,
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
      loading: false,
    }
  }
  /**
   * DAO 멤버 추방
   * @param payload
   */
  const editDaoMemberDeport = async (payload: DaoType.DaoMemberDeport) => {
    await DaoService.editDaoMemberDeport(payload)
    // Store 에서 삭제한 임시저장 데이터 찾아서 제거
    const daoMemberItemIndex = daoMemberManagementList.value.items.findIndex(
      (item) => item.daoMbrSn === payload.daoMbrSn
    )
    daoMemberManagementList.value.items.splice(daoMemberItemIndex, 1)
    daoMemberManagementList.value.count =
      daoMemberManagementList.value.count - 1
  }

  /**
   * DAO 멤버 관리자 임명 /해제
   * @param payload
   */
  const editDaoMemberModerator = async (
    payload: DaoType.DaoMemberModerator
  ) => {
    const { data } = await DaoService.editDaoMemberModerator(payload)

    // DAO 멤버 목록 아이템 최신 상태 업데이트
    const replaceTargetIdx = daoMemberManagementList.value.items.findIndex(
      (item) => item.daoMbrSn === data.daoMbrSn
    )
    if (replaceTargetIdx !== -1) {
      daoMemberManagementList.value.items[replaceTargetIdx] = {
        ...daoMemberManagementList.value.items[replaceTargetIdx],
        ...data,
      }
    }
    /* daoMemberList
      .sort((a, b) => a.daoAuthorLevel - b.daoAuthorLevel)
      .reduce((before, after) => {
        const beforeDaoMbrSns = before.map((item) => item.daoMbrSn)
        return beforeDaoMbrSns.includes(after.daoMbrSn) ? before : [...before, after];
      }) */

    return data
  }

  /**
   * DAO 멤버 탈퇴
   * @param payload
   */
  const editDaoMemberSecession = async (
    payload: DaoType.DaoMemberSecessionPayload
  ) => {
    const { data } = await DaoService.editDaoMemberSecession(payload)
    // const daoMemberManageItemIndex =
    //   daoMemberManagementList.value.items.findIndex(
    //     (item) => item.daoMbrSn === payload.daoMbrSn
    //   )
    // if (daoMemberManageItemIndex > -1) {
    //   daoMemberManagementList.value.items.splice(index, 1)
    // }
    return data
  }

  // -------------- 투표 --------------------
  /**
   * DAO 투표 생성
   * @param payload
   */
  const createDaoVote = async (payload: DaoType.DaoVoteCreatePayload) => {
    const { data } = await DaoService.createDaoVote(payload)
    const defaultListItem: DaoType.DaoVoteListItem = {
      daoColctSn: payload.daoColctSn,
      daoVoteBeginDt: '',
      daoVoteEndDt: '',
      daoVoteMbrRspnsCo: 0,
      daoVoteNm: '',
      daoVoteRatio: 0,
      daoVoteSn: 0,
      daoVoteSttusCode: 'P',
      daoVoteSttusName: '',
      daoVoteTotTrgcnt: 0,
      remainingDaysToVoteEnd: 0,
      voteAt: false,
    }
    const newItem = {
      ...defaultListItem,
      ...data,
    }
    daoVoteList.value.items.unshift(newItem)
    return data
  }
  /**
   * DAO 투표 삭제
   * @param payload
   */
  const deleteDaoVote = async (payload: DaoType.DaoVoteDeletePayload) => {
    const { data } = await DaoService.deleteDaoVote(payload)
    // data daoVoteList 받아서 리스트에서 수정 해줘야 함요~
    // 삭제한 데이터를 리스트에서 찾아서 삭제
    const voteItemIndex = daoVoteList.value.items.findIndex(
      (item) => item.daoVoteSn === payload.daoVoteSn
    )
    if (voteItemIndex > -1) {
      daoVoteList.value.items.splice(voteItemIndex, 1)
    }
    // 상세보기 데이터 초기화
    daoVote.value = null
    return data
  }

  /**
   * DAO 투표 상세 조회
   * @param payload
   */
  const fetchDaoVote = async (payload: DaoType.DaoVoteDetailPayload) => {
    const { data } = await DaoService.fetchDaoVote(payload)
    daoVote.value = data
    console.log('data-0-0-0-0-0-0-0-0-0-')
    console.log(data)

    return data
  }
  /**
   * DAO 투표 참여 조건 조회
   * @param payload
   */
  const fetchDaoVoteEligibility = async (
    payload: DaoType.DaoVoteEligibilityPayload
  ) => {
    const { data } = await DaoService.fetchDaoVoteEligibility(payload)
    daoVoteEligibility.value = data
    return data
  }
  /**
   * DAO 투표 목록 조회
   * @param payload
   * @param refresh
   */
  const fetchDaoVoteList = async (
    payload: DaoType.DaoVoteListPayload,
    refresh = false
  ) => {
    if (refresh) {
      daoVoteList.value.payload.lastDaoVoteSn = undefined
    }

    const _payload = {
      ...daoVoteList.value.payload,
      ...payload,
      pageNum: refresh ? 1 : payload.pageNum + 1,
      lastDaoVoteSn: daoVoteList.value.payload.lastDaoVoteSn,
    }
    const { data, headers } = await DaoService.fetchDaoVoteList(_payload)
    const items = refresh
      ? data.creatorDaoVoteList
      : daoVoteList.value.items.concat(data.creatorDaoVoteList)

    const lastDaoVoteSn =
      items.length > 0 ? items[items.length - 1].daoVoteSn : undefined
    daoVoteList.value = {
      creatorDaoMemberAuthorityStatusDto:
        data.creatorDaoMemberAuthorityStatusDto,
      items,
      payload: {
        ..._payload,
        ...(lastDaoVoteSn && { lastDaoVoteSn }),
      },
      refresh,
      last: typeof headers['pagination-location'] === 'undefined',
    }
  }

  /**
   * DAO 투표 수정
   * @param payload
   */
  const editDaoVote = async (payload: DaoType.DaoVoteEditPayload) => {
    const { data } = await DaoService.editDaoVote(payload)
    const voteItemIndex = daoVoteList.value.items.findIndex(
      (item) => item.daoVoteSn === payload.daoVoteSn
    )
    if (voteItemIndex > -1) {
      daoVoteList.value.items[voteItemIndex] = data
    }
    // 상세 데이터는 초기화
    daoVote.value = null

    return data
  }

  /**
   * DAO 투표 하기
   * @param payload
   */
  const createDaoVoting = async (payload: DaoType.DaoVotingPayload) => {
    const { data } = await DaoService.createDaoVoting(payload)
    // 인덱스 찾기
    const daoVoteItemIndex = daoVoteList.value.items.findIndex(
      (item) => item.daoVoteSn === payload.daoVoteSn
    )
    daoVoteList.value.items[daoVoteItemIndex] = {
      ...daoVoteList.value.items[daoVoteItemIndex],
      ...data,
      voteAt: true,
    }
    return data
  }

  /**
   * DAO 상세보기 초기화
   */
  const refreshVoteDetail = () => {
    daoVote.value = null
  }
  return {
    // 데이터
    daoOpeningAvailable,
    daoJoinStatus,
    daoInfo,
    daoStatus,
    daoPost,
    daoCommentsAndReplies,
    daoPostsList,
    daoNoticeList,
    daoNoticeListSimple,
    daoTempPostsList,
    daoMemberJoinRequestList,
    daoMemberManagementList,
    daoVote,
    daoVoteList,
    daoVoteEligibility,
    // 함수
    deleteDaoDisband,
    fetchDaoInfo,
    fetchDaoStatus,
    createDaoJoin,
    fetchDaoJoinStatus,
    createDaoOpening,
    fetchDaoOpeningAvailable,
    fetchDaoValidateDuplicate,
    createDaoBoardPost,
    editDaoBoardPost,
    deleteDaoBoardPost,
    fetchDaoBoardPost,
    updateDaoPostModel,
    createDaoBoardPostRecommend,
    createDaoBoardPostReply,
    editDaoBoardPostRecommend,
    deleteDaoBoardPostReply,
    fetchDaoBoardPostReply,
    fetchDaoBoardPosts,
    fetchDaoBoardNotices,
    // fetchDaoBoardPostsSimple,
    editDaoBoardPostReply,
    createDaoBoardPostReplyRecommend,
    editDaoBoardPostReplyRecommend,
    fetchDaoBoardTempPost,
    createDaoBoardTempPost,
    deleteDaoBoardTempPost,
    fetchDaoBoardTempPostList,
    daoBoardActiveFilter,
    changeBoardActiveFilter,
    createDaoMemberJoinApproval,
    createDaoMemberJoinReject,
    fetchDaoMemberJoinRequestList,
    fetchDaoMemberManagementList,
    editDaoMemberDeport,
    editDaoMemberModerator,
    editDaoMemberSecession,
    // ---- 투표 ----
    createDaoVote,
    deleteDaoVote,
    fetchDaoVote,
    fetchDaoVoteEligibility,
    fetchDaoVoteList,
    editDaoVote,
    createDaoVoting,
    refreshVoteDetail,
  }
})
