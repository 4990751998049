<script setup lang="ts"></script>

<template>
  <NovaLayoutWrapper>
    <NovaHeader />

    <NovaLayoutDefault :page-mode="true">
      <template #left>
        <NovaHeaderLeftSide :scroll="true">
          <NovaBoxHashtagAll />
        </NovaHeaderLeftSide>
      </template>

      <template #center>
        <slot />
      </template>

      <template #right>
        <NovaHeaderRightSide :scroll="true">
          <NovaBoxHashtagRankings />
        </NovaHeaderRightSide>
      </template>
    </NovaLayoutDefault>
  </NovaLayoutWrapper>
</template>
