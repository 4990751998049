<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import type {
  NovaBoxCreatorCategoriesEmits,
  NovaBoxCreatorCategoriesProps,
} from './NovaBoxCreatorCategories.types'

const { gtEvent } = useGoogleTag()
const emit = defineEmits<NovaBoxCreatorCategoriesEmits>()
const props = withDefaults(defineProps<NovaBoxCreatorCategoriesProps>(), {
  isLoading: false,
  isError: false,
  overflowVisible: false,
  withBar: false,
  showNavigation: false,
})

const swiperOptions = computed(() => {
  return {
    navigation: props.showNavigation,
    slidesPerView: 'auto',
    breakpoints: {
      320: {
        spaceBetween: props.withBar ? 10 : 10,
      },
      768: {
        spaceBetween: props.withBar ? 10 : 10,
      },
    },
    modules: [Navigation],
  }
})

const handleOnChangeCreatorCategory = (category: any) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `${props.gaSlot} > 카테고리 선택`,
    eventLabel: category.ctgryNm,
    eventSlot: `${props.gaSlot}`,
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  emit('update:categoryChange', category.crtrCtgrySn)
}
</script>

<template>
  <ClientOnly>
    <div :class="['category-list', { 'with-bar': withBar }]">
      <Swiper
        v-if="!isLoading && !isError && creatorCategories.length"
        v-bind="swiperOptions"
        class="swiper-category"
        :style="{
          overflow: props.overflowVisible ? 'visible' : 'hidden',
        }"
      >
        <SwiperSlide
          v-for="category in creatorCategories"
          :key="category.crtrCtgrySn"
        >
          <div
            :class="[
              'category-item',
              { 'with-bar': withBar },
              { on: selectCreatorCategorySn === category.crtrCtgrySn },
            ]"
            @click="() => handleOnChangeCreatorCategory(category)"
          >
            {{ category.ctgryNm }}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.category-list {
  padding-top: 8px;
  &.with-bar {
    position: relative;
    padding-top: 0 !important;

    &:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: inline-block;
      width: 100%;
      height: 3px;
      content: '';
      background: #f0f0f0;
      transform: translateX(-50%);
    }
  }
  .swiper-category {
    :deep(.swiper-slide) {
      width: auto;
    }

    :deep(.swiper-button-prev) {
      left: 0;
      &:after {
        color: $color-text-12;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
      }
    }

    :deep(.swiper-button-next) {
      right: 0;
      &:after {
        color: $color-text-12;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
      }
    }

    .category-item {
      color: #a0a0a0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.4px;
      cursor: pointer;

      @include mobile {
        font-size: 14px;
        letter-spacing: -0.325px;
      }

      &.with-bar {
        position: relative;
        padding: 6px 10px;
        &.on {
          &:after {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 100%;
            height: 3px;
            content: '';
            background-color: $color-primary-blue-dark;
            transform: translateX(-50%);
          }
        }
      }

      &.on {
        color: $color-primary-blue-dark;
        font-weight: 700;
      }
    }
  }
}
</style>
