import apiPath from '@/store/apiPath'

export default {
  /**
   * DAO 해산 신청
   * @method DELETE
   */
  DELETE_DAO_DISBAND: `${apiPath.DAO}/v1/disband`,

  /**
   * DAO 정보 조회
   * @method GET
   */
  FETCH_DAO_INFO: `${apiPath.DAO}/v1/info/:daoSn`,

  /**
   * DAO 개설 신청
   * @method POST인
   */
  CREATE_DAO_OPENING: `${apiPath.DAO}/v1/opening`,

  /**
   * DAO 개설 가능 여부 조회
   * @method GET
   */
  FETCH_DAO_OPENING_AVAILABLE: `${apiPath.DAO}/v1/opening-available`,

  /**
   * DAO 상태 조회
   * @method GET
   */
  FETCH_DAO_STATUS: `${apiPath.DAO}/v1/status`,

  /**
   * DAO 명 중복 확인
   * @method GET
   */
  FETCH_DAO_VALIDATE_DUPLICATE: `${apiPath.DAO}/v1/validate-duplicate`,

  /**
   * DAO 게시판 게시물 상세조회
   * @method GET
   */
  FETCH_DAO_BOARD_POST: `${apiPath.DAO}/board/v1/post`,

  /**
   * DAO 포스트 등록
   * @method POST
   */
  CREATE_DAO_BOARD_POST: `${apiPath.DAO}/board/v1/post`,

  /**
   * DAO 포스트 삭제
   * @method DELETE
   */
  DELETE_DAO_BOARD_POST: `${apiPath.DAO}/board/v1/post`,

  /**
   * DAO 포스트 수정
   * @method PUT
   */
  EDIT_DAO_BOARD_POST: `${apiPath.DAO}/board/v1/post`,

  /**
   * DAO 포스트 좋아요
   * @method POST
   */
  CREATE_DAO_BOARD_POST_RECOMMEND: `${apiPath.DAO}/board/v1/post/recommend`,

  /**
   * DAO 포스트 좋아요 취소
   * @method PUT
   */
  EDIT_DAO_BOARD_POST_RECOMMEND: `${apiPath.DAO}/board/v1/post/recommend`,

  /**
   * DAO 포스트 댓글/대댓글 목록 조회
   * @method GET
   */
  FETCH_DAO_BOARD_POST_REPLY: `${apiPath.DAO}/board/v1/post/reply`,

  /**
   * DAO 게시판 게시물 목록 조회
   * @method GET
   */
  FETCH_DAO_BOARD_POSTS: `${apiPath.DAO}/board/v1/posts`,

  /**
   * DAO 게시판 게시물 목록 조회 - simple
   * @method GET
   */
  FETCH_DAO_BOARD_POSTS_SIMPLE: `${apiPath.DAO}/board/v1/posts/simple`,

  /**
   * DAO 포스트 댓글/대댓글 목록 작성
   * @method POST
   */
  CREATE_DAO_BOARD_POST_REPLY: `${apiPath.DAO}/board/v1/reply`,

  /**
   * DAO 포스트 댓글/대댓글 목록 수정
   * @method PUT
   */
  EDIT_DAO_BOARD_POST_REPLY: `${apiPath.DAO}/board/v1/reply`,

  /**
   * DAO 포스트 댓글/대댓글 목록 삭제
   * @method DELETE
   */
  DELETE_DAO_BOARD_POST_REPLY: `${apiPath.DAO}/board/v1/reply`,

  /**
   * DAO 포스트 댓글/대댓글 종아요
   * @method POST
   */
  CREATE_DAO_BOARD_POST_REPLY_RECOMMEND: `${apiPath.DAO}/board/v1/reply/recommend`,

  /**
   * DAO 포스트 댓글/대댓글 좋아요 취소
   * @method PUT
   */
  EDIT_DAO_BOARD_POST_REPLY_RECOMMEND: `${apiPath.DAO}/board/v1/reply/recommend`,

  /**
   * DAO 게시판 임시 게시물 상세조회
   * @method GET
   */
  FETCH_DAO_BOARD_TEMP_POST: `${apiPath.DAO}/board/v1/temp-post`,

  /**
   * DAO 게시판 임시 게시물 등록
   * @method POST
   */
  CREATE_DAO_BOARD_TEMP_POST: `${apiPath.DAO}/board/v1/temp-post`,

  /**
   * DAO 게시판 임시 게시물 삭제
   * @method DELETE
   */
  DELETE_DAO_BOARD_TEMP_POST: `${apiPath.DAO}/board/v1/temp-post`,

  /**
   * DAO 게시판 임시 게시물 목록 조회
   * @method GET
   */
  FETCH_DAO_BOARD_TEMP_POST_LIST: `${apiPath.DAO}/board/v1/temp-posts`,

  // ----------------멤버-----------

  /**
   * DAO  멤버 추방
   * @method PUT
   */
  EDIT_DAO_MEMBER_MANAGEMENT_DEPORT: `${apiPath.DAO}/member/v1/deport`,

  /**
   * DAO 가입 신청
   * @method POST
   */
  CREATE_DAO_JOIN: `${apiPath.DAO}/member/v1/join`,

  /**
   * DAO 가입 여부 조회
   * @method GET
   */
  FETCH_DAO_JOIN_STATUS: `${apiPath.DAO}/member/v1/join-status/:daoSn`,

  /**
   * DAO Creator DAO 가입 승인
   * @method POST
   */
  CREATE_DAO_MEMBER_JOIN_APPROVAL: `${apiPath.DAO}/member/v1/join/approval`,
  /**
   * DAO Creator DAO 가입 거절
   * @method POST
   */
  CREATE_DAO_MEMBER_JOIN_REJECT: `${apiPath.DAO}/member/v1/join/reject`,
  /**
   * DAO Creator DAO 가입 요청 목록 조회
   * @method GET
   */
  FETCH_DAO_MEMBER_JOIN_REQUESTS: `${apiPath.DAO}/member/v1/join/requests`,
  /**
   * DAO Creator DAO 멤버 목록 조회
   * @method GET
   */
  FETCH_DAO_MEMBER_MANAGEMENT_LIST: `${apiPath.DAO}/member/v1/list`,
  /**
   * DAO  멤버 관리자 임명/해제
   * @method PUT
   */
  EDIT_DAO_MEMBER_MANAGEMENT_MODERATOR: `${apiPath.DAO}/member/v1/moderator`,
  /**
   * DAO  멤버 탈퇴
   * @method PUT
   */
  EDIT_DAO_MEMBER_MANAGEMENT_SECESSION: `${apiPath.DAO}/member/v1/secession`,

  // ========================== 투표 =====================

  /**
   * DAO 투표 생성
   * @method POST
   */
  CREATE_DAO_VOTE: `${apiPath.DAO}/voting/v1/create`,

  /**
   * DAO 투표 삭제
   * @method DELETE
   */
  DELETE_DAO_VOTE: `${apiPath.DAO}/voting/v1/delete`,

  /**
   * DAO 투표 상세 조회
   * @method GET
   */
  FETCH_DAO_VOTE_DETAIL: `${apiPath.DAO}/voting/v1/detail`,

  /**
   * DAO 투표 참여 조건 조회
   * @method GET
   */
  FETCH_DAO_VOTE_ELIGIBILITY: `${apiPath.DAO}/voting/v1/eligibility-criteria`,

  /**
   * DAO 투표 목록 조회
   * @method GET
   */
  FETCH_DAO_VOTE_LIST: `${apiPath.DAO}/voting/v1/list`,

  /**
   * DAO 투표 수정
   * @method PUT
   */
  EDIT_DAO_VOTE: `${apiPath.DAO}/voting/v1/modify`,

  /**
   * DAO 투표 하기
   * @method POST
   */
  CREATE_DAO_VOTING: `${apiPath.DAO}/voting/v1/vote`,
}
