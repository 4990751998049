<script setup lang="ts">
import type { NovaBoxUserUnitSummeryProps } from './NovaBoxUserUnitSummery.types'
// import { ROUTES } from '@configs'

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const props = withDefaults(defineProps<NovaBoxUserUnitSummeryProps>(), {
  coins: null,
  creator: null,
})

const { userStore, showSignInDialog } = useMembershipProcess()
const { show: modalShow } = useModal()
const lmCoins = computed(() => useNumberCompact(Number(props.coins) || 0, 1))
// const showChatButton = computed(() => {
//   const name = useGetRouteName()
//   return name.includes(ROUTES.CREATOR.name)
// })

// 팔로워 상세보기
const handleOnOpenFollowersDetail = async () => {
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '팔로워 상세보기',
    eventLabel: t('userSummery.followers'),
    eventSlot: 'user-summery',
    eventI18nAddr: useKoreanTranslation('userSummery.followers'),
    eventComponent: 'Button',
    userSn: props.userSn,
  })
  // if (!props.isEqualUser) return
  await modalShow(modalsName.MODAL_MY_FOLLOWERS, { userSn: props.userSn })
}
// 팔로잉 상세보기
const handleOnOpenFollowingsDetail = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '팔로잉 상세보기',
    eventLabel: t('userSummery.follow'),
    eventSlot: 'user-summery',
    eventI18nAddr: useKoreanTranslation('userSummery.follow'),
    eventComponent: 'Button',
    userSn: props.userSn,
  })
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }
  // if (!props.isEqualUser) return
  await modalShow(modalsName.MODAL_MY_FOLLOWINGS, { userSn: props.userSn })
}
</script>

<template>
  <div class="user-summery">
    <div
      :class="['summery-item', { 'able-clock': isEqualUser }]"
      @click="handleOnOpenFollowersDetail"
    >
      <p class="label">{{ $t('userSummery.followers') }}</p>
      <p class="count">{{ followers }}</p>
    </div>

    <div
      :class="['summery-item', { 'able-clock': isEqualUser }]"
      @click="handleOnOpenFollowingsDetail"
    >
      <p class="label">{{ $t('userSummery.follow') }}</p>
      <p class="count">{{ followings }}</p>
    </div>

    <div v-if="coins !== null" class="summery-item no-cursor">
      <p class="label">{{ $t('userSummery.lmCoins') }}</p>
      <p class="count">{{ lmCoins }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-summery {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
  @include mobile {
    gap: 12px;
  }

  .summery-item {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
    cursor: pointer;
    &.no-cursor {
      cursor: default;
    }
    p.label {
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: -0.3px;
      color: $color-99;
      @include mobile {
        font-size: 14px;
      }
    }

    p.count {
      display: block;
      position: relative;
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: -0.3px;
      color: $color-text-12;

      &:before {
        display: block;
        content: '';
        width: 110%;
        min-width: 42px;
        height: 13px;
        opacity: 0.1;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
