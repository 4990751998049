import type { MessagePayload } from '@firebase/messaging'
import { type BooleanYn, FirebaseDevice } from '@store'

/**
 * Firebase error status codes
 */
export enum FirebaseExceptionStatus {
  INIT_FAIL = 'initFail',

  // messaging
  NOTIFICATION_IS_UNAVAILABLE = 'notificationIsUnavailable',
  PERMISSION_IS_DENIED = 'permissionIsDenied',
  REQUEST_PERMISSION_FAIL = 'requestPermissionFail',
  GET_FCM_APP_TOKEN_FAIL = 'getFcmAppTokenFail',
  GET_FCM_WEB_TOKEN_FAIL = 'getFcmWebTokenFail',
  SEND_FCM_TOKEN_TO_SERVER_FAIL = 'sendFcmTokenToServerFail',
  RECEIVE_FCM_MESSAGING_FAIL = 'receiveFcmMessagingFail',
  MESSAGING_PAYLOAD_MODE_IS_NOT_MATCH = 'messagingModeIsNotMatch', // firebase messaging mode(max, min) is not match
  HANDLER_TYPE_IS_NOT_MATCH = 'handlerTypeIsNotMatch', // handler type(alarm, dlink, systemevent) is not match
  EXCEPTION_IN_FCM_ACTION = 'exceptionInFcmAction',
}

/**
 * Firebase app status
 * @description firebase 초기화 이후 서버에 저장 성공한 정보
 */
export interface FirebaseTokenStatus {
  fcmDevice: FirebaseDevice
  fcmAppToken: string
  fcmWebToken: string
}

/**
 * Firebase message payload handler
 * @description 사전 정의된 메세지 타입 헨들러
 * @enum {string}
 * @readonly
 */
export enum FcmHandler {
  DEEP_LINK = 'dlink', // 리다이렉트용
  ALARM = 'alarm', // Notification 노출
  SYSTEM_EVENT = 'systemevent', // 모델 업데이트등
}

/**
 * Firebase message action handler
 * @description Firebase 메시지로부터 받은 다양한 종류의 액션에 대한 핸들러
 */
export enum FcmActionHandler {
  // DEEP_LINK
  DIRECT_ROUTE = 'directRoute',
  LOGIN_DIRECT_ROUTE = 'loginDirectRoute',
  URL_BROWSE = 'urlBrowse',
  LOGIN_URL_BROWSE = 'loginUrlBrowse',

  // SYSTEM_EVENT
  LIVE_UPDATE = 'liveUpdate',
  CHAT_UPDATE = 'chatUpdate',
}

/**
 * Firebase message url handler
 */
export enum FcmUrlHandler {
  USER_HOME = 'userhome', // ['/userhome?userSn={userSn}#reward] 크리에이터 혹은 일반 사용자 홈 랜딩 헨들러(사용자 특정을 위한 사전 작업 필요 헨들러)
  USER_HOME_DAO = 'dao', // ['/dao?userSn={userSn}] 크리에이터 DAO 홈 랜딩 헨들러(사용자 특정을 위한 사전 작업 필요 헨들러)
  CHAT = 'chat', // ['/chat?channelUrl={channelUrl}'] 채팅 랜딩 헨들러(외부 브라우저로 링크)
  LIVE = 'live', // ['/live?liveEventId={liveEventId}'] 라이브 랜딩 헨들러(외부 브라우저 링크)
  LIVE_UPDATE = 'live-update', // ['/live-update?liveEventId={liveEventId}&status=ongoing'] 라이브 데이터 업데이트
  CHAT_UPDATE = 'chat-update', // ['/chat-update?channelUrl={channelUrl}&status=created'] 챗 데이터 업데이트

  // 그외 url 은 헨들러 관리 하지 않고 이동
}

/**
 * Firebase message payload data
 * @description firebase message 수신 받는 데이터
 */
export interface FcmPayloadData {
  [Key: string]: any
  type: FcmHandler
  action: FcmActionHandler
  url: string
}

/**
 * Firebase message payload
 * @description firebase message payload type override
 */
export interface FcmPayload extends MessagePayload {
  data: FcmPayloadData
}

/**
 * 미등록 사용자를 위한 마케팅 푸시 동의
 * 동의에는 상태와 동의 날짜가 포함
 *
 * @interface UnSigninMarketingPushAgree
 */
export interface UnSigninMarketingPushAgree {
  marketingPushAgreeAt: BooleanYn
  marketingPushAgreeDt: Date
  fcmDevice: FirebaseDevice
}
