<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { NovaBoxDaoHomeActionProps } from './NovaBoxDaoHomeAction.types'
defineProps<NovaBoxDaoHomeActionProps>()

const { getPrivateKey } = useMembershipProcess()

const tippyRef = ref<typeof Tippy | null>(null)

const { show: modalShow } = useModal()
const { t } = useI18n()
const { gtEvent } = useGoogleTag()

const userStore = useUserStore()
const daoStore = useDaoStore()
const creatorStore = useCreatorStore()

const onShowMobileAction = () => {}

const openDaoInfo = async () => {
  const privateKey = await getPrivateKey(userStore.user!.userSn)
  await daoStore.fetchDaoStatus()
  await modalShow(modalsName.MODAL_DAO_INFO, {
    source: {
      daoNm: daoStore.daoInfo?.daoNm,
      daoDc: daoStore.daoInfo?.daoDc,
      daoMbrSn: daoStore.daoInfo?.daoMbrSn,
      daoSn: creatorStore.profile?.creatorDaoSn,
      daoImg: daoStore.daoInfo?.daoImgUrl,
    },
    isDisbandable: daoStore.daoInfo?.isDisbandable,
    privateKey,
  })
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: 'DAO 홈 > DAO 정보 버튼 클릭',
    eventLabel: 'settings',
    eventSlot: 'DAO 정보 모달 열기',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
}
const signOut = async () => {
  if (tippyRef.value) {
    await new Promise((resolve) => {
      tippyRef.value?.hide()
      setTimeout(resolve, 300)
    })
  }
  userStore.signOut()
}
</script>

<template>
  <Tippy
    ref="tippyRef"
    :append-to="'parent'"
    :interactive="true"
    :placement="'bottom-end'"
    :theme="'popover'"
    :trigger="'click'"
    @click.stop="onShowMobileAction"
  >
    <NovaButtonIcon :icon="{ type: 'outline', name: 'settings' }" :size="22" />
    <template #content>
      <div v-if="isEqualUser" class="header-floating-button-box">
        <div class="profile-panal-opend">
          <button class="profile-buttton" type="button" @click="openDaoInfo">
            <span class="label">{{
              t('dao.dissolution.dissolutionModel.title')
            }}</span>
            <NovaIcon
              :icon="{ type: 'outline', name: 'chev-compact-right' }"
              class="chev"
            />
          </button>
          <button class="profile-buttton" type="button" @click="signOut()">
            <span class="label">{{ t('sign.out') }}</span>
            <NovaIcon
              :icon="{ type: 'outline', name: 'chev-compact-right' }"
              class="chev"
            />
          </button>
        </div>
      </div>
      <div v-else class="header-floating-button-box">
        <div class="profile-panal-opend">
          <button class="profile-buttton" type="button" @click="openDaoInfo">
            <span class="label">{{
              t('dao.dissolution.dissolutionModel.title')
            }}</span>
            <NovaIcon
              :icon="{ type: 'outline', name: 'chev-compact-right' }"
              class="chev"
            />
          </button>
        </div>
      </div>
    </template>
  </Tippy>
</template>
<style lang="scss" scoped>
:deep(.btn-icon) {
  .icon-wrap {
    .icon {
      color: $color-text-12;
    }
  }
}
.header-floating-button-box {
  position: relative;
  .profile-panal-opend {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 200px !important;
    gap: 10px;
    padding: 10px 18px 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px #00000014;
    background-color: $color-bg-3;
    .profile-buttton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      gap: 20px;
      height: 30px;
      .label {
        @include text-style($text-body-14-medium);
        color: $color-text-12;
      }
      .chev {
        flex-shrink: 0;
        color: $color-text-3;
      }
    }
  }
}
</style>
