import type { DaoMemberFilter, DaoPostsOrderBy } from '@store/dao/type'
import { commonError } from '@locales/ko/commonError'
import type { DaoCreatorsFilter } from '@store'
/**
 * DAO
 */
export const dao = {
  opening: {
    dialog: {
      title: 'หมายเหตุ',
      creatorMessage:
        'แอปพลิเคชัน FAM สามารถทำได้โดยศิลปินเท่านั้น กรุณาสมัครศิลปินและใช้มัน',
      followMessage:
        'เฉพาะศิลปินที่มีผู้ติดตามมากกว่า 1,000 คนเท่านั้นที่สามารถสมัคร FAM ได้',
      dissolutionMessage:
        '<strong>คุณสามารถสมัครได้หลังจาก 30 วันตามวันที่ยุบ FAM</strong>',
      cancelMessage: 'คุณจะยกเลิกแอปพลิเคชัน FAM หรือไม่',
      completeMessage: 'แอปพลิเคชัน FAM ของคุณเสร็จสมบูรณ์แล้ว โปรดรอฟังผล',
      btnBox: {
        checkBtn: 'OK',
        cancelBtn: 'Cancel',
        applyBtn: 'สมัครผู้สร้าง',
      },
    },
  },
  entry: {
    possible: 'สร้าง FAM',
  },
  form: {
    title: 'สร้าง FAM',
    name: {
      title: 'กรุณาเขียนชื่อ FAM',
      placeHolder: 'โปรดป้อนเป็นภาษาอังกฤษ',
      button: 'เช็คซ้ำ',
      message: {
        possible: 'พร้อมใช้งาน',
        impossible: 'มีอยู่แล้ว',
      },
    },
    desc: {
      title: 'กรุณาเขียนสั้น ๆ เกี่ยวกับ FAM',
      placeHolder: 'กรุณาเขียนสั้น ๆ เกี่ยวกับ FAM',
    },
    nft: {
      title: 'โปรดลงทะเบียนภาพที่จะใช้เป็น NFT สมาชิก',
      placeHolder: 'โปรดอัปโหลดรูปภาพ',
      button: {
        file: 'ค้นหาแฟ้ม',
        add: 'สิ่งที่แนบมาด้วย',
        delete: 'ลบ',
      },
      message: {
        fileError: 'ยังไม่ได้อัปโหลดไฟล์',
        volume: 'โปรดอัปโหลดไฟล์ขนาดไม่เกิน 15MB',
        size: 'ความกว้างและความยาวของภาพต้องมีอย่างน้อย 500px',
        guide: 'ความกว้างและความยาวของภาพต้องเท่ากัน',
        imgError: 'แฟ้มภาพไม่ถูกต้อง',
        imgSuccess: 'ภาพของคุณถูกอัปโหลดแล้ว',
      },
      description: [
        'ความจุและรูปแบบของภาพ: แฟ้มภาพประเภท JPG, PNG มากกว่า 15 MB',
        'ขนาดภาพ: มากกว่า 500*500px',
      ],
    },
    caution: {
      title: 'ข้อควรระวัง',
      description: [
        '- ข้อกำหนดสำหรับการสมัคร: ผู้ติดตามมากกว่า 1,000 คน',
        '- มันยากที่จะเปลี่ยนข้อมูลของ FAM ดังนั้นโปรดเขียนมันอย่างระมัดระวัง',
        '- โปรดตรวจสอบคู่มือรูปภาพ NFT และอัปโหลดไฟล์ที่ตรงกับแบบฟอร์ม',
        '- การอนุมัติใบสมัครจะเสร็จสิ้นภายในสามถึงเจ็ดวันทำการนับจากวันที่สมัคร',
      ],
      label: {
        duplication: 'เช็คซ้ำ',
      },
      message: {
        guide: 'โปรดตรวจสอบความระมัดระวัง',
      },
      check: 'ตรวจสอบความระมัดระวัง',
    },
    dialog: {
      title: 'การแจ้งเตือน',
      content: 'แอปพลิเคชัน FAM ของคุณเสร็จสมบูรณ์แล้ว',
      btnBox: {
        checkBtn: 'OK',
      },
    },
    message: {
      available: 'พร้อมใช้งาน',
      notAvailable: 'มีอยู่แล้ว',
      notWritten: 'คุณยังไม่ได้สร้างมันขึ้นมา',
      overSize: 'สามารถแนบรูปภาพได้สูงสุด 15MB',
      caution: 'โปรดตรวจสอบความระมัดระวัง',
      check: 'คุณต้องตรวจสอบซ้ำอีกครั้ง',
    },
    errorMessage: {
      1999: 'เป็นไปไม่ได้เพราะผู้ใช้ถูกจำกัดการใช้งาน',
      2006: commonError.network.message, // 필수값이 누락되었습니다.,
      2024: 'เกิดข้อผิดพลาดในการสร้างกระเป๋าคุมข้อมูล',
      2033: commonError.network.message, // 파라미터 패턴 오류
      2038: 'เกินขีดความสามารถ',
      2043: 'แอปพลิเคชัน FAM เป็นไปไม่ได้',
      2901: 'โปรดตรวจสอบรูปแบบรูปภาพอีกครั้ง',
      2902: 'โปรดตรวจสอบขนาดภาพอีกครั้ง',
      default: commonError.network.message,
    },
  },
  approve: {
    dialog: {
      title: 'หมายเหตุ',
      companionMessage:
        'แอปพลิเคชัน FAM ของคุณถูกปฏิเสธ กรุณาสมัครอีกครั้งหลังจากยืนยันเหตุผลแล้ว',
      companionReasonTitle: 'เหตุผลของการปฏิเสธ FAM:',
      approvalMessage:
        'การมีส่วนร่วมของ FAM เสร็จสมบูรณ์แล้ว เปลี่ยนเป็น FAM ในบ้านฉันเดี๋ยวนี้',
      btnBox: {
        checkBtn: 'OK',
      },
    },
  },
  accession: {
    btn: {
      apply: 'เข้าร่วม',
      pending: 'การทบทวน',
    },
    applyModel: {
      title: '[{daoNm}] โปรแกรมที่จะเข้าร่วมกับ FAM',
      message: 'เขียนเหตุผลของการสมัคร',
      placeholder: 'เขียนเหตุผลของการสมัคร',
      errorMessage: 'คุณยังไม่ได้สร้างมันขึ้นมา',
      btnBox: {
        applyBtn: 'สร้าง',
        participating: 'เข้าร่วม',
        pending: 'การทบทวน',
      },
    },
    dialog: {
      title: 'หมายเหตุ',
      companionMessage:
        'แอปพลิเคชัน FAM ของคุณถูกปฏิเสธ กรุณาสมัครอีกครั้งหลังจากยืนยันเหตุผลแล้ว',
      companionReasonTitle: 'เหตุผลของการปฏิเสธ FAM:',
      approvalMessage:
        'การมีส่วนร่วมของ FAM เสร็จสมบูรณ์แล้ว เปลี่ยนเป็น FAM ในบ้านฉันเดี๋ยวนี้',
      applyMessage: '[{daoNm}] FAM join เสร็จสมบูรณ์แล้ว',
      beforeMessage: 'คคุณไม่มีสิทธิ์ออกเสียง กรุณาเข้าร่วมกับ FAM และใช้มัน',
      btnBox: {
        checkBtn: 'OK',
      },
    },
  },
  vote: {
    message: {
      empty: 'ไม่มีวาระการลงคะแนนเสียง',
    },
    create: {
      header: 'สร้างการโหวต',
      conditions: {
        title: 'เงื่อนไขการมีส่วนร่วม.',
        errMsg: "โปรดเลือก 'NFT'",
        placeHolder: 'เลือก NFT',
      },
      name: {
        title: 'ชื่อเรื่อง',
        placeHolder: 'โปรดป้อนชื่อผู้ลงคะแนน',
        errMsg: 'คุณยังไม่ได้สร้างมันขึ้นมา',
      },
      contents: {
        title: 'ตัวเลือกต่าง ๆ',
        add: 'เพิ่ม',
        placeHolder: 'ตัวเลือกต่าง ๆ {index}',
        over: 'จำนวนรายการลงคะแนนสูงสุดคือ 10 รายการ',
        error: {
          select: 'จำเป็นต้องมีอย่างน้อยสองรายการ',
          value: 'มีรายการที่คุณยังไม่ได้ป้อน',
        },
      },
      period: {
        title: 'ระยะเวลา',
        start: 'วันที่เริ่ม',
        errStartMsg: 'โปรดเลือกวันที่เริ่มก่อน',
        end: 'วันสิ้นสุด',
        errMsg: 'คุณยังไม่ได้เลือกช่วงการลงคะแนนเสียง',
      },
      button: {
        create: 'สร้าง',
      },
    },
    modify: {
      header: 'แก้ไขการโหวต',
      button: {
        delete: 'ลบ',
        modify: 'แก้ไข',
      },
    },
    list: {
      badge: 'การมีส่วนร่วมของ',
      message: 'มันเป็นการโหวตอย่างต่อเนื่อง',
    },
    createButton: 'สร้าง',
    sort: {
      all: 'ทั้งหมด',
      inProgress: 'กำลังดำเนินการอยู่',
      expected: 'คัมมิ่งซูน',
      end: 'ความสมบูรณ์',
    },
    dialog: {
      title: 'หมายเหตุ',
      deleteMessage: 'คุณต้องการลบการลงคะแนนเสียงหรือไม่?',
      modifyMessage: 'คุณต้องการแก้ไขการลงคะแนนเสียงหรือไม่?',
      actions: {
        negative: 'Cancel',
        positive: 'OK',
      },
    },
    detail: {
      badge: {
        noVote: 'การไม่เข้าร่วม',
      },
      count: 'เสียง',
      button: {
        votesAvailable: 'การลงคะแนน',
        votesUnavailable: 'คุณไม่มีสิทธิ์ลงคะแนนเสียง',
        completeVote: 'การลงคะแนนเสียงเสร็จสิ้นแล้ว',
      },
    },
    success: 'การโหวตเสร็จสิ้นแล้ว',
    errorMessage: {
      1999: 'เป็นไปไม่ได้เพราะผู้ใช้ถูกจำกัดการใช้งาน',
      2006: commonError.network.message, // '필수값이 누락되었습니다.',
      2039: 'คุณสามารถลงทะเบียนได้ถึง 2 ถึง 10 คำถามในการลงคะแนนเสียง',
      2040: 'ป้อนระยะเวลาการลงคะแนน FAM ไม่ถูกต้อง กรุณาป้อนใหม่',
      2041: 'ไม่สามารถแก้ไขการลงคะแนนเสียงได้',
      2042: 'คุณโหวตไม่ได้',
      2049: commonError.network.message, // 'NFT 스냅샷 자산 조회 에러',
      2050: commonError.network.message, // '스냅샷 상태 조회 에러',
      2990: 'กำลังดำเนินการร้องขอ โปรดลองอีกครั้งในอีกสักครู่',
      5400: 'คุณไม่ได้รับอนุญาต',
      default: commonError.network.message,
    },
    // } as { [Property in CommentsAndRepliesOrderBy]: string },
  },
  board: {
    title: 'กระดาน',
    createButton: 'โพสต์',
    list: {
      title: 'ประกาศ',
      message: 'กรุณาตรวจสอบโพสต์หลังจากลงทะเบียนใน FAM',
    },
    create: {
      header: 'การโพสต์',
      dropdown: {
        tempSave: 'โหลดร่าง',
        readPermission: {
          placeHolder: 'เลือกสิทธิ์ในการดู',
          follower: 'I LIKE LM ผู้ใช้',
          onlyMember: 'สมาชิก FAM เท่านั้น',
        },
      },
      title: {
        placeHolder: 'กรุณากรอกชื่อเรื่อง',
      },
      option: {
        cmments: 'อนุญาตให้แสดงความคิดเห็น',
        notice: 'การลงทะเบียนประกาศ',
      },
      contents: {
        placeHolder: 'เลือกดูสิทธิ์ที่อนุญาตก่อน',
      },
    },
    sorter: {
      commentAndReply: {
        newest: 'เรียงตามล่าสุด',
        oldest: 'เรียงตามเก่า',
      } as { [Property in DaoPostsOrderBy]: string },
    },
    errorMessage: {
      1999: 'เป็นไปไม่ได้เพราะผู้ใช้ถูกจำกัดการใช้งาน',
      2000: 'ไม่พบข่าวนี้',
      2006: commonError.network.message, // '필수값이 누락되었습니다.',
      2009: commonError.network.message, // '지정되지 않은 타입 코드 입니다.',
      2010: 'คุณถูกทำซ้ำ',
      2013: 'ณไม่สามารถลบโพสต์ที่เขียนโดยผู้ใช้รายอื่นได้',
      2015: 'เกินจำนวนแฮชแท็กที่อนุญาตแล้ว โปรดลองอีกครั้ง',
      2018: 'คุณเกินจำนวนอักขระแฮชแท็กที่อนุญาตแล้ว โปรดลองอีกครั้ง',
      2041: 'ไม่สามารถแก้ไขการลงคะแนนเสียงได้',
      2990: 'กำลังดำเนินการร้องขอ โปรดลองอีกครั้งในอีกสักครู่',
      4002: 'คุณเป็นสมาชิกที่ไม่ได้ลงทะเบียน',
      5400: 'คุณไม่ได้รับอนุญาต',
      5401: 'คุณไม่ได้รับอนุญาต',
      8050: 'เธอไม่ชอบตัวเองหรอก', // 활동 대상 오류 - 본인에게 활동 한 경우
      default: commonError.network.message,
    },
  },
  member: {
    approval: {
      pending: 'คำร้อง',
      approval: 'อนุมัติ',
      refusal: 'ปฏิเสธ',
    },
    memberList: {
      empty: 'ไม่มีข้อมูลรายการ',
      error: 'ไม่สามารถรับรายการนั้นได้ โปรดลองใหม่อีกครั้งในตอนนี้',
    },
    management: {
      member: 'สมาชิก',
      dialog: {
        title: 'หมายเหตุ',
        approveMessage: '<strong>คุณต้องการอนุมัติหรือไม่?</strong>',
        refuseMessage: 'คุณจะปฏิเสธเหรอ?',
        refusePlaceholder: 'ป้อนเหตุผลของการปฏิเสธ',
        deportMessage: 'คุณต้องการที่จะขับไล่ผู้ใช้รายนี้หรือไม่?',
        deportPlaceholder: 'โปรดป้อนเหตุผลสำหรับการขับไล่',
        appointMessage:
          'คุณต้องการแต่งตั้ง ่<strong>[{userName}]</strong> เป็นผู้จัดการหรือไม',
        appointErrorMessage: 'ความจุของผู้จัดการสูงถึงห้าคน',
        dismissMessage: 'คุณแน่ใจหรือว่าต้องการไล่ผู้จัดการออก',
        leaveMessage:
          'คุณไม่ได้ทำงานใน FAM อีกต่อไป? <strong>NFT สมาชิกที่คุณเป็นเจ้าของจะถูกลบออก</strong> FAM ที่ถอนตัวสามารถเข้าร่วมได้อีกครั้งหลังจาก 30 วัน ณ วันที่ถอนตัว',
        leaveSuccess: 'การถอนเงินของคุณได้รับการดำเนินการแล้ว',
        disbandSuccess: 'FAM ถูกยุบ.',
        actions: {
          negative: 'Cancel',
          positive: 'OK',
        },
        successMessage: {
          approval: 'มันได้รับการอนุมัติแล้ว',
          refuse: 'การอนุมัติถูกปฏิเสธ',
          accession: 'ขณะนี้คุณเป็นสมาชิกของ FAM',
          appoint: 'คุณได้รับการแต่งตั้งเป็นผู้จัดการ FAM',
          dismiss: 'คุณได้ปิดผู้จัดการ FAM แล้ว',
          deport: 'สมาชิกถูกไล่ออก',
        },
        errorMessage: {
          1999: 'เป็นไปไม่ได้เพราะผู้ใช้ถูกจำกัดการใช้งาน',
          2006: commonError.network.message, // 필수값이 누락되었습니다.,
          2010: 'คุณถูกทำซ้ำ',
          2011: 'ไม่พบข้อมูลผู้ใช้',
          2044: 'การยุบ FAM เป็นไปไม่ได้',
          2990: 'กำลังดำเนินการร้องขอ โปรดลองอีกครั้งในอีกสักครู่',
          5400: commonError.network.message, // '개설자는 탈퇴 불가능 합니다.',
          default: commonError.network.message,
        },
      },
      btnBox: {
        deportBtn: 'การขับไล่.',
        appointBtn: 'แต่งตั้ง',
        dismissBtn: 'เลิกใช้',
      },
    },
    errorMessage: {
      1999: 'เป็นไปไม่ได้เพราะผู้ใช้ถูกจำกัดการใช้งาน',
      2006: commonError.network.message, // 필수값이 누락되었습니다.,
      2009: commonError.network.message, // 지정되지 않은 타입 코드 입니다.
      2010: 'คุณถูกทำซ้ำ',
      2011: 'ไม่มีสมาชิกอยู่',
      2024: 'เกิดข้อผิดพลาดในการสร้างกระเป๋าคุมข้อมูล',
      2041: 'ไม่สามารถแก้ไขการลงคะแนนเสียงได้',
      2046: 'คุณได้เข้าร่วมกับ FAM แล้ว',
      2048: 'คุณไม่สามารถลงชื่อด้วยการปฏิเสธ (7 วัน) หรือการถอน (30 วัน) ได้',
      2051: 'คุณถูกเนรเทศจาก FAM และไม่สามารถเข้าร่วมอย่างถาวร',
      2990: 'กำลังดำเนินการร้องขอ โปรดลองอีกครั้งในอีกสักครู่',
      5400: 'คุณไม่ได้รับอนุญาต',
      5402: 'เกินจำนวนผู้จัดการสูงสุดแล้ว',
      default: commonError.network.message,
    },
  },
  dissolution: {
    dissolutionModel: {
      title: 'ข้อมูลรายละเอียดของ FAM',
      name: 'ชื่อ FAM',
      introduction: 'บทนำ',
      membershipNFT: 'รูปภาพ NFT ของสมาชิก',
      dissolutionBox: {
        title: 'การยุบ',
        leave: 'การจากไป',
        message:
          'การยื่นคำร้องขอยุบ FAM จะทำได้ก็ต่อเมื่อสมาชิกคนสุดท้ายออกไปและไม่มีผู้รอเข้าร่วม หากมีคนรอเข้าร่วม จะมีการอนุมัติ โปรดปฏิเสธ',
        btn: 'คำร้อ',
      },
    },
    dialog: {
      title: 'หมายเหตุ',
      message: 'คุณแน่ใจนะว่าคุณไม่ได้เป็นเจ้าหน้าที่ FAM?',
      actions: {
        negative: 'Cancel',
        positive: 'OK',
      },
    },
  },
  filter: {
    member: {
      apply: 'เข้าร่วม',
      management: 'จัดการ',
    } as { [Property in DaoMemberFilter]: string },
    board: {
      all: 'ALL',
      notice: 'หมายเหตุ',
    },
    creator: {
      all: 'ALL',
      dao: 'FAM',
    } as { [Property in DaoCreatorsFilter]: string },
  },
  leave: {
    model: {
      title: 'การถอน',
      message:
        'ขณะนี้คุณเป็นสมาชิกของ FAM เมื่อถอนแล้ว NFT สมาชิก FAM ที่คุณเป็นเจ้าของจะถูกลบ และ FAM จะได้รับการประมวลผลด้วย',
      importantMessage: 'คุณแน่ใจหรือว่าต้องการดำเนินการถอนสมาชิก?',
      form: {
        title: 'รหัสผ่าน',
        placeHolder: 'โปรดป้อนรหัสผ่านของคุณ',
      },
      actions: {
        negative: 'Cancel',
        positive: 'OK',
      },
    },
    dialog: {
      title: 'หมายเหตุ',
      message: 'คุณสามารถสมัครถอนได้หลังจากยุบ FAM ในฐานะหัวหน้า FAM',
      actions: {
        checkBtn: 'OK',
      },
    },
    successMessage: 'คุณถูกถอนตัวแล้ว',
  },
  createCard: {
    sttusCode: {
      pCode: 'การทบทวน',
      sCode: 'เข้าร่วม',
    },
  },
  index: {
    daoItem01: {
      textBox: {
        text01: 'Together We Create, Together We Thrive',
        text02: 'FAM',
      },
      btnBox: {
        listOfDAO: 'รายชื่อ FAM',
        create: 'สร้าง',
        reviewing: 'การทบทวน',
      },
    },
    daoItem02: {
      textBox: {
        title: '<span>What</span> is a FAM?',
        message:
          'FAM ประกอบไปด้วยแฟนคลับและศิลปิน ผ่านภารกิจนี้ คุณบรรลุเป้าหมายของคุณ มันเป็นชุมชนที่มีส่วนร่วมที่คุณจะได้รับรางวัล',
      },
      governanceBox: {
        title: '<span>FAM</span> Governance',
        nft: 'Digital Card',
        voting: 'Voting',
        community: 'Community',
      },
    },
    daoItem03: {
      title: '<span>HOW</span> to use FAM?',
      daoCreator: {
        title: 'Artist',
        creative: {
          title: 'Creative',
          message:
            'สื่อสารกับแฟน ๆ และสร้างเนื้อหาที่สร้างสรรค์โดยสะท้อนความคิดใหม่ ๆ',
        },
        interaction: {
          title: 'Interaction',
          message:
            'ผ่านการสื่อสารโดยตรงกับแฟน ๆ พวกเขาเติบโตด้วยกันและสร้างความผูกพันที่ลึกซึ้ง',
        },
      },
      daoMember: {
        title: 'Member',
        cooperation: {
          title: 'Voting & Proposing',
          message:
            'มีความกระตือรือร้นในชุมชนโดยการโหวตให้โปรเจกต์ศิลปินหรือเสนอไอเดีย',
        },
        communication: {
          title: 'Communication',
          message: 'ร่วมมือกับแฟนคลับคนอื่น ๆ เพื่อเสริมสร้างชุมชน FAM',
        },
      },
    },
    daoItem04: {
      title: '<span>FAM</span> เงื่อนไขเข้าร่วม',
      textBox: {
        creation: {
          title: 'การสร้าง FAM :',
          message: 'ศิลปินที่มีผู้ติดตามมากกว่า 1,000 คน.',
        },
        participation: {
          title: 'การมีส่วนร่วมของ FAM :',
          message: "สมาชิก 'I LIKE LM' และแฟน ๆ ของศิลปิน",
        },
      },
    },
  },
  home: {
    topNotice: 'ตรวจสอบ FAM.',
  },
}
