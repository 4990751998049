<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'
import type { Swiper as SwiperType } from 'swiper'
import type { NovaLayoutTopBannerProps } from './NovaLayoutTopBanner.types'

const props = withDefaults(defineProps<NovaLayoutTopBannerProps>(), {
  isLoading: false,
  isError: false,
})

const { t } = useI18n()

const swiper = ref<SwiperType | null>(null)
const currentSlide = ref(1)
const totalSlides = ref(props.banners.length)
const onSwiper = (swiperInstance: SwiperType) => {
  swiper.value = swiperInstance
  totalSlides.value = swiperInstance.slides.length
}
const onSlideChange = () => {
  if (swiper.value) {
    currentSlide.value = swiper.value.realIndex + 1
  }
}
const swiperOptions = computed(() => ({
  pagination: {
    clickable: true,
  },
  // watchOverflow: true,
  loop: props.banners.length > 1,
  loopAdditionalSlides: 1,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  navigation: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
  },
  modules: props.banners.length > 1 ? [Navigation, Pagination, Autoplay] : [],
}))
onMounted(() => {
  if (swiper.value) {
    totalSlides.value = swiper.value.slides.length
  }
})
</script>
<template>
  <ClientOnly>
    <div class="top-banner-wrap">
      <div class="top-banner-box">
        <div class="top-banner">
          <Swiper
            v-if="!isLoading && !isError"
            v-bind="swiperOptions"
            class="swiper-top-banner"
            @swiper="onSwiper"
            @slide-change="onSlideChange"
          >
            <SwiperSlide v-for="banner in banners" :key="banner.bannerSn">
              <NovaBoxTopBanner :source="banner" :ga-slot="gaSlot" />
            </SwiperSlide>
          </Swiper>
          <div class="swiper-pagination"></div>
          <div class="swiper-pagination-mob">
            {{ currentSlide }} / {{ totalSlides }}
          </div>
          <NovaLoadingIndicator
            v-if="isLoading"
            :fill="true"
            :bg-bright="'light'"
            class="loading-indicator"
          />
          <NovaBoxEmptyMessage
            v-if="isError"
            :message="t('home.topBannerError')"
          />
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.top-banner-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  width: 100%;
  max-width: 1232px;
  margin: 0 auto;
  .top-banner-box {
    width: 100%;
    :deep(.swiper) {
      padding: 0 0 40px 0;
      margin: 0 16px;
      border-radius: 20px;
      @include tablet {
        padding: 0 0 20px 0;
        border-radius: 16px;
        overflow: hidden;
      }
    }

    .swiper-slide {
      border-radius: 20px;
      overflow: hidden;
      @include tablet {
        border-radius: 16px;
      }
    }
    :deep(.swiper-button-prev) {
      left: 5px;
      cursor: pointer;
      z-index: 10;
      transform: translateY(calc(-50% - 20px));
      &:after {
        width: 32px;
        height: 32px;
        color: $color-white;
        text-shadow: none;
        border-radius: 50%;
      }
      &:hover:after {
        transition: all 0.2s ease-in-out;
        background-color: $color-black-50p;
      }
      @include tablet {
        display: none;
      }
    }

    :deep(.swiper-button-next) {
      right: 5px;
      cursor: pointer;
      z-index: 10;
      transform: translateY(calc(-50% - 20px));
      &:after {
        width: 32px;
        height: 32px;
        color: $color-white;
        text-shadow: none;

        border-radius: 50%;
      }
      &:hover:after {
        transition: all 0.2s ease-in-out;
        background-color: $color-black-50p;
      }
      @include tablet {
        display: none;
      }
    }

    .top-banner {
      position: relative;
      width: 100%;
      background-color: $color-bg-3;
      font-size: 0;
      z-index: 0;
      overflow: hidden;
      .swiper-pagination-mob {
        display: none;
        position: absolute;
        width: 50px;
        bottom: 30px;
        right: 26px;
        padding: 2px 6px;
        font-size: 14px;
        color: $color-bg-3;
        text-align: center;
        border-radius: 12px;
        background-color: $color-black-30p;
        z-index: 30;

        @include tablet {
          display: block;
        }
      }
      :deep(.swiper-pagination) {
        font-size: 14px;
        width: 100%;
        bottom: 14px;
        @include tablet {
          display: none;
        }
        .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: $color-bg-custom-11;
          &.swiper-pagination-bullet-active {
            background-color: $color-primary-blue-dark;
          }
        }
      }
      .loading-indicator {
        height: 220px;
      }
    }
  }
}
</style>
