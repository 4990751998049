<script setup lang="ts">
import type { MenuGroupProps } from '@components/NovaHeaderMenuGroup/NovaHeaderMenuGroup.types'

const props = withDefaults(defineProps<MenuGroupProps>(), {
  groupName: '',
})

const hasLink = computed(() => typeof props.groupLink !== 'undefined')

const handleOnGoGroupLink = () => {
  if (!hasLink.value) return
  props.groupLink?.()
}
</script>

<template>
  <div class="menu-group">
    <h4
      v-show="groupName"
      :class="['group-name', { 'has-link': hasLink }]"
      @click.stop="handleOnGoGroupLink"
    >
      {{ groupName }}

      <span class="extra">
        <slot name="extra" />

        <NovaIcon
          v-if="hasLink"
          :icon="{ type: 'outline', name: 'chev-thin-right' }"
        />
      </span>
    </h4>

    <div class="menus">
      <slot name="menus" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-group {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .group-name {
    @include text-style($text-body-14-bold);
    color: $color-text-2;
    padding: 4px 0;

    &.has-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    .extra {
      display: inline-flex;
      align-items: center;
      gap: 6px;
    }
  }

  .menus {
    display: flex;
    flex-direction: column;
  }
}
</style>
