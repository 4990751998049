<script setup lang="ts">
import type { NovaTextareaEmits, NovaTextareaProps } from './NovaTextarea.types'

const emit = defineEmits<NovaTextareaEmits>()
const props = withDefaults(defineProps<NovaTextareaProps>(), {
  id: '',
  name: '',
  rows: 8,
  maxByte: -1,
  minLengthMet: -1,
  maxLength: -1,
  placeholder: '',
  label: '',
  disabled: false,
  errorMsg: '',
  theme: 'default',
})

const countByte = computed(() => useCalcByte(String(props.modelValue)))
const countLength = computed(() => String(props.modelValue).length)
const isMinLengthMet = computed(
  () => props.minLengthMet > 0 && props.modelValue.length >= props.minLengthMet
)
const themeClass = computed(() => `theme-${props.theme}`)

const handleOnUpdate = (e: any) => {
  if (
    props.maxByte !== -1 &&
    countByte.value >= props.maxByte &&
    e.target.value > props.modelValue
  ) {
    e.target.value = props.modelValue
    return
  }
  emit('update:modelValue', e.target.value)
  emit('on-change')
}
</script>

<template>
  <div class="textarea-wrap">
    <label v-if="label" :for="id">{{ label }}</label>
    <div :class="['textarea', themeClass]">
      <textarea
        :id="id"
        :value="modelValue"
        :placeholder="placeholder"
        :name="name"
        :rows="rows"
        :disabled="disabled"
        :maxlength="maxLength === -1 ? 99999 : maxLength"
        @input="handleOnUpdate"
      />
    </div>

    <div v-if="maxByte > 0" :class="['text-option']">
      <div class="option-unit">
        {{ useFormatThousandsSeparators(countByte, 0) }}/{{
          useFormatThousandsSeparators(maxByte, 0)
        }}
      </div>
    </div>

    <div
      v-if="maxLength > 0"
      :class="['text-option', { 'on-error': errorMsg }]"
    >
      <p v-if="errorMsg" class="error-message">{{ errorMsg }}</p>
      <div class="option-unit">
        <div
          v-if="minLengthMet > 0"
          :class="['circle-checkbox', { highlight: isMinLengthMet }]"
        >
          <NovaIcon :icon="{ type: 'outline', name: 'check' }" :size="14" />
        </div>
        {{ useFormatThousandsSeparators(countLength, 0) }}/{{
          useFormatThousandsSeparators(maxLength, 0)
        }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.textarea-wrap {
  position: relative;
  display: flex;
  flex-direction: column;

  .textarea {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid $color-bg-custom-3;
    border-radius: 8px;
    overflow: auto;
    background-color: $color-white;

    textarea {
      width: 100%;
      @include text-style($text-body-14-regular);
      background-color: $color-white;
      resize: none;
      height: 100%;

      &::placeholder {
        color: $color-text-3;
      }
    }
    &.theme-search {
      background-color: $color-bg-1;
      border: 0;
      textarea {
        background-color: $color-bg-1;
        &::placeholder {
          color: $color-text-3;
        }
      }
    }

    &.theme-bgwhite {
      background-color: $color-white;
    }

    &.theme-destroy {
      border-color: #727272;
    }
    &.theme-bggray {
      background-color: $color-gray-100;
      border-color: $color-gray-100;
      textarea {
        background-color: $color-gray-100;
        &::placeholder {
          color: $color-text-12;
        }
      }
    }
  }

  .text-option {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 6px;

    &.on-error {
      justify-content: space-between;
    }

    .error-message {
      display: flex;
      align-items: center;
      gap: 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.55px;
      color: $color-bg-custom-12;

      &:before {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $color-bg-custom-12;
        content: '!';
        line-height: 1;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
      }
    }

    .option-unit {
      display: flex;
      align-items: center;
      gap: 4px;
      @include text-style($text-body-12-regular);
      color: $color-text-3;
      line-height: 1;
    }
  }

  .circle-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    border: 2px solid $color-text-3;

    &.highlight {
      color: $color-primary-blue;
      border-color: $color-primary-blue;
    }

    :deep(.icon) {
      margin-left: -1px;
    }
  }
}
</style>
