import type { DaoState } from './type'
export const initDaoState: DaoState = {
  daoOpeningAvailable: null,
  daoJoinStatus: {
    daoSbscrbAt: null,
    resultCn: null,
    sbscrbSttusCode: null,
    loading: true,
  },
  daoInfo: null,
  daoStatus: null,
  daoBoardActiveFilter: null,
  daoPost: null,
  daoCommentsAndReplies: {
    items: [],
    payload: {
      daoNttSn: -1,
      pageNum: 0,
      pageSize: 10,
      orderBy: 'newest',
      lastDaoNttSn: 0,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  daoPostsList: {
    items: [],
    payload: {
      fndrUserSn: 0,
      lastDaoNttSn: 0,
      nttNoticeAt: 'N',
      orderBy: 'newest',
      pageSize: 10,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  daoNoticeList: {
    items: [],
    payload: {
      fndrUserSn: 0,
      lastDaoNttSn: 0,
      nttNoticeAt: 'Y',
      orderBy: 'newest',
      pageSize: 10,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  daoNoticeListSimple: {
    items: [],
    payload: {
      fndrUserSn: 0,
      nttNoticeAt: 'Y',
      orderBy: 'newest',
      pageSize: 3,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  daoTempPostsList: {
    items: [],
    payload: {
      fndrUserSn: 0,
      pageNum: 0,
      pageSize: 10,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  daoMemberJoinRequestList: {
    count: 0,
    items: [],
    payload: {
      fndrUserSn: 0,
      pageNum: 0,
      pageSize: 10,
      sbscrbSttusCode: 'P',
    },
    refresh: false,
    last: false,
  },
  daoMemberManagementList: {
    count: 0,
    items: [],
    payload: {
      fndrUserSn: 0,
      pageNum: 0,
      pageSize: 10,
    },
    refresh: false,
    last: false,
    loading: false,
  },
  daoVote: null,
  daoVoteList: {
    creatorDaoMemberAuthorityStatusDto: null,
    items: [],
    payload: {
      daoSn: 0,
      daoVoteSttusCode: 'ALL',
      pageNum: 0,
      pageSize: 10,
      lastDaoVoteSn: 0,
    },
    refresh: false,
    last: false,
  },
  daoVoteEligibility: null,
}
