<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { BlockDialog } from './NovaModalMyBlock.types'

defineOptions({
  inheritAttrs: false,
})

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const show = ref(false)
const { hide: modalHide } = useModal()
const blockDialog = ref<BlockDialog>()

const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '차단 해제 확인 창 > 닫기',
    eventLabel: t('blockMessage.actions.cancel'),
    eventSlot: 'Modal',
    eventI18nAddr: useKoreanTranslation('blockMessage.actions.cancel'),
    eventComponent: 'Button',
  })
  await modalHide(modalsName.MODAL_MY_BLOCK)
}

const handleOnGetParams = (evt: RouteParams) => {
  blockDialog.value = (
    evt.value as unknown as { messageWrap: BlockDialog }
  ).messageWrap
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_MY_BLOCK"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="title">
            <span v-dompurify-html="blockDialog?.title || ''" />
          </div>
          <NovaButtonIcon
            :icon="{ type: 'outline', name: 'close-extend' }"
            :theme="'transparent'"
            :size="24"
            class="btn-close"
            @click="handleOnClose"
          />
        </div>
        <div class="modal-body">
          <div
            v-dompurify-html="blockDialog?.contents.title || ''"
            class="title"
          />
          <div
            v-dompurify-html="blockDialog?.contents.message || ''"
            class="message"
          />
        </div>

        <div class="modal-actions">
          <NovaButtonText
            :label="$t('blockMessage.actions.cancel')"
            :theme="'secondary-gray'"
            :size="40"
            :full-width="false"
            :width="198"
            @click="handleOnClose"
          />
          <NovaButtonText
            :label="blockDialog?.action.label"
            :theme="'primary-blue-dark'"
            :size="40"
            :width="198"
            :full-width="false"
            @click="blockDialog?.action.onClick"
          />
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 538px;
    height: 100%;
    min-height: 260px;
    max-height: 290px;
    border-radius: 20px !important;
    pointer-events: auto;
    padding-bottom: 20px;
    .modal-header {
      position: relative;
      padding: 24px;
      @include mobile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 20px;
      }
      .title {
        text-align: center;
        @include text-style($text-heading2-bold);
      }

      .btn-close {
        position: absolute;
        top: 30px;
        right: 30px;
        color: $color-black;
        z-index: 50;
        @include mobile {
          top: 18px;
          right: 20px;
        }
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: hidden;
      padding: 24px;
      gap: 5px;
      .title {
        text-align: center;
        @include text-style($text-heading2-bold);
        word-break: break-all;
      }
      .message {
        text-align: center;
        @include text-style($text-heading3-regular);
        color: $color-99;
      }
    }
    .modal-actions {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      gap: 30px;
      padding: 24px;
    }
  }
}
</style>
