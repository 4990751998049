export const mainTop = {
  mainTopLink: {
    guide: 'I LIKE LM 소개',
    reward: '리워드 안내',
    event: '이벤트',
    support: '펀드 지원',
  },
  topBanner: {
    titleSub: 'Web3 Creator FAM Platform',
    title: '크리에이터와 팬이 함께 <br /> 소통하고, 성장하고, 살아가는 공간',
  },
}
