<script setup lang="ts">
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import type { NovaBoxPremiumSponsorProps } from './NovaBoxPremiumSponsor.types'

const props = defineProps<NovaBoxPremiumSponsorProps>()

const { t, locale } = useI18n()
// const title = computed(() =>
//   t('sponsors.sponsorsDetail.title', {
//     premiumBrand: t('subscribe.premiumBrand'),
//   })
// )
/* const sponsorStatus = computed(() => {
  switch (props.source.sbscrptnProgrsSttusCode) {
    case 'SBSC':
    case 'SBED':
      return '후원 중'
    case 'RQER':
      return '처리오류'
    case 'TRMN':
      return '후원 종료'
    default:
      throw new Error('sponsor status code is not matching')
  }
}) */
const sponsorStatus = computed(() =>
  t(`sponsors.sponsorStatus.name.${props.source.sbscrptnProgrsSttusCode}`)
)
const paymentStatus = computed(() =>
  t(`sponsors.paymentStatus.name.${props.source.sbscrptnProgrsSttusCode}`)
)

/*
const pgPayment = computed(() => {
  switch (props.source.pgPaymeanCode) {
    case '21001':
      return '가상계좌'
    case '10009':
      return '신용카드'
    default:
      console.error('지급수단이 없습니다.')
      return '-'
  }
})
*/
const pgPayment = computed(() =>
  props.source.pgPaymeanCode
    ? t(`sponsors.pgPayment.name.${props.source.pgPaymeanCode}`)
    : '-'
)

const subscribeAmount = computed(() => {
  const price = useFormatThousandsSeparators(
    props.source.sbscrptnOdrSetleAmt,
    2
  )
  switch (props.source.crrncySymbolCode) {
    // 카드 결제
    case 'USD':
      return `$ ${price}`
    // 무통장 입금
    case 'KRW':
      return `${price}원`
    default:
      throw new Error('Pay type is not match')
  }
})
/* const subscribeAmount = computed(() =>
  t(`sponsors.subscribeAmount.name.${props.source.crrncySymbolCode}`, {
    price: props.source.sbscrptnOdrSetleAmt,
  })
) */

const payDate = computed(() => {
  return dayjs(props.source.sbscrptnOdrSetleDt)
    .locale(locale.value)
    .format('YYYY.MM.DD A HH:mm')
})
</script>

<template>
  <!-- 후원 상세 내역 -->
  <section class="box-sponsors-detail-section">
    <article class="box-detail-wrap">
      <div class="title-box">
        <h4 class="title">
          <span
            v-dompurify-html="
              t('sponsors.sponsorsDetail.title', {
                premiumBrand: t('subscribe.premiumBrand'),
              })
            "
          />
        </h4>
      </div>
      <div class="box-sponsors-section-contents">
        <div class="contents box-two">
          <div class="box creator">
            <div class="user-info">
              <NovaPortraitContainer
                :image-url="source.crtrProfileImgUrl"
                :size="'sm'"
              />
              <div class="more-info">
                <div class="user-name-wrap">
                  <span
                    v-dompurify-html="
                      t('sponsors.sponsorsDetail.userBenefit', {
                        creatorNickname: source.crtrNcnm,
                        premiumBrand: t('subscribe.premiumBrand'),
                      })
                    "
                    class="user-name"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="box benefits">
            <dl class="check-list">
              <dt class="title">
                {{ t('sponsors.sponsorsDetail.benefit') }}
              </dt>
              <dd
                v-for="(benefit, index) in source.prmbrshPgmCnList"
                :key="index"
                class="item"
              >
                <div>
                  <NovaIcon
                    :icon="{ type: 'outline', name: 'check' }"
                    :size="30"
                    class="icon"
                  />
                  {{ benefit }}
                </div>
              </dd>

              <!-- <dd class="item">
                <div>
                  <NovaIcon
                    :icon="{ type: 'outline', name: 'check' }"
                    :size="30"
                    class="icon"
                  />주 1회 웹툰 업로드
                </div>
              </dd>-->
            </dl>
          </div>
        </div>
      </div>
    </article>

    <article class="box-detail-wrap">
      <div class="title-box">
        <h4 class="title">
          {{ t('sponsors.sponsorsDetail.sponsorsInfo.title') }}
        </h4>
      </div>
      <div class="box-sponsors-section-contents">
        <div class="contents">
          <div class="box title-detail-box">
            <dl>
              <dt>
                {{ t('sponsors.sponsorsDetail.sponsorsInfo.sponsorStatus') }}
              </dt>
              <dd>{{ sponsorStatus }}</dd>
            </dl>
            <dl>
              <dt>
                {{
                  t(
                    'sponsors.sponsorsDetail.sponsorsInfo.userPrmbrshPgmSbscrptnSn'
                  )
                }}
              </dt>
              <dd>{{ source.userPrmbrshPgmSbscrptnSn }}</dd>
            </dl>
            <dl>
              <dt>
                {{
                  t('sponsors.sponsorsDetail.sponsorsInfo.sbscrptnOdrBeginDt')
                }}
              </dt>
              <dd>{{ source.sbscrptnOdrBeginDt }}</dd>
            </dl>
            <dl>
              <dt>
                {{ t('sponsors.sponsorsDetail.sponsorsInfo.sbscrptnOdrEndDt') }}
              </dt>
              <dd>
                {{ source.sbscrptnOdrBeginDt }} ~
                {{ source.sbscrptnOdrEndDt }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </article>

    <article class="box-detail-wrap">
      <div class="title-box">
        <h4 class="title">
          {{ t('sponsors.sponsorsDetail.payment.title') }}
        </h4>
      </div>
      <div class="box-sponsors-section-contents">
        <div class="contents">
          <div class="box title-detail-box">
            <dl>
              <dt>
                {{ t('sponsors.sponsorsDetail.payment.pgPayment') }}
              </dt>
              <dd>
                {{ pgPayment }}
              </dd>
            </dl>
            <dl>
              <dt>
                {{ t('sponsors.sponsorsDetail.payment.subscribeAmount') }}
              </dt>
              <dd>
                {{ subscribeAmount }}
              </dd>
            </dl>
            <dl>
              <dt>
                {{ t('sponsors.sponsorsDetail.payment.sponsorStatus') }}
              </dt>
              <dd>
                {{ payDate }}
                {{ paymentStatus }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<style lang="scss" scoped>
// 후원 상세 내역
.box-sponsors-detail-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: #f7f7f7;

  @include mobile {
    padding: 15px 10px;
    border-radius: 10px;
  }
  .box-detail-wrap {
    margin-top: 20px;
    padding: 20px;
    border-radius: 15px;
    background-color: $color-bg-3;
    &:first-child {
      margin-top: 0px;
    }
    @include mobile {
      padding: 10px;
      margin-top: 15px;
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  .title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid $color-gray-1;
    .title {
      @include text-style($text-heading2-bold);
      color: $color-text-1;
      line-height: 1.5em;
      text-align: left;
      width: 100%;
      padding-left: 10px;
      word-break: keep-all;
      padding-bottom: 14px;
      @include mobile {
        @include text-style($text-custom-18-bold);
      }
    }
  }

  .box-sponsors-section-contents {
    .contents {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 40px 0 20px 0;
      @include mobile {
        padding: 20px 0 0 0;
      }
      .box {
        width: 100%;
        padding: 0 30px;
        @include mobile {
          padding: 0 10px;
        }
        .title {
          padding-bottom: 10px;
          font-size: 18px;
          font-weight: 700;
          color: #3f4354;
          word-break: break-all;
        }
        &.title-detail-box {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          row-gap: 30px;
          @include mobile {
            row-gap: 15px;
          }
          dl {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            dt {
              width: 180px;
              padding-right: 15px;
              font-size: 18px;
              font-weight: 700;
              @include mobile {
                width: 90px;
                padding-right: 10px;
                font-size: 16px;
                line-height: 1.4em;
              }
            }
            dd {
              width: calc(100% - 180px);
              font-size: 18px;
              font-weight: 400;
              @include mobile {
                font-size: 16px;
                width: calc(100% - 90px);
              }
            }
          }
        }
        .check-list {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
          @include mobile {
            padding-top: 15px;
            row-gap: 8px;
          }
          .item {
            div {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 18px;
              line-height: 1.2em;
              color: #3f4354;
              @include mobile {
                font-size: 16px;
              }
            }
          }
        }
      }
      &.box-two {
        .box {
          width: 50%;
          @include mobile {
            width: 100%;
          }
          &.creator {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
.user-info {
  display: flex;
  //justify-content: flex-start;
  //align-items: center;
  flex-direction: column;
  gap: 12px;
  flex-wrap: wrap;

  .portrait-container {
    margin: 0 auto;
    width: 120px;
    height: 120px;

    :deep(.inner) {
      border-radius: 60px;
    }

    @include mobile {
      width: 40px;
      height: 40px;
      justify-content: flex-start;

      :deep(.inner) {
        border-radius: $radius-10;
      }
    }
  }

  .user-name {
    word-break: break-all;
    @include text-style($text-custom-18-bold);
  }
}
</style>
