export const mainTop = {
  mainTopLink: {
    guide: 'About I LIKE LM',
    reward: 'Reward Guide',
    event: 'Event',
    support: 'Fund Support',
  },
  topBanner: {
    titleSub: 'Web3 Creator FAM Platform',
    title: 'Where Creators and Fans <br />Connect, Grow and Live Together',
  },
}
