<script setup lang="ts"></script>

<template>
  <NovaLayoutWrapper>
    <NovaHeader />

    <NovaLayoutDefault :page-mode="true" :mobile-flex-column="true">
      <template #left>
        <NovaHeaderLeftSide :scroll="true">
          <slot name="leftSideBar" />
        </NovaHeaderLeftSide>
      </template>

      <template #center>
        <slot />
      </template>
    </NovaLayoutDefault>
  </NovaLayoutWrapper>
</template>
