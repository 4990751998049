<script setup lang="ts"></script>

<template>
  <NovaLayoutWrapper>
    <NovaHeader />

    <NovaLayoutDefault :page-mode="true">
      <template #header>
        <NovaLayoutFundHeader />
      </template>

      <template #center>
        <slot />
      </template>
    </NovaLayoutDefault>
  </NovaLayoutWrapper>
</template>
