<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import { AxiosError } from 'axios'
import type { ModalDaoJoinParams } from '@components/NovaModalDaoJoin/NovaModalDaoJoin.types'
import type { DaoJoinPayload } from '@store'
import { NovaModalDialog } from '#components'
const { show: modalShow, hide: modalHide } = useModal()
const { gtEvent } = useGoogleTag()
const { t, messages, locale } = useI18n()

const creatorStore = useCreatorStore()
const daoStore = useDaoStore()

const show = ref(false)
const creatorDaoNm = ref('')
const state = reactive({
  value: '',
  errorMsg: '',
  maxLength: 500,
})

const handleOnGetParams = (evt: RouteParams) => {
  const payload = evt.value as unknown as ModalDaoJoinParams
  creatorDaoNm.value = payload.daoNm
  state.value = ''
}
const confirm = async () => {
  try {
    await gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: 'DAO 가입 신청 버튼 클릭',
      eventLabel: t('dao.accession.applyModel.btnBox.applyBtn'),
      eventSlot: '',
      eventI18nAddr: useKoreanTranslation(
        'dao.accession.applyModel.btnBox.applyBtn'
      ),
      eventComponent: 'button',
    })
    if (state.value.length < 1) {
      state.errorMsg = t('dao.accession.applyModel.errorMessage')
      return
    }
    const payload: DaoJoinPayload = {
      daoSn: creatorStore.profile?.creatorDaoSn || 0,
      reqstCn: state.value,
    }
    await daoStore.createDaoJoin(payload)
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: '확인',
            theme: 'primary-blue-dark',
            size: 32,
            onClick: async () => {
              await modalHide(modalsName.MODAL_DIALOG)
              await close()
            },
          },
        ],
      },
      slots: {
        title: t('dao.accession.dialog.title'),
        content: t('dao.accession.dialog.applyMessage', {
          daoNm: creatorDaoNm.value,
        }),
      },
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      const errCode = err.response.status.toString()
      const errCodes = Object.keys(
        (messages.value[locale.value].dao as any).member.errorMessage
      )
      if (errCodes.includes(errCode)) {
        await useToast(t(`dao.member.errorMessage.${errCode}`))
        return
      }
      await useToast(t('dao.member.errorMessage.default'))
    }
  }
}

const close = () => {
  modalHide(modalsName.MODAL_DAO_JOIN)
  console.log('close')
  // gtEvent('clickEvent', {
  //   eventCategory: '클릭',
  //   eventAction: 'DAO 가입 신청 모달 닫기',
  //   eventLabel: close-extend,
  //   eventSlot: '',
  //   eventI18nAddr: 'close-extend',
  //   eventComponent: 'Button',
  // })
  // await emit('close')
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_DAO_JOIN"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="close"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-desktop">
            <h4 class="title">
              {{
                t('dao.accession.applyModel.title', {
                  daoNm: creatorDaoNm,
                })
              }}
            </h4>
            <div class="close-wrap" @click="close">
              <NovaButtonIcon
                :icon="{ type: 'outline', name: 'close-extend' }"
                class="btn-close"
                @click="close"
              />
            </div>
          </div>
        </div>
        <div class="modal-body">
          <section class="form-field-wrap">
            <div class="section-title-wrap">
              <h4
                v-dompurify-html="t('dao.accession.applyModel.message')"
                class="title"
              ></h4>
            </div>
            <div class="form-field-wrap">
              <div class="field">
                <NovaTextarea
                  v-model="state.value"
                  :placeholder="t('dao.accession.applyModel.placeholder')"
                  :error-msg="state.errorMsg"
                  :max-length="state.maxLength"
                >
                </NovaTextarea>
              </div>
            </div>
          </section>
          <section>
            <div class="form-field-wrap-btns">
              <NovaButtonText
                :label="t('dao.accession.applyModel.btnBox.applyBtn')"
                :width="200"
                :theme="'primary-blue-dark'"
                :full-width="true"
                @click="confirm"
              />
            </div>
          </section>
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;
    background-color: $color-border-1;

    .modal-header {
      .on-desktop {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        flex-grow: 1;
        background-color: $color-white;
        border-radius: 20px 20px 0 0;
        @include text-style($text-display-medium);

        > .title {
          flex-grow: 1;
          color: $color-black;
        }

        .close-wrap {
          .btn-close {
            color: $color-text-2;
          }
        }
      }
    }

    .modal-body {
      display: flex;
      gap: 12px;
      padding: 24px 20px 32px 20px;
      border-radius: 20px;
      flex-direction: column;

      .section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 35px;
      }

      .form-field-wrap {
        display: flex;
        flex-direction: column;
        gap: 6px;

        &.jc-sb {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          @include mobile {
            flex-direction: column;
          }

          > div {
            width: calc(50% - 20px);
            @include mobile {
              width: 100%;
            }
          }

          .error-message {
            display: flex;
            align-items: center;
            gap: 6px;
            @include text-style($text-body-12-regular);
            color: $color-primary-red;

            &:before {
              @include error-icon;
            }
          }
        }

        .form-field-wrap-btns {
          display: flex;
          flex-direction: row;
          gap: 6px;
        }
      }
    }
  }
}
@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
<style>
.dp__action_row {
  flex-direction: column !important;
  align-items: flex-end;
  gap: 5px;
}

.dp__arrow_top {
  left: 50%;
  top: -1px;
  height: 0;
  width: 0;
}
</style>
