<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import type { StatisticsOrder } from './NovaBoxCreatorStatisticsDetail.types'

const { gtEvent } = useGoogleTag()
const { t, locale, messages } = useI18n()
const sortItems = computed(
  () =>
    Object.keys(
      (messages.value[locale.value].creatorMyPage as any).sort.stats
    ).map((key) => ({
      id: key,
      name: t(`creatorMyPage.sort.stats.${key}`),
    })) as unknown as StatisticsOrder[]
)
const activeSortItem = ref(sortItems.value[0])
const isAll = ref(false)
const date = ref()
const datePickerStatus = ref(false)
const statStartDt = ref('')
const statEndDt = ref('')

const handleDate = (modelData: any) => {
  ;[...modelData].forEach((item: Date, index) => {
    const year = item.getFullYear()
    const month =
      item.getMonth() + 1 < 10
        ? '0' + (item.getMonth() + 1)
        : item.getMonth() + 1
    const day = item.getDate() < 10 ? '0' + item.getDate() : item.getDate()
    if (index === 0) {
      statStartDt.value = `${year}-${month}-${day}`
    } else {
      statEndDt.value = `${year}-${month}-${day}`
    }
  })
  activeSortItem.value = {
    id: 'T',
    name: '기간별',
  } as unknown as StatisticsOrder
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '통계 > 기간 선택 > 캘린더',
    eventLabel: '',
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'DatePicker',
    statStartDt: statStartDt.value,
    statEndDt: statEndDt.value,
  })
}

// sort item change
const handleOnChangeActiveSortItem = (sortItem: StatisticsOrder) => {
  statStartDt.value = ''
  statEndDt.value = ''
  activeSortItem.value = sortItem
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '통계 > 기간 선택',
    eventLabel: sortItem.name,
    eventSlot: '',
    eventI18nAddr: sortItem.name,
    eventComponent: 'Button',
  })
}

const onClickAll = () => {
  isAll.value = true
}

const format = (date: Date[]) => {
  const previewDate = date.map((item) => {
    return `${item.getFullYear()}-${
      item.getMonth() + 1 < 10
        ? '0' + (item.getMonth() + 1)
        : item.getMonth() + 1
    }-${item.getDate() < 10 ? '0' + item.getDate() : item.getDate()}`
  })
  return previewDate
}

const customPosition = () => ({ top: 50, left: -220 })

const checkDatePicker = () => {
  datePickerStatus.value = !datePickerStatus.value
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `캘린더 ${datePickerStatus.value ? '열기' : '닫기'}`,
    eventLabel: 'btn-filter-date',
    eventSlot: '유저 홈 > 통계',
    eventI18nAddr: 'btn-filter-date',
    eventComponent: 'Button',
  })
}
</script>

<template>
  <section class="box-date">
    <NovaBoxSorter
      :sort-items="sortItems"
      :active-sort-item="activeSortItem"
      @on-change-active-sort-item="handleOnChangeActiveSortItem"
    />

    <VueDatePicker
      v-model="date"
      :alt-position="customPosition"
      :preview-format="format"
      :hide-navigation="['time']"
      range
      :max-date="new Date()"
      style="width: auto"
      @update:model-value="handleDate"
    >
      <template #trigger>
        <button class="btn-filter-date" @click="checkDatePicker">filter</button>
        <!-- <input type="hidden" :value="value" /> -->
      </template>
    </VueDatePicker>
  </section>
  <NovaBoxCreatorStatisticsDetailMain
    :active-sort-item="activeSortItem"
    :stat-start-dt="statStartDt"
    :stat-end-dt="statEndDt"
    @change-all-view="onClickAll"
  />
  <!--  <NovaBoxCreatorStatisticsDetailMore-->
  <!--    :active-sort-item="activeSortItem"-->
  <!--    :stat-start-dt="statStartDt"-->
  <!--    :stat-end-dt="statEndDt"-->
  <!--    @change-all-view="onClickAll"-->
  <!--  />-->
</template>

<style lang="scss" scoped>
.btn-filter-date {
  width: 44px;
  height: 44px;
  text-indent: -99999px;
  background-image: url('assets/images/icon-filter-date.png');
  background-size: 100% 100%;
}

.box-date {
  border-radius: $radius-10;
  background-color: #ffffff;
  box-shadow: 5px 5px 10px 0 rgba(149, 149, 149, 0.05);
  transition: box-shadow 0.2s;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding: 14px;
  margin: 0 0 30px 0;

  @include mobile {
    margin-bottom: 12px;
  }

  .btn-primary-blue {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.55px;
    min-height: 32px;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 7px;
    background-color: #ffffff;
    color: #3f4354;
    &.active {
      background-color: #347ae2;
      color: #fff;
    }
  }
}
</style>

<style scoped>
.dp__main {
  width: auto !important;
}
.dp__action_row {
  flex-direction: column !important;
  align-items: flex-end;
  gap: 5px;
}

.dp__arrow_top {
  left: 50%;
  top: -1px;
  height: 0;
  width: 0;
}
</style>
