<script lang="ts" setup>
import VueDatePicker from '@vuepic/vue-datepicker'
import { ko, th, es } from 'date-fns/locale'
import '@vuepic/vue-datepicker/dist/main.css'
import type { RouteParams } from 'vue-router'
import { AxiosError } from 'axios'
import type { ModalDaoVoteParams } from './NovaModalDaoVoteCreate.types'
import { NovaLayoutScrollContainer, NovaModalDialog } from '#components'
import type { DropdownItemType } from '@components/NovaDropdownItem/NovaDropdownItem.types'
import type {
  DaoVoteCreatePayload,
  DaoVoteDeletePayload,
  DaoVoteEligibilityPayload,
  DaoVoteEditPayload,
  DaoVoteStatusCode,
} from '@store'
import useDayjs from '@composables/useDayjs'

interface VoteCondition {
  value: number
  placeholder: string
  categories: DropdownItemType[]
  errorMsg: string
  onChangeValue: (value: number) => void
  validator: (showErrorMsg?: boolean) => boolean
}

interface voteContentsValue {
  qesitmAnswerNm: string
}

interface VoteContents {
  value: voteContentsValue[]
  placeholder: string
  maxLength: number
  errorMsg: string
  validator: (showErrorMsg?: boolean) => boolean
}

defineOptions({
  inheritAttrs: false,
})

const { t, messages, locale } = useI18n()
const { gtEvent } = useGoogleTag()
const { show: modalShow, hide: modalHide } = useModal()
const { dayjs } = useDayjs()

const daoStore = useDaoStore()
const creatorStore = useCreatorStore()
const layoutStore = useLayoutStore()

const timezone = ref<string>()
const timezoneOffsetInHours = ref<number>()
const calenderLocale = computed(() => {
  switch (locale.value) {
    case 'ko':
      return ko
    case 'th':
      return th
    case 'es':
      return es
    case 'en':
    default:
      return undefined
  }
})

const daoVoteCreateRef = ref<InstanceType<
  typeof NovaLayoutScrollContainer
> | null>(null)

const show = ref(false)
const headerTitle = ref<string>('')
const isEdit = ref<boolean>(false)
const listType = ref<DaoVoteStatusCode>('A')
const collectionId = ref<number>(0)

const minDateForStart = ref<Date | string>('')
const maxDateForStart = ref<Date | string>('')
const minDateForEnd = ref<Date | string>('')

// 마감일을 시작일의 다음 날부터 선택 가능하도록 설정
// const minDateForEnd = computed(() => {
//   if (votePeriod.startDate !== t('dao.vote.create.period.start')) {
//     const startDate = new Date(votePeriod.startDate)
//     return new Date(startDate.setDate(startDate.getDate() + 1))
//   }
// })

watchEffect(() => {
  if (show.value) {
    const today = dayjs()

    // 1. 시작일 선택 가능한 최소 날짜 지정
    minDateForStart.value = today.add(1, 'day').toDate()

    // 2. 시작일 선택 가능한 최대 날짜 지정
    if (isSetEndDate.value) {
      const endDate = dayjs(votePeriod.endDate)
      maxDateForStart.value = endDate.subtract(1, 'day').toDate()
    } else {
      maxDateForStart.value = ''
    }

    // 3. 종료일 선택 가능한 최소 날짜 지정
    if (isSetStartDate.value) {
      const startDate = dayjs(votePeriod.startDate)
      minDateForEnd.value = startDate.add(1, 'day').toDate()
    }
  }
})

// 모델: NFT 선택
const voteCondition = reactive<VoteCondition>({
  value: 0,
  placeholder: t('dao.vote.create.conditions.placeHolder'),
  categories: [],
  errorMsg: '',
  onChangeValue: (value: number) => {
    voteCondition.value = value
    voteCondition.validator()
  },
  validator: (showErrorMsg = true): boolean => {
    const pass = voteCondition.value !== 0
    if (showErrorMsg) {
      voteCondition.errorMsg = pass
        ? ''
        : t('dao.vote.create.conditions.errMsg')
    }
    return pass
  },
})

// 모델: 투표 제목
const voteName = reactive({
  value: '',
  placeHolder: t('dao.vote.create.name.placeHolder'),
  maxLength: 300,
  errorMsg: '',
  validator: (showErrorMsg = true): boolean => {
    const pass = voteName.value !== ''
    if (showErrorMsg) {
      voteName.errorMsg = pass ? '' : t('dao.vote.create.name.errMsg')
    }
    return pass
  },
})
// 투표 제목 입력 길이 수 제한
const initVoteName = (event: any) => {
  let inputValue = event.target.value
  if (inputValue.length > 300) {
    inputValue = inputValue.slice(0, 300) // 최대 50자 제한
  }
  voteName.value = inputValue
}

// 모델: 투표 항목
const voteContents = reactive<VoteContents>({
  value: [
    {
      qesitmAnswerNm: '',
    },
  ],
  placeholder: t('dao.vote.create.name.placeHolder'),
  maxLength: 100,
  errorMsg: '',
  validator: (showErrorMsg = true): boolean => {
    let errMsg = ''
    // 배열의 길이 확인
    const hasMinLength = voteContents.value.length >= 2
    if (!hasMinLength) errMsg = t('dao.vote.create.contents.error.select')
    // 모든 항목의 qesitmAnswerNm 값 확인
    const allAnswersFilled = voteContents.value.every(
      (item) => item.qesitmAnswerNm.trim() !== ''
    )
    if (!allAnswersFilled) errMsg = t('dao.vote.create.contents.error.value')
    // 최종 유효성 확인
    const pass = hasMinLength && allAnswersFilled
    if (showErrorMsg) {
      voteContents.errorMsg = pass ? '' : errMsg
    }
    return pass
  },
})
// 투표 항목 길이 수 제한
const initVoteContent = (event: any, index: number) => {
  let inputValue = event.target.value
  if (inputValue.length > 100) {
    inputValue = inputValue.slice(0, 100) // 최대 50자 제한
  }
  voteContents.value[index].qesitmAnswerNm = inputValue
}
// 모델: 투표 기간
const votePeriod = reactive({
  startDate: t('dao.vote.create.period.start'),
  endDate: t('dao.vote.create.period.end'),
  errorMsg: '',
  validator: (showErrorMsg = true): boolean => {
    // 시작일, 마감일 둘다 선택했는지 검사
    const pass = isSetStartDate.value && isSetEndDate.value
    if (showErrorMsg) {
      votePeriod.errorMsg = pass ? '' : t('dao.vote.create.period.errMsg')
    }
    return pass
  },
})

const isSetStartDate = computed(
  () => votePeriod.startDate !== t('dao.vote.create.period.start')
)

const isSetEndDate = computed(
  () => votePeriod.endDate !== t('dao.vote.create.period.end')
)

const handleOnGetParams = async (evt: RouteParams) => {
  const payload = evt.value as unknown as ModalDaoVoteParams

  isEdit.value = payload.daoVoteData.type === 'modify'
  listType.value = payload.listType
  collectionId.value = payload.collectionId
  // 기본 값 세팅
  setInit(isEdit.value)

  headerTitle.value = t(`dao.vote.${payload.daoVoteData.type}.header`)
  // nft 카테고리 생성
  if (creatorStore.profile) {
    const payload: DaoVoteEligibilityPayload = {
      daoSn: creatorStore.profile.creatorDaoSn || 0,
    }
    await daoStore.fetchDaoVoteEligibility(payload)
  }
  if (daoStore.daoVoteEligibility) {
    voteCondition.categories = daoStore.daoVoteEligibility.map((item) => ({
      label: item.seasonNm,
      value: item.daoColctSn,
    }))
  }
}
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '투표 생성 모달 닫기',
    eventLabel: 'close-extend',
    eventSlot: 'DAO > 투표 > 생성 모달',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
  })
  await modalHide(modalsName.MODAL_REG_DAO_VOTE)
}

onMounted(() => {
  timezone.value = dayjs.tz.guess()
  timezoneOffsetInHours.value = dayjs().tz(timezone.value).utcOffset() / 60
})

// 투표 항목 추가
const addVoteContents = () => {
  // 10개 넘어가면 메시지 보여주고 리턴
  if (voteContents.value.length > 9) {
    useToast(t('dao.vote.create.contents.over'))
    return
  }
  voteContents.value.push({
    qesitmAnswerNm: '',
  })
}
// 투표 항목 제거
const removeVoteContents = (index: number) => {
  voteContents.value.splice(index, 1)
}

const calendarDateFormatter = (date: Date) => {
  const formattedDate = dayjs(date).format('YYYY-MM-DD')
  return formattedDate
}

const handleDate = (date: Date, target: 'start' | 'end') => {
  const format = calendarDateFormatter(date)
  if (target === 'start') {
    votePeriod.startDate = format
  } else {
    votePeriod.endDate = format
  }
}
// 마감일 선택 액션 (시작일을 선택 하지 않으면 리턴)
const handleTriggerClick = (event: Event) => {
  if (!isSetStartDate.value) {
    event.preventDefault()
    event.stopPropagation()
    useToast('시작일을 먼저 선택 해 주세요.')
  }
}

// 투표 생성
const createVote = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    // 유효성 검사
    let allPass = true
    const validators = [
      voteCondition.validator,
      voteName.validator,
      voteContents.validator,
      votePeriod.validator,
    ]
    validators.forEach((validator) => {
      const isValid = validator()
      if (!isValid) allPass = false
    })

    if (allPass) {
      const payload = {
        daoColctSn: voteCondition.value,
        daoSn: creatorStore.profile?.creatorDaoSn,
        daoVoteBeginDt: votePeriod.startDate,
        daoVoteEndDt: votePeriod.endDate,
        qesitmRspnsList: voteContents.value,
        qesitmSjNm: voteName.value,
      } as DaoVoteCreatePayload
      await daoStore.createDaoVote(payload)
      await modalHide(modalsName.MODAL_REG_DAO_VOTE)
    }
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '투표 생성',
      eventLabel: t('dao.vote.create.button.create'),
      eventSlot: 'DAO > 투표 > 생성 모달',
      eventI18nAddr: useKoreanTranslation('dao.vote.create.button.create'),
      eventComponent: 'Button',
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      const errCode = err.response?.status.toString() ?? ''
      const errCodes = Object.keys(
        (messages.value[locale.value].dao as any).vote.errorMessage
      )
      if (errCodes.includes(errCode)) {
        await useToast(t(`dao.vote.errorMessage.${errCode}`))
        return
      }
      await useToast(t('dao.vote.errorMessage.default'))
    }
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
  // 유효성 검사 실행
}

const modifyVote = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '투표 수정',
    eventLabel: t('dao.vote.modify.button.modify'),
    eventSlot: 'DAO > 투표 > 수정 모달 > 수정버튼',
    eventI18nAddr: useKoreanTranslation('dao.vote.modify.button.modify'),
    eventComponent: 'Button',
  })
  await modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_DIALOG,
      btns: [
        {
          label: t('dao.vote.dialog.actions.negative'),
          theme: 'secondary-gray-200',
          size: 32,
          onClick: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '투표 수정 > 취소',
              eventLabel: t('dao.vote.dialog.actions.negative'),
              eventSlot: 'DAO > 투표 > 수정 모달 > 수정 > 취소',
              eventI18nAddr: useKoreanTranslation(
                'dao.vote.dialog.actions.negative'
              ),
              eventComponent: 'Button',
            })
            await modalHide(modalsName.MODAL_DIALOG)
          },
        },
        {
          label: t('dao.vote.dialog.actions.positive'),
          theme: 'primary-blue-dark',
          size: 32,
          onClick: async () => {
            try {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '투표 수정 > 수정',
                eventLabel: t('dao.vote.dialog.actions.positive'),
                eventSlot: 'DAO > 투표 > 수정 모달 > 수정 > 수정',
                eventI18nAddr: useKoreanTranslation(
                  'dao.vote.dialog.actions.positive'
                ),
                eventComponent: 'Button',
              })

              const payload: DaoVoteEditPayload = {
                daoColctSn: voteCondition.value,
                daoVoteBeginDt: votePeriod.startDate,
                daoVoteEndDt: votePeriod.endDate,
                daoVoteQesitmSn:
                  daoStore.daoVote!.creatorDaoVoteDetailDtoList[0]
                    .daoVoteQesitmSn,
                daoVoteSn:
                  daoStore.daoVote!.creatorDaoVoteDetailDtoList[0].daoVoteSn,
                qesitmRspnsList: voteContents.value,
                qesitmSjNm: voteName.value,
              }
              await daoStore.editDaoVote(payload)
              await modalHide(modalsName.MODAL_DIALOG)
              await handleOnClose()
            } catch (err) {
              if (err instanceof AxiosError) {
                const errCode = err.response?.status.toString() ?? ''
                const errCodes = Object.keys(
                  (messages.value[locale.value].dao as any).vote.errorMessage
                )
                if (errCodes.includes(errCode)) {
                  await useToast(t(`dao.vote.errorMessage.${errCode}`))
                  return
                }
                await useToast(t('dao.vote.errorMessage.default'))
              }
            } finally {
              await modalHide(modalsName.MODAL_DIALOG)
            }
          },
        },
      ],
    },
    slots: {
      title: t('dao.vote.dialog.title'),
      content: t('dao.vote.dialog.modifyMessage'),
    },
  })
}

// 투표 삭제
const deleteVote = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '투표 삭제',
    eventLabel: t('dao.vote.modify.button.delete'),
    eventSlot: 'DAO > 투표 > 수정 모달 > 삭제버튼',
    eventI18nAddr: useKoreanTranslation('dao.vote.modify.button.delete'),
    eventComponent: 'Button',
  })
  await modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_DIALOG,
      btns: [
        {
          label: t('dao.vote.dialog.actions.negative'),
          theme: 'secondary-gray-200',
          size: 32,
          onClick: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '투표 삭제 > 취소',
              eventLabel: t('dao.vote.dialog.actions.negative'),
              eventSlot: 'DAO > 투표 > 수정 모달 > 삭제 > 취소',
              eventI18nAddr: useKoreanTranslation(
                'dao.vote.dialog.actions.negative'
              ),
              eventComponent: 'Button',
            })
            await modalHide(modalsName.MODAL_DIALOG)
          },
        },
        {
          label: t('dao.vote.dialog.actions.positive'),
          theme: 'primary-blue-dark',
          size: 32,
          onClick: async () => {
            try {
              gtEvent('clickEvent', {
                eventCategory: '클릭',
                eventAction: '투표 삭제 > 삭제',
                eventLabel: t('dao.vote.dialog.actions.positive'),
                eventSlot: 'DAO > 투표 > 수정 모달 > 삭제 > 삭제',
                eventI18nAddr: useKoreanTranslation(
                  'dao.vote.dialog.actions.positive'
                ),
                eventComponent: 'Button',
              })
              const payload: DaoVoteDeletePayload = {
                daoVoteQesitmSn:
                  daoStore.daoVote!.creatorDaoVoteDetailDtoList[0]
                    .daoVoteQesitmSn,
                daoVoteSn:
                  daoStore.daoVote!.creatorDaoVoteDetailDtoList[0].daoVoteSn,
              }
              await daoStore.deleteDaoVote(payload)
              await modalHide(modalsName.MODAL_DIALOG)
              await handleOnClose()
            } catch (err) {
              if (err instanceof AxiosError) {
                const errCode = err.response?.status.toString() ?? ''
                const errCodes = Object.keys(
                  (messages.value[locale.value].dao as any).vote.errorMessage
                )
                if (errCodes.includes(errCode)) {
                  await useToast(t(`dao.vote.errorMessage.${errCode}`))
                  return
                }
                await useToast(t('dao.vote.errorMessage.default'))
              }
            }
          },
        },
      ],
    },
    slots: {
      title: t('dao.vote.dialog.title'),
      content: t('dao.vote.dialog.deleteMessage'),
    },
  })
}

// 모달 열릴때 기본 데이터 초기화 및 수정 데이터 세팅
const setInit = (isEdit: boolean) => {
  // const { localStrToISOString } = useDateFormat()

  voteName.errorMsg = ''
  voteCondition.errorMsg = ''
  votePeriod.errorMsg = ''
  voteContents.errorMsg = ''

  if (isEdit) {
    const voteDetail = daoStore.daoVote?.creatorDaoVoteDetailDtoList[0]
    voteName.value = voteDetail?.qesitmSjNm || ''
    voteCondition.value = collectionId.value
    voteContents.value =
      daoStore.daoVote?.creatorDaoVoteDetailDtoList[0].questionList.map(
        ({ voteCount: _voteCount, ...rest }) => rest
      ) || []

    if (voteDetail?.daoVoteBeginDt) {
      handleDate(voteDetail.daoVoteBeginDt as unknown as Date, 'start')
    } else {
      votePeriod.startDate = t('dao.vote.create.period.start')
    }

    if (voteDetail?.daoVoteEndDt) {
      handleDate(voteDetail.daoVoteEndDt as unknown as Date, 'end')
    } else {
      votePeriod.endDate = t('dao.vote.create.period.end')
    }
  } else {
    voteName.value = ''
    voteCondition.value = 0
    voteContents.value = [{ qesitmAnswerNm: '' }]
    votePeriod.startDate = t('dao.vote.create.period.start')
    votePeriod.endDate = t('dao.vote.create.period.end')
  }
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :content-class="'modal-content'"
    :name="modalsName.MODAL_REG_DAO_VOTE"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-desktop">
            <h4 class="title">{{ headerTitle }}</h4>
            <div class="close-wrap" @click="handleOnClose">
              <NovaButtonIcon
                :icon="{ type: 'outline', name: 'close-extend' }"
                class="btn-close"
              />
            </div>
          </div>
        </div>

        <NovaLayoutScrollContainer ref="daoVoteCreateRef" class="modal-body">
          <section class="section">
            <!-- 1-1. 투표 참여 조건 -->
            <div class="form-field-wrap">
              <div
                v-dompurify-html="t('dao.vote.create.conditions.title')"
                class="label"
              ></div>

              <div class="field">
                <NovaDropdown
                  :active-value="voteCondition.value"
                  :menus="voteCondition.categories"
                  :placeholder="voteCondition.placeholder"
                  @update="voteCondition.onChangeValue"
                />
                <p v-if="voteCondition.errorMsg" class="error-message">
                  {{ voteCondition.errorMsg }}
                </p>
              </div>
            </div>
          </section>
          <!-- 1-2. 투표 제목 -->
          <section class="section">
            <div class="form-field-wrap">
              <div
                v-dompurify-html="t('dao.vote.create.name.title')"
                class="label"
              ></div>

              <div class="field">
                <NovaInput
                  v-model="voteName.value"
                  :max-length="voteName.maxLength"
                  :placeholder="voteName.placeHolder"
                  :theme="'bgwhite'"
                  @input="(event: InputEvent) => initVoteName(event)"
                  @on-blur="voteName.validator()"
                />
                <p v-if="voteName.errorMsg" class="error-message">
                  {{ voteName.errorMsg }}
                </p>
              </div>
            </div>
          </section>
          <!-- 1-3. 투표 항목 -->
          <section class="section">
            <div class="form-field-wrap">
              <div
                v-dompurify-html="t('dao.vote.create.contents.title')"
                class="label"
              ></div>

              <div
                v-for="(item, index) in voteContents.value"
                :key="index"
                class="field"
              >
                <div class="vote-contents-wrap">
                  <NovaInput
                    v-model="item.qesitmAnswerNm"
                    :max-length="voteContents.maxLength"
                    :placeholder="
                      t('dao.vote.create.contents.placeHolder', {
                        index: index + 1,
                      })
                    "
                    :theme="'bgwhite'"
                    @input="(event: InputEvent) => initVoteContent(event, index)"
                    @on-blur="voteContents.validator()"
                  />
                  <NovaButtonIcon
                    v-if="voteContents.value.length > 1"
                    :icon="{ type: 'outline', name: 'circle-minus' }"
                    :size="20"
                    :class="'btn-clear'"
                    @click="removeVoteContents(index)"
                  />
                </div>
              </div>
              <p v-if="voteContents.errorMsg" class="error-message">
                {{ voteContents.errorMsg }}
              </p>
            </div>
            <NovaButtonText
              :disabled="false"
              :full-width="true"
              :label="t('dao.vote.create.contents.add')"
              :outline="true"
              :size="40"
              :theme="'white'"
              :class="'btn-add'"
              @click="addVoteContents()"
            />
          </section>
          <!-- 1-4. 투표 기간 -->
          <section class="section">
            <div class="form-field-wrap">
              <div
                v-dompurify-html="t('dao.vote.create.period.title')"
                class="label"
              ></div>
              <div class="date-wrap">
                <div class="date-box">
                  <div class="title">
                    {{ t('dao.vote.create.period.start') }}
                  </div>
                  <div class="box-date">
                    <div class="box-date-item">
                      <div class="date-item">
                        {{ votePeriod.startDate }}
                      </div>
                      <VueDatePicker
                        v-model="votePeriod.startDate"
                        :format-locale="calenderLocale"
                        :hide-navigation="['time']"
                        :position="'center'"
                        :min-date="minDateForStart"
                        :max-date="maxDateForStart"
                        :format="calendarDateFormatter"
                        :cancel-text="t('cancel')"
                        :select-text="t('confirm')"
                        @update:model-value="(date: Date) => handleDate(date, 'start')"
                      >
                        <template #trigger>
                          <button class="btn-filter-date">filter</button>
                        </template>
                      </VueDatePicker>
                    </div>
                  </div>
                </div>
                <div class="date-box">
                  <div>{{ t('dao.vote.create.period.end') }}</div>
                  <div class="box-date">
                    <div class="box-date-item">
                      <div class="date-item">
                        {{ votePeriod.endDate }}
                      </div>
                      <VueDatePicker
                        v-model="votePeriod.endDate"
                        :format-locale="calenderLocale"
                        :hide-navigation="['time']"
                        :min-date="minDateForEnd"
                        :position="'right'"
                        :format="calendarDateFormatter"
                        :cancel-text="t('cancel')"
                        :select-text="t('confirm')"
                        @update:model-value="(date: Date) => handleDate(date, 'end')"
                      >
                        <template #trigger>
                          <button
                            class="btn-filter-date"
                            @click="handleTriggerClick"
                          >
                            filter
                          </button>
                        </template>
                      </VueDatePicker>
                    </div>
                  </div>
                </div>
              </div>
              <p v-if="votePeriod.errorMsg" class="error-message">
                {{ votePeriod.errorMsg }}
              </p>
            </div>
          </section>
          <section v-if="isEdit" class="section">
            <div class="form-field-wrap-btns">
              <NovaButtonText
                :full-width="true"
                :label="t('dao.vote.modify.button.delete')"
                :theme="'secondary-gray-200'"
                :width="200"
                @click.stop="deleteVote()"
              />
              <NovaButtonText
                :full-width="true"
                :label="t('dao.vote.modify.button.modify')"
                :theme="'primary-blue-dark'"
                :width="200"
                @click.stop="modifyVote()"
              />
            </div>
          </section>
          <section v-else class="section">
            <div class="form-field-wrap-btns">
              <NovaButtonText
                :full-width="true"
                :label="t('dao.vote.create.button.create')"
                :theme="'primary-blue-dark'"
                :width="200"
                @click="createVote()"
              />
            </div>
          </section>
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;
    background-color: $color-border-1;

    .modal-header {
      .on-desktop {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        flex-grow: 1;
        background-color: $color-white;
        border-radius: 20px 20px 0 0;
        @include text-style($text-display-medium);

        > .title {
          flex-grow: 1;
          color: $color-black;
        }

        .close-wrap {
          .btn-close {
            color: $color-text-2;
          }
        }
      }
    }

    .modal-body {
      display: flex;
      gap: 30px;
      padding: 24px 20px 32px 20px;
      border-radius: 20px;
      min-height: 396px;

      .section {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .form-field-wrap {
        display: flex;
        flex-direction: column;
        gap: 6px;

        > .label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include text-style($text-display-bold);
          color: $color-text-2;
        }

        .vote-conditions-drop-down {
          width: 102px;
        }
        .date-wrap {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          gap: 30px;
          @include mobile {
            gap: 10px;
          }
          .date-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 6px;
            .title {
              @include text-style($text-body-14-regular);
            }
            .box-date-item {
              display: flex;
              justify-content: space-between;
              width: 100%;
            }
          }
        }

        .error-message {
          display: flex;
          align-items: center;
          gap: 6px;
          @include text-style($text-body-12-regular);
          color: $color-primary-red;

          &:before {
            @include error-icon;
          }
        }
      }
      .btn-add {
        @include text-style($text-body-14-regular);
        border: 1px solid $color-bg-custom-2;
      }
      .vote-contents-wrap {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;
        .btn-clear {
          .icon-wrap {
            .icon {
              color: $color-bg-custom-9;
            }
          }
        }
      }

      .form-field-wrap-btns {
        display: flex;
        flex-direction: row;
        gap: 6px;
        padding-top: 10px;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-body {
        border-radius: 0;
        gap: 30px;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}

.box-date {
  border-radius: $radius-10;
  background-color: $color-bg-3;
  box-shadow: 5px 5px 10px 0 rgba(149, 149, 149, 0.05);
  transition: box-shadow 0.2s;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid $color-bg-custom-2;

  @include mobile {
    margin-bottom: 12px;
  }

  .box-date-item {
    padding: 0 15px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .btn-primary-blue {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.55px;
    min-height: 32px;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 7px;
    background-color: #ffffff;
    color: #3f4354;

    &.active {
      background-color: #347ae2;
      color: #fff;
    }
  }
}

.btn-filter-date {
  width: 15px;
  height: 15px;
  text-indent: -99999px;
  background-image: url('assets/images/icon-calendar.png');
  background-size: 100% 100%;
}
</style>
<style scoped>
.dp__main {
  width: 0% !important;
}

.dp__action_row {
  flex-direction: column !important;
  align-items: flex-end;
  gap: 5px;
}

.dp__arrow_top {
  left: 50%;
  top: -1px;
  height: 0;
  width: 0;
}
</style>
