<script setup lang="ts">
import type {
  NovaBoxCreatorStatisticsDetailMainEmits,
  NovaBoxCreatorStatisticsDetailMainProps,
} from './NovaBoxCreatorStatisticsDetailMain.types'

defineEmits<NovaBoxCreatorStatisticsDetailMainEmits>()
const props = withDefaults(
  defineProps<NovaBoxCreatorStatisticsDetailMainProps>(),
  {
    activeSortItem: null,
    statStartDt: '',
    statEndDt: '',
  }
)

const { locale } = useI18n()
const creatorStore = useCreatorStore()
const layoutStore = useLayoutStore()
const isError = ref(false)
const isLoading = ref(false)

watch(
  () => [props.activeSortItem, props.statStartDt, props.statEndDt],
  async () => {
    await fetchCreatorStatsInfo()
  }
)

watch(
  () => locale.value,
  async () => {
    await fetchCreatorStatsInfo()
  }
)

onMounted(async () => {
  await fetchCreatorStatsInfo()
})

// fetch rewards
const fetchCreatorStatsInfo = async () => {
  try {
    isLoading.value = true
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    isError.value = false
    await creatorStore.fetchCreatorStatsInfo({
      statTyCode: props.activeSortItem?.id || '',
      statStartDt: props.statStartDt,
      statEndDt: props.statEndDt,
    })
  } catch {
    isError.value = true
  } finally {
    isLoading.value = false
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}
</script>

<template>
  <section class="contents-statistics">
    <div class="box-summary">
      <div class="box-sub">
        <!-- 조회수 -->
        <strong>{{ $t('creatorHome.statistics.menu.views') }}</strong>
        <div>
          <span>{{ creatorStore.creatorStatItem.statRdVal }}</span>
          <em>{{ creatorStore.creatorStatItem.statRdDiff }}%</em>
        </div>
      </div>
      <div class="box-sub">
        <!-- 팔로워수 -->
        <strong>{{ $t('creatorHome.statistics.menu.follows') }}</strong>
        <div>
          <span
            >{{ creatorStore.creatorStatItem.statFwVal }}
            {{ $t('creatorHome.statistics.menu.followUnit') }}</span
          >
          <em>{{ creatorStore.creatorStatItem.statFwDiff }}%</em>
        </div>
      </div>
      <div class="box-sub">
        <!-- 랭킹 -->
        <strong>{{ $t('creatorHome.statistics.menu.ranking') }}</strong>
        <div>
          <span
            >{{ creatorStore.creatorStatItem.statRankVal
            }}{{ $t('creatorHome.statistics.menu.rankingUnit') }}</span
          >
          <em>{{ creatorStore.creatorStatItem.statRankDiff }}%</em>
        </div>
      </div>
      <div class="box-sub">
        <!-- 보상 -->
        <strong>{{ $t('creatorHome.statistics.menu.reward') }}</strong>
        <div>
          <span>{{ creatorStore.creatorStatItem.statRewardVal }}LM</span>
          <em>{{ creatorStore.creatorStatItem.statRewardDiff }}%</em>
        </div>
      </div>
    </div>
    <div class="box-articles">
      <div class="box-header">
        <!-- 공유된 콘텐츠 -->
        <h4 class="title">
          {{ $t('creatorHome.statistics.menu.sharedContents') }}
        </h4>
      </div>
      <div class="box-contents">
        <div class="box-contents-links">
          <ul>
            <li>
              <a href="javascript:void(0);">
                <span>{{ $t('creatorHome.statistics.menu.notice') }}</span>
                <em> {{ creatorStore.creatorStatItem.shareNormalCount }} </em>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <span>{{ $t('creatorHome.statistics.menu.video') }}</span>
                <em>{{ creatorStore.creatorStatItem.shareVideoCount }}</em>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.contents-statistics {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include mobile {
    gap: 12px;
  }

  .box-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    .box-sub {
      width: calc(50% - 6px);
      border-radius: $radius-10;
      background-color: #ffffff;
      box-shadow: 5px 5px 10px 0 rgba(149, 149, 149, 0.05);
      transition: box-shadow 0.2s;
      justify-content: space-between;
      padding: 28px 14px;
      strong {
        display: block;
        font-size: 15px;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 10px;
        span {
          font-size: 20px;
        }
        em {
          font-size: 11px;
          color: #43981b;
        }
      }
    }
  }

  .box-articles {
    border-radius: $radius-10;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    padding: 30px 20px !important;
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
    .box-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0;
        color: #3f4354;
      }
      .btn-more-text {
        font-size: 12px;
        color: $color-99;
      }
    }
    .box-contents-links {
      margin-top: 3px;
      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      li {
        a {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          gap: 13px;
        }
      }
      span {
        font-size: 14px;
      }
      em {
        display: block;
        font-size: 12px;
        padding-right: 5px;
        position: relative;
        color: $color-99;
        //&:before {
        //  display: block;
        //  content: '';
        //  width: 5px;
        //  height: 10px;
        //  position: absolute;
        //  top: 50%;
        //  right: 0;
        //  transform: translateY(-50%);
        //  background-size: 100% 100%;
        //  background-repeat: no-repeat;
        //  background-position: center center;
        //  opacity: 0.5;
        //  background-image: url('assets/images/creator/ico-arrow-black.png');
        //}
      }
    }
  }
}
</style>
