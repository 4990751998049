<script setup lang="ts">
// import { AxiosError } from 'axios'
import type { NovaBoxMyCollectionNftDetailProps } from './NovaBoxMyCollectionNftDetail.types'
import { NovaModalDialog, NovaPortraitContainer } from '#components'
import success from '@assets/images/icon-round-success.svg?url'
import fail from '@assets/images/icon-round-fail.svg?url'

withDefaults(defineProps<NovaBoxMyCollectionNftDetailProps>(), {
  isDetail: true,
})

const myPageStore = useMyPageStore()
const { myCollectionNft } = storeToRefs(myPageStore)
const layoutStore = useLayoutStore()
const { show: modalShow, hide: modalHide } = useModal()
const { t } = useI18n()

const seasonDescShowMore = ref(true)
const colctDescShowMore = ref(true)

const issueDate = computed(() => {
  return formatDateToGMT9(myCollectionNft.value!.nftitmIssDt)
})
const formatDateToGMT9 = (issueDate: string) => {
  const date = new Date(issueDate)

  const dateInGMT9 = new Intl.DateTimeFormat('en-CA').format(date)
  return `${dateInGMT9} (GMT +9)`
}

const onHandleSeasonDc = () => {
  seasonDescShowMore.value = !seasonDescShowMore.value
}
const onHandleColctDc = () => {
  colctDescShowMore.value = !colctDescShowMore.value
}

const seasonDescOverFlow = ref(true)
const colDescOverFlow = ref(true)

onMounted(async () => {
  await nextTick()
  const elSeasonDesc = document.getElementsByClassName('message-season')
  const elColDesc = document.getElementsByClassName('message-col')
  if (elSeasonDesc[0].clientHeight >= elSeasonDesc[0].scrollHeight)
    seasonDescOverFlow.value = false
  if (elColDesc[0] && elColDesc[0].clientHeight >= elColDesc[0].scrollHeight)
    colDescOverFlow.value = false
})

const revealNft = async (randboxSn: number) => {
  let revealMessage = t('mypage.myCollection.reveal.success')
  let dialogModalImage = success
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    await myPageStore.reqRandomBoxReveal({ randboxSn })
  } catch (err) {
    revealMessage = t('mypage.myCollection.reveal.error')
    dialogModalImage = fail
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        contentsTextAlign: 'center',
        containsComponents: true,
        message: revealMessage,
        btns: [
          {
            label: t('confirm'),
            theme: 'primary-blue-dark',
            size: 44,
            onClick: async () => {
              await modalHide(modalsName.MODAL_DIALOG)
            },
          },
        ],
      },
      slots: {
        title: t('notification'),
        content: {
          component: NovaPortraitContainer,
          bind: {
            imageUrl: dialogModalImage,
            size: '80',
          },
        },
      },
    })
  }
}
</script>

<template>
  <div :class="['collections-detail', { 'on-detail': isDetail }]">
    <div class="body">
      <div class="contents media">
        <NovaMediaContainer
          :image="
            myCollectionNft?.mediaTyCode === 'I'
              ? { source: myCollectionNft.fileUrl }
              : undefined
          "
          :video="
            myCollectionNft?.mediaTyCode === 'V'
              ? {
                  source: myCollectionNft.fileUrl,
                  poster: myCollectionNft.fileUrl,
                }
              : undefined
          "
          :auto-play="true"
          :preload="'auto'"
          :nft="true"
        />
      </div>
      <div class="contents season-box">
        <div class="title">
          {{ myCollectionNft?.seasonNm }}
        </div>
        <div class="message-box">
          <div
            v-dompurify-html="myCollectionNft?.seasonDc"
            :class="['message-season', { show: !seasonDescShowMore }]"
          />
          <div v-if="seasonDescOverFlow" class="btn-more">
            <NovaButtonText
              :label="
                seasonDescShowMore
                  ? $t('mypage.myCollection.button.showMore')
                  : $t('mypage.myCollection.button.showLess')
              "
              :size="32"
              class="btn-action"
              @click="onHandleSeasonDc"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="contents">
        <div class="title">
          {{ myCollectionNft?.colctNm }}
        </div>
        <div v-if="myCollectionNft?.nftitmSn" class="message-box">
          <div
            v-dompurify-html="myCollectionNft.colctDc"
            :class="['message-col', { show: !colctDescShowMore }]"
          />
          <div v-if="colDescOverFlow" class="btn-more">
            <NovaButtonText
              :label="
                colctDescShowMore
                  ? $t('mypage.myCollection.button.showMore')
                  : $t('mypage.myCollection.button.showLess')
              "
              :size="32"
              class="btn-action"
              @click="onHandleColctDc"
            />
          </div>
        </div>
      </div>
      <hr />
      <div class="contents collections-data-box">
        <div class="collections-data">
          <dl>
            <dt>{{ t('mypage.myCollection.detail.owner') }}</dt>
            <dd class="user-uu-id">
              {{ myCollectionNft?.userNcnm }}({{ myCollectionNft?.userUuid }})
            </dd>
          </dl>
        </div>
        <div v-if="myCollectionNft?.nftitmRareCodeNm" class="collections-data">
          <dl>
            <dt>{{ t('mypage.myCollection.detail.rarity') }}</dt>
            <dd>
              <span :class="[`on-${myCollectionNft.nftitmRareCodeNm}`]">{{
                myCollectionNft.nftitmRareCodeNm
              }}</span>
            </dd>
          </dl>
        </div>
        <div class="collections-data">
          <dl>
            <dt>{{ t('mypage.myCollection.detail.issueDate') }}</dt>
            <dd>{{ issueDate }}</dd>
          </dl>
        </div>
      </div>
      <div v-if="!myCollectionNft?.nftitmRareCodeNm" class="contents">
        <NovaButtonText
          :label="t('mypage.myCollection.detail.reveal')"
          :size="44"
          :theme="'primary-blue-dark'"
          class="reveal-btn-action"
          @click="revealNft(myCollectionNft!.randboxSn)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.collections-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px 15px 15px 15px;
  background-color: $color-bg-3;
  hr {
    border: 1px solid $color-bg-custom-1;
  }
  &.on-detail {
    border-radius: $radius-10;
  }
  .contents {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
    &.media {
      padding: 0 0 15px 0;
    }
    .title {
      @include text-style($text-custom-18-bold);
    }
    .message-box {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .message-season {
        @include text-style($text-display-medium);
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
        &.show {
          display: block;
          height: auto;
          -webkit-line-clamp: none;
        }
        &.type {
          color: $color-text-12;
        }
      }
      .message-col {
        @include text-style($text-display-medium);
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
        &.show {
          display: block;
          height: auto;
          -webkit-line-clamp: none;
        }
        &.type {
          color: $color-text-12;
        }
      }
      .btn-more {
        display: flex;
        justify-content: flex-end;
        color: $color-text-12;
        :deep(.btn-32) {
          padding: 0;
          @include text-style($text-display-medium);
          color: $color-text-12;
          &:hover {
            text-decoration: underline;
            text-underline-offset: 4px;
          }
        }
      }
    }
    &.season-box {
      .title {
        color: $color-primary-blue-dark;
      }
    }
    &.collections-data-box {
      gap: 24px;
    }
    .collections-data {
      dl {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        dt {
          @include text-style($text-display-bold);
        }
        dd {
          @include text-style($text-display-medium);
          word-break: break-all;
          &.user-uu-id {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;
            @include mobile {
              max-width: 70%;
            }
          }
          .on-Normal {
            color: #7c7c7c;
          }
          .on-Rare {
            color: #1a6642;
          }
          .on-Epic {
            color: #11336e;
          }
          .on-Unique {
            color: #68006a;
          }
          .on-Legendary {
            color: #e97132;
          }
        }
      }
    }
  }
}
</style>
