<script setup lang="ts">
const etcStore = useEtcStore()
const mainBannersStatus = reactive({
  isLoading: false,
  isError: false,
})

// 메인배너 목록 조회
const fetchMainBanners = async () => {
  try {
    mainBannersStatus.isLoading = true
    mainBannersStatus.isError = false
    await etcStore.fetchMainBanners()
  } catch {
    mainBannersStatus.isError = true
  } finally {
    mainBannersStatus.isLoading = false
  }
}

onMounted(async () => {
  await fetchMainBanners()
})
</script>

<template>
  <div class="main-top-banner">
    <NovaLayoutTopBanner
      :banners="etcStore.mainBanners"
      :is-loading="mainBannersStatus.isLoading"
      :is-error="mainBannersStatus.isError"
      :ga-slot="'메인 탑 배너'"
    />
  </div>
</template>

<style scoped lang="scss">
.main-top-banner {
  background-color: $color-white;
}
</style>
