<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Autoplay } from 'swiper/modules'
import type { Swiper as SwiperType } from 'swiper'
import { AxiosError } from 'axios'
import type {
  CreatorHomeTabType,
  NovaBoxCreatorTopHeaderEmits,
  NovaBoxCreatorTopHeaderProps,
} from './NovaBoxCreatorTopHeader.types'
import { BlockingTarget, LayerType } from '@store'
import type { TabData } from '@components/NovaTab/NovaTab.types'
import type { HomeActionProps } from '@components/NovaBoxHomeAction/NovaBoxHomeAction.types'
import { type ShowCreatorNoticeDetailModalParams } from '@components/NovaBoxCreatorNoticeListItem/NovaBoxCreatorNoticeListItem.types'
import { useReCaptcha } from '@composables/useRechaptcha'
import { ROUTES } from '@configs'
const emit = defineEmits<NovaBoxCreatorTopHeaderEmits>()
const props = withDefaults(defineProps<NovaBoxCreatorTopHeaderProps>(), {
  isShowTabMenu: true,
  isDao: false,
})
const { t } = useI18n()
const { gtEvent } = useGoogleTag()
const creatorStore = useCreatorStore()
const commStore = useCommStore()
const daoStore = useDaoStore()
const layoutStore = useLayoutStore()
const appStore = useAppStore()
const { showFollowErrorMessage, showUnfollowErrorMessage } = useFollow()
const { userStore, showSignInDialog } = useMembershipProcess()
const { show: modalShow } = useModal()
const { generateReCaptchaToken } = useReCaptcha()
const { tabs, profile, initTab } = toRefs(props)
const isLoading = ref(false)
const isOpen = ref(true)

// 통계 탭 제거하면서 코드 제거 > item.id === 'statistics' ||
const creatorMyTaps = computed(() => {
  const _tabs = tabs.value.filter(
    (item) =>
      !(
        userStore.user?.userSn !== profile.value?.userSn &&
        (item.id === 'activity' ||
          item.id === 'reward' ||
          item.id === 'donation' ||
          item.id === 'collections')
      )
  )
  return _tabs
})

const creatorDaoMyTaps = computed(() => {
  const _tabs = tabs.value
  return _tabs
})

const isDaoCreator = computed(
  () => creatorStore.profile?.creatorDaoOpenedAt === 'Y'
)

const isEqualUser = computed(
  () =>
    userStore.isSignIn &&
    creatorStore.profile?.userSn === userStore.user?.userSn
)

const creatorProfileDescription = computed(() =>
  (creatorStore.profile?.proflDc || '').replace(/(?:\r\n|\r|\n)/g, '<br />')
)

const homeActionParams = computed(() => {
  if (props.isDao) {
    return false
  }
  const profile = creatorStore.profile
  const params: HomeActionProps | null = profile
    ? {
        target: BlockingTarget.CREATOR,
        userSn: profile.userSn,
        userNcnm: profile.userNcnm,
        isDonation: profile.prmbrshSbscrbAt === 'Y',
        blockingAt: profile.blockingAt,
        followerAt: profile.followerAt,
      }
    : null
  return params
})

const daoHomeActionsCondition = computed(() => {
  return props.isDao && daoStore.daoJoinStatus?.daoSbscrbAt === 'Y'
})

const applyJoinDaoBtnVisible = computed(() => {
  // DAO 탭인지 + 이미 가입한 사람인지
  if (daoHomeActionsCondition.value) {
    return false
  } else {
    if (
      daoStore.daoJoinStatus === null ||
      daoStore.daoJoinStatus?.sbscrbSttusCode === 'P' ||
      daoStore.daoJoinStatus?.sbscrbSttusCode === 'T' ||
      daoStore.daoJoinStatus?.sbscrbSttusCode === 'R' ||
      daoStore.daoJoinStatus?.sbscrbSttusCode === null
    ) {
      return true
    }
    return false
  }
})

const applyJoinDaoBtnLabel = computed(() => {
  if (daoStore.daoJoinStatus?.sbscrbSttusCode === 'P') {
    return t('dao.accession.btn.pending')
  } else {
    return t('dao.accession.btn.apply')
  }
})

const applyJoinDaoBtnDisable = computed(() => {
  if (
    daoStore.daoJoinStatus?.sbscrbSttusCode === 'P' ||
    daoStore.daoJoinStatus?.sbscrbSttusCode === 'T' ||
    daoStore.daoJoinStatus?.sbscrbSttusCode === 'S' ||
    daoStore.daoJoinStatus?.sbscrbSttusCode === 'R'
  ) {
    return true
  }
  if (!daoStore.daoJoinStatus) {
    return false
  }
})

const onChangeTab = (tabId: TabData<CreatorHomeTabType>) => {
  emit('onChangeTab', tabId.id)
}

const handleOnToggleFollow = async () => {
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }
  const doToggleFollow = async (
    toggleAction: () => Promise<void>,
    type: 'follow' | 'unfollow'
  ) => {
    try {
      await toggleAction()

      if (creatorStore.profile) {
        switch (type) {
          case 'follow':
            creatorStore.profile.followerCount += 1
            break
          case 'unfollow':
            creatorStore.profile.followerCount -= 1
            break
          default:
            break
        }
      }
    } catch {}
  }
  creatorStore.profile?.followerYn === 'N'
    ? await doToggleFollow(() => handleOnFollow(), 'follow')
    : await doToggleFollow(() => handleOnUnFollow(), 'unfollow')
}

const handleOnFollow = async () => {
  try {
    isLoading.value = true

    await useSleeper()
    // const { generateReCaptchaToken } = useReCaptcha()
    const recaptchaToken = await generateReCaptchaToken('FOLLOW')
    await commStore.reqFollow({
      flwUserSn: creatorStore.profile!.userSn,
      recaptchaToken,
    })
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '팔로우',
      eventLabel: t('follow'),
      eventSlot: '팔로우 하기',
      eventI18nAddr: useKoreanTranslation('follow'),
      eventComponent: 'Button',
      flwUserSn: creatorStore.profile!.userSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showFollowErrorMessage(err, creatorStore.profile?.userNcnm)
    }
  } finally {
    isLoading.value = false
  }
}

const handleOnUnFollow = async () => {
  try {
    isLoading.value = true

    await useSleeper()
    await commStore.reqUnfollow({
      flwUserSn: creatorStore.profile!.userSn,
    })
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '언팔로우',
      eventLabel: t('following'),
      eventSlot: '언팔로우 하기',
      eventI18nAddr: useKoreanTranslation('following'),
      eventComponent: 'Button',
      flwUserSn: creatorStore.profile!.userSn,
    })
  } catch (err) {
    if (err instanceof AxiosError) {
      showUnfollowErrorMessage(err, creatorStore.profile?.userNcnm)
    }
  } finally {
    isLoading.value = false
  }
}

// 기부하기
const handleOnDonate = async () => {
  await useDonate({
    userSn: creatorStore.profile!.userSn,
    userNcnm: creatorStore.profile!.userNcnm,
    userProflUrl: creatorStore.profile!.userProflUrl,
  })
}

const profileData = reactive({
  userSn: creatorStore.profile!.userSn,
  followerCount: creatorStore.profile?.followerCount,
  followingCount: creatorStore.profile?.followingCount,
  coins:
    userStore.user?.userSn === creatorStore.profile?.userSn
      ? creatorStore.profile?.rwardSmLM
      : undefined,
  profile: creatorStore.profile,
})

const swiperOptions = computed(() => ({
  watchOverflow: true,
  loop:
    creatorStore.profile?.notices && creatorStore.profile?.notices.length > 1,
  loopAdditionalSlides: 1,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  modules:
    creatorStore.profile?.notices && creatorStore.profile?.notices.length > 1
      ? [Pagination, Autoplay]
      : [],
}))
const swiper = ref<SwiperType | null>(null)
const totalSlides = ref(0)

const onSwiper = (swiperInstance: SwiperType) => {
  swiper.value = swiperInstance
  totalSlides.value = swiperInstance.slides.length
}

const openIntroduceModal = () => {
  modalShow(modalsName.MODAL_CREATOR_PROFILE, {
    creatorProfile: creatorStore.profile,
  })
}

const currentIndex = ref(0)
const openNoticeDetailModal = () => {
  modalShow(modalsName.MODAL_CREATOR_NOTICE_DETAIL, {
    creatorNotice: creatorStore.profile?.notices[currentIndex.value],
  } as ShowCreatorNoticeDetailModalParams)
}

const onSlideChange = () => {
  if (swiper.value) {
    currentIndex.value = swiper.value.realIndex
  }
}

const isNotice = computed(() => {
  return (
    creatorStore.profile?.notices && creatorStore.profile?.notices.length > 0
  )
})
const transitionDao = () => {
  emit('onChangeDao')
  useNavigations({
    url: useRoutePathIdChange(
      props.isDao ? ROUTES.CREATOR.path : ROUTES.CREATOR_DAO_VOTE.path,
      {
        id: `@${creatorStore.profile?.creatorLink}`,
      }
    ),
  })
}

const applyJoinDao = async () => {
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }
  await modalShow(modalsName.MODAL_DAO_JOIN, {
    daoNm: creatorStore.profile?.creatorDaoNm,
  })
}

// DAO 신청 페이지로 이동
const goDaoApplyPage = () => {
  useNavigations({ url: ROUTES.APPLY_DAO.path })
}

const showInitTab = ref(false)
onMounted(async () => {
  // DAO 개설 한 크리에이터 일때 DAO 정보 조회
  if (creatorStore.profile?.creatorDaoSn) {
    await daoStore.fetchDaoInfo({
      daoSn: creatorStore.profile.creatorDaoSn,
    })
  }
  // 로그인 한 유저 일때 다오 가입 정보 조회
  if (userStore.isSignIn) {
    if (creatorStore.profile?.creatorDaoSn) {
      await daoStore.fetchDaoJoinStatus({
        daoSn: creatorStore.profile?.creatorDaoSn,
      })
    }
    // 로그인 한 유저가 본인의 DAO 홈에 접속한 경우 조회
    if (userStore.user?.userSn !== creatorStore.profile?.userSn) {
      await daoStore.fetchDaoStatus()
    }
    showInitTab.value = true
  } else {
    // 비 로그인 회원
    showInitTab.value = true
  }
})

onUnmounted(() => {
  layoutStore['layer:close'](LayerType.USER_PROFILE_EDIT_PANEL)
})
</script>

<template>
  <div :class="['creator-top', { on: isNotice }]">
    <div v-if="isDao" class="box-articles">
      <div class="box-contents" @click="goDaoApplyPage">
        <div class="box-contents-notice">
          <NovaIcon class="icon" :icon="{ type: 'outline', name: 'speaker' }" />
          <p v-dompurify-html="t('dao.home.topNotice')" class="on" />
        </div>
        <NovaButtonIcon
          :icon="{ type: 'outline', name: 'chev-right' }"
          :size="16"
          class="btn-more"
        />
      </div>
    </div>
    <div v-if="isNotice && !isDao" class="box-articles">
      <div class="box-contents" @click="openNoticeDetailModal">
        <div class="box-contents-notice">
          <NovaIcon class="icon" :icon="{ type: 'outline', name: 'speaker' }" />
          <Swiper
            v-bind="swiperOptions"
            :pagination="{
              clickable: true,
              el: '.swiper-pagination',
              type: 'custom',
            }"
            :direction="'vertical'"
            @swiper="onSwiper"
            @slide-change-transition-end="onSlideChange"
          >
            <SwiperSlide
              v-for="notice in creatorStore.profile?.notices"
              :key="notice.crtrNttSn"
            >
              <p v-dompurify-html="notice.crtrNttSj" class="on" />
            </SwiperSlide>
          </Swiper>
        </div>
        <NovaButtonIcon
          :icon="{ type: 'outline', name: 'chev-right' }"
          :size="16"
          class="btn-more"
        />
      </div>
    </div>
    <div :class="['top-section', { off: !isOpen }]">
      <div :class="['dao-wrap', { on: isDao }]">
        <!-- DAO 전환 스위치 -->
        <div v-if="isDaoCreator" class="switch-container">
          <label class="switch btn-dao-mode-switch" @click="transitionDao">
            <input
              id="dao_mode"
              type="checkbox"
              :checked="isDao"
              name="dao_mode"
              value="1"
            />
            <label
              for="dao_mode"
              data-on="FAM"
              data-off="HOME"
              class="btn-dao-mode-switch-inner"
            />
          </label>
        </div>
        <!-- 프로필 박스 -->
        <div class="profile-wrap">
          <div v-if="isOpen" class="profile-area">
            <div class="profile-item-box">
              <div class="profile-left-box">
                <div class="profile-box">
                  <NovaBoxCreatorPortrait
                    v-if="creatorStore.profile"
                    :block-status="blockStatus"
                    :profile="creatorStore.profile"
                  />
                  <NovaCreatorGradeBadge
                    :creator-grade="creatorStore.profile!.crtrSpclGradCode"
                    :creator-organization="creatorStore.profile!.crtrOrgnztTyCode"
                    :size="'md'"
                    :tooltip-position="'top'"
                    class="creator-badge"
                  />
                </div>
              </div>
              <div class="profile-right-box">
                <div v-if="isDao" class="dao-name-box">
                  <div class="dao-name">
                    <p class="name">{{ daoStore.daoInfo?.daoNm }}</p>
                    <p class="dao-member">
                      <NovaIcon
                        :icon="{ type: 'fill', name: 'group' }"
                        :size="14"
                      />
                      <span class="num">{{
                        daoStore.daoInfo?.memberCount
                      }}</span>
                    </p>
                  </div>
                </div>

                <div v-else class="home-name-box">
                  <!-- 닉네임 -->
                  <div class="nickname-box">
                    <p class="nickname">
                      <span class="text">{{
                        creatorStore.profile?.userNcnm
                      }}</span>
                      <NovaIcon
                        class="btn-edit"
                        :icon="{ type: 'outline', name: 'chev-right' }"
                        :size="12"
                        @click="openIntroduceModal"
                      />
                    </p>
                  </div>
                  <ClientOnly>
                    <div v-if="!isShowBlockMessage" class="subscribe-box">
                      <NovaBoxUserUnitSummery
                        :user-sn="profileData?.userSn"
                        :followers="profile?.followerCount || 0"
                        :followings="profile?.followingCount || 0"
                        :coins="profileData?.coins"
                        :is-equal-user="isEqualUser"
                        :creator="profileData?.profile"
                      />
                    </div>
                  </ClientOnly>
                </div>
              </div>
              <!-- 홈 설정 버튼 -->
              <NovaBoxHomeAction
                v-if="homeActionParams"
                :source="homeActionParams"
                :is-equal-user="isEqualUser"
                class="home-action"
              />
              <!--DAO 홈 설정 버튼-->
              <NovaBoxDaoHomeAction
                v-if="daoHomeActionsCondition"
                :is-equal-user="isEqualUser"
                class="home-action-dao"
              />
            </div>
            <!-- 다오 소개 문구 -->
            <div v-if="isDao" class="dao-message">
              <span v-dompurify-html="daoStore.daoInfo?.daoDc"></span>
            </div>
            <!-- 한줄 소개 -->
            <div v-else class="content-box">
              <span>
                <span
                  v-dompurify-html="creatorProfileDescription"
                  class="text"
                />
              </span>
            </div>
            <!-- 팔로우 후원 버튼 -->
            <div v-if="!isDao" class="home-btn-box">
              <ClientOnly>
                <div v-if="!isShowBlockMessage">
                  <div
                    v-if="
                      userStore.user?.userSn !== creatorStore.profile?.userSn
                    "
                    class="btns-box"
                  >
                    <NovaButtonText
                      :on="creatorStore.profile?.followerYn === 'Y'"
                      :label="
                        t(
                          creatorStore.profile?.followerYn === 'N'
                            ? 'follow'
                            : 'following'
                        )
                      "
                      :responsive-size="{ desktop: 32, mobile: 32 }"
                      :loading="isLoading"
                      :class="
                        creatorStore.profile?.followerYn === 'Y'
                          ? 'btn-creator-unfollow'
                          : 'btn-creator-follow'
                      "
                      @click.stop="handleOnToggleFollow"
                    />
                    <!-- TODO: 앱 버전 릴리즈를 위해 기능 숨김 -->
                    <!--              <NovaButtonText
                  v-if="
                    !(
                      userStore.user &&
                      userStore.user.userSn === creatorStore.profile?.userSn
                    ) && !(appStore.isApp || appStore.envMode === 'production')
                  "
                  :label="
                    t(
                      `subscribe.actions.${
                        creatorStore.profile?.prmbrshSbscrbAt === 'Y'
                          ? 'supporting'
                          : 'support'
                      }`,
                      {
                        subscribeGrade: t('subscribe.subscribeGrade.novaPlus'),
                      }
                    )
                  "
                  :responsive-size="{ desktop: 40, mobile: 32 }"
                  :class="
                    creatorStore.profile?.prmbrshSbscrbAt === 'Y'
                      ? 'btn-creator-unfollow'
                      : 'btn-creator-follow'
                  "
                  @click.stop="handleOnSubscribe"
                />-->

                    <!-- TODO: 앱 버전 릴리즈를 위해 기능 숨김 -->
                    <!-- 노바+ 후원하기가 아닌 일반 후원하기 -->
                    <!-- 원래 조건: v-if="!appStore.isApp && appStore.envMode === 'production'" -->
                    <NovaButtonText
                      v-if="
                        !appStore.isApp &&
                        creatorStore.profile?.donationAvailableAt === 'Y'
                      "
                      :label="$t('donate')"
                      :responsive-size="{ desktop: 32, mobile: 32 }"
                      :class="'btn-creator-boost'"
                      @click.stop="handleOnDonate"
                    />
                  </div>
                </div>
              </ClientOnly>
            </div>
            <!-- DAO 가입 버튼 -->
            <div v-if="applyJoinDaoBtnVisible && isDao" class="dao-btn-box">
              <NovaButtonText
                :label="applyJoinDaoBtnLabel"
                :responsive-size="{ desktop: 32, mobile: 32 }"
                :class="'btn-dao-join'"
                :disabled="applyJoinDaoBtnDisable"
                @click.stop="applyJoinDao()"
              />
            </div>
          </div>
        </div>
        <!-- 사용자 차단 안내 -->
        <slot name="blockContents" />
      </div>

      <div v-if="isShowTabMenu && showInitTab" class="menu-box">
        <NovaTab
          :tab-value="isDao ? creatorDaoMyTaps : creatorMyTaps"
          :init-tab="initTab"
          :align="'center'"
          :horizon-padding="20"
          @on-change-tab="onChangeTab"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.creator-top {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 42px;
  &.on {
    padding-top: 16px;
  }
  @include mobile {
    gap: 8px;
    padding-top: 20px;
    &.on {
      padding-top: 8px;
    }
  }
}

.top-section {
  flex-shrink: 0;
  position: relative;
  border-radius: 16px;
  background-color: $color-white;

  :deep(.top-section-bg) {
    &.is-load-success {
      background: inherit;
    }
  }

  .top-section-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 245px;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(
      97.77deg,
      $color-secondary-blue-light-80 -5.67%,
      $color-highlight 126.11%
    );
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 245px;
      border-radius: 10px 10px 0 0;
    }

    &::after {
      background: hex-to-rgba($color-bg-custom-7, 0.3);
    }
  }
  .dao-wrap {
    padding: 20px 20px 0;
    border-radius: 10px 10px 0 0;
    @include mobile {
      padding: 12px 12px 0;
    }
    &.on {
      background-color: $color-blue-900;
      .profile-area {
        .dao-name-box {
          .dao-name {
            .name {
              color: $color-white;
            }
            .dao-member {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 5px;
              color: $color-white;
              .num {
                margin-top: -1px;
              }
            }
          }
        }
      }
      :deep(.btn-icon) {
        .icon-wrap {
          .icon {
            color: $color-white;
            &:hover {
              color: $color-white-90p;
            }
          }
        }
      }
      .profile-wrap {
        .profile-area {
          .home-action {
            color: $color-white;
          }
        }
      }
      :deep(.block-wrap) {
        color: #fff;
      }
      :deep(.btn-toggle) {
        .icon-wrap {
          .icon {
            color: $color-white;
            &:hover {
              color: $color-white-90p;
            }
          }
        }
      }
    }
  }
  .switch-container {
    display: block;
    text-align: center;
    padding-bottom: 22px;
    @include mobile {
      padding-bottom: 24px;
    }
    label {
      @include text-style($text-body-14-regular);
      color: $color-text-12;
    }
    .btn-dao-mode-switch {
      position: relative;
      display: inline-block;
      width: 100%;
      max-width: 312px;
      margin: 0 auto;
      & > label.btn-dao-mode-switch-inner {
        display: block;
        position: relative;
        width: 100%;
        height: 40px;
        background: $color-text-9;
        border-radius: 20px;
        overflow: hidden;
        transition: all 0.3s ease;
        &:before {
          display: flex;
          justify-content: center;
          align-items: center;
          content: attr(data-on);
          position: absolute;
          height: 34px;
          top: 3px;
          right: 4px;
          width: calc(56% - 8px);
          @include text-style($text-body-14-regular);
        }
        &:after {
          display: flex;
          justify-content: center;
          align-items: center;
          content: attr(data-off);
          width: calc(56% - 8px);
          height: 34px;
          background: #fff;
          border-radius: 18px;
          position: absolute;
          left: 4px;
          top: 3px;
          text-align: center;
          transition: all 0.3s ease;
          box-shadow: 0 3px 4px rgba(0, 0, 0, 0.08);
          @include text-style($text-body-14-bold);
          color: $color-black;
        }
      }
    }
    input[type='checkbox'] {
      cursor: pointer;
      width: 100%;
      height: 40px;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .btn-dao-mode-switch
    input[type='checkbox']:checked
    + label.btn-dao-mode-switch-inner {
    background-color: rgba(0, 0, 0, 0.5);
    color: $color-text-13;
    &:after {
      content: attr(data-on);
      left: calc(100% - 56% + 4px);
    }
    &:before {
      content: attr(data-off);
      color: $color-text-13;
      right: auto;
    }
  }

  &.off {
    &::before,
    &::after {
      height: 50px;
    }

    .profile-wrap {
      max-height: 100px;
    }

    .option-tool {
      justify-content: space-between;
    }
  }

  > .badge-live {
    position: absolute;
    top: calc(245px + 19px);
    right: 32px;
    z-index: 11;
    //z-index: 30;

    @include mobile {
      top: calc(245px + 10px);
      right: 18px;
    }
  }
}

.profile-wrap {
  position: relative;
  z-index: 10;
  @include transition(max-height 0.3s);
  boreder: 1px solid red;
}

.option-tool {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $color-white;
  height: 20px;

  .title {
    @include text-style($text-heading2-bold);
  }

  .option-button {
    display: flex;
    gap: 8px;
    position: relative;
  }

  .page-setting {
    bottom: auto;
    top: 100%;
    right: 0;
    transform: translateY(10px);
  }
}

.menu-box {
  border-top: 1px solid hex-to-rgba($color-black, 0.06);
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
}

.top-section {
  .profile-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 30px;
    color: $color-text-4;
    opacity: 1;
    @include transition(opacity 0.15s ease-in-out);
    .profile-item-box {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 312px;
      gap: 12px;
      padding-bottom: 12px;
      @include mobile {
        gap: 16px;
      }
      .profile-left-box {
        width: 86px;
      }
      .profile-right-box {
        width: calc(100% - 72px);
      }
    }
    .profile-box {
      position: relative;
      .btn-box,
      .btn-delete {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .btn-delete {
        color: $color-white;
        top: 0;
        bottom: auto;
      }

      .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;

        .input-file {
          width: 0;
          height: 0;
          visibility: hidden;
        }
      }

      .creator-badge {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .nickname-box {
      margin-top: 8px;
      width: calc(100% - 44px);
      @include mobile {
        width: calc(100% - 44px);
      }
      .nickname {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3px;
        .text {
          font-weight: 700;
          font-size: 16px;
          line-height: 1.2em;
          color: #111111;
          word-break: break-all;
          @include mobile {
            font-size: 16px;
          }
        }
        .btn-edit {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          cursor: pointer;
          color: #111111;
          font-weight: 700;
        }
      }
    }
    .dao-name {
      margin-top: 6px;
      width: calc(100% - 44px);
      display: flex;
      flex-direction: column;
      gap: 4px;
      .name {
        @include text-style($text-display-bold);
      }
      .dao-member {
        @include text-style($text-body-12-regular);
        color: $color-text-9;
      }
    }
    .dao-message {
      width: 100%;
      max-width: 312px;
      padding-top: 10px;
      @include text-style($text-display-regular);
      color: $color-text-9;
      span {
        @include ellipsis(2);
      }
    }
    .content-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      max-width: 312px;
      padding-top: 10px;
      text-align: left;
      @include mobile {
        width: 100%;
      }
      .text {
        @include text-style($text-display-regular);
        display: inline;
        color: $color-text-2;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }

      .btn-edit {
        margin-left: 8px;
      }
    }
    .edit-box {
      width: 100%;
      .action-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 10px;
      }
    }

    .subscribe-box {
      display: flex;
      flex-direction: column;
      margin: 6px 0 0 0;

      .subscribs {
        display: flex;
        align-items: center;
        gap: 24px;
        p {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          gap: 6px;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.3px;
            color: $color-99;
          }
          strong {
            display: block;
            position: relative;
            font-weight: bold;
            font-size: 24px;
            line-height: 100%;
            letter-spacing: -0.3px;
            color: #3f4354;
            &:before {
              display: block;
              content: '';
              width: 110%;
              min-width: 42px;
              height: 13px;
              background: #347ae2;
              opacity: 0.1;
              position: absolute;
              left: 50%;
              bottom: -5px;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  :deep(.portrait-container) {
    height: 72px;
    width: 72px;
  }
  .home-action {
    position: absolute;
    top: 6px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    z-index: 12;
    :deep(.btn-icon) {
      width: 24px;
      height: 24px;
    }
  }

  .home-action-dao {
    position: absolute;
    top: 6px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    z-index: 12;

    .icon-dao-toggle {
      width: 100%;
      height: 100%;
      background-image: url('@/assets/images/icon-dao-toggle.svg?url');
      background-repeat: no-repeat;
      background-size: 22px;
      background-position: center;

      @include mobile {
        background-position: center;
      }
    }
  }
}
.home-btn-box {
  width: 100%;
  max-width: 312px;
  margin: 0 auto;
  .btns-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 10px 0 0 0;
    .btn-creator-follow {
      width: 50%;
      padding: 0 20px;
      @include text-style($text-body-14-bold);
      background: $color-primary-blue-dark;
      color: $color-white;
      border-radius: 5px;
    }
    .btn-creator-unfollow {
      width: 50%;
      padding: 0 20px;
      @include text-style($text-body-14-bold);
      background: $color-bg-1;
      color: #3f4354;
      border-radius: 5px;
    }
    .btn-creator-boost {
      width: 50%;
      padding: 0 20px;
      @include text-style($text-body-14-bold);
      background: $color-gray-200;
      color: $color-black;
      border-radius: 5px;
    }
  }
}
.dao-btn-box {
  width: 100%;
  max-width: 312px;
  padding-top: 16px;
  .btn-dao-join {
    width: 50%;
    padding: 0 20px;
    @include text-style($text-body-14-bold);
    color: $color-black;
    background: $color-white;
  }
}

.page-setting {
  z-index: 100;
}
.top-section {
  :deep(.block-wrap) {
    padding-bottom: 20px;
    min-height: auto;
  }
}

@include mobile {
  .menu-box {
    :deep(.tabs .swiper-wrapper) {
      justify-content: flex-start !important;
    }
  }
}

.box-articles {
  width: 100%;
  position: relative;
  z-index: 2;
  @include mobile {
    padding: 0 8px;
  }
  .box-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 16px !important;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 #00000014;
    .box-contents-notice {
      display: flex;
      align-items: center;
      gap: 10px;
      :deep(.swiper) {
        height: 24px;
        .swiper-wrapper {
          .swiper-slide {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            p {
              @include text-style($text-body-14-medium);
            }
          }
        }
      }
    }
    .btn-more {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
