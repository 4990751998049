<script setup lang="ts">
import { NovaLayoutScrollContainer } from '#components'

defineOptions({
  inheritAttrs: false,
})

const { hide: modalHide } = useModal()
const { t } = useI18n()
const { gtEvent } = useGoogleTag()

const creatorRewardStatisticsRef = ref<InstanceType<
  typeof NovaLayoutScrollContainer
> | null>(null)
const show = ref(false)

/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '리워드 탭 통계 모달 닫기',
    eventLabel: t('mypage.reward.title.statistics'),
    eventSlot: '크리에이터 홈 > 리워드 > 리워드 통계 모달',
    eventI18nAddr: useKoreanTranslation('mypage.reward.title.statistics'),
    eventComponent: 'Button',
  })
  await modalHide(modalsName.MODAL_CREATOR_STATISTICS)
}

/**
 * 팝업 닫은 후 스토어에서 데이터 초기화
 */
const handleOnClosed = () => {
  console.log('closed')
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_CREATOR_STATISTICS"
    :content-class="'modal-content'"
    classes="modal-container"
    @closed="handleOnClosed"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-desktop">
            <h4 class="title">{{ t('mypage.reward.title.statistics') }}</h4>
            <div class="close-wrap" @click="handleOnClose">
              <NovaButtonIcon
                :icon="{ type: 'outline', name: 'close-extend' }"
                class="btn-close"
              />
            </div>
          </div>
        </div>

        <NovaLayoutScrollContainer
          ref="creatorRewardStatisticsRef"
          class="modal-body"
        >
          <NovaBoxCreatorStatisticsDetail />
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;
    background-color: $color-border-1;
    .modal-header {
      .on-desktop {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        flex-grow: 1;
        background-color: $color-white;
        border-radius: 20px 20px 0 0;
        @include text-style($text-display-medium);
        > .title {
          flex-grow: 1;
          color: $color-black;
        }
        .close-wrap {
          .btn-close {
            color: $color-text-2;
          }
        }
      }
    }

    .modal-body {
      display: flex;
      gap: 12px;
      padding: 20px 10px 32px 10px;
      border-radius: $radius-10;
      min-height: 396px;
      > .post {
        border-radius: 0;
        box-shadow: none;
      }

      .async-status-message {
        min-height: 200px;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
