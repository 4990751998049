import { default as applyf9RaWEMHvfMeta } from "/codebuild/output/src96923476/src/pages/apply-creator/apply.vue?macro=true";
import { default as indexoJgCPH7gqQMeta } from "/codebuild/output/src96923476/src/pages/apply-creator/index.vue?macro=true";
import { default as formpdbAp4INcOMeta } from "/codebuild/output/src96923476/src/pages/apply-dao/form.vue?macro=true";
import { default as indexL0LNYsRHpeMeta } from "/codebuild/output/src96923476/src/pages/apply-dao/index.vue?macro=true";
import { default as form6nZC22qFwCMeta } from "/codebuild/output/src96923476/src/pages/apply-kpop-cover-dance/form.vue?macro=true";
import { default as additionalA9c1q3K1P8Meta } from "/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/additional.vue?macro=true";
import { default as completeE8OTMruSQJMeta } from "/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/complete.vue?macro=true";
import { default as indexfviBRrHTKuMeta } from "/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/index.vue?macro=true";
import { default as indexFzSz51ZVivMeta } from "/codebuild/output/src96923476/src/pages/apply-rent/index.vue?macro=true";
import { default as form0KFSaxeaxgMeta } from "/codebuild/output/src96923476/src/pages/apply-super-moon/form.vue?macro=true";
import { default as indexzyWCHIr0VVMeta } from "/codebuild/output/src96923476/src/pages/apply-super-moon/index.vue?macro=true";
import { default as _91id_93PKaLXTCFA7Meta } from "/codebuild/output/src96923476/src/pages/board/event/[id].vue?macro=true";
import { default as indexC1qVXap85ZMeta } from "/codebuild/output/src96923476/src/pages/board/event/index.vue?macro=true";
import { default as _91id_93wCJc84pjoTMeta } from "/codebuild/output/src96923476/src/pages/board/faq/[id].vue?macro=true";
import { default as indexBnOHKFUstyMeta } from "/codebuild/output/src96923476/src/pages/board/faq/index.vue?macro=true";
import { default as _91id_93EMkdQm4EopMeta } from "/codebuild/output/src96923476/src/pages/board/news/[id].vue?macro=true";
import { default as indexXHo23IJSTxMeta } from "/codebuild/output/src96923476/src/pages/board/news/index.vue?macro=true";
import { default as _91id_93jcTElSnD1zMeta } from "/codebuild/output/src96923476/src/pages/board/notice/[id].vue?macro=true";
import { default as index3YdKmfUz0aMeta } from "/codebuild/output/src96923476/src/pages/board/notice/index.vue?macro=true";
import { default as payoLlelQoqWlMeta } from "/codebuild/output/src96923476/src/pages/callback/pay.vue?macro=true";
import { default as signinIyIBqVJnoTMeta } from "/codebuild/output/src96923476/src/pages/callback/signin.vue?macro=true";
import { default as signupMXFa1sRR3wMeta } from "/codebuild/output/src96923476/src/pages/callback/signup.vue?macro=true";
import { default as _91postId_93nyT8UMSmD8Meta } from "/codebuild/output/src96923476/src/pages/creator/[id]/activities/[postId].vue?macro=true";
import { default as activitiesCgqWVzJaGsMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/activities.vue?macro=true";
import { default as _91collectionId_93PShutfT3EGMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/collections/[collectionId].vue?macro=true";
import { default as collectionsaOa9zxpIagMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/collections.vue?macro=true";
import { default as _91daoPostId_9319W1y8hzrdMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/dao/board/[daoPostId].vue?macro=true";
import { default as indexbgMDPadkwzMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/dao/board/index.vue?macro=true";
import { default as indexCTuuWDOYO1Meta } from "/codebuild/output/src96923476/src/pages/creator/[id]/dao/index.vue?macro=true";
import { default as memberwSDPJ6gXSBMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/dao/member.vue?macro=true";
import { default as _91voteId_939LCEnZYr0fMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/dao/vote/[voteId].vue?macro=true";
import { default as voteo3eQGNUMSsMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/dao/vote.vue?macro=true";
import { default as donationAsxskx6krzMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/donation.vue?macro=true";
import { default as guestbookldDb5yewq9Meta } from "/codebuild/output/src96923476/src/pages/creator/[id]/guestbook.vue?macro=true";
import { default as _91postId_93rOWBLvtpcnMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/index/[postId].vue?macro=true";
import { default as indexvAOP3ZBhsvMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/index.vue?macro=true";
import { default as _91noticeId_93CM4MFagnP8Meta } from "/codebuild/output/src96923476/src/pages/creator/[id]/notice/[noticeId].vue?macro=true";
import { default as noticeg7nfopJIdLMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/notice.vue?macro=true";
import { default as _91postId_93l0HRXq7Y9LMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/nova-plus/[postId].vue?macro=true";
import { default as indexdbL2di9wqNMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/nova-plus/index.vue?macro=true";
import { default as _91postId_93NBraM0BpCmMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/posts/[postId].vue?macro=true";
import { default as indexEh16WbHmtqMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/posts/index.vue?macro=true";
import { default as rewardU4ozmCZukZMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/reward.vue?macro=true";
import { default as statisticsqMXkBIBrJwMeta } from "/codebuild/output/src96923476/src/pages/creator/[id]/statistics.vue?macro=true";
import { default as sponsors0SeefXTRrKMeta } from "/codebuild/output/src96923476/src/pages/creator/sponsors.vue?macro=true";
import { default as _91creatorUserSn_93Q6jZgqgf4IMeta } from "/codebuild/output/src96923476/src/pages/creator/subscribe/cancel/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93wiENJChGPFMeta } from "/codebuild/output/src96923476/src/pages/creator/subscribe/opened/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93KPmXLUrDbUMeta } from "/codebuild/output/src96923476/src/pages/creator/subscribe/refund/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93VlZ5vYJE8tMeta } from "/codebuild/output/src96923476/src/pages/creator/subscribe/support/[creatorUserSn].vue?macro=true";
import { default as allYXfu7kZR3ZMeta } from "/codebuild/output/src96923476/src/pages/creators/all.vue?macro=true";
import { default as mys6RvG2YrA7Meta } from "/codebuild/output/src96923476/src/pages/creators/my.vue?macro=true";
import { default as indexF4HeGVyAuMMeta } from "/codebuild/output/src96923476/src/pages/daily-check/index.vue?macro=true";
import { default as _91id_936DIG8mLVaRMeta } from "/codebuild/output/src96923476/src/pages/feed/[id].vue?macro=true";
import { default as indexVWnZcctOYhMeta } from "/codebuild/output/src96923476/src/pages/feed/index.vue?macro=true";
import { default as indexUNQU2LRuyQMeta } from "/codebuild/output/src96923476/src/pages/fund/gaudi-fund/index.vue?macro=true";
import { default as gaudigJlr7yEks3Meta } from "/codebuild/output/src96923476/src/pages/fund/gaudi.vue?macro=true";
import { default as _91id_93rv8NITHt48Meta } from "/codebuild/output/src96923476/src/pages/fund/history/[id].vue?macro=true";
import { default as historygWwHi9oY5sMeta } from "/codebuild/output/src96923476/src/pages/fund/history.vue?macro=true";
import { default as _91id_93gAA2o4oTPbMeta } from "/codebuild/output/src96923476/src/pages/fund/index/[id].vue?macro=true";
import { default as indexnZiYPaiXeRMeta } from "/codebuild/output/src96923476/src/pages/fund/index.vue?macro=true";
import { default as _91id_93t9na43qbOyMeta } from "/codebuild/output/src96923476/src/pages/fund/notice/[id].vue?macro=true";
import { default as indexRLvxpctWZ3Meta } from "/codebuild/output/src96923476/src/pages/fund/notice/index.vue?macro=true";
import { default as _91id_936ZM24YzD6VMeta } from "/codebuild/output/src96923476/src/pages/hashtag/[id].vue?macro=true";
import { default as indexegaaWRyesKMeta } from "/codebuild/output/src96923476/src/pages/hashtag/index.vue?macro=true";
import { default as indexxjw7675zhkMeta } from "/codebuild/output/src96923476/src/pages/index.vue?macro=true";
import { default as _91id_93U8Ku3vwRgAMeta } from "/codebuild/output/src96923476/src/pages/nova-plus/[id].vue?macro=true";
import { default as indexb8WCCPmeGQMeta } from "/codebuild/output/src96923476/src/pages/nova-plus/index.vue?macro=true";
import { default as indexAvvkRnWutgMeta } from "/codebuild/output/src96923476/src/pages/oulim/index.vue?macro=true";
import { default as _91id_93vcbGOG9xVoMeta } from "/codebuild/output/src96923476/src/pages/post/[id].vue?macro=true";
import { default as _64_91id_93wN8cE4uZ08Meta } from "/codebuild/output/src96923476/src/pages/referral/@[id].vue?macro=true";
import { default as _91id_93891AWN9gRTMeta } from "/codebuild/output/src96923476/src/pages/search/detail/comment/[id].vue?macro=true";
import { default as index57aJhlhGGzMeta } from "/codebuild/output/src96923476/src/pages/search/detail/comment/index.vue?macro=true";
import { default as hashtagMwlKjxiJQBMeta } from "/codebuild/output/src96923476/src/pages/search/detail/hashtag.vue?macro=true";
import { default as _91id_93Gp2zpUXSmbMeta } from "/codebuild/output/src96923476/src/pages/search/detail/index/[id].vue?macro=true";
import { default as indexmgd9RAFmmFMeta } from "/codebuild/output/src96923476/src/pages/search/detail/index/index.vue?macro=true";
import { default as _91id_93QsvVkCxgMwMeta } from "/codebuild/output/src96923476/src/pages/search/detail/nova-plus/[id].vue?macro=true";
import { default as indexAReWp9uIqrMeta } from "/codebuild/output/src96923476/src/pages/search/detail/nova-plus/index.vue?macro=true";
import { default as userRsQrHOz8euMeta } from "/codebuild/output/src96923476/src/pages/search/detail/user.vue?macro=true";
import { default as _91id_93fHIHHL16wgMeta } from "/codebuild/output/src96923476/src/pages/search/index/[id].vue?macro=true";
import { default as indexHNMSXY8E2kMeta } from "/codebuild/output/src96923476/src/pages/search/index.vue?macro=true";
import { default as _91id_93AJUXaFYVLMMeta } from "/codebuild/output/src96923476/src/pages/terms/[id].vue?macro=true";
import { default as _91postId_93qp0fkxXh3hMeta } from "/codebuild/output/src96923476/src/pages/user/[id]/activities/[postId].vue?macro=true";
import { default as activitiesi7R4THMQi0Meta } from "/codebuild/output/src96923476/src/pages/user/[id]/activities.vue?macro=true";
import { default as _91collectionId_93Qot6JE58laMeta } from "/codebuild/output/src96923476/src/pages/user/[id]/collections/[collectionId].vue?macro=true";
import { default as collectionsWIZDgR6aauMeta } from "/codebuild/output/src96923476/src/pages/user/[id]/collections.vue?macro=true";
import { default as donationlSs4HAcvitMeta } from "/codebuild/output/src96923476/src/pages/user/[id]/donation.vue?macro=true";
import { default as _91postId_93jtZaKnUyWtMeta } from "/codebuild/output/src96923476/src/pages/user/[id]/index/[postId].vue?macro=true";
import { default as indexP7tzcUEHybMeta } from "/codebuild/output/src96923476/src/pages/user/[id]/index/index.vue?macro=true";
import { default as rewardtWEPg2ojDEMeta } from "/codebuild/output/src96923476/src/pages/user/[id]/reward.vue?macro=true";
import { default as index5SnOIbsLqtMeta } from "/codebuild/output/src96923476/src/pages/user/sponsors/index.vue?macro=true";
import { default as _91id_935r6wWBxjmJMeta } from "/codebuild/output/src96923476/src/pages/w3cf/detail/[id].vue?macro=true";
import { default as indexfyoDS63UKiMeta } from "/codebuild/output/src96923476/src/pages/w3cf/index.vue?macro=true";
export default [
  {
    name: "apply-creator-apply___ko",
    path: "/apply-creator/apply",
    meta: applyf9RaWEMHvfMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator-apply___en",
    path: "/en/apply-creator/apply",
    meta: applyf9RaWEMHvfMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator-apply___th",
    path: "/th/apply-creator/apply",
    meta: applyf9RaWEMHvfMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-creator/apply.vue")
  },
  {
    name: "apply-creator___ko",
    path: "/apply-creator",
    meta: indexoJgCPH7gqQMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-creator___en",
    path: "/en/apply-creator",
    meta: indexoJgCPH7gqQMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-creator___th",
    path: "/th/apply-creator",
    meta: indexoJgCPH7gqQMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-creator/index.vue")
  },
  {
    name: "apply-dao-form___ko",
    path: "/apply-dao/form",
    meta: formpdbAp4INcOMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-dao/form.vue")
  },
  {
    name: "apply-dao-form___en",
    path: "/en/apply-dao/form",
    meta: formpdbAp4INcOMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-dao/form.vue")
  },
  {
    name: "apply-dao-form___th",
    path: "/th/apply-dao/form",
    meta: formpdbAp4INcOMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-dao/form.vue")
  },
  {
    name: "apply-dao___ko",
    path: "/apply-dao",
    meta: indexL0LNYsRHpeMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-dao/index.vue")
  },
  {
    name: "apply-dao___en",
    path: "/en/apply-dao",
    meta: indexL0LNYsRHpeMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-dao/index.vue")
  },
  {
    name: "apply-dao___th",
    path: "/th/apply-dao",
    meta: indexL0LNYsRHpeMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-dao/index.vue")
  },
  {
    name: "apply-kpop-cover-dance-form___ko",
    path: "/apply-kpop-cover-dance/form",
    meta: form6nZC22qFwCMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-kpop-cover-dance/form.vue")
  },
  {
    name: "apply-kpop-cover-dance-form___en",
    path: "/en/apply-kpop-cover-dance/form",
    meta: form6nZC22qFwCMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-kpop-cover-dance/form.vue")
  },
  {
    name: "apply-kpop-cover-dance-form___th",
    path: "/th/apply-kpop-cover-dance/form",
    meta: form6nZC22qFwCMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-kpop-cover-dance/form.vue")
  },
  {
    name: "apply-rent-facility-id-additional___ko",
    path: "/apply-rent/facility-:id()/additional",
    meta: additionalA9c1q3K1P8Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-additional___en",
    path: "/en/apply-rent/facility-:id()/additional",
    meta: additionalA9c1q3K1P8Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-additional___th",
    path: "/th/apply-rent/facility-:id()/additional",
    meta: additionalA9c1q3K1P8Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/additional.vue")
  },
  {
    name: "apply-rent-facility-id-complete___ko",
    path: "/apply-rent/facility-:id()/complete",
    meta: completeE8OTMruSQJMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id-complete___en",
    path: "/en/apply-rent/facility-:id()/complete",
    meta: completeE8OTMruSQJMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id-complete___th",
    path: "/th/apply-rent/facility-:id()/complete",
    meta: completeE8OTMruSQJMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/complete.vue")
  },
  {
    name: "apply-rent-facility-id___ko",
    path: "/apply-rent/facility-:id()",
    meta: indexfviBRrHTKuMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent-facility-id___en",
    path: "/en/apply-rent/facility-:id()",
    meta: indexfviBRrHTKuMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent-facility-id___th",
    path: "/th/apply-rent/facility-:id()",
    meta: indexfviBRrHTKuMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/facility-[id]/index.vue")
  },
  {
    name: "apply-rent___ko",
    path: "/apply-rent",
    meta: indexFzSz51ZVivMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-rent___en",
    path: "/en/apply-rent",
    meta: indexFzSz51ZVivMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-rent___th",
    path: "/th/apply-rent",
    meta: indexFzSz51ZVivMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-rent/index.vue")
  },
  {
    name: "apply-super-moon-form___ko",
    path: "/apply-super-moon/form",
    meta: form0KFSaxeaxgMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon-form___en",
    path: "/en/apply-super-moon/form",
    meta: form0KFSaxeaxgMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon-form___th",
    path: "/th/apply-super-moon/form",
    meta: form0KFSaxeaxgMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-super-moon/form.vue")
  },
  {
    name: "apply-super-moon___ko",
    path: "/apply-super-moon",
    meta: indexzyWCHIr0VVMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "apply-super-moon___en",
    path: "/en/apply-super-moon",
    meta: indexzyWCHIr0VVMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "apply-super-moon___th",
    path: "/th/apply-super-moon",
    meta: indexzyWCHIr0VVMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/apply-super-moon/index.vue")
  },
  {
    name: "board-event-id___ko",
    path: "/board/event/:id()",
    meta: _91id_93PKaLXTCFA7Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event-id___en",
    path: "/en/board/event/:id()",
    meta: _91id_93PKaLXTCFA7Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event-id___th",
    path: "/th/board/event/:id()",
    meta: _91id_93PKaLXTCFA7Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/event/[id].vue")
  },
  {
    name: "board-event___ko",
    path: "/board/event",
    meta: indexC1qVXap85ZMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/event/index.vue")
  },
  {
    name: "board-event___en",
    path: "/en/board/event",
    meta: indexC1qVXap85ZMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/event/index.vue")
  },
  {
    name: "board-event___th",
    path: "/th/board/event",
    meta: indexC1qVXap85ZMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/event/index.vue")
  },
  {
    name: "board-faq-id___ko",
    path: "/board/faq/:id()",
    meta: _91id_93wCJc84pjoTMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq-id___en",
    path: "/en/board/faq/:id()",
    meta: _91id_93wCJc84pjoTMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq-id___th",
    path: "/th/board/faq/:id()",
    meta: _91id_93wCJc84pjoTMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/faq/[id].vue")
  },
  {
    name: "board-faq___ko",
    path: "/board/faq",
    meta: indexBnOHKFUstyMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/faq/index.vue")
  },
  {
    name: "board-faq___en",
    path: "/en/board/faq",
    meta: indexBnOHKFUstyMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/faq/index.vue")
  },
  {
    name: "board-faq___th",
    path: "/th/board/faq",
    meta: indexBnOHKFUstyMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/faq/index.vue")
  },
  {
    name: "board-news-id___ko",
    path: "/board/news/:id()",
    meta: _91id_93EMkdQm4EopMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news-id___en",
    path: "/en/board/news/:id()",
    meta: _91id_93EMkdQm4EopMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news-id___th",
    path: "/th/board/news/:id()",
    meta: _91id_93EMkdQm4EopMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/news/[id].vue")
  },
  {
    name: "board-news___ko",
    path: "/board/news",
    meta: indexXHo23IJSTxMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/news/index.vue")
  },
  {
    name: "board-news___en",
    path: "/en/board/news",
    meta: indexXHo23IJSTxMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/news/index.vue")
  },
  {
    name: "board-news___th",
    path: "/th/board/news",
    meta: indexXHo23IJSTxMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/news/index.vue")
  },
  {
    name: "board-notice-id___ko",
    path: "/board/notice/:id()",
    meta: _91id_93jcTElSnD1zMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice-id___en",
    path: "/en/board/notice/:id()",
    meta: _91id_93jcTElSnD1zMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice-id___th",
    path: "/th/board/notice/:id()",
    meta: _91id_93jcTElSnD1zMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/notice/[id].vue")
  },
  {
    name: "board-notice___ko",
    path: "/board/notice",
    meta: index3YdKmfUz0aMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/notice/index.vue")
  },
  {
    name: "board-notice___en",
    path: "/en/board/notice",
    meta: index3YdKmfUz0aMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/notice/index.vue")
  },
  {
    name: "board-notice___th",
    path: "/th/board/notice",
    meta: index3YdKmfUz0aMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/board/notice/index.vue")
  },
  {
    name: "callback-pay___ko",
    path: "/callback/pay",
    meta: payoLlelQoqWlMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/callback/pay.vue")
  },
  {
    name: "callback-pay___en",
    path: "/en/callback/pay",
    meta: payoLlelQoqWlMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/callback/pay.vue")
  },
  {
    name: "callback-pay___th",
    path: "/th/callback/pay",
    meta: payoLlelQoqWlMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/callback/pay.vue")
  },
  {
    name: "callback-signin___ko",
    path: "/callback/signin",
    meta: signinIyIBqVJnoTMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signin___en",
    path: "/en/callback/signin",
    meta: signinIyIBqVJnoTMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signin___th",
    path: "/th/callback/signin",
    meta: signinIyIBqVJnoTMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/callback/signin.vue")
  },
  {
    name: "callback-signup___ko",
    path: "/callback/signup",
    meta: signupMXFa1sRR3wMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/callback/signup.vue")
  },
  {
    name: "callback-signup___en",
    path: "/en/callback/signup",
    meta: signupMXFa1sRR3wMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/callback/signup.vue")
  },
  {
    name: "callback-signup___th",
    path: "/th/callback/signup",
    meta: signupMXFa1sRR3wMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/callback/signup.vue")
  },
  {
    name: "creator-id-activities___ko",
    path: "/creator/:id()/activities",
    meta: activitiesCgqWVzJaGsMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93nyT8UMSmD8Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-activities___en",
    path: "/en/creator/:id()/activities",
    meta: activitiesCgqWVzJaGsMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93nyT8UMSmD8Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-activities___th",
    path: "/th/creator/:id()/activities",
    meta: activitiesCgqWVzJaGsMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/activities.vue"),
    children: [
  {
    name: "creator-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93nyT8UMSmD8Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "creator-id-collections___ko",
    path: "/creator/:id()/collections",
    meta: collectionsaOa9zxpIagMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93PShutfT3EGMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-collections___en",
    path: "/en/creator/:id()/collections",
    meta: collectionsaOa9zxpIagMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93PShutfT3EGMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-collections___th",
    path: "/th/creator/:id()/collections",
    meta: collectionsaOa9zxpIagMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/collections.vue"),
    children: [
  {
    name: "creator-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93PShutfT3EGMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "creator-id-dao-board-daoPostId___ko",
    path: "/creator/:id()/dao/board/:daoPostId()",
    meta: _91daoPostId_9319W1y8hzrdMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/board/[daoPostId].vue")
  },
  {
    name: "creator-id-dao-board-daoPostId___en",
    path: "/en/creator/:id()/dao/board/:daoPostId()",
    meta: _91daoPostId_9319W1y8hzrdMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/board/[daoPostId].vue")
  },
  {
    name: "creator-id-dao-board-daoPostId___th",
    path: "/th/creator/:id()/dao/board/:daoPostId()",
    meta: _91daoPostId_9319W1y8hzrdMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/board/[daoPostId].vue")
  },
  {
    name: "creator-id-dao-board___ko",
    path: "/creator/:id()/dao/board",
    meta: indexbgMDPadkwzMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/board/index.vue")
  },
  {
    name: "creator-id-dao-board___en",
    path: "/en/creator/:id()/dao/board",
    meta: indexbgMDPadkwzMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/board/index.vue")
  },
  {
    name: "creator-id-dao-board___th",
    path: "/th/creator/:id()/dao/board",
    meta: indexbgMDPadkwzMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/board/index.vue")
  },
  {
    name: "creator-id-dao___ko",
    path: "/creator/:id()/dao",
    meta: indexCTuuWDOYO1Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/index.vue")
  },
  {
    name: "creator-id-dao___en",
    path: "/en/creator/:id()/dao",
    meta: indexCTuuWDOYO1Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/index.vue")
  },
  {
    name: "creator-id-dao___th",
    path: "/th/creator/:id()/dao",
    meta: indexCTuuWDOYO1Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/index.vue")
  },
  {
    name: "creator-id-dao-member___ko",
    path: "/creator/:id()/dao/member",
    meta: memberwSDPJ6gXSBMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/member.vue")
  },
  {
    name: "creator-id-dao-member___en",
    path: "/en/creator/:id()/dao/member",
    meta: memberwSDPJ6gXSBMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/member.vue")
  },
  {
    name: "creator-id-dao-member___th",
    path: "/th/creator/:id()/dao/member",
    meta: memberwSDPJ6gXSBMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/member.vue")
  },
  {
    name: "creator-id-dao-vote___ko",
    path: "/creator/:id()/dao/vote",
    meta: voteo3eQGNUMSsMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/vote.vue"),
    children: [
  {
    name: "creator-id-dao-vote-voteId___ko",
    path: ":voteId()",
    meta: _91voteId_939LCEnZYr0fMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/vote/[voteId].vue")
  }
]
  },
  {
    name: "creator-id-dao-vote___en",
    path: "/en/creator/:id()/dao/vote",
    meta: voteo3eQGNUMSsMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/vote.vue"),
    children: [
  {
    name: "creator-id-dao-vote-voteId___en",
    path: ":voteId()",
    meta: _91voteId_939LCEnZYr0fMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/vote/[voteId].vue")
  }
]
  },
  {
    name: "creator-id-dao-vote___th",
    path: "/th/creator/:id()/dao/vote",
    meta: voteo3eQGNUMSsMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/vote.vue"),
    children: [
  {
    name: "creator-id-dao-vote-voteId___th",
    path: ":voteId()",
    meta: _91voteId_939LCEnZYr0fMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/dao/vote/[voteId].vue")
  }
]
  },
  {
    name: "creator-id-donation___ko",
    path: "/creator/:id()/donation",
    meta: donationAsxskx6krzMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-donation___en",
    path: "/en/creator/:id()/donation",
    meta: donationAsxskx6krzMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-donation___th",
    path: "/th/creator/:id()/donation",
    meta: donationAsxskx6krzMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/donation.vue")
  },
  {
    name: "creator-id-guestbook___ko",
    path: "/creator/:id()/guestbook",
    meta: guestbookldDb5yewq9Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id-guestbook___en",
    path: "/en/creator/:id()/guestbook",
    meta: guestbookldDb5yewq9Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id-guestbook___th",
    path: "/th/creator/:id()/guestbook",
    meta: guestbookldDb5yewq9Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/guestbook.vue")
  },
  {
    name: "creator-id___ko",
    path: "/creator/:id()",
    meta: indexvAOP3ZBhsvMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___ko",
    path: ":postId()",
    meta: _91postId_93rOWBLvtpcnMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id___en",
    path: "/en/creator/:id()",
    meta: indexvAOP3ZBhsvMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___en",
    path: ":postId()",
    meta: _91postId_93rOWBLvtpcnMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id___th",
    path: "/th/creator/:id()",
    meta: indexvAOP3ZBhsvMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/index.vue"),
    children: [
  {
    name: "creator-id-index-postId___th",
    path: ":postId()",
    meta: _91postId_93rOWBLvtpcnMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/index/[postId].vue")
  }
]
  },
  {
    name: "creator-id-notice___ko",
    path: "/creator/:id()/notice",
    meta: noticeg7nfopJIdLMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___ko",
    path: ":noticeId()",
    meta: _91noticeId_93CM4MFagnP8Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-notice___en",
    path: "/en/creator/:id()/notice",
    meta: noticeg7nfopJIdLMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___en",
    path: ":noticeId()",
    meta: _91noticeId_93CM4MFagnP8Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-notice___th",
    path: "/th/creator/:id()/notice",
    meta: noticeg7nfopJIdLMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/notice.vue"),
    children: [
  {
    name: "creator-id-notice-noticeId___th",
    path: ":noticeId()",
    meta: _91noticeId_93CM4MFagnP8Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/notice/[noticeId].vue")
  }
]
  },
  {
    name: "creator-id-nova-plus-postId___ko",
    path: "/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93l0HRXq7Y9LMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus-postId___en",
    path: "/en/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93l0HRXq7Y9LMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus-postId___th",
    path: "/th/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93l0HRXq7Y9LMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/nova-plus/[postId].vue")
  },
  {
    name: "creator-id-nova-plus___ko",
    path: "/creator/:id()/nova-plus",
    meta: indexdbL2di9wqNMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-nova-plus___en",
    path: "/en/creator/:id()/nova-plus",
    meta: indexdbL2di9wqNMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-nova-plus___th",
    path: "/th/creator/:id()/nova-plus",
    meta: indexdbL2di9wqNMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/nova-plus/index.vue")
  },
  {
    name: "creator-id-posts-postId___ko",
    path: "/creator/:id()/posts/:postId()",
    meta: _91postId_93NBraM0BpCmMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts-postId___en",
    path: "/en/creator/:id()/posts/:postId()",
    meta: _91postId_93NBraM0BpCmMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts-postId___th",
    path: "/th/creator/:id()/posts/:postId()",
    meta: _91postId_93NBraM0BpCmMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/posts/[postId].vue")
  },
  {
    name: "creator-id-posts___ko",
    path: "/creator/:id()/posts",
    meta: indexEh16WbHmtqMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-posts___en",
    path: "/en/creator/:id()/posts",
    meta: indexEh16WbHmtqMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-posts___th",
    path: "/th/creator/:id()/posts",
    meta: indexEh16WbHmtqMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/posts/index.vue")
  },
  {
    name: "creator-id-reward___ko",
    path: "/creator/:id()/reward",
    meta: rewardU4ozmCZukZMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-reward___en",
    path: "/en/creator/:id()/reward",
    meta: rewardU4ozmCZukZMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-reward___th",
    path: "/th/creator/:id()/reward",
    meta: rewardU4ozmCZukZMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/reward.vue")
  },
  {
    name: "creator-id-statistics___ko",
    path: "/creator/:id()/statistics",
    meta: statisticsqMXkBIBrJwMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-id-statistics___en",
    path: "/en/creator/:id()/statistics",
    meta: statisticsqMXkBIBrJwMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-id-statistics___th",
    path: "/th/creator/:id()/statistics",
    meta: statisticsqMXkBIBrJwMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/[id]/statistics.vue")
  },
  {
    name: "creator-sponsors___ko",
    path: "/creator/sponsors",
    meta: sponsors0SeefXTRrKMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-sponsors___en",
    path: "/en/creator/sponsors",
    meta: sponsors0SeefXTRrKMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-sponsors___th",
    path: "/th/creator/sponsors",
    meta: sponsors0SeefXTRrKMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/sponsors.vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___ko",
    path: "/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93Q6jZgqgf4IMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___en",
    path: "/en/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93Q6jZgqgf4IMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___th",
    path: "/th/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_93Q6jZgqgf4IMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/cancel/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___ko",
    path: "/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93wiENJChGPFMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___en",
    path: "/en/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93wiENJChGPFMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___th",
    path: "/th/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93wiENJChGPFMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/opened/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___ko",
    path: "/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93KPmXLUrDbUMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___en",
    path: "/en/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93KPmXLUrDbUMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___th",
    path: "/th/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93KPmXLUrDbUMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/refund/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___ko",
    path: "/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93VlZ5vYJE8tMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___en",
    path: "/en/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93VlZ5vYJE8tMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creator-subscribe-support-creatorUserSn___th",
    path: "/th/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93VlZ5vYJE8tMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creator/subscribe/support/[creatorUserSn].vue")
  },
  {
    name: "creators-all___ko",
    path: "/creators/all",
    meta: allYXfu7kZR3ZMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creators/all.vue")
  },
  {
    name: "creators-all___en",
    path: "/en/creators/all",
    meta: allYXfu7kZR3ZMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creators/all.vue")
  },
  {
    name: "creators-all___th",
    path: "/th/creators/all",
    meta: allYXfu7kZR3ZMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creators/all.vue")
  },
  {
    name: "creators-my___ko",
    path: "/creators/my",
    meta: mys6RvG2YrA7Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creators/my.vue")
  },
  {
    name: "creators-my___en",
    path: "/en/creators/my",
    meta: mys6RvG2YrA7Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creators/my.vue")
  },
  {
    name: "creators-my___th",
    path: "/th/creators/my",
    meta: mys6RvG2YrA7Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/creators/my.vue")
  },
  {
    name: "daily-check___ko",
    path: "/daily-check",
    meta: indexF4HeGVyAuMMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/daily-check/index.vue")
  },
  {
    name: "daily-check___en",
    path: "/en/daily-check",
    meta: indexF4HeGVyAuMMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/daily-check/index.vue")
  },
  {
    name: "daily-check___th",
    path: "/th/daily-check",
    meta: indexF4HeGVyAuMMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/daily-check/index.vue")
  },
  {
    name: "feed-id___ko",
    path: "/feed/:id()",
    component: () => import("/codebuild/output/src96923476/src/pages/feed/[id].vue")
  },
  {
    name: "feed-id___en",
    path: "/en/feed/:id()",
    component: () => import("/codebuild/output/src96923476/src/pages/feed/[id].vue")
  },
  {
    name: "feed-id___th",
    path: "/th/feed/:id()",
    component: () => import("/codebuild/output/src96923476/src/pages/feed/[id].vue")
  },
  {
    name: "feed___ko",
    path: "/feed",
    component: () => import("/codebuild/output/src96923476/src/pages/feed/index.vue")
  },
  {
    name: "feed___en",
    path: "/en/feed",
    component: () => import("/codebuild/output/src96923476/src/pages/feed/index.vue")
  },
  {
    name: "feed___th",
    path: "/th/feed",
    component: () => import("/codebuild/output/src96923476/src/pages/feed/index.vue")
  },
  {
    name: "fund-gaudi-fund___ko",
    path: "/fund/gaudi-fund",
    meta: indexUNQU2LRuyQMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi-fund___en",
    path: "/en/fund/gaudi-fund",
    meta: indexUNQU2LRuyQMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi-fund___th",
    path: "/th/fund/gaudi-fund",
    meta: indexUNQU2LRuyQMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/gaudi-fund/index.vue")
  },
  {
    name: "fund-gaudi___ko",
    path: "/fund/gaudi",
    meta: gaudigJlr7yEks3Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-gaudi___en",
    path: "/en/fund/gaudi",
    meta: gaudigJlr7yEks3Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-gaudi___th",
    path: "/th/fund/gaudi",
    meta: gaudigJlr7yEks3Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/gaudi.vue")
  },
  {
    name: "fund-history___ko",
    path: "/fund/history",
    meta: historygWwHi9oY5sMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src96923476/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund-history___en",
    path: "/en/fund/history",
    meta: historygWwHi9oY5sMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src96923476/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund-history___th",
    path: "/th/fund/history",
    meta: historygWwHi9oY5sMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/history.vue"),
    children: [
  {
    name: "fund-history-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src96923476/src/pages/fund/history/[id].vue")
  }
]
  },
  {
    name: "fund___ko",
    path: "/fund",
    meta: indexnZiYPaiXeRMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src96923476/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund___en",
    path: "/en/fund",
    meta: indexnZiYPaiXeRMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src96923476/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund___th",
    path: "/th/fund",
    meta: indexnZiYPaiXeRMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/index.vue"),
    children: [
  {
    name: "fund-index-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src96923476/src/pages/fund/index/[id].vue")
  }
]
  },
  {
    name: "fund-notice-id___ko",
    path: "/fund/notice/:id()",
    meta: _91id_93t9na43qbOyMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice-id___en",
    path: "/en/fund/notice/:id()",
    meta: _91id_93t9na43qbOyMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice-id___th",
    path: "/th/fund/notice/:id()",
    meta: _91id_93t9na43qbOyMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/notice/[id].vue")
  },
  {
    name: "fund-notice___ko",
    path: "/fund/notice",
    meta: indexRLvxpctWZ3Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/notice/index.vue")
  },
  {
    name: "fund-notice___en",
    path: "/en/fund/notice",
    meta: indexRLvxpctWZ3Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/notice/index.vue")
  },
  {
    name: "fund-notice___th",
    path: "/th/fund/notice",
    meta: indexRLvxpctWZ3Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/fund/notice/index.vue")
  },
  {
    name: "hashtag-id___ko",
    path: "/hashtag/:id()",
    meta: _91id_936ZM24YzD6VMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag-id___en",
    path: "/en/hashtag/:id()",
    meta: _91id_936ZM24YzD6VMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag-id___th",
    path: "/th/hashtag/:id()",
    meta: _91id_936ZM24YzD6VMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/hashtag/[id].vue")
  },
  {
    name: "hashtag___ko",
    path: "/hashtag",
    meta: indexegaaWRyesKMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/hashtag/index.vue")
  },
  {
    name: "hashtag___en",
    path: "/en/hashtag",
    meta: indexegaaWRyesKMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/hashtag/index.vue")
  },
  {
    name: "hashtag___th",
    path: "/th/hashtag",
    meta: indexegaaWRyesKMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/hashtag/index.vue")
  },
  {
    name: "index___ko",
    path: "/",
    meta: indexxjw7675zhkMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexxjw7675zhkMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/index.vue")
  },
  {
    name: "index___th",
    path: "/th",
    meta: indexxjw7675zhkMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/index.vue")
  },
  {
    name: "nova-plus-id___ko",
    path: "/nova-plus/:id()",
    meta: _91id_93U8Ku3vwRgAMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus-id___en",
    path: "/en/nova-plus/:id()",
    meta: _91id_93U8Ku3vwRgAMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus-id___th",
    path: "/th/nova-plus/:id()",
    meta: _91id_93U8Ku3vwRgAMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/nova-plus/[id].vue")
  },
  {
    name: "nova-plus___ko",
    path: "/nova-plus",
    meta: indexb8WCCPmeGQMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/nova-plus/index.vue")
  },
  {
    name: "nova-plus___en",
    path: "/en/nova-plus",
    meta: indexb8WCCPmeGQMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/nova-plus/index.vue")
  },
  {
    name: "nova-plus___th",
    path: "/th/nova-plus",
    meta: indexb8WCCPmeGQMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/nova-plus/index.vue")
  },
  {
    name: "oulim___ko",
    path: "/oulim",
    meta: indexAvvkRnWutgMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/oulim/index.vue")
  },
  {
    name: "oulim___en",
    path: "/en/oulim",
    meta: indexAvvkRnWutgMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/oulim/index.vue")
  },
  {
    name: "oulim___th",
    path: "/th/oulim",
    meta: indexAvvkRnWutgMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/oulim/index.vue")
  },
  {
    name: "post-id___ko",
    path: "/post/:id()",
    meta: _91id_93vcbGOG9xVoMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/post/[id].vue")
  },
  {
    name: "post-id___en",
    path: "/en/post/:id()",
    meta: _91id_93vcbGOG9xVoMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/post/[id].vue")
  },
  {
    name: "post-id___th",
    path: "/th/post/:id()",
    meta: _91id_93vcbGOG9xVoMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/post/[id].vue")
  },
  {
    name: "referral-@id___ko",
    path: "/referral/@:id()",
    meta: _64_91id_93wN8cE4uZ08Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/referral/@[id].vue")
  },
  {
    name: "referral-@id___en",
    path: "/en/referral/@:id()",
    meta: _64_91id_93wN8cE4uZ08Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/referral/@[id].vue")
  },
  {
    name: "referral-@id___th",
    path: "/th/referral/@:id()",
    meta: _64_91id_93wN8cE4uZ08Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/referral/@[id].vue")
  },
  {
    name: "search-detail-comment-id___ko",
    path: "/search/detail/comment/:id()",
    meta: _91id_93891AWN9gRTMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment-id___en",
    path: "/en/search/detail/comment/:id()",
    meta: _91id_93891AWN9gRTMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment-id___th",
    path: "/th/search/detail/comment/:id()",
    meta: _91id_93891AWN9gRTMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/comment/[id].vue")
  },
  {
    name: "search-detail-comment___ko",
    path: "/search/detail/comment",
    meta: index57aJhlhGGzMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-comment___en",
    path: "/en/search/detail/comment",
    meta: index57aJhlhGGzMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-comment___th",
    path: "/th/search/detail/comment",
    meta: index57aJhlhGGzMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/comment/index.vue")
  },
  {
    name: "search-detail-hashtag___ko",
    path: "/search/detail/hashtag",
    meta: hashtagMwlKjxiJQBMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-hashtag___en",
    path: "/en/search/detail/hashtag",
    meta: hashtagMwlKjxiJQBMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-hashtag___th",
    path: "/th/search/detail/hashtag",
    meta: hashtagMwlKjxiJQBMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/hashtag.vue")
  },
  {
    name: "search-detail-index-id___ko",
    path: "/search/detail/:id()",
    meta: _91id_93Gp2zpUXSmbMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index-id___en",
    path: "/en/search/detail/:id()",
    meta: _91id_93Gp2zpUXSmbMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index-id___th",
    path: "/th/search/detail/:id()",
    meta: _91id_93Gp2zpUXSmbMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/index/[id].vue")
  },
  {
    name: "search-detail-index___ko",
    path: "/search/detail",
    meta: indexmgd9RAFmmFMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-index___en",
    path: "/en/search/detail",
    meta: indexmgd9RAFmmFMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-index___th",
    path: "/th/search/detail",
    meta: indexmgd9RAFmmFMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/index/index.vue")
  },
  {
    name: "search-detail-nova-plus-id___ko",
    path: "/search/detail/nova-plus/:id()",
    meta: _91id_93QsvVkCxgMwMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus-id___en",
    path: "/en/search/detail/nova-plus/:id()",
    meta: _91id_93QsvVkCxgMwMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus-id___th",
    path: "/th/search/detail/nova-plus/:id()",
    meta: _91id_93QsvVkCxgMwMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/nova-plus/[id].vue")
  },
  {
    name: "search-detail-nova-plus___ko",
    path: "/search/detail/nova-plus",
    meta: indexAReWp9uIqrMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-nova-plus___en",
    path: "/en/search/detail/nova-plus",
    meta: indexAReWp9uIqrMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-nova-plus___th",
    path: "/th/search/detail/nova-plus",
    meta: indexAReWp9uIqrMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/nova-plus/index.vue")
  },
  {
    name: "search-detail-user___ko",
    path: "/search/detail/user",
    meta: userRsQrHOz8euMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/user.vue")
  },
  {
    name: "search-detail-user___en",
    path: "/en/search/detail/user",
    meta: userRsQrHOz8euMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/user.vue")
  },
  {
    name: "search-detail-user___th",
    path: "/th/search/detail/user",
    meta: userRsQrHOz8euMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/detail/user.vue")
  },
  {
    name: "search___ko",
    path: "/search",
    meta: indexHNMSXY8E2kMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___ko",
    path: ":id()",
    meta: _91id_93fHIHHL16wgMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: indexHNMSXY8E2kMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___en",
    path: ":id()",
    meta: _91id_93fHIHHL16wgMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "search___th",
    path: "/th/search",
    meta: indexHNMSXY8E2kMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/index.vue"),
    children: [
  {
    name: "search-index-id___th",
    path: ":id()",
    meta: _91id_93fHIHHL16wgMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/search/index/[id].vue")
  }
]
  },
  {
    name: "terms-id___ko",
    path: "/terms/:id()",
    meta: _91id_93AJUXaFYVLMMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/terms/[id].vue")
  },
  {
    name: "terms-id___en",
    path: "/en/terms/:id()",
    meta: _91id_93AJUXaFYVLMMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/terms/[id].vue")
  },
  {
    name: "terms-id___th",
    path: "/th/terms/:id()",
    meta: _91id_93AJUXaFYVLMMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/terms/[id].vue")
  },
  {
    name: "user-id-activities___ko",
    path: "/user/:id()/activities",
    meta: activitiesi7R4THMQi0Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93qp0fkxXh3hMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-activities___en",
    path: "/en/user/:id()/activities",
    meta: activitiesi7R4THMQi0Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93qp0fkxXh3hMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-activities___th",
    path: "/th/user/:id()/activities",
    meta: activitiesi7R4THMQi0Meta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/activities.vue"),
    children: [
  {
    name: "user-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93qp0fkxXh3hMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/activities/[postId].vue")
  }
]
  },
  {
    name: "user-id-collections___ko",
    path: "/user/:id()/collections",
    meta: collectionsWIZDgR6aauMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93Qot6JE58laMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-collections___en",
    path: "/en/user/:id()/collections",
    meta: collectionsWIZDgR6aauMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93Qot6JE58laMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-collections___th",
    path: "/th/user/:id()/collections",
    meta: collectionsWIZDgR6aauMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/collections.vue"),
    children: [
  {
    name: "user-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93Qot6JE58laMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/collections/[collectionId].vue")
  }
]
  },
  {
    name: "user-id-donation___ko",
    path: "/user/:id()/donation",
    meta: donationlSs4HAcvitMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-donation___en",
    path: "/en/user/:id()/donation",
    meta: donationlSs4HAcvitMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-donation___th",
    path: "/th/user/:id()/donation",
    meta: donationlSs4HAcvitMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/donation.vue")
  },
  {
    name: "user-id-index-postId___ko",
    path: "/user/:id()/:postId()",
    meta: _91postId_93jtZaKnUyWtMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index-postId___en",
    path: "/en/user/:id()/:postId()",
    meta: _91postId_93jtZaKnUyWtMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index-postId___th",
    path: "/th/user/:id()/:postId()",
    meta: _91postId_93jtZaKnUyWtMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/index/[postId].vue")
  },
  {
    name: "user-id-index___ko",
    path: "/user/:id()",
    meta: indexP7tzcUEHybMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-index___en",
    path: "/en/user/:id()",
    meta: indexP7tzcUEHybMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-index___th",
    path: "/th/user/:id()",
    meta: indexP7tzcUEHybMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/index/index.vue")
  },
  {
    name: "user-id-reward___ko",
    path: "/user/:id()/reward",
    meta: rewardtWEPg2ojDEMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-id-reward___en",
    path: "/en/user/:id()/reward",
    meta: rewardtWEPg2ojDEMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-id-reward___th",
    path: "/th/user/:id()/reward",
    meta: rewardtWEPg2ojDEMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/[id]/reward.vue")
  },
  {
    name: "user-sponsors___ko",
    path: "/user/sponsors",
    meta: index5SnOIbsLqtMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/sponsors/index.vue")
  },
  {
    name: "user-sponsors___en",
    path: "/en/user/sponsors",
    meta: index5SnOIbsLqtMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/sponsors/index.vue")
  },
  {
    name: "user-sponsors___th",
    path: "/th/user/sponsors",
    meta: index5SnOIbsLqtMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/user/sponsors/index.vue")
  },
  {
    name: "w3cf-detail-id___ko",
    path: "/w3cf/detail/:id()",
    meta: _91id_935r6wWBxjmJMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf-detail-id___en",
    path: "/en/w3cf/detail/:id()",
    meta: _91id_935r6wWBxjmJMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf-detail-id___th",
    path: "/th/w3cf/detail/:id()",
    meta: _91id_935r6wWBxjmJMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/w3cf/detail/[id].vue")
  },
  {
    name: "w3cf___ko",
    path: "/w3cf",
    meta: indexfyoDS63UKiMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/w3cf/index.vue")
  },
  {
    name: "w3cf___en",
    path: "/en/w3cf",
    meta: indexfyoDS63UKiMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/w3cf/index.vue")
  },
  {
    name: "w3cf___th",
    path: "/th/w3cf",
    meta: indexfyoDS63UKiMeta || {},
    component: () => import("/codebuild/output/src96923476/src/pages/w3cf/index.vue")
  }
]