<script setup lang="ts">
import type { NovaBoxPremiumBannerItemProps } from './NovaBoxPremiumBannerItem.types'

const props = defineProps<NovaBoxPremiumBannerItemProps>()

const handleOnGoLink = () => {
  if (props.source.bannerLinkAt === 'Y')
    useNavigations({
      external: props.source.bannerExtrlLinkAt === 'Y',
      url: props.source.bannerLinkUrl,
    })
}
</script>

<template>
  <button type="button" class="premium-banner-item" @click="handleOnGoLink">
    <NovaImageContainer
      :ratio="'1:1'"
      :image-url="source.bannerMobileImageUrl"
      class="mobile-img"
    />

    <NovaImageContainer
      :ratio="'1:1'"
      :image-url="source.bannerImageUrl"
      class="desktop-img"
    />

    <div class="banner-cont">
      <div class="banner-title">{{ source.bannerSj }}</div>
    </div>
  </button>
</template>

<style lang="scss" scoped>
.premium-banner-item {
  width: 100%;
  border-radius: $radius-10;
  overflow: hidden;

  .mobile-img {
    display: none;
    @include mobile {
      display: flex;
    }
  }
  .desktop-img {
    display: flex;

    @include mobile {
      display: none;
    }
  }
  .banner-cont {
    width: 100%;
    padding: 15px 20px;
    font-weight: 500;
    background: $color-bg-3;
    z-index: 2;
    @include mobile {
      padding: 12px 20px;
    }
    .banner-title {
      font-size: 16px;
      color: $color-black;
      font-weight: 700;
      @include mobile {
        font-size: 14px;
      }
    }
    .pick-cn {
      display: none;
      @include mobile {
        display: block;
        min-height: 41px;
        padding-top: 5px;
        font-size: 12px;
        line-height: 1.5em;
      }
    }
  }
}
</style>
