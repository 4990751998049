<script setup lang="ts">
import type { NovaHeaderNavgationProps } from './NovaHeaderTools.types'
import NovaHeaderToolsMenu from '@components/NovaHeaderToolsMenu/NovaHeaderToolsMenu.vue'

withDefaults(defineProps<NovaHeaderNavgationProps>(), {
  onPopup: false,
})

const layoutStore = useLayoutStore()
const { responsive } = storeToRefs(layoutStore)
</script>

<template>
  <div class="tools">
    <NovaHeaderToolsLocaleChange />

    <NovaHeaderToolsNotifications v-if="!onPopup" />

    <NovaHeaderToolsUser v-if="!onPopup && responsive !== 'mobile'" />

    <NovaHeaderToolsMenu v-if="!onPopup" />

    <ClientOnly>
      <NovaBoxAccount v-if="!onPopup" />
      <NovaBoxNotificationSetting v-if="!onPopup" />
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.tools {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  width: 100%;
  font-size: 0;

  @include mobile {
    gap: 18px;
  }
}
</style>
