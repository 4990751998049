import type { BooleanYn } from '@store'

export enum KpopFestivalId {
  FIRST = '52143',
  SECOND_DEV = '54396',
  SECOND_PROD = '52233',
}

export enum KpopFestivalApplyCondition {
  UNAVAILABLE_APPLICATION_PERIOD_CLOSES = 'applicationPeriodCloses', // 마감 기간 초과
  UNAVAILABLE_REQUIRE_SIGN_IN = 'requireLogin', // 로그인 필요
  UNAVAILABLE_ALREADY_APPLIED = 'alreadyApply', // 이미 신청 했음
  AVAILABLE_APPLY = 'availableApply', // 참가 신청 가능
}

export type KpopFestivalTermPayload = number

export type KpopFestivalApplyCode = 'G' | 'P'

export type MemberListItem = {
  age: number
  email: string
  mobileNumber: string
  name: string
  reprsntAt: BooleanYn
  applcntCxwaletAdres?: string
}
/**
 * 커버댄스 이벤트 참가 신청 폼
 */
export interface KpopFestivalFormPayload {
  agreeFstvlApprtcStplatSnList: number[] // 동의 약관 일련번호 목록
  fstvlSectCode: KpopFestivalApplyCode // 참가 부문 코드
  introductionVideoUrl: string // 참가 소개 영상 URL
  memberList: MemberListItem[] // 참가 멤버 리스트
  motivationForApplication?: string // 지원 동기
  teamName?: string // 팀 명
}

/**
 * 커버댄스 이벤트 약관 목록 타입
 */
export interface KpopFestivalTerms {
  fstvlApprtcStplatSn: number // 약관 일련번호
  stplatEssntlAt: BooleanYn // 약관 필수 여부
  stplatNm: string // 약관 명
}

/**
 * 커버댄스 이벤트 약관 상세 타입
 */
export interface KpopFestivalTerm {
  fstvlApprtcStplatSn: number // 약관 일련번호
  stplatCn: string // 약관 내용
  stplatDetailCn: string // 약관 상세 내용
  stplatNm: string // 약관 명
}

/**
 * 커버댄스 이벤트 참가 신청 현황 타입
 */
export interface KpopFestivalApplyStatus {
  applicationAvailableAt: BooleanYn // 신청 가능 여부
}

/**
 * 커버댄스 이벤트 스테이트 타입
 */
export interface KpopFestivalState {
  kpopFestivalTerms: Array<KpopFestivalTerms> | null // 커버댄스 이벤트 약관 목록
  kpopFestivalApplyStatus: KpopFestivalApplyStatus | null // 커버댄스 이벤트 신청 현황 조회
}
