<script setup lang="ts">
import type { NovaButtonTextProps } from './NovaButtonText.types'

const props = withDefaults(defineProps<NovaButtonTextProps>(), {
  size: 40,
  icon: null,
  iconPosition: 'left',
  theme: '',
  corner: 'filet',
  label: '',
  labelBadge: '',
  fullWidth: false,
  badgeLabel: '',
  disabled: false,
  outline: false,
  width: 0,
  on: false,
  responsiveSize: () => ({}),
  loading: false,
  readonly: false,
  bgBright: 'dark',
})

const prefix = 'btn'
const sizeClass = computed<string>(() => `${prefix}-${props.size}`)
const fullWidthClass = props.fullWidth ? `${prefix}-full` : ''
const colorClass = computed<string>(() => `${prefix}-${props.theme}`)
const iconPositionClass = computed(() =>
  props.icon && props.iconPosition ? `${prefix}-icon-${props.iconPosition}` : ''
)
const cornerClass = computed(() => `${prefix}-${props.corner.toLowerCase()}`)
const fixWidthClass = computed(() =>
  props.width > 0 ? `${prefix}-fix-width` : ''
)
const responsiveSizeClass = computed(() => {
  const keys = Object.keys(props.responsiveSize) as Responsive[]
  return keys.map((key) => `${prefix}-${key}-${props.responsiveSize[key]}`)
})
const outlineClass = computed(() => (props.outline ? `${prefix}-outline` : ''))
</script>

<template>
  <button
    type="button"
    :class="[
      prefix,
      sizeClass,
      fullWidthClass,
      colorClass,
      iconPositionClass,
      cornerClass,
      fixWidthClass,
      outlineClass,
      { on },
      { loading },
      { readonly },
      ...responsiveSizeClass,
    ]"
    :disabled="disabled"
    :style="{ width: width ? `${width}px` : '' }"
  >
    <span v-if="icon && iconPosition === 'left'" class="icon">
      <NovaIcon :icon="icon" />
    </span>

    <span v-if="label" class="label">
      <span v-dompurify-html="label" />
      <span v-if="labelBadge" class="label-badge">{{ labelBadge }}</span>
    </span>

    <span v-if="icon && iconPosition === 'right'" class="icon">
      <NovaIcon :icon="icon" />
    </span>

    <NovaLoadingIndicator
      v-if="loading"
      :bg-bright="bgBright"
      class="loading-indicator"
    />
  </button>
</template>

<style lang="scss" scoped>
.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  gap: 4px;
  width: auto;
  background-color: transparent;
  padding: 0 22px;
  font-size: 0;
  border: 0 solid transparent;
  line-height: 44px;
  text-align: center;
  cursor: pointer;
  @include transition(
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out
  );
  overflow: hidden;
  color: $color-neutral-dark-1;

  &.readonly {
    pointer-events: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.loading {
    pointer-events: none;

    > .icon {
      opacity: 0.2;
    }

    > .label {
      opacity: 0.2;
    }
  }

  > .icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 0;

    > i {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      background-size: 20px;
      font-size: 20px;
      vertical-align: middle;

      .badge {
        position: absolute;
        top: -$base-unit * 1.5;
        right: -$base-unit * 1.5;

        &.badge-dot {
          top: calc($base-unit / 2);
          left: 100%;
        }
      }
    }
  }

  > .label {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    > span:not(.label-badge) {
      line-height: inherit;
    }

    .label-badge {
      position: absolute;
      display: flex;
      align-items: center;
      top: 50%;
      left: 100%;
      height: $base-unit * 5;
      padding: 0 $base-unit * 2;
      border-radius: $base-unit * 2.5;
      transform: translateY(-50%);
      margin-left: $base-unit;
      white-space: nowrap;
      @include text-style($text-body-14-regular);

      color: $color-white;
      background-color: $color-neutral-dark-1;
    }
  }

  > .loading-indicator {
    position: absolute;
  }

  //////////////////////////////////////////////////////////////
  // color style ///////////////////////////////////////////////
  //////////////////////////////////////////////////////////////
  &.btn-primary-red {
    background-color: $color-secondary-red-light-80;
    color: $color-bg-3;

    @media (hover: hover) {
      &:hover {
        background-color: $color-primary-red;
      }
    }

    &:active {
      background-color: $color-secondary-red-dark-80;
    }
  }
  &.btn-secondary-red {
    background-color: $color-primary-red;
    color: $color-bg-3;

    @media (hover: hover) {
      &:hover {
        background-color: $color-secondary-red-dark-90;
      }
    }

    &:active {
      background-color: $color-secondary-red-dark-90;
    }
  }
  &.btn-primary-red-light {
    background-color: $color-secondary-red-light-70;
    color: $color-bg-3;

    @media (hover: hover) {
      &:hover {
        background-color: $color-secondary-red-light-90;
      }
    }

    &:active {
      background-color: $color-secondary-red-dark-90;
    }
  }

  &.btn-primary-red-ultra-light {
    background-color: $color-secondary-red-light-10;
    color: $color-secondary-red-light-80;

    @media (hover: hover) {
      &:hover {
        background-color: $color-secondary-red-custom-3;
      }
    }

    &:active {
      background-color: $color-secondary-red-custom-4;
    }
  }

  &.btn-primary-yellow {
    background-color: $color-primary-yellow;
    color: $color-bg-3;

    @media (hover: hover) {
      &:hover {
        background-color: $color-secondary-yellow-dark-90;
      }
    }

    &:active {
      background-color: $color-secondary-yellow-dark-80;
    }
  }

  &.btn-primary-blue {
    background-color: $color-primary-blue;
    color: $color-bg-3;
    @media (hover: hover) {
      &:hover {
        background-color: $color-secondary-blue-dark-80;
      }
    }
    &:active {
      background-color: $color-secondary-blue-dark-60;
    }
    &.btn-outline {
      background-color: $color-bg-3;
      color: $color-primary-blue;
      border: 2px solid $color-primary-blue;
      @media (hover: hover) {
        &:hover {
          border: 2px solid $color-secondary-blue-dark-80;
          background-color: $color-secondary-blue-dark-80;
          color: $color-bg-3;
        }
      }
    }
  }
  &.btn-primary-blue-dark {
    background-color: $color-blue-700;
    color: $color-bg-3;
    @media (hover: hover) {
      &:hover {
        background-color: $color-blue-800;
      }
    }
    &:active {
      background-color: $color-blue-900;
    }
  }
  &.btn-primary-blue-dark-700 {
    background-color: $color-blue-700;
    color: $color-bg-3;
    @media (hover: hover) {
      &:hover {
        background-color: $color-blue-800;
      }
    }
    &:active {
      background-color: $color-blue-900;
    }
  }

  &.btn-primary-blue-light {
    background-color: $color-secondary-blue-light-70;
    color: $color-bg-3;

    @media (hover: hover) {
      &:hover {
        background-color: $color-secondary-blue-light-90;
      }
    }

    &:active {
      background-color: $color-secondary-blue-dark-90;
    }
  }

  &.btn-primary-blue-ultra-light {
    background-color: $color-bg-2;
    color: $color-text-2;
    font-weight: 500 !important;
    padding: 10px !important;

    > .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-text-5;
    }

    &.on {
      background-color: $color-secondary-blue-light-70;
      color: $color-bg-3;

      > .icon {
        color: $color-white;
      }

      @media (hover: hover) {
        &:hover {
          background-color: $color-secondary-blue-light-70;
        }
      }

      &:active {
        background-color: $color-secondary-blue-light-70;
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: $color-bg-1;
      }
    }

    &:active {
      background-color: $color-bg-1;
    }
  }

  &.btn-secondary-green-light-80 {
    background-color: $color-secondary-green-light-80;
    color: $color-bg-3;

    @media (hover: hover) {
      &:hover {
        background-color: $color-secondary-green;
      }
    }

    &:active {
      background-color: $color-secondary-green;
    }
  }

  &.btn-secondary-gray {
    background-color: $color-bg-2;
    color: $color-text-2;

    @media (hover: hover) {
      &:hover {
        background-color: $color-bg-custom-2;
      }
    }
    &:active {
      background-color: $color-bg-custom-3;
    }
  }
  &.btn-gray-text-white {
    background-color: $color-text-12;
    color: $color-white;

    @media (hover: hover) {
      &:hover {
        background-color: $color-text-11;
      }
    }

    &:active {
      background-color: $color-text-11;
    }
  }
  &.btn-transparent-text-white {
    background-color: transparent;
    color: $color-white;
    border: 1px solid $color-white;
    @media (hover: hover) {
      &:hover {
        background-color: $color-text-11;
      }
    }

    &:active {
      background-color: $color-text-11;
    }
  }
  &.btn-secondary-gray-200 {
    background-color: $color-gray-200;
    color: $color-neutral-dark-2;
    @media (hover: hover) {
      &:hover {
        background-color: $color-gray-400;
      }
    }

    &:active {
      background-color: $color-gray-400;
    }
  }

  &.btn-transparent {
    background-color: transparent;
    color: $color-text-3;

    @media (hover: hover) {
      &:hover {
        color: $color-text-2;
      }
    }

    &:active {
      color: $color-dark-2;
    }
  }

  &.btn-white {
    background-color: $color-bg-3;
    color: $color-text-2;

    @media (hover: hover) {
      &:hover {
        background-color: $color-bg-custom-2;
      }
    }

    &:active {
      background-color: $color-bg-custom-2;
    }
  }

  &.btn-white-border-gray {
    background-color: $color-bg-3;
    color: $color-text-2;
    border: 1px solid $color-de;

    @media (hover: hover) {
      &:hover {
        background-color: $color-primary-blue;
        border: 1px solid $color-primary-blue;
        color: $color-white;
      }
    }

    &.on,
    &:active {
      background-color: $color-primary-blue;
      border: 1px solid $color-primary-blue;
      color: $color-white;
    }
  }

  &.btn-sky-blue {
    position: relative;
    background-color: $color-sky-blue;
    color: $color-bg-3;

    &:before {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 7px;
      border: 3px solid $color-blue-800;
      opacity: 0;
      pointer-events: none;
      @include transition(opacity 0.15s ease-in-out);
    }

    @media (hover: hover) {
      &:hover {
        background-color: $color-blue-800;
        &:before {
          opacity: 1;
        }
      }
    }

    &.on,
    &:active {
      background-color: $color-bg-1;
      color: $color-text-2;

      &:before {
        opacity: 0;
      }
    }

    &:disabled {
      background-color: $color-bg-1;
      color: $color-text-9;

      &:before {
        opacity: 0;
      }
    }
  }
  &.btn-black {
    background-color: $color-black;
    color: $color-bg-2;

    @media (hover: hover) {
      &:hover {
        background-color: $color-black-80p;
      }
    }

    &:active {
      background-color: $color-black-80p;
    }
  }
  &.btn-blue-light-border {
    color: $color-sky-blue-border;
    border: 1px solid $color-sky-blue-border;
    background-color: $color-sky-blue-bg;
    @media (hover: hover) {
      &:hover {
        background-color: $color-sky-blue-light;
      }
    }

    &:active {
      background-color: $color-sky-blue-light;
    }
  }
  &.btn-red-light-border {
    color: $color-bg-custom-12;
    border: 1px solid $color-bg-custom-12;
    background-color: $color-secondary-red-custom-5;
    @media (hover: hover) {
      &:hover {
        background-color: $color-secondary-red-custom-4;
      }
    }

    &:active {
      background-color: $color-secondary-red-custom-4;
    }
  }
  &.btn-disable-gray {
    color: $color-gray-600;
    border: 1px solid $color-gray-200;
    background-color: $color-gray-200;
    @media (hover: hover) {
      &:hover {
        background-color: $color-gray-200;
      }
    }

    &:active {
      background-color: $color-gray-200;
    }
  }

  //////////////////////////////////////////////////////////////
  // full width style //////////////////////////////////////////
  //////////////////////////////////////////////////////////////
  &.btn-full {
    display: block;
    width: 100% !important;

    > .icon {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
    }
  }

  //////////////////////////////////////////////////////////////
  // padding style for icon position ///////////////////////////
  //////////////////////////////////////////////////////////////
  &.btn-icon {
    /* &-left {
      padding-left: 5px;

      .icon {
        margin-right: 5px;
      }
    }

    &-right {
      padding-right: 10px;

      .icon {
        margin-left: 10px;
      }
    } */
  }

  //////////////////////////////////////////////////////////////
  // corner style //////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  &.btn-round {
    border-radius: 22px;

    &.btn-20 {
      border-radius: calc(20px / 2);
    }

    &.btn-28 {
      border-radius: calc(28px / 2);
    }

    &.btn-32 {
      border-radius: calc(32px / 2);
    }

    &.btn-38 {
      border-radius: calc(38px / 2);
    }

    &.btn-40 {
      border-radius: calc(40px / 2);
    }
    &.btn-44 {
      border-radius: calc(44px / 2);
    }

    &.btn-50 {
      border-radius: calc(50px / 2);
    }
    &.btn-60 {
      border-radius: calc(60px / 2);
    }
  }

  &.btn-filet {
    border-radius: 7px;
  }

  &.btn-angle {
    border-radius: 0;
  }

  //////////////////////////////////////////////////////////////
  // min style /////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////
  &.btn-min {
    padding: 0;

    > .icon {
      margin: 0;
    }
  }

  //////////////////////////////////////////////////////////////
  // Size style ////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////
  @mixin btn-size-base($height, $padding-vertical, $padding-horizontal) {
    min-height: $height;
    padding: $padding-vertical $padding-horizontal;

    &.btn-fix-width {
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }
  @mixin btn-icon-position-padding($padding) {
    &.btn-icon {
      &-left {
        padding-left: $padding;

        .icon {
          margin-right: $padding;
        }
      }

      &-right {
        padding-right: $padding;

        .icon {
          margin-left: $padding;
        }
      }
    }
  }
  @mixin btn-full($btn-padding, $icon-position) {
    &.btn-full {
      padding: 0 $btn-padding;

      > .icon {
        margin: 0;
        left: auto;
        right: auto;
      }
      &.btn-icon-left > .icon {
        left: $icon-position;
      }
      &.btn-icon-right > .icon {
        right: $icon-position;
      }
    }
  }
  @mixin btn-min {
    &.btn-min {
      padding: 0;

      > .icon {
        margin: 0;
      }
    }
  }
  @mixin btn-60 {
    @include text-style($text-custom-22-medium);
    line-height: 30px;
    @include btn-size-base(60px, 10px, 20px);
    /* @include btn-icon-position-padding(10px); */
    @include btn-full(22px, 10px);
    @include btn-min();
    &.btn-outline {
      @include btn-size-base(60px, 8px, 18px);
    }
  }
  @mixin btn-50 {
    @include text-style($text-custom-22-medium);
    line-height: 24px;
    @include btn-size-base(50px, 10px, 20px);
    /* @include btn-icon-position-padding(10px); */
    @include btn-full(22px, 10px);
    @include btn-min();
    &.btn-outline {
      @include btn-size-base(50px, 8px, 18px);
    }
    &.btn-round {
      border-radius: 25px;
    }
  }
  @mixin btn-44 {
    @include text-style($text-heading3-medium);
    line-height: 30px;
    @include btn-size-base(44px, 10px, 20px);
    /* @include btn-icon-position-padding(10px); */
    @include btn-full(22px, 10px);
    @include btn-min();
    &.btn-outline {
      @include btn-size-base(44px, 8px, 18px);
    }
  }
  @mixin btn-40 {
    @include text-style($text-body-14-bold);
    line-height: 20px;
    @include btn-size-base(40px, 10px, 20px);
    /* @include btn-icon-position-padding(10px); */
    @include btn-full(22px, 10px);
    @include btn-min();
    &.btn-outline {
      @include btn-size-base(40px, 8px, 18px);
    }
  }

  @mixin btn-38 {
    @include text-style($text-body-14-bold);
    @include btn-size-base(38px, 5px, 14px);
    /* @include btn-icon-position-padding(10px); */
    @include btn-full(22px, 10px);
    @include btn-min();
    &.btn-outline {
      @include btn-size-base(38px, 3px, 12px);
    }
  }
  @mixin btn-32 {
    @include text-style($text-body-14-bold);
    @include btn-size-base(32px, 5px, 14px);
    /* @include btn-icon-position-padding(10px); */
    @include btn-full(12px, 10px);
    @include btn-min();
    &.btn-outline {
      @include btn-size-base(32px, 3px, 12px);
    }
  }
  @mixin btn-28 {
    @include btn-size-base(28px, 4px, 9px);
    & {
      @include text-style($text-body-14-bold);
      line-height: 20px;
    }
    /* @include btn-icon-position-padding(10px); */
    @include btn-full(14px, 6px);
    @include btn-min();
    &.btn-outline {
      @include btn-size-base(28px, 2px, 7px);
    }
  }
  @mixin btn-20 {
    @include text-style($text-body-12-regular);
    @include btn-size-base(20px, 2px, 8px);
    /* @include btn-icon-position-padding(10px); */
    @include btn-full(22px, 10px);
    @include btn-min();
    &.btn-outline {
      @include btn-size-base(20px, 2px, 6px);
    }
  }
  &.btn-60 {
    @include btn-60;
  }
  &.btn-50 {
    @include btn-50;
  }
  &.btn-44 {
    @include btn-44;
  }
  &.btn-40 {
    @include btn-40;
  }
  &.btn-38 {
    @include btn-38;
  }
  &.btn-32 {
    @include btn-32;
  }
  &.btn-28 {
    @include btn-28;
  }
  &.btn-20 {
    @include btn-20;
  }

  //////////////////////////////////////////////////////////////
  // Responsive style //////////////////////////////////////////
  //////////////////////////////////////////////////////////////
  &.btn-desktop {
    &-60 {
      @include btn-60;
    }
    &-50 {
      @include btn-50;
    }
    &-44 {
      @include btn-44;
    }
    &-40 {
      @include btn-40;
    }
    &-38 {
      @include btn-38;
    }
    &-32 {
      @include btn-32;
    }
    &-28 {
      @include btn-28;
    }
    &-20 {
      @include btn-20;
    }
  }

  &.btn-tablet {
    @include tablet {
      &-60 {
        @include btn-60;
      }
      &-50 {
        @include btn-50;
      }
      &-44 {
        @include btn-44;
      }
      &-40 {
        @include btn-40;
      }
      &-38 {
        @include btn-38;
      }
      &-32 {
        @include btn-32;
      }
      &-28 {
        @include btn-28;
      }
      &-20 {
        @include btn-20;
      }
    }
  }

  &.btn-mobile {
    @include mobile {
      &-60 {
        @include btn-60;
      }
      &-50 {
        @include btn-50;
      }
      &-40 {
        @include btn-44;
      }
      &-40 {
        @include btn-40;
      }
      &-38 {
        @include btn-38;
      }
      &-32 {
        @include btn-32;
      }
      &-28 {
        @include btn-28;
      }
      &-20 {
        @include btn-20;
      }
    }
  }
}
</style>
