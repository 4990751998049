<script setup lang="ts">
import type { NovaLayoutMainProps } from './NovaLayoutMain.types'

withDefaults(defineProps<NovaLayoutMainProps>(), {
  pageMode: false,
})

const layoutStore = useLayoutStore()
const slots = useSlots()
</script>

<template>
  <main
    :class="[
      'home',
      { 'page-mode': pageMode },
      { 'bg-white': layoutStore.mainBgWhite },
    ]"
  >
    <div v-if="slots['full-width-contents']" class="full-width-contents">
      <slot name="full-width-contents" />
    </div>

    <div class="inner">
      <slot name="default" class="page" />
    </div>
  </main>
</template>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $color-bg-2;

  &.bg-white {
    background-color: $color-bg-3;
  }

  &.page-mode {
    > .inner {
      height: fit-content;
      margin: 0 auto;

      .left {
        position: sticky;
        top: 0;
      }

      > .center {
        height: auto;
        //overflow: visible;
      }

      .right {
        position: sticky;
        top: 0;
      }
    }
  }

  &.scroll-lock {
    overflow: hidden !important;
  }

  > .inner {
    @include inner-base;

    &.fixed {
      max-width: calc(900px + 16px + 16px);
    }

    .left {
      @include inner-left-base;
    }

    .center {
      @include inner-center-base;
    }

    .right {
      @include inner-right-base;
    }
  }

  @include tablet {
    .inner {
      max-width: $base-tablet-main-max-width;

      .right {
        display: none;
      }
    }
  }

  @include mobile {
  }
}
</style>
