import {
  type KpopFestivalApplyStatus,
  type Profile,
  KpopFestivalApplyCondition,
} from '@store'
import { ROUTES } from '@configs'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const mypageStore = useMyPageStore()
  const kpopFestivalStore = useKpopFestivalStore()
  const now = new Date() // 현재 시간
  const endDate = new Date('2024-12-21') // 마감 일자
  let profile: Profile | null = null
  let applyStatus: KpopFestivalApplyStatus | null = null
  const { data: _profile } = await useAsyncData('profile', () =>
    mypageStore.fetchProfile()
  )
  profile = _profile.value

  const { data: _applyStatus } = await useAsyncData('applyStatus', () =>
    kpopFestivalStore.fetchKpopFestivalApplyStatus()
  )
  applyStatus = _applyStatus.value
  const applyStatusAvail = applyStatus?.applicationAvailableAt === 'Y'
  // 비 로그인 상태에서 라우트가드 무한 동작 방지용 조건문
  if (
    (from.fullPath === ROUTES.BOARD_EVENT_DETAIL.path && !profile) ||
    (to.fullPath === ROUTES.BOARD_EVENT_DETAIL.path && !profile)
  ) {
    return
  }
  const kpopFestivalAvailableStatus = () => {
    if (now > endDate) {
      return KpopFestivalApplyCondition.UNAVAILABLE_APPLICATION_PERIOD_CLOSES
    }
    if (!profile) {
      return KpopFestivalApplyCondition.UNAVAILABLE_REQUIRE_SIGN_IN
    }
    if (!applyStatusAvail) {
      return KpopFestivalApplyCondition.UNAVAILABLE_ALREADY_APPLIED
    }
    return KpopFestivalApplyCondition.AVAILABLE_APPLY
  }
  switch (kpopFestivalAvailableStatus()) {
    case KpopFestivalApplyCondition.UNAVAILABLE_REQUIRE_SIGN_IN:
    case KpopFestivalApplyCondition.UNAVAILABLE_ALREADY_APPLIED:
      await useNavigations({
        url: useRoutePathIdChange(ROUTES.BOARD_EVENT_DETAIL.path, {
          id: KpopFestivalId.SECOND,
        }),
      })
      break
    case KpopFestivalApplyCondition.UNAVAILABLE_APPLICATION_PERIOD_CLOSES:
    case KpopFestivalApplyCondition.AVAILABLE_APPLY:
      break
    default:
      throw showError({
        statusCode: 500,
        statusMessage: 'check conditions error in middleware rent guard',
      })
  }
})
