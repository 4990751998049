<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type {
  SorterByButtonData,
  NovaBoxSorterEmits,
  NovaBoxSorterProps,
} from './NovaBoxSorter.types'

const emit = defineEmits<NovaBoxSorterEmits>()
const props = withDefaults(defineProps<NovaBoxSorterProps>(), {
  sortItems: () => [],
  hideBgBox: false,
  optionMenu: null,
})

const slots = useSlots()
const swiper = ref<typeof Swiper | null>(null)
const activeIdx = computed(() =>
  props.activeSortItem
    ? props.sortItems!.findIndex((item) => item.id === props.activeSortItem.id)
    : 0
)
const handleOnSetSwiper = (evt: typeof Swiper) => {
  swiper.value = evt
}

const onChangeActiveSortItem = (sortItem: SorterByButtonData<string>) => {
  emit('onChangeActiveSortItem', sortItem)
}

// const isHide = ref(true)
// const { show: modalShow } = useModal()
// const clickHandler = async () => {
//   const isMobile = useCheckMobile()
//   isHide.value = false
//   if (isMobile) {
//     await modalShow(modalsName.MODAL_OPTION_MENU, props.optionMenu)
//   }
// }
</script>

<template>
  <NovaBoxBase
    :class="[
      'sorter',
      { 'hide-bg-box': hideBgBox },
      { 'has-tools': slots['tools'] },
    ]"
  >
    <ClientOnly>
      <Swiper
        class="swiper"
        :initial-slide="activeIdx"
        :slides-per-view="'auto'"
        :space-between="12"
        @swiper="handleOnSetSwiper"
      >
        <SwiperSlide v-for="(item, index) in sortItems" :key="item.id">
          <NovaButtonText
            :label="item.name"
            :icon="item.icon"
            :responsive-size="{ desktop: 32, mobile: 32 }"
            :corner="'filet'"
            :theme="
              activeIdx === index
                ? 'black'
                : hideBgBox
                ? 'white'
                : 'secondary-gray'
            "
            :loading="item.loading || false"
            class="btn-sort"
            @click="() => onChangeActiveSortItem(item)"
          />
        </SwiperSlide>
      </Swiper>
      <div v-if="slots['tools']" class="tools">
        <slot name="tools" />
      </div>
    </ClientOnly>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.sorter {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end;
  gap: 12px !important;
  padding: 10px 0 !important;

  &.hide-bg-box {
    padding: 0 !important;
    background-color: transparent !important;
    border-radius: 0 !important;

    .swiper {
      padding: 0;
    }
  }

  &.has-tools {
    flex-wrap: wrap;
  }

  &:deep(.drop-down-wrap) {
    flex-shrink: 1;
  }

  .swiper {
    flex-grow: 1;
    margin: 0;
    padding: 0 10px;

    .swiper-slide {
      width: auto;

      :deep(.btn) {
        padding-left: 10px;
        padding-right: 10px;
      }
      :deep(.btn) {
        .icon {
          color: $color-text-5;
        }

        &.btn-primary-blue-light .icon {
          color: $color-bg-3;
        }
      }
    }
  }
  .lists-options {
    display: flex;
    flex-direction: column;
    label {
      width: 100%;
      height: 52px;
      padding: 0 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 19px;
      input {
        width: 20px;
        height: 20px;
        display: none;
      }
      span {
        width: 100%;
        font-size: 15px;
        font-weight: bold;
        background-size: 24px 24px;
        background-image: url('assets/images/icon-checkbox.png');
        background-repeat: no-repeat;
        background-position: right center;
      }
      input:checked + span {
        background-image: url('assets/images/icon-checkbox-checked.png');
      }
    }
  }

  .tools {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 0 20px;
    gap: 8px;
  }

  @include mobile {
    padding: 10px 0 !important;
    border-radius: 14px !important;

    .swiper {
      padding: 0 10px;

      .swiper-slide {
        :deep(.btn .icon) {
          display: none;
        }
      }
    }

    .tools {
      padding: 0 10px;
    }
  }
}
</style>
