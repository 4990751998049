<script setup lang="ts">
import type { NovaModalReportCustomerProps } from './NovaModalReportCustomer.types'
import { useCheckEmpty } from '@composables/useCheckEmpty'
import type { Classification } from '@store'
import { NovaModalDialog } from '#components'

const props = withDefaults(defineProps<NovaModalReportCustomerProps>(), {
  message: '',
  where: '',
  title: '',
})
const { gtEvent } = useGoogleTag()
const { show: modalShow, hide: modalHide } = useModal()
const { t } = useI18n()
const etcStore = useEtcStore()
const userStore = useUserStore()

const forms = computed(() => ({
  dropdownPlaceholder: t('qna.placeholder.dropdown'),
  textareaPlaceholder: t('qna.placeholder.content'),
  textareaMax: 4000,
  btnLabel: [t('qna.button.cancel'), t('qna.button.confirm')],
}))

const csData = reactive({
  qnaContent: '',
  qnaValue: 0,
})

const menus = computed(() =>
  etcStore.classifications.map((item) => {
    return { value: item.bbsNttClSn, label: item.nttClNm }
  })
)

const isDisabled = computed(() => {
  return !(csData.qnaValue && csData.qnaContent.length >= 10)
})
const textareaPlaceholder = computed(() => {
  let placeholder = forms.value.textareaPlaceholder

  const menu = menus.value.find((item) => item.value === csData.qnaValue)

  if (menu) {
    placeholder = forms.value.textareaPlaceholder + `(${t('required')})`
  }

  return placeholder
})
const selectedLabel = computed(() => {
  const label = menus.value.find((item) => item.value === csData.qnaValue)
  if (label) return label.label
})

const confirm = async () => {
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: useKoreanTranslation('qna.title'),
    eventLabel: forms.value.btnLabel[1],
    eventSlot: t('qna.reportMessage'),
    eventI18nAddr: '',
    eventComponent: 'button',
    cmtyNttSn: props.cmtyNttSn,
    cmtyNttAnswerSn: props.cmtyNttAnswerSn,
  })

  if (useCheckEmpty(csData.qnaContent)) {
    useToast(textareaPlaceholder.value)
    csData.qnaContent = ''
    return
  }

  try {
    const selectCtg: Classification[] = etcStore.classifications.filter(
      (item) => item.bbsNttClSn === csData.qnaValue
    )
    const payload = {
      bbsNttClSn: selectCtg[0].bbsNttClSn,
      email: userStore.userCommonInfo!.email,
      nttClNm: selectCtg[0].nttClNm,
      nttCn: csData.qnaContent,
      userCmmnSn: userStore.userCommonInfo!.userCmmnSn,
    }
    await etcStore.sendQnA(payload)
    await modalHide(modalsName.MODAL_CS)
    await useSleeper()

    await modalShow({
      component: NovaModalDialog,
      bind: {
        name: modalsName.MODAL_DIALOG,
        btns: [
          {
            label: t('confirm'),
            theme: 'primary-blue-dark',
            size: 32,
            onClick: () => {
              modalHide(modalsName.MODAL_DIALOG)
            },
          },
        ],
      },
      slots: {
        title: t('qna.dialog.title'),
        content: t('qna.dialog.success'),
      },
    })
  } catch (err) {
    useToast(t('qna.error'))
  }
}

const close = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `${useKoreanTranslation('qna.title')} 취소`,
    eventLabel: forms.value.btnLabel[0],
    eventSlot: t('qna.reportMessage'),
    eventI18nAddr: useKoreanTranslation('cs'),
    eventComponent: 'Button',
  })
  modalHide(modalsName.MODAL_CS)
}

const onUpdateMenu = (value: string) => {
  csData.qnaValue = value
  gtEvent('selectEvent', {
    eventCategory: '선택',
    eventAction: `${forms.value.dropdownPlaceholder} 선택`,
    eventLabel: `${selectedLabel.value}`,
    eventSlot: t('qna.reportMessage'),
    eventI18nAddr: '',
    eventComponent: 'DropDown',
  })
}
</script>

<template>
  <div class="report-wrap">
    <NovaDropdown
      :placeholder="forms.dropdownPlaceholder"
      :menus="menus"
      :active-value="csData.qnaValue"
      @update="(value) => {
        onUpdateMenu(value as string)
      }"
    />
    <NovaTextarea
      v-model="csData.qnaContent"
      :placeholder="textareaPlaceholder"
      :max-byte="forms.textareaMax"
    />
    <p v-dompurify-html="t('qna.reportMessage') || ''" class="report-message" />
    <div class="action-box">
      <NovaButtonText
        :label="forms.btnLabel[0]"
        :theme="'secondary-gray-200'"
        :size="32"
        class="btn-action"
        @click="close"
      />
      <NovaButtonText
        :label="forms.btnLabel[1]"
        :theme="'primary-blue-dark'"
        :size="32"
        :disabled="isDisabled"
        class="btn-action"
        @click="confirm"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.report-wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.report-message {
  text-align: right;
  @include text-style($text-body-12-regular);
}

.action-box {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  > .btn-action {
    width: 100px;
  }
}

@include mobile {
  .action-box {
    display: flex;
    margin-top: 0;
    padding: 0;
    gap: 8px;

    > .btn-action {
      width: 50%;
    }
  }
}
</style>
