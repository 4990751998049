import { ref, useRuntimeConfig } from '#imports'

declare const grecaptcha: {
  enterprise: {
    ready: (callback: () => void) => void
    execute: (siteKey: string, options: { action: string }) => Promise<string>
  }
}

export type tokenType = 'FOLLOW' | 'RECOMMEND' | 'PUBLISH'

export const useReCaptcha = () => {
  const config = useRuntimeConfig()
  const siteKey = ref(config.public.GOOGLE_RECAPTCHA_KEY as string)

  // reCAPTCHA 스크립트 로드
  const loadReCaptchaScript = () => {
    return new Promise<void>((resolve, reject) => {
      if (document.getElementById('recaptcha-script')) {
        resolve() // 스크립트가 이미 로드되었다면, 다시 로드하지 않음
        return
      }

      const script = document.createElement('script')
      script.id = 'recaptcha-script'
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey.value}`
      script.onload = () => resolve()
      script.onerror = () =>
        reject(new Error('Failed to load reCAPTCHA script'))
      document.head.appendChild(script)
    })
  }

  // reCAPTCHA 토큰 요청
  const generateReCaptchaToken = async (action: tokenType) => {
    try {
      await loadReCaptchaScript() // 스크립트가 완전히 로드될 때까지 대기
      return new Promise<string>((resolve, reject) => {
        grecaptcha.enterprise.ready(() => {
          grecaptcha.enterprise
            .execute(siteKey.value, { action })
            .then((token: string) => {
              resolve(token)
            })
            .catch(() => {
              reject(new Error('Failed to execute reCAPTCHA'))
            })
        })
      })
    } catch (error) {
      console.error('reCAPTCHA initialization failed:', error)
      throw error
    }
  }

  return {
    loadReCaptchaScript,
    generateReCaptchaToken,
  }
}
