import { decode } from 'html-entities'
import type { UseHeadInput } from 'unhead'
import type { MergeHead } from 'zhead'
import type { UseSeoMetaInput } from '@unhead/schema'
import { metaDefault, locales } from '@configs'

type ArgTypes<F extends Function> = F extends (...args: infer A) => any
  ? A
  : never
type UseHeadArgs = ArgTypes<typeof useHead>
type UseSeoMetaArgs = ArgTypes<typeof useSeoMeta>
interface UseSetSEOParams {
  useHeadArgs: UseHeadArgs
  useSeoMetaArgs: UseSeoMetaArgs
}

/**
 * @function useSetSEO
 * @description SEO 메타 정보를 설정하고 비어있는 head 태그를 채우는 함수
 * @sample
 *   useHead({
 *     title: `${post?.nttSj} | ${config.public.APP_NAME}`,
 *   })
 *   useSeoMeta({
 *     ogTitle: `${post?.nttSj} | ${config.public.APP_NAME}`,
 *     ogDescription: useGetMaxThreeParagraphTexts(post.nttCn),
 *     ogUrl: `${config.public.APP_URL}${route.fullPath}`,
 *     ogImage:
 *       useGetFirstImageSource(post.nttCn) ||
 *       `${config.public.APP_URL}/ilikelm-og.png`,
 *   })
 * @param {UseSetSEOParams} { useHeadArgs, useSeoMetaArgs } - useSetSEO 함수를 위한 인수
 */
export const useSetSEO = (
  { useHeadArgs, useSeoMetaArgs }: UseSetSEOParams = {
    useHeadArgs: [{}],
    useSeoMetaArgs: [{}],
  }
) => {
  // const headers = useRequestHeaders()
  // console.log('SERVER HEADERS', headers['user-agent'])
  const nuxtApp = useNuxtApp()
  const headers = useRequestHeaders()
  const config = nuxtApp.$config
  const route = useRoute()
  const { locale } = useNuxtApp().$i18n
  const metaDefaultByLocale = metaDefault[locale.value]
  const titleByENV =
    config.public.MODE === 'production'
      ? metaDefaultByLocale.title
      : `${config.public.MODE.toUpperCase()} | ${metaDefaultByLocale.title}`
  const headInput = useHeadArgs[0] as UseHeadInput<MergeHead>

  const seoMetaInput = useSeoMetaArgs[0] as UseSeoMetaInput
  const title = headInput.title
    ? `${decode(String(headInput.title))} | ${titleByENV}`
    : titleByENV
  const defaultDescription = {
    name: 'description',
    content: metaDefaultByLocale.description,
  }
  const defaultKeywords = {
    name: 'keywords',
    content: metaDefaultByLocale.keywords,
  }
  const defaultLink = [
    ...locales.map((item) => {
      const pathCode = useGetRouteConst(String(route.name))?.path || ''
      let path = String(pathCode)
      const pathVariables = useGetRoutePathVariables(pathCode)

      pathVariables.forEach(({ key, match }) => {
        path = path.replace(match, String(route.params[key]))
      })

      const href = item.default
        ? `${config.public.APP_URL}${path}`
        : `${config.public.APP_URL}/${item.code}${path}`

      // 1. empty head fill
      return {
        rel: 'alternate',
        hreflang: item.iso,
        href,
      }
    }),
    {
      rel: 'alternate',
      hreflang: 'x-default',
      href: config.public.APP_URL,
    },
  ]

  // 1. fill head:title
  headInput.title = title
  // 1-1. fill head:description
  const descriptionIdx = headInput.meta?.findIndex(
    (item) => item.name === 'description'
  )
  if (!headInput.meta) {
    headInput.meta = [defaultDescription, defaultKeywords]
  } else if (typeof descriptionIdx === 'undefined') {
    headInput.meta.push(defaultDescription)
  } else if (!headInput.meta[descriptionIdx].content) {
    headInput.meta[descriptionIdx] = defaultDescription
  }

  // 2. empty seo meta
  // 1-2. fill head:link
  if (!headInput.link) {
    headInput.link = defaultLink
  } else if (headInput.link.length) {
    headInput.link = [...headInput.link, ...defaultLink]
  }
  // 2. fill seo meta
  seoMetaInput.ogTitle = title
  if (!seoMetaInput.ogDescription) {
    seoMetaInput.ogDescription = metaDefaultByLocale.description
  }
  if (!seoMetaInput.ogUrl) {
    seoMetaInput.ogUrl = `${config.public.APP_URL}${route.fullPath}`
  }
  if (!seoMetaInput.ogImage) {
    const userAgent = headers['user-agent']
    const regxTelegramBot = /TelegramBot/i
    const isTelegramBot = regxTelegramBot.test(userAgent)

    seoMetaInput.ogImage = `${config.public.APP_URL}${
      isTelegramBot ? '/ilikelm-og-telegram.png' : '/ilikelm-og.png'
    }`
  }
  useHead(...useHeadArgs)
  useSeoMeta(...useSeoMetaArgs)
}
