import type { FirebaseOptions } from '@firebase/app'
import { local, development, production } from './env.consts.js'

export type Envs = 'local' | 'development' | 'staging' | 'production'
export const clientId = 'CMTY_EncryptedID'
export const naverAnalyticsSource = 'https://wcs.naver.net/wcslog.js'
export const kakaoSdkSource =
  'https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js'
export const googleAnalyticsSource = (appKey: string) =>
  `https://www.googletagmanager.com/gtag/js?id=${appKey}`
export const iOSWhiteListException = [
  'https://www.youtube.com',
  'https://youtube.be',
  'https://vimeo.com',
  'https://player.vimeo.com',
]
export const AppGateDeepLink = 'https://ilikelm.page.link/mwadl'
export const ServiceStartYear = 2023

export interface AppEnv {
  MODE: string
  API_URL: string
  APP_URL: string
  APP_NAME: string
  KAKAO_APP_KEY: string
  NAVER_APP_KEY: string
  GTAG_DEFAULT_APP_KEY: string
  GTAG_PROMOTION_APP_KEY: string
  AUTH_URL: string
  IMAGE_URL: string
  POST_RELEASED_IMAGE_URL: string
  TEMP_IMAGE_URL: string
  REWARD_URL: string
  AVATAR_URL: string
  FIREBASE_CONFIG: FirebaseOptions
  FIREBASE_VAPIDKEY: string
  DEEPLINK_ENTRY_URL: string
  LIVE_URL: string
  CHAT_URL: string
  NOTION_URL_KO: string
  NOTION_URL_EN: string
  NOTION_URL_TH: string
  ILIKELMGUIDE_URL_KO: string
  ILIKELMGUIDE_URL_EN: string
  ILIKELMGUIDE_URL_TH: string
  REWARD_GUIDE_URL_KO: string
  REWARD_GUIDE_URL_EN: string
  REWARD_GUIDE_URL_TH: string
  USER_GUIDE_KO: string
  USER_GUIDE_EN: string
  NAVER_VERIFY_ID: string
  USER_GUIDE_TH: string
  GOOGLE_RECAPTCHA_KEY: string
}

export default {
  local: {
    MODE: 'local',
    API_URL: 'https://dev-api.lmnova.com',
    // API_URL: 'http://10.10.10.111:8080', // backend 개발자 아이피
    APP_URL: local.APP_URL,
    APP_NAME: 'LOCAL | I LIKE LM',
    KAKAO_APP_KEY: 'be4efd95e4624500e7c274309d1dcd9a',
    NAVER_APP_KEY: 'ee453499d76ce8',
    GTAG_DEFAULT_APP_KEY: 'G-JE1GSTRQJD',
    GTAG_PROMOTION_APP_KEY: 'AW-10800101378',
    AUTH_URL: 'https://dev-authapi.themoonlabs.net/pn/v1/auth/playnomm/service',
    IMAGE_URL: 'https://d3j8b1jkcxmuqq.cloudfront.net/lmnova',
    POST_RELEASED_IMAGE_URL:
      'https://d3j8b1jkcxmuqq.cloudfront.net/lmnova/contents/release',
    TEMP_IMAGE_URL:
      'https://d3j8b1jkcxmuqq.cloudfront.net/lmnova/contents/temp',
    REWARD_URL: 'https://dev.ilikelm.com',
    AVATAR_URL: 'https://dev-avatar.themoonlabs.net/avatar/setup.do',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyCMzsPmlTfKCbbxFWApbXnGGJRexaIwG00',
      authDomain: 'themoonlabs-665d6.firebaseapp.com',
      projectId: 'themoonlabs-665d6',
      storageBucket: 'themoonlabs-665d6.appspot.com',
      messagingSenderId: '1033451512674',
      appId: '1:1033451512674:web:5d924fab819d4bbbb7823b',
      measurementId: 'G-ZKZMMZ8KZS',
    },
    FIREBASE_VAPIDKEY:
      'BCwQJFlmVTifSewuZjIxLxOluLCBGsnXqlRPtOBHon8zltMOWbHFoJqQwrJRSX6iAJ8W_W7IbVZMgcFneyvVZac',
    DEEPLINK_ENTRY_URL: 'https://dev.lmnova.com',
    // LIVE_URL: 'http://localhost:8010',
    LIVE_URL: 'https://live.lmnova.com',
    // CHAT_URL: 'http://localhost:8020',
    CHAT_URL: 'https://chat.lmnova.com',
    NAVER_VERIFY_ID: local.NAVER_VERIFY_ID,
    NOTION_URL_KO:
      'https://ilikelm.notion.site/I-LIKE-LM-Help-Center-2e32de0d6d104993ba611cd8e7c3d62e?pvs=74',
    NOTION_URL_EN:
      'https://ilikelm.notion.site/I-LIKE-LM-Help-Center-74a14afce29e41c0aa6c720a914f7423',
    NOTION_URL_TH:
      'https://ilikelm.notion.site/I-LIKE-LM-1275d252fe22809fa146f6464b900283',
    ILIKELMGUIDE_URL_KO:
      'https://ilikelm.notion.site/3-7fcd4f950edc4bdeafb68c86374e8c07',
    ILIKELMGUIDE_URL_EN:
      'https://ilikelm.notion.site/Web3-Creator-DAO-Platform-a99d1615cfa144229472cd8b2f15287c?pvs=4',
    ILIKELMGUIDE_URL_TH:
      'https://ilikelm.notion.site/Web3-Creator-DAO-1275d252fe228129bf4ad333844b090b',
    REWARD_GUIDE_URL_KO:
      'https://ilikelm.notion.site/154ed6142d2f460b95bf2760f3e4f199',
    REWARD_GUIDE_URL_EN:
      'https://ilikelm.notion.site/How-to-use-the-rewards-04caed5254b0499786a8ead76b5ae2d2?pvs=4',
    REWARD_GUIDE_URL_TH:
      'https://ilikelm.notion.site/1275d252fe2281ee9c81feee555dc355',
    USER_GUIDE_KO:
      'https://ilikelm.notion.site/I-LIKE-LM-a5a02f50fc97456a8fbc149b6d97d206',
    USER_GUIDE_EN:
      'https://ilikelm.notion.site/I-LIKE-LM-Reward-System-810fdb0047074f9b85068bf4ab114bcf',
    USER_GUIDE_TH:
      'https://ilikelm.notion.site/I-LIKE-LM-1275d252fe2280e39d56ebca476969b9',
    GOOGLE_RECAPTCHA_KEY: '6LdxpUopAAAAADf7v78t4NW1OmYwICsdvizxUVLu',
  },
  development: {
    MODE: 'development',
    API_URL: 'https://dev-api.lmnova.com',
    APP_URL: development.APP_URL,
    APP_NAME: 'DEV | I LIKE LM',
    KAKAO_APP_KEY: '3175d8a3dade33470246c8c650f985f1',
    NAVER_APP_KEY: 'ee453499d76ce8',
    GTAG_DEFAULT_APP_KEY: 'G-JE1GSTRQJD',
    GTAG_PROMOTION_APP_KEY: 'AW-10800101378',
    AUTH_URL: 'https://dev-authapi.themoonlabs.net/pn/v1/auth/playnomm/service',
    IMAGE_URL: 'https://d3j8b1jkcxmuqq.cloudfront.net/lmnova',
    POST_RELEASED_IMAGE_URL:
      'https://d3j8b1jkcxmuqq.cloudfront.net/lmnova/contents/release',
    TEMP_IMAGE_URL:
      'https://d3j8b1jkcxmuqq.cloudfront.net/lmnova/contents/temp',
    REWARD_URL: 'https://dev.ilikelm.com',
    AVATAR_URL: 'https://dev-avatar.themoonlabs.net/avatar/setup.do',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyCMzsPmlTfKCbbxFWApbXnGGJRexaIwG00',
      authDomain: 'themoonlabs-665d6.firebaseapp.com',
      projectId: 'themoonlabs-665d6',
      storageBucket: 'themoonlabs-665d6.appspot.com',
      messagingSenderId: '1033451512674',
      appId: '1:1033451512674:web:5d924fab819d4bbbb7823b',
      measurementId: 'G-ZKZMMZ8KZS',
    },
    FIREBASE_VAPIDKEY:
      'BCwQJFlmVTifSewuZjIxLxOluLCBGsnXqlRPtOBHon8zltMOWbHFoJqQwrJRSX6iAJ8W_W7IbVZMgcFneyvVZac',
    DEEPLINK_ENTRY_URL: 'https://dev.lmnova.com',
    LIVE_URL: 'https://live.lmnova.com',
    CHAT_URL: 'https://chat.lmnova.com',
    NAVER_VERIFY_ID: development.NAVER_VERIFY_ID,
    NOTION_URL_KO:
      'https://ilikelm.notion.site/I-LIKE-LM-Help-Center-2e32de0d6d104993ba611cd8e7c3d62e?pvs=74',
    NOTION_URL_EN:
      'https://ilikelm.notion.site/I-LIKE-LM-Help-Center-74a14afce29e41c0aa6c720a914f7423',
    NOTION_URL_TH:
      'https://ilikelm.notion.site/I-LIKE-LM-1275d252fe22809fa146f6464b900283',
    ILIKELMGUIDE_URL_KO:
      'https://ilikelm.notion.site/3-7fcd4f950edc4bdeafb68c86374e8c07',
    ILIKELMGUIDE_URL_EN:
      'https://ilikelm.notion.site/Web3-Creator-DAO-Platform-a99d1615cfa144229472cd8b2f15287c?pvs=4',
    ILIKELMGUIDE_URL_TH:
      'https://ilikelm.notion.site/Web3-Creator-DAO-1275d252fe228129bf4ad333844b090b',
    REWARD_GUIDE_URL_KO:
      'https://ilikelm.notion.site/154ed6142d2f460b95bf2760f3e4f199',
    REWARD_GUIDE_URL_EN:
      'https://ilikelm.notion.site/How-to-use-the-rewards-04caed5254b0499786a8ead76b5ae2d2?pvs=4',
    REWARD_GUIDE_URL_TH:
      'https://ilikelm.notion.site/1275d252fe2281ee9c81feee555dc355',
    USER_GUIDE_KO:
      'https://ilikelm.notion.site/I-LIKE-LM-a5a02f50fc97456a8fbc149b6d97d206',
    USER_GUIDE_EN:
      'https://ilikelm.notion.site/I-LIKE-LM-Reward-System-810fdb0047074f9b85068bf4ab114bcf',
    USER_GUIDE_TH:
      'https://ilikelm.notion.site/I-LIKE-LM-1275d252fe2280e39d56ebca476969b9',
    GOOGLE_RECAPTCHA_KEY: '6LdxpUopAAAAADf7v78t4NW1OmYwICsdvizxUVLu',
  },
  production: {
    MODE: 'production',
    API_URL: 'https://api.ilikelm.com',
    APP_URL: production.APP_URL,
    APP_NAME: 'I LIKE LM',
    KAKAO_APP_KEY: 'b5157cb9800700e4871ec6988f5e4b61',
    NAVER_APP_KEY: '17d3cc7cd29b5c',
    GTAG_DEFAULT_APP_KEY: 'G-RX96EQPCCV',
    GTAG_PROMOTION_APP_KEY: 'AW-10800101378',
    AUTH_URL: 'https://authapi.themoonlabs.net/pn/v1/auth/playnomm/service',
    IMAGE_URL: 'https://d2t5puzz68k49j.cloudfront.net/lmnova',
    POST_RELEASED_IMAGE_URL:
      'https://d2t5puzz68k49j.cloudfront.net/lmnova/contents/release',
    TEMP_IMAGE_URL:
      'https://d2t5puzz68k49j.cloudfront.net/lmnova/contents/temp',
    REWARD_URL: 'https://ilikewallet.com',
    AVATAR_URL: 'https://avatar.ilikelm.com/avatar/setup.do',
    FIREBASE_CONFIG: {
      apiKey: 'AIzaSyCMzsPmlTfKCbbxFWApbXnGGJRexaIwG00',
      authDomain: 'themoonlabs-665d6.firebaseapp.com',
      projectId: 'themoonlabs-665d6',
      storageBucket: 'themoonlabs-665d6.appspot.com',
      messagingSenderId: '1033451512674',
      appId: '1:1033451512674:web:db6d3c7aa45fe4c8b7823b',
      measurementId: 'G-EGT0KRN1FV',
    },
    FIREBASE_VAPIDKEY:
      'BCwQJFlmVTifSewuZjIxLxOluLCBGsnXqlRPtOBHon8zltMOWbHFoJqQwrJRSX6iAJ8W_W7IbVZMgcFneyvVZac',
    DEEPLINK_ENTRY_URL: 'https://ilikelm.com',
    LIVE_URL: 'https://live.ilikelm.com',
    CHAT_URL: 'https://chat.ilikelm.com',
    NAVER_VERIFY_ID: production.NAVER_VERIFY_ID,
    NOTION_URL_KO:
      'https://ilikelm.notion.site/I-LIKE-LM-Help-Center-2e32de0d6d104993ba611cd8e7c3d62e?pvs=74',
    NOTION_URL_EN:
      'https://ilikelm.notion.site/I-LIKE-LM-Help-Center-74a14afce29e41c0aa6c720a914f7423',
    NOTION_URL_TH:
      'https://ilikelm.notion.site/I-LIKE-LM-1275d252fe22809fa146f6464b900283',
    ILIKELMGUIDE_URL_KO:
      'https://ilikelm.notion.site/3-7fcd4f950edc4bdeafb68c86374e8c07',
    ILIKELMGUIDE_URL_EN:
      'https://ilikelm.notion.site/Web3-Creator-DAO-Platform-a99d1615cfa144229472cd8b2f15287c?pvs=4',
    ILIKELMGUIDE_URL_TH:
      'https://ilikelm.notion.site/Web3-Creator-DAO-1275d252fe228129bf4ad333844b090b',
    REWARD_GUIDE_URL_KO:
      'https://ilikelm.notion.site/154ed6142d2f460b95bf2760f3e4f199',
    REWARD_GUIDE_URL_EN:
      'https://ilikelm.notion.site/How-to-use-the-rewards-04caed5254b0499786a8ead76b5ae2d2?pvs=4',
    REWARD_GUIDE_URL_TH:
      'https://ilikelm.notion.site/1275d252fe2281ee9c81feee555dc355',
    USER_GUIDE_KO:
      'https://ilikelm.notion.site/I-LIKE-LM-a5a02f50fc97456a8fbc149b6d97d206',
    USER_GUIDE_EN:
      'https://ilikelm.notion.site/I-LIKE-LM-Reward-System-810fdb0047074f9b85068bf4ab114bcf',
    USER_GUIDE_TH:
      'https://ilikelm.notion.site/I-LIKE-LM-1275d252fe2280e39d56ebca476969b9',
    GOOGLE_RECAPTCHA_KEY: '6Lf1pEopAAAAAB6uHyXlHZsEXiPFYzegLNR9dxQt',
  },
} as { [Properties in Envs]: AppEnv }
