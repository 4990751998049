<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { NovaBoxFollowActionPrivateProps } from './NovaBoxFollowActionPrivate.types'
import { BlockingTarget } from '@store'

const props = withDefaults(defineProps<NovaBoxFollowActionPrivateProps>(), {
  popoverAppendTarget: null,
})

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const tippyRef = ref<typeof Tippy | null>(null)
const { showRemoveFollowerModal } = useFollow()
const userType = computed(() => {
  if (props.source.cmtyUserSeCode === 'C') return BlockingTarget.CREATOR

  return BlockingTarget.USER
})
const tippyOpen = ref(false)
const checkTippyOpened = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: tippyOpen.value ? '닫기' : '열기',
    eventLabel: 'more-horizontal',
    eventSlot: '팔로워 모달 > more 버튼',
    eventI18nAddr: '',
    eventComponent: 'Button',
    cmtyUserSeCode: props.source.cmtyUserSeCode,
    userNcnm: props.source.userNcnm,
    userSn: props.source.userSn,
  })
  tippyOpen.value = !tippyOpen.value
  tippyOpen.value ? tippyRef.value?.show() : tippyRef.value?.hide()
}
// 액션 실행 후 Tippy 닫기
// const handleOnClose = () => {
//   tippyRef.value?.hide()
// }
</script>

<template>
  <Tippy
    ref="tippyRef"
    :append-to="popoverAppendTarget || 'parent'"
    :interactive="true"
    :placement="'bottom-end'"
    :theme="'popover'"
    :trigger="'click'"
  >
    <NovaBoxPostAction
      :icon="{ type: 'outline', name: 'more-horizontal' }"
      :action-type="'privateActions'"
      :display-number="-1"
      @click="checkTippyOpened"
    />

    <template #content>
      <NovaBoxBase class="follow-action-panel" :floating="true">
        <button
          type="button"
          class="action-item"
          @click.stop="showRemoveFollowerModal(source.userSn, userType)"
        >
          <span class="label">{{ t('mypage.myFollow.button.delete') }}</span>
          <NovaIcon
            :icon="{ type: 'outline', name: 'chev-compact-right' }"
            class="chev"
          />
        </button>
      </NovaBoxBase>
    </template>
  </Tippy>
</template>

<style lang="scss" scoped>
.follow-action-panel {
  width: 200px !important;
  padding: 10px 0 !important;
}
.action-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 30px;
  padding: 0 15px 0 20px;

  > .label {
    flex-grow: 1;
    display: flex;
    align-items: center;
    @include text-style($text-body-14-regular);
    line-height: 18px;
    color: $color-text-2;
  }

  > .chev {
    flex-shrink: 0;
    color: $color-text-3;
  }
}
</style>
