<script setup lang="ts">
import { AxiosError } from 'axios'
import { type RouteParamsGeneric, useRoute as useNativeRoute } from 'vue-router'
import { BlockStatus } from '@composables/useBlockStatus'
import {
  BlockingTarget,
  type HomeProfile,
  type Post,
  type MyCollectionNft,
} from '@store'
import type {
  MyPagePostTab,
  UserContentType,
} from '@components/NovaBoxUserTopHeader/NovaBoxUserTopHeader.types'
import { ROUTES } from '@configs'

const { t, locale } = useI18n()

const route = useNativeRoute()
const postStore = usePostStore()
const myPageStore = useMyPageStore()
const userStore = useUserStore()

const showContents = ref<boolean | null>(null)
const err = ref<AxiosError>()

const { blockStatus, blockHeaderMessage, blockContentsMessage } =
  useBlockStatus({
    target: BlockingTarget.USER,
    onShowContents: () => {
      showContents.value = true
    },
  })

const showContentsObserver = computed(() => {
  return !(
    blockStatus.value === BlockStatus.MUTUAL_BLOCKING ||
    blockStatus.value === BlockStatus.BLOCKED_BY_OTHER_USER ||
    (blockStatus.value === BlockStatus.BLOCKED_BY_CURRENT_USER &&
      !showContents.value)
  )
})

// 상황에 맞게 라우트별 프로세스 호출
const processByRouteName = {
  // 가장 기본이 되는 프로세스
  profile: {
    fetch: async (id: string) => {
      try {
        const result = await myPageStore.fetchHomeProfile({
          userNcnm: id.replace('@', ''),
        })
        return Promise.resolve(result)
      } catch (_err) {
        err.value = _err as AxiosError
        return Promise.reject(_err)
      }
    },
    seo: ({ profile }: { profile: HomeProfile }) => {
      useSetSEO({
        useHeadArgs: [
          {
            title: profile.userNcnm,
            meta: [
              {
                name: 'description',
                content: profile.proflDc,
              },
            ],
          },
        ],
        useSeoMetaArgs: [
          {
            ogTitle: profile.userNcnm,
            ogDescription: profile.proflDc,
            ogImage: profile.userProflUrl,
          },
        ],
      })
    },
  },

  // 포스트 상세 프로세스
  post: {
    fetch: async (postId: string) => {
      try {
        const result = await postStore.fetchPost({
          cmtyNttSn: Number(postId),
        })
        return Promise.resolve(result)
      } catch (_err) {
        err.value = _err as AxiosError
        return Promise.reject(_err)
      }
    },
    seo: ({ profile, post }: { profile: HomeProfile; post: Post }) => {
      useSetSEO({
        useHeadArgs: [
          {
            title: `${post.nttSj} | ${profile.userNcnm}`,
            meta: [
              {
                name: 'description',
                content: useGetMaxThreeParagraphTexts(post.nttCn),
              },
            ],
          },
        ],
        useSeoMetaArgs: [
          {
            ogTitle: `${post.nttSj} | ${profile.userNcnm}`,
            ogDescription: useGetMaxThreeParagraphTexts(post.nttCn),
            ogImage: useGetPostThumbUrl(post),
          },
        ],
      })
    },
  },

  // NFT 컬렉션 상세 프로세스
  collection: {
    fetch: async (collectionId: string) => {
      try {
        const result = await myPageStore.fetchMyCollectionNft({
          randboxSn: Number(collectionId),
        })
        return Promise.resolve(result)
      } catch (_err) {
        err.value = _err as AxiosError
        return Promise.reject(_err)
      }
    },
    seo: ({
      profile,
      collection,
    }: {
      profile: HomeProfile
      collection: MyCollectionNft
    }) => {
      useSetSEO({
        useHeadArgs: [
          {
            title: `${collection.colctNm} | ${profile.userNcnm}`,
            meta: [
              {
                name: 'description',
                content: useGetMaxThreeParagraphTexts(collection.colctDc),
              },
            ],
          },
        ],
        useSeoMetaArgs: [
          {
            ogTitle: collection.colctNm,
            ogDescription: useGetMaxThreeParagraphTexts(collection.colctDc),
            ogImage: collection.fileUrl,
          },
        ],
      })
    },
  },
}

const setSeoCaseByCase = ({
  profile,
  post,
  collection,
  err,
}: {
  profile?: HomeProfile | null
  post?: Post | null
  collection?: MyCollectionNft | null
  err?: AxiosError
}) => {
  if (err instanceof AxiosError) {
    switch (err.response?.status) {
      case 4002:
        throw showError({
          statusCode: 404,
          statusMessage: `homeProfile ${route.params.id} is not find`,
        })
      default:
        throw showError({
          statusCode: err.response?.status,
          statusMessage: `homeProfile ${route.params.id} is error`,
        })
    }
  }

  if (profile && post) {
    processByRouteName.post.seo({ profile, post })
    return
  }

  if (profile && collection) {
    processByRouteName.collection.seo({ profile, collection })
    return
  }

  if (profile) {
    processByRouteName.profile.seo({ profile })
  }
}

// CSR 에서만 작동 하도록 immediate 옵션은 사용하지 말것
// route.path 변경 감지는 값을 사용하지 않더라도 트리거를 위해 필요
watch(
  () => [route.params, route.path],
  async ([_curParams], [_prevParams]) => {
    const routeName = useGetRouteName(String(route.name))
    const curParams = _curParams as RouteParamsGeneric
    const prevParams = _prevParams as RouteParamsGeneric

    // 0. 유저 홈 일 경우에만 작동
    if (!routeName.includes('user-id')) {
      return
    }

    // 1. 유저 홈 사용자 닉네임 변경시 새로운 프로필 조회
    if (curParams.id && curParams.id !== prevParams.id) {
      await processByRouteName.profile.fetch(String(curParams.id))
    }

    // 2. 각 하위 페이지에서 동적 URL을 사용하는 상세 페이지 일때
    // 2-1. 유저 홈 > 대표 포스트 상세 || 유저 홈 > 포스트 리스트 > 포스트 상세
    if (
      curParams.postId &&
      curParams.postId !== prevParams.postId &&
      (routeName === ROUTES.USER_HOME_DETAIL.name ||
        routeName === ROUTES.USER_ACTIVITIES_DETAIL.name)
    ) {
      const post = await processByRouteName.post.fetch(String(curParams.postId))
      setSeoCaseByCase({
        profile: myPageStore.homeProfile,
        post,
        err: err.value,
      })
      return
    }

    // 2-2. 유저 홈 > NFT 컬렉션 > NFT 컬렉션 상세
    else if (
      curParams.collectionId &&
      curParams.collectionId !== prevParams.collectionId &&
      routeName === ROUTES.USER_COLLECTIONS_DETAIL.name
    ) {
      const collection = await processByRouteName.collection.fetch(
        String(curParams.collectionId)
      )

      setSeoCaseByCase({
        profile: myPageStore.homeProfile,
        collection,
        err: err.value,
      })
      return
    }

    setSeoCaseByCase({
      profile: myPageStore.homeProfile,
      err: err.value,
    })
  },
  { deep: true }
)

// Server Side: 유저 정보 조회
const { data: _profile } = await useAsyncData('_profile', () =>
  processByRouteName.profile.fetch(String(route.params.id))
)

// Server Side: 포스트 조회
const { data: _post } = await useAsyncData('_post', () => {
  const fetchPostRoutes = [
    ROUTES.USER_ACTIVITIES_DETAIL.name,
    ROUTES.USER_HOME_DETAIL.name,
  ]
  const routeName = useGetRouteName(String(route.name))

  if (fetchPostRoutes.includes(routeName)) {
    return processByRouteName.post.fetch(String(route.params.postId))
  }

  return Promise.resolve(null)
})

// Server Side: 컬렉션 조회
const { data: _collection } = await useAsyncData('_collection', () => {
  const fetchMyCollectionRoutes = [ROUTES.USER_COLLECTIONS_DETAIL.name]

  const routeName = useGetRouteName(String(route.name))
  if (fetchMyCollectionRoutes.includes(routeName)) {
    return processByRouteName.collection.fetch(
      String(route.params.collectionId)
    )
  }
  return Promise.resolve(null)
})

// Server Side: SEO 설정
setSeoCaseByCase({
  profile: _profile.value,
  post: _post.value,
  collection: _collection.value,
  err: err.value,
})

const accessAll = computed(
  () =>
    userStore.isSignIn &&
    userStore.user?.userSn === myPageStore.homeProfile?.userSn
)
const accessTabsByAll: UserContentType[] = [
  'contents',
  'reward',
  'donation',
  'activity',
  'collections',
]
const accessTabsByLimit: UserContentType[] = ['contents']
const tabs = computed(
  () =>
    (accessAll.value ? accessTabsByAll : accessTabsByLimit).map((key) => ({
      id: key,
      name: t(`mypage.tabMenu.${key}`),
    })) as MyPagePostTab[]
)
const ROUTES_LOOKUP: { [Key in UserContentType]: string } = {
  contents: ROUTES.USER.path,
  reward: ROUTES.USER_REWARD.path,
  donation: ROUTES.USER_DONATION.path,
  activity: ROUTES.USER_ACTIVITIES.path,
  collections: ROUTES.USER_COLLECTIONS.path,
}
const tabId = computed<UserContentType>(() => {
  const name = useGetRouteName()
  switch (name) {
    case ROUTES.USER_ACTIVITIES.name:
    case ROUTES.USER_ACTIVITIES_DETAIL.name:
      return 'activity'
    case ROUTES.USER_REWARD.name:
      return 'reward'
    case ROUTES.USER_DONATION.name:
      return 'donation'
    case ROUTES.USER_COLLECTIONS.name:
    case ROUTES.USER_COLLECTIONS_DETAIL.name:
      return 'collections'
    case ROUTES.USER.name:
    case ROUTES.USER_HOME_DETAIL.name:
    default:
      return 'contents'
  }
})

const onChangeTab = (id: UserContentType) => {
  const userId = route.params.id
  const url = ROUTES_LOOKUP[id] || ROUTES.USER.path
  useNavigations({
    url: useRoutePathIdChange(url, {
      id: String(userId),
    }),
  })
}
</script>

<template>
  <NovaLayoutWrapper>
    <NovaHeader />
    <NovaLayoutDefault :page-mode="true">
      <template #center>
        <ClientOnly>
          <div v-if="myPageStore.homeProfile" class="page-user">
            <NovaBoxUserTopHeader
              :key="`${myPageStore.homeProfile.userSn}-${locale}`"
              :tabs="tabs"
              :init-tab="tabId"
              :home-profile="myPageStore.homeProfile!"
              :access-all="accessAll"
              :is-show-block-message="!!blockHeaderMessage"
              :is-show-tab-menu="showContentsObserver"
              @on-change-tab="onChangeTab"
            >
              <template v-if="blockHeaderMessage" #blockContents>
                <NovaBoxCreatorContentBlock
                  :title="blockHeaderMessage.title"
                  :message="blockHeaderMessage.message"
                  :action="blockHeaderMessage.action"
                />
              </template>
            </NovaBoxUserTopHeader>

            <NovaBoxCreatorContentBlock
              v-if="!showContentsObserver && blockContentsMessage"
              :title="blockContentsMessage.title"
              :message="blockContentsMessage.message"
              :action="blockContentsMessage.action"
            />

            <ClientOnly>
              <NuxtPage
                v-if="showContentsObserver"
                :key="myPageStore.homeProfile?.userSn"
              />
            </ClientOnly>
          </div>
          <NovaBoxError v-if="err" :error="err" :instance="true" />
        </ClientOnly>
      </template>
    </NovaLayoutDefault>
  </NovaLayoutWrapper>
</template>

<style lang="scss" scoped>
.page-user {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 0 24px;
}

@include mobile {
  .page-user {
    gap: 20px;
    padding: 20px 0 24px;
  }
}
</style>
