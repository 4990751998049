<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import { type ShowDaoNoticeModalParams } from '@components/NovaBoxDaoNoticeListItem/NovaBoxDaoNoticeListItem.types'
// import { AxiosError } from 'axios'

defineOptions({
  inheritAttrs: false,
})

const { gtEvent } = useGoogleTag()
const { hide: modalHide } = useModal()
const show = ref(false)
const isFail = ref(false)
const errorMessage = ref('')
const noticeSource = ref()

// 팝업을 여는 쪽에서 크리에이터 공지사항 모델 주입 받기
const handleOnGetParams = (evt: RouteParams) => {
  const params = evt.value as unknown as ShowDaoNoticeModalParams
  console.log(params)
  console.log('notice')
  noticeSource.value = params.source
  console.log(noticeSource.value)
}

/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: 'DAO 공지사항 상세보기 닫기',
    eventLabel: 'close-extend',
    eventSlot: 'DAO 공지사항 상세보기 닫기',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
  })
  await modalHide(modalsName.MODAL_DAO_NOTICE_DETAIL)
}

/**
 * 팝업 닫은 후 모달 상태 초기화
 */
const handleOnClosed = () => {
  errorMessage.value = ''
  isFail.value = false
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_DAO_NOTICE_DETAIL"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @closed="handleOnClosed"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-mobile">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'arrow-left' }"
              :theme="'transparent'"
              :size="24"
              class="btn-close-on-mobile"
              @click="handleOnClose"
            />
          </div>

          <div class="on-desktop">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'close-extend' }"
              :theme="'transparent'"
              :size="24"
              :is-modal="true"
              class="btn-close"
              @click="handleOnClose"
            />
          </div>
        </div>
        <NovaLayoutScrollContainer class="modal-body post-detail">
          <NovaBoxDaoNoticeListItem
            :source="noticeSource"
            :view-type="'detail'"
          />

          <NovaBoxEmptyMessage
            v-if="isFail"
            :message="errorMessage"
            :fill="true"
            class="async-status-message"
          />
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;

    .modal-header {
      flex-shrink: 0;
      height: 0;
      padding: 0;
      background-color: $color-bg-3;

      .on-mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        height: 50px;
      }

      .on-desktop {
        .btn-close {
          position: absolute;
          top: 30px;
          right: 30px;
          color: $color-bg-3;
        }
      }
    }

    .modal-body {
      border-radius: 20px;

      > .post {
        border-radius: 0;
        box-shadow: none;
      }

      .async-status-message {
        min-height: 200px;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-header {
        flex-shrink: 0;
        height: 56px;
        padding: 0 20px;

        .on-mobile {
          display: flex;
        }

        .on-desktop {
          .btn-close {
            display: none;
          }
        }
      }

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
