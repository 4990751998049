import type { DaoMemberFilter, DaoPostsOrderBy } from '@store/dao/type'
import { commonError } from '@locales/ko/commonError'
import type { DaoCreatorsFilter } from '@store'
/**
 * DAO
 */
export const dao = {
  opening: {
    dialog: {
      title: '안내',
      creatorMessage:
        'FAM 개설은 아티스트만 가능합니다. <strong>아티스트 신청 후</strong> 이용 해 주세요.',
      followMessage:
        '<strong>팔로워 1,000명 이상 아티스트만</strong> FAM 개설이 가능합니다. ',
      dissolutionMessage:
        '<strong>FAM 해산일 기준 30일 이후 신청이 가능합니다.</strong>',
      cancelMessage: '<strong>FAM 신청을 취소</strong>하시겠습니까?',
      completeMessage:
        '<strong>FAM 개설 신청이 완료</strong>되었습니다. 심사 결과를 기다려주세요.',
      btnBox: {
        checkBtn: '확인',
        cancelBtn: '취소',
        applyBtn: '크리에이터 신청',
      },
    },
  },
  entry: {
    possible: 'FAM 개설하기',
  },
  form: {
    title: 'FAM 신청',
    name: {
      title: '생성할 FAM 이름을 기재해 주세요.',
      placeHolder: '영문으로 입력해주세요.',
      button: '중복 확인',
      message: {
        possible: '사용 가능한 FAM명 입니다.',
        impossible: '이미 존재하는 FAM명 입니다.',
      },
    },
    desc: {
      title: 'FAM에 대해 간단히 작성해 주세요',
      placeHolder: 'FAM에 대해 작성해 주세요.',
    },
    nft: {
      title: '멤버십 NFT로 이용할 이미지를 등록해 주세요.',
      placeHolder: '이미지(1개)를 업로드 하세요.',
      button: {
        file: '파일 찾기',
        add: '파일 첨부',
        delete: '삭제',
      },
      message: {
        fileError: '파일을 업로드하지 않았습니다.',
        volume: '파일 용량은 15MB 이하로 업로드 해주세요.',
        size: '이미지의 가로와 세로는 최소 500px 이상이어야 합니다.',
        guide: '이미지는 가로와 세로의 길이가 같아야 합니다.',
        imgError: '유효하지 않은 이미지 파일입니다.',
        imgSuccess: '이미지가 업로드 되었습니다.',
      },
      description: [
        '이미지 용량 및 형식: <strong>15MB 이하</strong>의 <strong> JPG,PNG</strong> 파일',
        '이미지 사이즈: <strong>500*500px 이상 정방형</strong>',
      ],
    },
    caution: {
      title: '유의 사항',
      description: [
        '<strong>신청조건 : 팔로워가 1,000명 이상</strong>',
        '<strong>FAM의 정보 변경은 어려우니,</strong> 신중히 작성 바랍니다.',
        'NFT이미지 가이드를 확인하고 <strong>양식에 맞는 파일을 업로드</strong> 해주세요',
        '신청일에서 영업일 <strong>3~7일 이내에 신청 승인이 완료됩니다.</strong>',
      ],
      label: {
        duplication: '중복 확인',
      },
      message: {
        guide: '유의 사항을 확인 해 주세요.',
      },
      check: '유의 사항 내용을 확인 하였습니다.',
    },
    dialog: {
      title: '알림',
      content: 'FAM 개설 신청이 완료되었습니다.',
      btnBox: {
        checkBtn: '확인',
      },
    },
    message: {
      available: '사용 가능한 FAM명 입니다.',
      notAvailable: '이미 존재하는 FAM명 입니다.',
      notWritten: '작성하지 않았습니다.',
      overSize: '이미지는 최대 15MB까지 첨부 가능합니다.',
      caution: '유의 사항을 확인해 주세요.',
      check: '중복 체크 해야 합니다.',
    },
    errorMessage: {
      1999: '제재된 사용자 입니다.',
      2006: commonError.network.message, // 필수값이 누락되었습니다.,
      2024: '지갑 생성에 오류가 발생 했습니다.',
      2033: commonError.network.message, // 파라미터 패턴 오류
      2038: '용량이 초과 되었습니다.',
      2043: 'FAM 개설이 불가 합니다.',
      2901: '이미지 형식을 다시 확인 해주세요.',
      2902: '이미지 사이즈를 다시 확인 해주세요.',
      default: commonError.network.message,
    },
  },
  approve: {
    dialog: {
      title: '안내',
      companionMessage:
        '<strong>FAM 개설 신청이 반려</strong>되었습니다.<br/> 사유를 확인 후 재신청 해주세요.',
      companionReasonTitle: '*FAM 개설 반려 사유 :',
      approvalMessage:
        'FAM 개설이 완료되었습니다.<br/> 지금 마이홈에서 FAM으로 전환해 보세요.',
      btnBox: {
        checkBtn: '확인',
      },
    },
  },
  accession: {
    btn: {
      apply: '가입신청',
      pending: '신청중',
    },
    applyModel: {
      title: '[{daoNm}] FAM 가입 신청',
      message: '신청 사유를 기재해주세요.',
      placeholder: '신청 사유를 기재해주세요.',
      errorMessage: '작성하지 않았습니다.',
      btnBox: {
        applyBtn: '신청하기',
        participating: '참여중',
        pending: '신청중',
      },
    },
    dialog: {
      title: '안내',
      companionMessage:
        '<strong>FAM 개설 신청이 반려</strong>되었습니다. 사유를 확인 후 재신청 해주세요.',
      companionReasonTitle: '*FAM 개설 반려 사유 :',
      approvalMessage:
        'FAM 개설이 완료되었습니다. 지금 마이홈에서 FAM으로 전환해 보세요.',
      applyMessage:
        '<strong>[{daoNm}] FAM</strong>에 <strong>가입 신청이 완료</strong>되었습니다.',
      beforeMessage:
        '투표 대상이 아닙니다. <strong>FAM 가입 후 이용</strong>해 주세요.',
      btnBox: {
        checkBtn: '확인',
      },
    },
  },
  vote: {
    message: {
      empty: '투표 안건이 없습니다.',
    },
    create: {
      header: '투표 생성하기',
      conditions: {
        title: '투표 참여 조건',
        errMsg: 'NFT를 선택해 주세요.',
        placeHolder: 'NFT 선택',
      },
      name: {
        title: '투표 제목',
        placeHolder: '투표 제목을 입력해주세요.',
        errMsg: '작성하지 않았습니다.',
      },
      contents: {
        title: '항목 입력',
        add: '+ 항목 추가',
        placeHolder: '항목 {index} 입력',
        over: '투표 항목 최대 개수는 10개 입니다.',
        error: {
          select: '최소 2개 이상 항목이 필요합니다.',
          value: '입력하지 않은 항목이 있습니다.',
        },
      },
      period: {
        title: '투표 기간',
        start: '시작일',
        errStartMsg: '시작일을 먼저 선택 해 주세요.',
        end: '마감일',
        errMsg: '투표 기간을 선택하지 않았습니다.',
      },
      button: {
        create: '생성',
      },
    },
    modify: {
      header: '투표 수정하기',
      button: {
        delete: '삭제',
        modify: '수정',
      },
    },
    list: {
      badge: '참여',
      message: '진행 예정중인 투표 입니다.',
    },
    createButton: '생성하기',
    sort: {
      all: '전체',
      inProgress: '진행중',
      expected: '예정',
      end: '마감',
    },
    dialog: {
      title: '안내',
      deleteMessage: '투표를 삭제하시겠습니까?',
      modifyMessage: '투표를 수정하시겠습니까?',
      actions: {
        negative: '취소',
        positive: '확인',
      },
    },

    detail: {
      badge: {
        noVote: '미참여',
      },
      count: '표',
      button: {
        votesAvailable: '투표하기',
        votesUnavailable: '투표 대상이 아닙니다.',
        completeVote: '투표 완료',
      },
    },
    success: '투표가 완료되었습니다.',
    errorMessage: {
      1999: '제재된 사용자 입니다.',
      2006: commonError.network.message, // '필수값이 누락되었습니다.',
      2039: '투표 문항 답변 목록은 2~10개까지 등록 가능 합니다.',
      2040: 'FAM 투표 기간을 잘못 입력 하였습니다. 다시 입력 해주세요.',
      2041: '투표 수정이 불가 합니다.',
      2042: '투표가 불가합니다.',
      2049: commonError.network.message, // 'NFT 스냅샷 자산 조회 에러',
      2050: commonError.network.message, // '스냅샷 상태 조회 에러',
      2990: '요청 처리중 입니다. 잠시 후 다시 시도해주세요.',
      5400: '권한이 없습니다.',
      default: commonError.network.message,
    },
    // } as { [Property in CommentsAndRepliesOrderBy]: string },
  },
  board: {
    title: '게시판',
    createButton: '글쓰기',
    list: {
      title: '공지',
      message: 'FAM 가입 후 게시글을 확인 해 주세요.',
    },
    create: {
      header: '게시판 작성',
      dropdown: {
        tempSave: '임시저장 불러오기',
        readPermission: {
          placeHolder: '보기 권한 선택',
          follower: '전체 회원',
          onlyMember: 'FAM 회원만',
        },
      },
      title: {
        placeHolder: '제목을 입력해주세요.',
      },
      option: {
        cmments: '댓글 허용',
        notice: '공지 등록',
      },
      contents: {
        placeHolder: '보기 권한을 먼저 선택하세요.',
      },
    },
    sorter: {
      commentAndReply: {
        newest: '최신순',
        oldest: '과거순',
      } as { [Property in DaoPostsOrderBy]: string },
    },
    errorMessage: {
      1999: '제재된 사용자 입니다.',
      2000: '해당 글을 찾을 수 없습니다.',
      2006: commonError.network.message, // '필수값이 누락되었습니다.',
      2009: commonError.network.message, // '지정되지 않은 타입 코드 입니다.',
      2010: '중복 처리 되었습니다.',
      2013: '타인 작성 글은 삭제 불가합니다.',
      2015: '허용 가능한 해시태그 갯수를 초과했습니다. 다시 시도해 주세요.',
      2018: '허용 가능한 해시태그 글자수를 초과했습니다. 다시 시도해 주세요.',
      2041: '투표 수정이 불가 합니다.',
      2990: '요청 처리중 입니다. 잠시 후 다시 시도해주세요.',
      4002: '가입되지 않은 회원입니다.',
      5400: '권한이 없습니다.',
      5401: '권한이 없습니다.',
      8050: '자기 자신에게 좋아요를 할 수 없습니다.', // 활동 대상 오류 - 본인에게 활동 한 경우
      default: commonError.network.message,
    },
  },
  member: {
    approval: {
      pending: '대기',
      approval: '승인',
      refusal: '거절',
    },
    memberList: {
      empty: '목록 데이터가 없습니다.',
      error: '해당 목록을 가져올 수 없습니다. 잠시 후 다시 시도해 주세요.',
    },
    management: {
      member: '멤버',
      dialog: {
        title: '안내',
        approveMessage: '<strong>승인하시겠습니까?</strong>',
        refuseMessage: '승인을 <strong>거절하시겠습니까?</strong>',
        refusePlaceholder: '거절 사유를 입력해 주세요.',
        deportMessage: '<strong>해당 유저를 추방</strong>하시겠습니까?',
        deportPlaceholder: '추방 사유를 입력해 주세요.',
        appointMessage:
          '<strong>[{userName}]</strong>를 <strong>관리자로 임명</strong>하시겠습니까?',
        appointErrorMessage: '관리자 정원은 <strong>최대 5명</strong> 입니다.',
        dismissMessage: '<strong>관리자를 해제</strong>하시겠습니까?',
        leaveMessage:
          '탈퇴 시 부여 받은 <strong>멤버십 NFT는 소멸</strong> 됩니다. 탈퇴한 FAM은 <strong>탈퇴일 기준 30일 이후 재가입</strong>이 가능합니다.',
        leaveSuccess: '탈퇴 처리 되었습니다.',
        disbandSuccess: 'FAM 해산 처리 되었습니다.',
        actions: {
          negative: '취소',
          positive: '확인',
        },
        successMessage: {
          approval: '멤버 승인 되었습니다.',
          refuse: '멤버 승인이 거절 되었습니다.',
          accession: '현재 FAM 가입이 되어 있습니다.',
          appoint: '관리자로 임명되었습니다.',
          dismiss: '관리자를 해제 하였습니다.',
          deport: '멤버가 추방 되었습니다.',
        },
        errorMessage: {
          1999: '제재된 사용자 입니다.',
          2006: commonError.network.message, // 필수값이 누락되었습니다.,
          2010: '중복 처리 되었습니다.',
          2011: '사용자 정보를 찾을 수 없습니다.',
          2044: 'FAM 해산이 불가 합니다.',
          2990: '요청 처리중 입니다. 잠시 후 다시 시도해주세요.',
          5400: commonError.network.message, // '개설자는 탈퇴 불가능 합니다.'
          default: commonError.network.message,
        },
      },
      btnBox: {
        deportBtn: '추방',
        appointBtn: '임명',
        dismissBtn: '해제',
      },
    },
    errorMessage: {
      1999: '제재된 사용자 입니다.',
      2006: commonError.network.message, // 필수값이 누락되었습니다.,
      2009: commonError.network.message, // 지정되지 않은 타입 코드 입니다.
      2010: '중복 처리 되었습니다.',
      2011: '멤버가 존재 하지 않습니다.',
      2024: '지갑 생성에 오류가 발생 했습니다.',
      2041: '투표 수정이 불가 합니다.',
      2046: '이미 가입된 FAM 멤버 입니다.',
      2048: '가입 거절(7일) 혹은 탈퇴(30일) 상태로 가입 불가합니다.',
      2051: '강제 추방으로 영구 가입이 불가합니다.',
      2990: '요청 처리중 입니다. 잠시 후 다시 시도해주세요.',
      5400: '권한이 없습니다.',
      5402: '관리자 최대 인원 초과 되었습니다.',
      default: commonError.network.message,
    },
  },
  dissolution: {
    dissolutionModel: {
      title: 'FAM 정보',
      name: 'FAM 명칭',
      introduction: 'FAM 소개',
      membershipNFT: 'MEMBERSHIP NFT 이미지',
      dissolutionBox: {
        title: 'FAM 해산',
        leave: 'FAM 탈퇴',
        message:
          'FAM 해산 신청은 마지막 멤버가 나가고 가입 대기 인원이 없을 때만 가능합니다. 가입 대기 인원이 있으면 가입 승인을 거절해 주세요.',
        btn: '신청',
      },
    },
    dialog: {
      title: '안내',
      message: 'FAM 해산 신청을 하시겠습니까?',
      actions: {
        negative: '취소',
        positive: '확인',
      },
    },
  },
  filter: {
    member: {
      apply: '가입 요청',
      management: '관리',
    } as { [Property in DaoMemberFilter]: string },
    board: {
      all: '전체',
      notice: '공지사항',
    },
    creator: {
      all: '전체',
      dao: 'FAM',
    } as { [Property in DaoCreatorsFilter]: string },
  },
  leave: {
    model: {
      title: '회원 탈퇴',
      message:
        '현재 FAM 가입이 되어 있습니다.<br /> 탈퇴 시 소유하고 있는 FAM Membership NFT가 소각 처리 되며,<br /> FAM 또한 탈퇴처리가 됩니다.',
      importantMessage: '정말로 회원 탈퇴를 진행하시겠습니까?',
      form: {
        title: '비밀번호',
        placeHolder: '비밀번호를 입력해 주세요.',
      },
      actions: {
        negative: '취소',
        positive: '확인',
      },
    },
    dialog: {
      title: '안내',
      message:
        'FAM 마스터로 <strong>FAM 해산 후 <br /> 탈퇴 신청이 가능</strong>합니다.',
      actions: {
        checkBtn: '확인',
      },
    },
    successMessage: '회원 탈퇴 되었습니다.',
  },
  createCard: {
    sttusCode: {
      pCode: '신청중',
      sCode: '참여중',
    },
  },
  index: {
    daoItem01: {
      textBox: {
        text01: 'Together We Create, Together We Thrive',
        text02: 'FAM',
      },
      btnBox: {
        listOfDAO: 'List of FAM',
        create: '개설 신청하기',
        reviewing: '신청 중',
      },
    },
    daoItem02: {
      textBox: {
        title: '<span>FAM</span> 이란 무엇일까요?',
        message:
          'FAM은 팬(Fans)과 아티스트(Artist)가 함께 미션을 통해 목표(Mission)를 달성하고 리워드를 받을 수 있는 참여형 커뮤니티입니다.',
      },
      governanceBox: {
        title: '<span>FAM</span> Governance',
        nft: 'Digital Card',
        voting: 'Voting',
        community: 'Community',
      },
    },
    daoItem03: {
      title: '<span>FAM</span> 의 역할',
      daoCreator: {
        title: '아티스트',
        creative: {
          title: 'Creative',
          message:
            '팬들과 소통하며 새로운 아이디어를 반영해 창의적인 콘텐츠를 제작할 수 있습니다.',
        },
        interaction: {
          title: 'Interaction',
          message:
            '팬들과 직접적인 소통을 통해 함께 성장하며 깊은 유대감을 형성합니다.',
        },
      },
      daoMember: {
        title: '멤버',
        cooperation: {
          title: 'Voting & Proposing',
          message:
            '아티스트의 프로젝트에 투표하거나 아이디어를 제안해 커뮤니티에 적극적으로 참여합니다.',
        },
        communication: {
          title: 'Communication',
          message:
            '다른 팬들과 협력하며 FAM 커뮤니티를 더욱 풍성하게 만듭니다.',
        },
      },
    },
    daoItem04: {
      title: '<span>FAM</span> 개설 및 참여 조건',
      textBox: {
        creation: {
          title: 'FAM 개설 :',
          message: '팔로워 1,000명 이상인 아티스트',
        },
        participation: {
          title: 'FAM 참여 :',
          message: 'I LIKE LM 회원, 아티스트의 팬',
        },
      },
    },
  },
  home: {
    topNotice: 'FAM에 대해 알아보세요!',
  },
}
