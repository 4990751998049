<script setup lang="ts">
import { NovaModalCommon, NovaModalRetireGuide } from '#components'

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { show: modalShow, hide: modalHide } = useModal()

const userStore = useUserStore()

const state = reactive({
  isShow: false,
  password: '',
  isError: false,
  isLoading: false,
})
const isValidate = computed(() => state.password !== '')

const openModalGuide = async () => {
  await modalShow({
    component: NovaModalCommon,
    bind: {
      name: modalsName.MODAL_MEMBERSHIP_OFF_GUIDE,
      showCloseBtn: true,
      mobileFull: true,
    },
    on: {
      close: async () => {
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '회원 탈퇴 가이드 모달 > 닫기',
          eventLabel: '',
          eventSlot: '회원 탈퇴',
          eventI18nAddr: '',
          eventComponent: 'Button',
        })
        await modalHide(modalsName.MODAL_MEMBERSHIP_OFF_GUIDE)
      },
    },
    slots: {
      title: t('retire.title'),
      noScrollContents: {
        component: NovaModalRetireGuide,
        on: {
          close: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '회원 탈퇴 가이드 모달 > 닫기',
              eventLabel: '',
              eventSlot: '회원 탈퇴',
              eventI18nAddr: '',
              eventComponent: 'Button',
            })
            await modalHide(modalsName.MODAL_MEMBERSHIP_OFF_GUIDE)
          },
        },
      },
    },
  })
}

const onMoveNextStep = async () => {
  if (userStore.user?.snsCnncAt === 'Y') {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '회원 탈퇴 > 확인',
      eventLabel: t('confirm'),
      eventSlot: 'SNS 회원',
      eventI18nAddr: useKoreanTranslation('confirm'),
      eventComponent: 'Button',
    })
    await modalHide(modalsName.MODAL_MEMBERSHIP_OFF)
    await openModalGuide()
    return
  }

  try {
    state.isLoading = true
    state.isError = false
    await userStore.checkRetirePassword({ password: state.password })
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '회원 탈퇴 > 확인',
      eventLabel: t('confirm'),
      eventSlot: '',
      eventI18nAddr: useKoreanTranslation('confirm'),
      eventComponent: 'Button',
    })
    await modalHide(modalsName.MODAL_MEMBERSHIP_OFF)
    await openModalGuide()
  } catch (err) {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '회원 탈퇴 > 확인 > 비밀번호 오류',
      eventLabel: t('confirm'),
      eventSlot: '',
      eventI18nAddr: useKoreanTranslation('confirm'),
      eventComponent: 'Button',
    })
    state.isError = true
  } finally {
    state.isLoading = false
  }
}
</script>

<template>
  <div class="retire-content">
    <NovaLayoutScrollContainer class="content-area">
      <div class="content-box">
        <div class="text-box">
          <span
            v-if="userStore.retireType?.subscribed"
            v-dompurify-html="t('dao.leave.model.message')"
          ></span>
          <span v-else class="text">{{
            t('retire.password.content.text')
          }}</span>
          <span class="text warning">{{
            t('retire.password.content.warning')
          }}</span>
        </div>
        <div v-if="userStore.user?.snsCnncAt === 'N'" class="form-box">
          <span class="title">{{ t('retire.password.title') }}</span>
          <NovaInput
            v-model="state.password"
            :type="state.isShow ? 'text' : 'password'"
            :is-clear="false"
            :theme="'search'"
          >
            <template #tools>
              <NovaButtonIcon
                :icon="{
                  type: 'outline',
                  name: state.isShow ? 'view' : 'view-off',
                }"
                class="show-button"
                @click="state.isShow = !state.isShow"
              />
            </template>
          </NovaInput>
          <span v-if="state.isError" class="validate error">{{
            t('retire.password.validate')
          }}</span>
        </div>
      </div>
    </NovaLayoutScrollContainer>
    <div class="action-box">
      <NovaButtonText
        :label="$t('confirm')"
        :full-width="true"
        :theme="'primary-blue'"
        :disabled="userStore.user?.snsCnncAt === 'Y' ? false : !isValidate"
        :loading="state.isLoading"
        class="btn-action"
        @click="onMoveNextStep"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.retire-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .content-area {
    align-items: center;
    width: 100%;

    .content-box {
      width: 100%;

      .text-box {
        .text {
          display: block;
          @include text-style($text-body-14-regular);
          color: $color-text-2;

          &.warning {
            color: $color-primary-red;
          }
        }
      }

      .form-box {
        padding-top: 20px;

        .title {
          display: block;
          margin-bottom: 4px;
          @include text-style($text-heading3-bold);
          color: $color-text-3;
        }

        .validate {
          display: block;
          @include text-style($text-body-12-regular);
          color: $color-primary-dark-3;
          margin-top: 8px;

          &.error {
            color: $color-primary-red;
          }
        }
      }
    }
  }

  .action-box {
    flex-shrink: 0;
    width: 100%;
    padding-top: 16px;
  }

  @include mobile {
    .content-area {
      padding: 16px 20px;
    }

    .action-box {
      padding: 16px 20px 24px;
    }
  }
}
</style>
