/**
 * KPOP Cover Dance 신청하기
 */
export const applyKpopCoverDance = {
  entry: 'แบบฟอร์มใบสมัครm',
  message: {
    err: 'จำเป็นต้องกรอกข้อมูลในช่องนี้',
    invalidUrl: 'ที่อยู่ URL ไม่ถูกต้อง โปรดป้อนลิงก์ที่ถูกต้อง',
    representative: '* หากอายุต่ำกว่า 18 ปี ต้องมีแบบฟอร์มยินยอมจากผู้ปกครอง',
    errEmail: 'รูปแบบอีเมลไม่ถูกต้อง',
    deleteMember: {
      title: 'Notice',
      contents: 'คุณแน่ใจหรือว่าต้องการลบ?',
      btn: {
        cancel: 'ยกเลิก',
        confirm: 'ยืนยัน',
      },
    },
    confirmApply: {
      title: 'Notice',
      contents:
        '<strong style="font-size: 16px">คุณต้องการดำเนินการส่งใ<br /> บสมัครให้เสร็จสิ้นหรือไม่?</strong>' +
        '<br /><br />* คำแนะนำจะถูกส่งไปยังที่อยู่อีเมลของตัวแทน',
      btn: {
        cancel: 'ยกเลิก',
        confirm: 'ยืนยัน',
      },
      applicationCloses: {
        title: 'Notice',
        content: 'ระยะเวลาการลงทะเบียนสิ้นสุดลงแล้ว',
      },
    },
  },
  member: {
    top: 'สมาชิก',
    name: {
      label: 'ชื่อ',
      placeholder: 'ชื่อ',
    },
    email: {
      label: 'อีเมล',
      placeholder: 'อีเมล',
    },
    phone: {
      label: 'หมายเลขโทรศัพท์มือถือ',
      placeholder: '000-000-0000',
    },
    age: {
      label: 'อายุ',
      placeholder: 'อายุ',
      message: '* หากอายุต่ำกว่า 18 ปี ต้องมีแบบฟอร์มยินยอมจากผู้ปกครอง',
    },
    wallet: {
      label: 'ที่อยู่กระเป๋า Bitkub',
      message: '* กรุณากรอกที่อยู่กระเป๋าของคุณเพื่อรับ airdrop',
      message2: '*  จำนวน airdrop: 1,000 LM (มูลค่า 123 THB)',
    },
  },
  addMember: '+ เพิ่มสมาชิกทีม',
  team: {
    label: 'ชื่อทีม',
    placeHolder: 'ชื่อทีม',
  },
  representative: {
    label: 'ตัวแทน',
  },
  introduce: {
    label: 'แนะนำตัว',
    video: {
      label: 'วิดีโอแนะนำตัว',
      placeHolder: 'Instagram, YouTube, TikTok',
      descriptions: [
        '<strong>กรุณาใส่ URL ของวิดีโอการเต้นคัฟเวอร์ K-POP ของคุณ</strong>',
        'กรุณาส่ง URL ของวิดีโอการเต้นคัฟเวอร์ที่มีความยาวระหว่าง 30 วินาทีถึง 1 นาท',
        ' วิดีโอนี้จะใช้สำหรับการคัดเลือกในรอบเบื้องต้น',
      ],
    },
    motivation: {
      label: 'แรงจูงใจในการสมัคร',
    },
  },
  applyBtn: 'ส่ง',
  eventDetail: {
    btn: {
      apply: 'ปรับใช้',
      complete: 'เสร็จสมบูรณ์แล้ว',
    },
  },
  terms: {
    agreeAll: 'ยอมรับข้อกำหนดและเงื่อนไขทั้งหมด',
    view: 'ดู',
    confirm: 'ยืนยัน',
    err: {
      title: 'Notice',
      content: 'มีข้อกำหนดและเงื่อนไขที่คุณไม่เห็นด้วย',
    },
  },
}
