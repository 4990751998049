import dayjs from 'dayjs'
import { DAY, HOUR, MINUTE, SECOND, WEEK } from '@configs'

export interface GmtParams {
  date: string
  prefixFormat?: string
}

export interface DateParams {
  date: string
  format: string
}

export type GapUnit = SECOND | MINUTE | HOUR | DAY | WEEK

/**
 * 날짜 형식 작업과 관련된 유틸리티 함수를 제공
 * @module useDateFormat
 */
export const useDateFormat = () => {
  const { t } = useNuxtApp().$i18n

  /**
   * 주어진 날짜와 현재 날짜 사이의 간격을 지정한 단위로 계산
   *
   * @param {string} date - 간격을 계산할 날짜, 날짜는 유효한 문자열 형태의 날짜
   * @param {GapUnit} unit - 간격을 계산할 단위, 이 단위는 GapUnit 열거형의 값 중 하나
   * @returns {number} - 주어진 날짜와 현재 날짜 사이의 간격을 지정한 단위로 표시한 값
   */
  const gapDate = (date: string, unit: GapUnit) => {
    date = localStrToISOString(date)
    const nowTimestamp = new Date().getTime()
    const past = new Date(date)
    const pastTimestamp = past.getTime()
    const gap = nowTimestamp - pastTimestamp
    return Math.ceil(gap / unit)
  }

  /**
   * ISO-8601 유효성 검사
   * @param date
   */
  const isValidISOString = (date: string) => {
    const iso8601 =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})$/

    if (!iso8601.test(date)) {
      return false
    }

    const parsedDate = new Date(date)

    return !isNaN(parsedDate.getTime())
  }

  /**
   * YYYY-MM-DD'T'HH:mm:ss(2021-06-15T01:48:37(로컬시간)) 유효성 검사
   * @param date
   */
  const isValidLocalString = (date: string) => {
    return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(date)
  }

  /**
   * 비표준 IOS 문자열 날짜를 ISO 문자열 날짜로 변환
   *
   * @param {string} date - 변환할 문자열 날짜(2021-06-15T01:48:37)
   * @returns {string} 변환된 ISO 문자열 날짜다.(2021-06-15T01:48:37Z)
   */
  const localStrToISOString = (date: string): string => `${date}Z`

  /**
   * 지정된 형식 패턴을 사용하여 주어진 날짜 문자열을 사용자 정의 형식으로 변환
   *
   * @param {string} date - 형식을 적용할 날짜 문자열
   * @param {string} format - 적용할 형식 패턴
   * @returns {string} - 형식이 적용된 날짜 문자열
   */
  const dateFormat = (date: string, format: string): string =>
    dayjs(date).utc().local().format(format)

  /**
   * 주어진 날짜를 시간대 정보와 함께 형식화
   *
   * @param {string} date - 문자열 형식으로 된 형식화할 날짜
   * @param {string} prefixFormat - 날짜 접두사의 형식
   * @returns {string} - 시간대 정보와 함께 형식화된 날짜
   */
  const dateFormatWithTimezone = (
    date: string,
    prefixFormat: string
  ): string => {
    const localDate = dateFormat(date, prefixFormat)
    const timezone = dayjs().format('Z')
    return `${localDate} (GMT ${timezone})`
  }

  /**
   * 주어진 날짜를 시간대 정보와 함께 형식화
   *
   * @param {string} date - 문자열 형식으로 된 형식화할 날짜
   * @param {string} prefixFormat - 날짜 접두사의 형식
   * @returns {string} - 시간대 정보와 함께 형식화된 날짜
   */
  const dateFormatWithKst = (date: string, prefixFormat: string): string => {
    const localDate = dateFormat(date, prefixFormat)
    return `${localDate} (KST)`
  }

  /**
   * 주어진 날짜를 현재 날짜와의 시간 차이를 계산하여 형식화
   *
   * @param {string} date - 형식화해야 하는 과거의 날짜
   * @returns {string} - 형식화된 날짜 문자열
   */
  const dateFormatWithHumanize = (date: string): string => {
    const nowTimestamp = new Date().getTime()
    const past = new Date(date)
    const pastTimestamp = past.getTime()
    const gap = nowTimestamp - pastTimestamp

    // 1시간 이내: 방금전
    if (gap < HOUR) return t('gapTime.underHour')
    // 1일 이내: x 시간전
    if (gap < DAY) return `${Math.floor(gap / HOUR)}${t('gapTime.underDay')}`
    // 1주일 이내: x 일전
    if (gap < WEEK) return `${Math.floor(gap / DAY)}${t('gapTime.underWeek')}`

    // 그외: YYYY-MM-DD hh:mm (GMT {TimezoneOffsetHours})
    return gmt({ date })
  }

  /**
   * 주어진 날짜 문자열을 지정된 형식으로 형식화된 날짜 문자열로 변환
   * @param {Object} dateParams - 날짜 매개변수
   * @param {string} dateParams.date - 형식화할 날짜 문자열
   * @param {string} dateParams.format - 출력 날짜 문자열의 형식
   * @returns {string} - 형식화된 날짜 문자열, 입력이 유효하지 않은 경우 빈 문자열을 반환
   */
  const date = ({ date, format }: DateParams): string => {
    if (isValidISOString(date)) return dateFormat(date, format)
    if (isValidLocalString(date)) {
      const _date = localStrToISOString(date)
      return dateFormat(_date, format)
    }
    return ''
  }

  /**
   * 주어진 날짜를 지정된 시간대 접두사와 함께 형식화한다.
   *
   * @param {Object} gmtParam - GMT 파라미터 객체.
   * @param {string} gmtParam.date - 형식화할 날짜. 유효한 ISO 문자열이나 유효한 로컬 문자열이어야 한다.
   * @param {string} [gmtParam.prefixFormat='YYYY-MM-DD HH:mm'] - 시간대 접두사의 형식. 기본값은 'YYYY-MM-DD HH:mm'이다.
   * @returns {string} - 시간대 접두사와 함께 형식화된 날짜, 또는 입력된 날짜가 유효하지 않다면 빈 문자열을 반환한다.
   */
  const gmt = ({
    date,
    prefixFormat = 'YYYY-MM-DD HH:mm',
  }: GmtParams): string => {
    if (isValidISOString(date)) {
      return dateFormatWithTimezone(date, prefixFormat)
    }
    if (isValidLocalString(date)) {
      const _date = localStrToISOString(date)
      return dateFormatWithTimezone(_date, prefixFormat)
    }

    return ''
  }

  /**
   * 주어진 날짜를 지정된 시간대 접두사와 함께 형식화한다.
   *
   * @param {Object} gmtParam - GMT 파라미터 객체.
   * @param {string} gmtParam.date - 형식화할 날짜. 유효한 ISO 문자열이나 유효한 로컬 문자열이어야 한다.
   * @param {string} [gmtParam.prefixFormat='YYYY-MM-DD HH:mm'] - 시간대 접두사의 형식. 기본값은 'YYYY-MM-DD HH:mm'이다.
   * @returns {string} - 시간대 접두사와 함께 형식화된 날짜, 또는 입력된 날짜가 유효하지 않다면 빈 문자열을 반환한다.
   */
  const kst = ({
    date,
    prefixFormat = 'YYYY-MM-DD HH:mm',
  }: GmtParams): string => {
    if (isValidISOString(date)) {
      return dateFormatWithKst(date, prefixFormat)
    }
    if (isValidLocalString(date)) {
      const _date = localStrToISOString(date)
      return dateFormatWithKst(_date, prefixFormat)
    }

    return ''
  }

  /**
   * 주어진 날짜와 현재 날짜의 차이를 사람이 읽을 수 있는 문자열로 반환.
   *
   * @param {string} date - 주어진 날짜
   * @returns {string} 계산된 문자열
   */
  const humanize = (date: string): string => {
    if (isValidISOString(date)) return dateFormatWithHumanize(date)
    if (isValidLocalString(date)) {
      const _date = localStrToISOString(date)
      return dateFormatWithHumanize(_date)
    }
    return ''
  }

  return {
    localStrToISOString,
    gapDate,
    date,
    gmt,
    kst,
    humanize,
  }
}
