<script setup lang="ts">
import { AxiosError } from 'axios'

const { hide: modalHide } = useModal()
const { t, messages, locale } = useI18n()
const { getPrivateKey } = useMembershipProcess()
const userStore = useUserStore()
const creatorStore = useCreatorStore()
const daoStore = useDaoStore()
const layoutStore = useLayoutStore()
const show = ref(false)

const state = reactive({
  value: '',
  placeholder: '',
  errorMsg: '',
  maxLength: 500,
})

const activeConfirm = computed(() => {
  return state.value.length > 1
})

const daoMbrSn = ref(0)
const handleOnGetParams = (evt: any) => {
  if (evt && evt.value) {
    const payload = evt.value as { daoMbrSn: number }

    daoMbrSn.value = payload.daoMbrSn
  } else {
    // evt가 올바르지 않을 때의 처리 로직
    console.error('Invalid RouteParams', evt)
  }
}

watch(
  () => show.value,
  (cur) => {
    if (!cur) {
      daoMbrSn.value = 0
      state.value = ''
    }
  }
)

const confirm = async () => {
  try {
    const privateKey = await getPrivateKey(userStore.user!.userSn)
    const payload = {
      daoMbrSn: daoMbrSn.value,
      fndrUserSn: creatorStore.profile!.userSn,
      privateKey,
      resultCn: state.value,
    } as DaoMemberDeport

    await modalHide(modalsName.MODAL_DAO_DEPORT)
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    await daoStore.editDaoMemberDeport(payload)
    await useToast(t('dao.member.management.dialog.successMessage.deport'))
  } catch (err) {
    if (err instanceof AxiosError) {
      const errCode = err.response.status.toString()
      const errCodes = Object.keys(
        (messages.value[locale.value].dao as any).member.errorMessage
      )
      if (errCodes.includes(errCode)) {
        await useToast(t(`dao.member.errorMessage.${errCode}`))
        return
      }
      await useToast(t('dao.member.errorMessage.default'))
    }
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

const close = () => {
  modalHide(modalsName.MODAL_DAO_DEPORT)
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_DAO_DEPORT"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @click-outside="close"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-desktop">
            <h4 class="title">{{ t('dao.member.management.dialog.title') }}</h4>
            <!--            <div class="close-wrap" @click="close">
              <NovaButtonIcon
                :icon="{ type: 'outline', name: 'close-extend' }"
                class="btn-close"
                @click="close"
              />
            </div>-->
          </div>
        </div>
        <div class="modal-body">
          <section class="form-field-wrap">
            <div class="section-title-wrap">
              <h4
                v-dompurify-html="
                  t('dao.member.management.dialog.deportMessage')
                "
                class="title"
              ></h4>
            </div>
            <div class="form-field-wrap">
              <div class="field">
                <NovaTextarea
                  v-model="state.value"
                  :placeholder="
                    t('dao.member.management.dialog.deportPlaceholder')
                  "
                  :error-msg="state.errorMsg"
                  :max-length="state.maxLength"
                  :rows="4"
                  :theme="'bggray'"
                />
              </div>
            </div>
          </section>
          <section>
            <div class="form-field-wrap-btns">
              <NovaButtonText
                :label="t('dao.member.management.dialog.actions.negative')"
                :width="200"
                :theme="'secondary-gray-200'"
                :full-width="true"
                @click="close"
              />
              <NovaButtonText
                :label="t('dao.member.management.dialog.actions.positive')"
                :width="200"
                :theme="'primary-blue-dark'"
                :full-width="true"
                :disabled="!activeConfirm"
                @click="confirm"
              />
            </div>
          </section>
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 12px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 360px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;
    background-color: $color-border-1;

    .modal-header {
      .on-desktop {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        flex-grow: 1;
        background-color: $color-white;
        border-radius: 20px 20px 0 0;
        @include text-style($text-display-medium);

        > .title {
          flex-grow: 1;
          color: $color-black;
          text-align: center;
          font-weight: 700;
        }

        .close-wrap {
          .btn-close {
            color: $color-text-2;
          }
        }
      }
    }

    .modal-body {
      display: flex;
      gap: 12px;
      padding: 0px 20px 32px 20px;
      border-radius: 0 0 20px 20px;
      flex-direction: column;
      background-color: $color-white;
      .section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 35px;
      }
      .section-title-wrap {
        text-align: center;
        .title {
          font-size: 16px;
        }
      }

      .form-field-wrap {
        display: flex;
        flex-direction: column;
        gap: 6px;
        :deep(.field) {
        }
        .error-message {
          display: flex;
          align-items: center;
          gap: 6px;
          @include text-style($text-body-12-regular);
          color: $color-primary-red;

          &:before {
            @include error-icon;
          }
        }
      }
      .form-field-wrap-btns {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 10px;
      }
    }
  }
}
/*@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}*/
</style>
