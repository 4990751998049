<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import { AxiosError } from 'axios'
import type { ShowCreatorNoticeDetailModalParams } from '@components/NovaBoxCreatorNoticeListItem/NovaBoxCreatorNoticeListItem.types'
import type { CreatorNotice } from '@store'

defineOptions({
  inheritAttrs: false,
})

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const creatorStore = useCreatorStore()
const creatorNotice = ref<CreatorNotice>()
const postStore = usePostStore()
const { hide: modalHide } = useModal()
const show = ref(false)
const isFail = ref(false)
const errorMessage = ref('')

watch(
  () => creatorStore.creatorNotice,
  (cur) => {
    if (!cur && show.value) {
      handleOnClose()
    }
  }
)

// 팝업을 여는 쪽에서 크리에이터 공지사항 모델 주입 받기
const handleOnGetParams = (evt: RouteParams) => {
  const notice = evt.value as unknown as ShowCreatorNoticeDetailModalParams

  if (notice.creatorNotice) {
    creatorNotice.value = notice.creatorNotice
    return
  }

  if (notice.err) {
    if (notice.err instanceof AxiosError) {
      switch (notice.err.response?.status) {
        case 2020:
          errorMessage.value = t('statusMessages.noticeDetail.error.drop')
          break
        default:
          errorMessage.value = t('statusMessages.noticeDetail.error.notFound')
          break
      }
    } else {
      errorMessage.value = t('statusMessages.noticeDetail.error.notFound')
    }
    isFail.value = true
  }

  // const payload = {
  //   crtrNttSn: notice.crtrNttSn,
  //   creatorUserSn: notice.crtrUserSn,
  // }
  // await creatorStore.fetchCreatorNotice(payload)
}

/**
 * 팝업 닫기: 버튼 클릭
 */
const handleOnClose = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '공지사항 상세보기 닫기',
    eventLabel: 'close-extend',
    eventSlot: '공지사항 상세보기 모달 닫기',
    eventI18nAddr: 'close-extend',
    eventComponent: 'Button',
    crtrNttSn: creatorNotice.value?.crtrNttSn,
    crtrNttSj: creatorNotice.value?.crtrNttSj,
    crtrUserSn: creatorNotice.value?.crtrUserSn,
  })
  await modalHide(modalsName.MODAL_CREATOR_NOTICE_DETAIL)
  creatorStore.initCreatorNotice()
}

/**
 * 팝업 닫은 후 모달 상태 초기화
 */
const handleOnClosed = () => {
  postStore.initPost()
  postStore.initCommentsAndReplies()
  creatorNotice.value = undefined
  errorMessage.value = ''
  isFail.value = false
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_CREATOR_NOTICE_DETAIL"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @closed="handleOnClosed"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaBoxBase v-if="show" class="modal-box">
        <div class="modal-header">
          <div class="on-mobile">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'arrow-left' }"
              :theme="'transparent'"
              :size="24"
              class="btn-close-on-mobile"
              @click="handleOnClose"
            />
          </div>

          <div class="on-desktop">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'close-extend' }"
              :theme="'transparent'"
              :size="24"
              :is-modal="true"
              class="btn-close"
              @click="handleOnClose"
            />
          </div>
        </div>
        <NovaLayoutScrollContainer class="modal-body post-detail">
          <NovaBoxCreatorNoticeListItem
            v-if="creatorNotice"
            :source="creatorNotice"
            :view-type="'detail'"
          />

          <NovaBoxEmptyMessage
            v-if="isFail"
            :message="errorMessage"
            :fill="true"
            class="async-status-message"
          />
        </NovaLayoutScrollContainer>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 670px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;

    .modal-header {
      flex-shrink: 0;
      height: 0;
      padding: 0;
      background-color: $color-bg-3;

      .on-mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        height: 50px;
      }

      .on-desktop {
        .btn-close {
          position: absolute;
          top: 30px;
          right: 30px;
          color: $color-bg-3;
        }
      }
    }

    .modal-body {
      border-radius: $radius-10;

      > .post {
        border-radius: 0;
        box-shadow: none;
      }

      .async-status-message {
        min-height: 200px;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-header {
        flex-shrink: 0;
        height: 56px;
        padding: 0 20px;

        .on-mobile {
          display: flex;
        }

        .on-desktop {
          .btn-close {
            display: none;
          }
        }
      }

      .modal-body {
        border-radius: 0;

        > .post {
          max-height: initial;
        }
      }
    }
  }
}
</style>
