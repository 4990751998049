<script setup lang="ts">
import type {
  NovaModalReportEmits,
  NovaModalReportProps,
} from './NovaModalReport.types'

const emit = defineEmits<NovaModalReportEmits>()
const props = withDefaults(defineProps<NovaModalReportProps>(), {
  message: '',
  where: '',
  title: '',
})
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const state = reactive({
  selectMenu: '',
  textInput: '',
})
const isDisabled = computed(() => {
  const isContentsRequired =
    props.menuSet.find((item) => item.value === state.selectMenu)
      ?.contentRequiredAt === 'Y'
  return isContentsRequired
    ? !(state.selectMenu && state.textInput.trim().length >= 5)
    : false
})
const textareaPlaceholder = computed(() => {
  let placeholder = props.detail.textareaPlaceholder

  const menu = props.menuSet.find((item) => item.value === state.selectMenu)

  if (menu) {
    placeholder =
      props.detail.textareaPlaceholder +
      `(${t(menu.contentRequiredAt === 'Y' ? 'required' : 'optional')})`
  }

  return placeholder
})
const selectedLabel = computed(() => {
  const label = props.menuSet.find((item) => item.value === state.selectMenu)
  if (label) return label.label
})

const confirm = async () => {
  await emit('confirm')
  gtEvent('userAction', {
    eventCategory: '사용자',
    eventAction: props.title,
    eventLabel: props.detail.btnLabel[1],
    eventSlot: props.where,
    eventI18nAddr: '',
    eventComponent: 'button',
    cmtyNttSn: props.cmtyNttSn,
    cmtyNttAnswerSn: props.cmtyNttAnswerSn,
  })
}

const close = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `${props.title} 취소`,
    eventLabel: props.detail.btnLabel[0],
    eventSlot: props.where,
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  await emit('close')
}

watch(
  () => state.textInput,
  (current) => {
    emit('updateText', current)
  }
)

const onUpdateMenu = (value: string) => {
  state.selectMenu = value
  gtEvent('selectEvent', {
    eventCategory: '선택',
    eventAction: `${props.detail.dropdownPlaceholder} 선택`,
    eventLabel: `${selectedLabel.value}`,
    eventSlot: `${props.where}`,
    eventI18nAddr: '',
    eventComponent: 'DropDown',
  })
  emit('updateMenu', value)
}
</script>

<template>
  <div class="report-wrap">
    <NovaDropdown
      :placeholder="detail.dropdownPlaceholder"
      :menus="menuSet"
      :active-value="state.selectMenu"
      @update="(value) => {
        onUpdateMenu(value as string)
      }"
    />
    <NovaTextarea
      v-model="state.textInput"
      :placeholder="textareaPlaceholder"
      :max-byte="detail.textareaMax"
    />
    <p v-dompurify-html="message || ''" class="report-message" />
    <div class="action-box">
      <NovaButtonText
        :label="detail.btnLabel[0]"
        :theme="'secondary-gray-200'"
        :size="32"
        class="btn-action"
        @click="close"
      />
      <NovaButtonText
        :label="detail.btnLabel[1]"
        :theme="'primary-blue-dark'"
        :size="32"
        :disabled="isDisabled"
        class="btn-action"
        @click="confirm"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.report-wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.report-message {
  text-align: right;
  @include text-style($text-body-12-regular);
}

.action-box {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  > .btn-action {
    width: 100px;
  }
}

@include mobile {
  .action-box {
    display: flex;
    margin-top: 0;
    padding: 0;
    gap: 8px;

    > .btn-action {
      width: 50%;
    }
  }
}
</style>
