<script setup lang="ts">
import type {
  NovaModalPostCreateTempSaveListEmits,
  NovaModalPostCreateTempSaveListProps,
} from './NovaModalPostCreateTempSaveList.types'
import type { TempPostItem } from '@store'
import { NovaModalDialog } from '#components'

const emit = defineEmits<NovaModalPostCreateTempSaveListEmits>()
const props = withDefaults(
  defineProps<NovaModalPostCreateTempSaveListProps>(),
  {
    nttTyCode: 'NORM',
  }
)

const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { date } = useDateFormat()
const postStore = usePostStore()
const layoutStore = useLayoutStore()
const { show: modalShow, hide: modalHide } = useModal()
const state = reactive({
  isLoading: true,
  isError: false,
})

onMounted(() => {
  updatePagination(true)
})

// 임시 저장 목록 조회
const updatePagination = async (refresh = false, isRecycle: boolean) => {
  try {
    state.isLoading = true
    state.isError = false
    await postStore.fetchTempPosts(
      {
        ...postStore.tempList.payload,
        nttTyCode: props.nttTyCode,
      },
      refresh
    )
    if (isRecycle) {
      gtEvent('scrollEvent', {
        eventCategory: '더보기',
        eventAction: '포스트 > 임시 저장 목록 조회',
        eventLabel: 'loadMore',
        eventSlot: '',
        eventI18nAddr: '',
        eventComponent: 'RecyclerView',
        nttTyCode: props.nttTyCode,
        pageNum: postStore.tempList.payload.pageNum,
        pageSize: postStore.tempList.payload.pageSize,
      })
    }
  } catch {
    state.isError = true
  } finally {
    state.isLoading = false
  }
}

// 임시 저장 아이템 삭제
const handleOnDeleteItem = async (tempSN: TempPostItem['tmprNttSn']) => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    await postStore.deleteTempPost({ tmprNttSn: tempSN })
    gtEvent('userAction', {
      eventCategory: '사용자',
      eventAction: '임시저장 된 내용 삭제 모달 > 삭제',
      eventLabel: t('dialog.confirmTempPostDelete.action.positive'),
      eventSlot: '포스트 임시저장 모달',
      eventI18nAddr: useKoreanTranslation(
        'dialog.confirmTempPostDelete.action.positive'
      ),
      eventComponent: 'Button',
      tmprNttSn: tempSN,
    })
  } catch {
    useToast(t('statusMessages.tempPostRemove.error'))
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

// 임시 저장 아이템 삭제전 컨펌
const showDeleteConfirm = async (tempSN: TempPostItem['tmprNttSn']) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '임시저장 된 내용 삭제 모달 > 삭제 아이콘 클릭',
    eventLabel: t('dialog.confirmTempPostDelete.action.positive'),
    eventSlot: '포스트 임시저장 모달',
    eventI18nAddr: useKoreanTranslation(
      'dialog.confirmTempPostDelete.action.positive'
    ),
    eventComponent: 'Button',
    tmprNttSn: tempSN,
  })
  await modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_TEMP_POST_DELETE_CONFIRM,
      btns: [
        {
          label: t('dialog.confirmTempPostDelete.action.negative'),
          theme: 'secondary-gray-200',
          size: 32,
          onClick: async () => {
            await gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '임시저장 된 내용 삭제 모달 > 삭제 확인 > 취소',
              eventLabel: t('dialog.confirmTempPostDelete.action.negative'),
              eventSlot: useKoreanTranslation(
                'dialog.confirmTempPostDelete.title'
              ),
              eventI18nAddr: useKoreanTranslation(
                'dialog.confirmTempPostDelete.action.negative'
              ),
              eventComponent: 'Button',
            })
            await modalHide(modalsName.MODAL_TEMP_POST_DELETE_CONFIRM)
          },
        },
        {
          label: t('dialog.confirmTempPostDelete.action.positive'),
          theme: 'primary-blue-dark',
          size: 32,
          onClick: async () => {
            gtEvent('clickEvent', {
              eventCategory: '클릭',
              eventAction: '임시저장 된 내용 삭제 모달 > 삭제 확인 > 확인',
              eventLabel: t('dialog.confirmTempPostDelete.action.positive'),
              eventSlot: useKoreanTranslation(
                'dialog.confirmTempPostDelete.title'
              ),
              eventI18nAddr: useKoreanTranslation(
                'dialog.confirmTempPostDelete.action.positive'
              ),
              eventComponent: 'Button',
            })
            await handleOnDeleteItem(tempSN)
            await modalHide(modalsName.MODAL_TEMP_POST_DELETE_CONFIRM)
          },
        },
      ],
    },
    slots: {
      title: t('dialog.confirmTempPostDelete.title'),
      content: t('dialog.confirmTempPostDelete.description'),
    },
  })
}

// 임시 저장 아이템 선택
const selectTempItem = (id: number, data: any) => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '임시저장 모달 > 선택',
    eventLabel: '',
    eventSlot: '포스트 임시저장 모달',
    eventI18nAddr: '',
    eventComponent: 'Button',
    nttSj: data.nttSj,
    tmprNttSn: data.tmprNttSn,
  })
  emit('selectItem', id)
}
</script>

<template>
  <div class="temp-list-wrap">
    <NovaLoadingIndicator
      v-if="state.isLoading"
      :size="80"
      :fill="true"
      :bg-bright="'light'"
    />
    <NovaBoxEmptyMessage
      v-if="
        !state.isLoading && !state.isError && !postStore.tempList.items.length
      "
      :message="$t('statusMessages.tempList.empty')"
    />
    <NovaBoxEmptyMessage
      v-if="state.isError"
      :message="$t('statusMessages.tempList.error')"
    />

    <NovaRecyclerGrid
      v-if="
        !state.isLoading && !state.isError && postStore.tempList.items.length
      "
      :items="postStore.tempList.items"
      :is-last="postStore.tempList.last"
      :is-loading="state.isLoading"
      :is-error="state.isError"
      :is-refresh="postStore.tempList.refresh"
      :key-name="'tmprNttSn'"
      :responsive="{
        mobile: { columns: 1, rowGap: 0, columnGap: 0 },
        tablet: { columns: 1, rowGap: 0, columnGap: 0 },
        desktop: { columns: 1, rowGap: 0, columnGap: 0 },
      }"
      :page-mode="false"
      :bottom-white-space="0"
      @load-more="updatePagination(false, true)"
    >
      <template #item="{ item }">
        <div class="temp-item" @click="selectTempItem(item.tmprNttSn, item)">
          <span class="title">{{ item.nttSj }}</span>
          <div class="tools">
            <span class="date">{{
              date({ date: item.tmprNttRegistDt, format: 'YYYY-MM-DD' })
            }}</span>
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'close' }"
              :size="20"
              :class="['btn-clear']"
              @click.stop="showDeleteConfirm(item.tmprNttSn)"
            />
          </div>
        </div>
      </template>
    </NovaRecyclerGrid>
  </div>
</template>

<style lang="scss" scoped>
.temp-list-wrap {
  display: flex;
  max-height: 100%;
  overflow: hidden;
}
.temp-list {
  display: flex;
  max-height: 100%;
  flex-direction: column;
  height: auto;

  :deep(.scroller) {
    padding: 0 20px;
  }

  .vue-recycle-scroller__item-view:last-of-type .temp-item {
    border-bottom: none;
  }
}

.temp-item {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-bottom: 1px solid $color-gray-1;
  width: 100%;
  padding: 0 20px;

  .title {
    @include text-style($text-body-14-regular);
    @include ellipsis(1);
    flex-grow: 1;
  }
  .tools {
    display: flex;
    gap: 12px;
    flex-shrink: 0;

    .date {
      @include text-style($text-body-12-regular);
      color: $color-text-4;
      width: 64px;
      text-align: right;
    }
  }
}
</style>
