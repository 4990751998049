<script setup lang="ts">
import type {
  NovaCreateHashtagEmit,
  NovaCreateHashtagProps,
} from './NovaCreateHashtag.types'

const emit = defineEmits<NovaCreateHashtagEmit>()
const props = withDefaults(defineProps<NovaCreateHashtagProps>(), {
  tags: () => [],
})
const { tags } = toRefs(props)
const state = reactive<{ tagSet: string[]; totalTagLength: number }>({
  tagSet: tags.value,
  totalTagLength: 0,
})

const { t } = useI18n()

watch(state.tagSet, (current) => {
  emit('updateTags', current)
})

const handleOnPushTag = (str: string) => {
  const newStr = str.replace(/ /g, '')
  const newTags = [...tags.value].concat(newStr)
  emit('updateTags', newTags)
}

const handleOnPopTag = () => {
  const newTags = [...tags.value]
  newTags.pop()
  emit('updateTags', newTags)
}

const handleOnDeleteTag = (index: number) => {
  const newTags = [...tags.value]
  newTags.splice(index, 1)
  emit('updateTags', newTags)
}

const checkDoubleTag = (str: string) => {
  const findTag = tags.value.findIndex((item) => item === str)
  if (findTag === -1) {
    return handleOnPushTag(str)
  } else {
    useToast(t('postCreate.toastMessage.validateHashtagDuplicate'))
  }
}
</script>

<template>
  <div class="hashtag-area">
    <div class="hashtag-box">
      <NovaCreateHashtagItem
        v-for="(item, index) in tags"
        :key="index"
        :index="index"
        :hashtag="item"
        :prefix="'#'"
        @update-delete="handleOnDeleteTag(index)"
      />
    </div>
    <NovaCreateHashtagTagInput
      :hashtag-set="hashtagSet"
      :tag-set="tags"
      @delete="handleOnPopTag"
      @update="checkDoubleTag"
    />
  </div>
</template>

<style lang="scss" scoped>
.hashtag-area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 15px;
  background-color: $color-white;
  border-radius: 8px;
  border: 1px solid $color-bg-custom-2;
}
.hashtag-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  .btn-close {
    margin-left: 8px;
  }
}
</style>
