import apiPath from '@/store/apiPath'

export default {
  /**
   * 커버댄스 이벤트 참가 신청
   * @method POST
   */
  REQ_KPOP_FESTIVAL_APPLY: `${apiPath.KPOPFESTIVAL}/v1/application`,

  /**
   * 커버댄스 이벤트 참가 신청 현황 조회
   * @method GET
   */
  FETCH_KPOP_FESTIVAL_STATUS: `${apiPath.KPOPFESTIVAL}/v1/application/status`,

  /**
   * 커버댄스 이벤트 약관 목록 조회
   * @method GET
   */
  FETCH_KPOP_FESTIVAL_TERMS: `${apiPath.KPOPFESTIVAL}/v1/application/terms`,

  /**
   * 커버댄스 이벤트 약관 상세 조회
   * @method GET
   */
  FETCH_KPOP_FESTIVAL_TERM: `${apiPath.KPOPFESTIVAL}/v1/application/term`,
}
