<script lang="ts" setup>
// import { AxiosError } from 'axios'
import { AxiosError } from 'axios'
import type { NovaBoxDaoVoteDetailProps } from './NovaBoxDaoVoteDetail.types'
import type { DaoVotingPayload } from '@store'

withDefaults(defineProps<NovaBoxDaoVoteDetailProps>(), {
  badgeThemeClass: 'primary-blue-dark',
})

const { dayjs } = useDayjs()
const daoStore = useDaoStore()
const creatorStore = useCreatorStore()
const layoutStore = useLayoutStore()
const userStore = useUserStore()
const { daoVote } = storeToRefs(daoStore)
const { getPrivateKey } = useMembershipProcess()
const { hide: modalHide } = useModal()
const { t, messages, locale } = useI18n()

// 선택 된 투표 항목 값
const checkedOption = ref('')

// 투표 가능여부, 투표 여부, 이전에 투표한 값의 Sn
const voteStatus = daoVote.value?.creatorDaoMemberVoteStatusDto

// 투표 상세 데이터
const voteDetailData = daoVote.value?.creatorDaoVoteDetailDtoList[0]

// 투표 참여 여부
const voteFinished = computed(() => {
  return voteDetailData?.daoVoteSttusCode === 'T' || voteStatus?.voteAt
})

// 투표 하기 버튼 라벨 (투표 완료, 투표 가능, 투표 대상이 아님)
const voteBtnLabel = computed(() => {
  let checkStatus
  if (voteStatus?.voteAt) {
    checkStatus = 'completeVote'
  } else {
    checkStatus = voteStatus?.votable ? 'votesAvailable' : 'votesUnavailable'
  }
  return t(`dao.vote.detail.button.${checkStatus}`)
})
// 투표 시작일 계산
const createAtTimestamp = new Date(
  dayjs.utc(voteDetailData?.daoVoteBeginDt).format()
).getTime()

const formattedDate = dayjs(createAtTimestamp).format('YYYY.MM.DD HH:mm')

// 투표 항목들
const voteOptions = computed(() => {
  // Find the maximum vote count
  const maxCount = Math.max(
    ...voteDetailData!.questionList.map((item) => item.voteCount || 0)
  )

  // Map the items and assign `topVotes: true` to those with the maximum count
  return voteDetailData!.questionList.map((item) => ({
    label: item.qesitmAnswerNm,
    value: item.daoVoteQesitmRspnsSn.toString(),
    count: item.voteCount || 0, // Default 0
    direction: 'row',
    topVotes: (item.voteCount || 0) === maxCount, // Assign true if count matches maxCount
  }))
})
// const voteOptions = computed(() => {
//   return voteDetailData!.questionList.map((item) => ({
//     label: item.qesitmAnswerNm,
//     value: item.daoVoteQesitmRspnsSn.toString(),
//     count: item.voteCount || 0, // 기본값 0
//     direction: 'row',
//   }))
// })

// 투표 했던 항목의 인덱스
const findVotedOptionIndex = computed(() => {
  return voteOptions.value.findIndex(
    (option) => option.value === voteStatus?.daoVoteQesitmRspnsSn?.toString()
  )
})

// 투표에 필요한 데이터
const voteCheckOptional = reactive({
  value: 'N',
  conditions: voteOptions,
  changeCondition: (value: string) => {
    voteCheckOptional.value = value
    checkedOption.value = value
  },
})

// 투표 항목 선택
const handleOnCheckVote = (value: string) => {
  checkedOption.value = value
}

// 투표 하기 액션
const handleOnVote = async () => {
  try {
    layoutStore.updateLoadingIndicatorGlobal({ show: true })
    const privateKey = await getPrivateKey(userStore.user!.userSn)
    const payload = {
      daoSn: creatorStore.profile?.creatorDaoSn,
      daoVoteQesitmRspnsSn: Number(checkedOption.value),
      daoVoteQesitmSn: voteDetailData?.daoVoteQesitmSn,
      daoVoteSn: voteDetailData?.daoVoteSn,
      privateKey,
    } as DaoVotingPayload
    await daoStore.createDaoVoting(payload)
    await useToast(t('dao.vote.success'))
    await modalHide(modalsName.MODAL_DAO_VOTE_DETAIL)
  } catch (err) {
    if (err instanceof AxiosError) {
      const errCode = err.response.status.toString()
      const errCodes = Object.keys(
        (messages.value[locale.value].dao as any).vote.errorMessage
      )
      if (errCodes.includes(errCode)) {
        await useToast(t(`dao.vote.errorMessage.${errCode}`))
        return
      }
      await useToast(t('dao.vote.errorMessage.default'))
    }
  } finally {
    layoutStore.updateLoadingIndicatorGlobal({ show: false })
  }
}

onMounted(() => {
  if (voteStatus?.daoVoteQesitmRspnsSn) {
    checkedOption.value = String(voteStatus.daoVoteQesitmRspnsSn)
  }
})
</script>

<template>
  <div class="dao-vote-detail">
    <div class="title-box">
      <div class="title-contents">
        <div class="title-contents-date">{{ formattedDate }}</div>
        <div class="title-badge">
          <NovaButtonText
            v-if="!voteStatus?.voteAt && voteFinished"
            :label="t('dao.vote.detail.badge.noVote')"
            :readonly="true"
            :size="20"
            :theme="badgeThemeClass"
          />
          <NovaButtonText
            :label="badgeLabel"
            :readonly="true"
            :size="20"
            :theme="badgeThemeClass"
          />
        </div>
      </div>
      <div class="title-contents-name">{{ voteDetailData?.qesitmSjNm }}</div>
    </div>
    <div v-if="voteFinished" class="voting-item-box">
      <!-- 투표 후 체크는 on 클래스 추가 -->
      <div
        v-for="(vote, index) in voteOptions"
        :key="vote.value"
        :class="['item', 'voting-result', { on: vote.topVotes }]"
      >
        <div class="item-title">
          <span class="txt">
            {{ vote.label }}
          </span>
          <span v-if="index === findVotedOptionIndex" class="my-check">
            <NovaIcon
              class="icon"
              :icon="{
                type: 'outline',
                name: 'check',
              }"
              :size="16"
            />
          </span>
        </div>
        <div class="voting-num">
          {{ vote.count }} {{ t('dao.vote.detail.count') }}
        </div>
      </div>
    </div>
    <div v-else class="voting-item-box">
      <div v-for="vote in voteOptions" :key="vote.value" class="item">
        <NovaCheckbox
          :font-size="'14px'"
          :font-weight="'500'"
          :is-check="checkedOption === vote.value"
          :label="vote.label"
          :theme="'circle-md-20'"
          :readonly="voteStatus?.voteAt || !voteStatus?.votable"
          @click="() => handleOnCheckVote(vote.value)"
        />
      </div>
    </div>
    <div class="btn-box">
      <NovaButtonText
        v-if="!voteFinished"
        :full-width="true"
        :label="voteBtnLabel"
        :size="44"
        :theme="'primary-blue-dark'"
        :disabled="!checkedOption || voteStatus?.voteAt || !voteStatus?.votable"
        @click="handleOnVote"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dao-vote-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 28px 20px;
  background-color: $color-bg-2;
  height: 100%;
  border-radius: 0;

  .title-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title-contents {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;

      .title-contents-date {
        @include text-style($text-body-14-regular);
        color: $color-text-12;
        @include mobile {
          @include text-style($text-body-12-regular);
        }
      }

      .title-badge {
        display: flex;
        gap: 4px;

        :deep(.btn) {
          color: $color-white;
          @include text-style($text-body-14-medium);
          line-height: 20px;
          border-radius: 12px;
          @include mobile {
            @include text-style($text-body-12-medium);
            line-height: 16px;
            border-radius: 10px;
          }
        }
      }
    }

    .title-contents-name {
      @include text-style($text-display-bold);
    }
  }

  .voting-item-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .item {
      &.voting-result {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .item-title {
          max-width: calc(100% - 70px);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;
          @include text-style($text-body-14-medium);

          .txt {
            word-break: break-all;
          }
        }

        .voting-num {
          width: 60px;
          text-align: right;
          @include text-style($text-body-14-medium);
        }
      }

      &.on {
        color: $color-blue-700;
        @include text-style($text-body-14-bold);
      }
    }
  }

  .btn-box {
    padding-top: 20px;
  }
}
</style>
