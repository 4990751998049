// import { subscribe } from './subscribe'
import type {
  CreatorCommentOrderBy,
  CreatorContentsOrderBy,
  CreatorRewardsOrderBy,
  StatisticsOrderBy,
  MyRecommendPostType,
} from '@store'
import type {
  CreatorHomeTabType,
  CreatorHomeDaoTabType,
} from '@components/NovaBoxCreatorTopHeader/NovaBoxCreatorTopHeader.types'

/**
 * 크리에이터 홈
 */
export const creatorMyPage = {
  home: {
    desc: 'Introduction',
    introductionPlaceholder: 'Insert the content',
    introductionEmpty: 'No introduction exists.',
    introductionModifyFail: 'Not available to introduction save. Try again.',
    introductionModifySuccess: 'It has been saved.',

    link: 'Link',
    linkEmpty: 'No link exists.',
    linkTitlePlaceholder: 'link title',
    linkUrlPlaceholder: 'add link',
    linkRemoveConfirmTitle: 'Delete link',
    linkRemoveConfirmMessage: 'Will you delete your link?',

    linkValidFail: 'Please complete the link title, add link field',
    linkAddSuccess: 'It has been added',
    linkAddFail: 'Not available to Social link add. Try again.',
    linkSaveSuccess: 'It has been saved.',
    linkSaveFail: 'Not available to Social link save. Try again.',
    linkRemoveSuccess: 'It has been deleted',
    linkRemoveFail: 'Not available to Social link delete. Try again',
  },
  profile: {
    title: 'Profile Information',
    placeholder: {
      nickname: 'Set your nickname.',
      content: 'Profile content',
    },
    button: {
      cancel: 'Cancel',
      save: 'Save',
    },
    dialog: {
      title: 'Notifications',
      initImage:
        'Deleting the image will change it to your default avatar. Are you sure you want to delete it?',
      doubleNickname:
        'This username is already taken. Please choose a different username.',
      noImage: 'The user profile does not exist.',
    },
    toastMessage: {
      imgError2005: 'This extension cannot be registered.',
      imgError: 'The image upload failed. Please try again later.',
    },
  },
  tabMenu: {
    // home: 'Home',
    contents: 'Post',
    // TODO: 앱 버전 릴리즈를 위해 기능 숨김
    // subscribeGrade: subscribe.premiumBrand,
    // guestbook: 'Guest book',
    // notice: 'Notice',
    reward: 'Reward',
    donation: 'Donation',
    activity: 'My activity',
    // statistics: 'Statistics',
    collections: 'My Collection',
  } as { [Property in CreatorHomeTabType]: string },
  tabsForMyHome: {
    reward: 'Reward',
    // TODO: 앱 버전 릴리즈를 위해 기능 숨김
    // subscribeGrade: subscribe.premiumBrand,
    contents: 'Post',
    // guestbook: 'Guest book',
    // notice: 'Notice',
    donation: 'Donation',
    activity: 'My activity',
    // statistics: 'Statistics',
    collections: 'My Collection',
  } as { [Property in CreatorHomeTabType]: string },
  tabsForDaoMember: {
    vote: 'Vote',
    board: 'Board',
    member: 'Member',
  } as { [Property in CreatorHomeDaoTabType]: string },
  tabsForDao: {
    vote: 'Vote',
    board: 'Board',
  } as { [Property in CreatorHomeDaoTabType]: string },
  sort: {
    contents: {
      newest: 'Newest',
      oldest: 'Oldest',
      hot: 'Hottest',
    } as { [Property in CreatorContentsOrderBy]: string },
    comment: {
      newest: 'Newest',
      oldest: 'Oldest',
      hot: 'Hottest',
    } as { [Property in CreatorCommentOrderBy]: string },
    reward: {
      newest: 'Newest',
      oldest: 'Oldest',
      rewardQty: 'Order by the reward amount',
    } as { [Property in CreatorRewardsOrderBy]: string },
    stats: {
      D: 'Yeaterday',
      W: 'Prev Week',
      M: 'Prev Month',
    } as { [Property in StatisticsOrderBy]: string },
  },
  panel: {
    reward: {
      title: 'Total rewards',
      empty: "You haven't received any rewards yet.",
    },
    post: {
      public: 'Public',
      private: 'Private',
    },
    comment: 'All comments',
    recommend: {
      get: 'Liked posts',
      set: 'Recommended posts',
    } as { [Property in MyRecommendPostType]: string },
    scrap: '(All) saved posts',
  },
  posts: {
    active: 'Activities',
    reward: {
      user: 'Weekly activity reward has been paid.',
      post: 'Weekly posting reward has been paid.',
    },
    toastMessage: {
      creatorContentsFilterLessOne: 'You must select at least one checkbox.',
    },
  },
  dao: {
    vote: {
      message: {
        empty: '투표 안건이 없습니다.',
      },
      modal: {
        create: {
          header: '투표 생성하기',
          conditions: '투표 참여 조건',
          name: {
            title: '투표 제목',
            placeHolder: '투표 제목을 입력해주세요.',
            errMsg: '작성하지 않았습니다.',
          },
          contents: {
            title: '항목 입력',
            add: '+ 항목 추가',
            placeHolder: '항목 <span>{index}</span> 입력',
          },
          period: {
            title: '투표 기간',
            start: '시작일',
            end: '마감일',
          },
          buttons: {
            create: '생성',
            delete: '삭제',
            modify: '수정',
          },
        },
      },
    },
  },
}
