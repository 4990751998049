<script setup lang="ts">
import type { RouteParams } from 'vue-router'
import type { ShowModalSelectBankParams } from './NovaModalSelectBank.types'
import type { Bank } from '@store'
import { NovaLayoutScrollContainer } from '#components'

defineOptions({
  inheritAttrs: false,
})

const { t } = useI18n()
const etcStore = useEtcStore()
const { hide: modalHide } = useModal()
const show = ref(false)
const scrollContainerRef = ref<InstanceType<typeof NovaLayoutScrollContainer>>()
const isLoading = ref(false)
const isFail = ref(false)
const selectBank = ref<Bank | null>(null)
const keyword = ref('')
const filteredBanks = computed(() =>
  etcStore.banks.filter((bank) =>
    keyword.value ? bank.bicBankNm.toLowerCase().includes(keyword.value) : true
  )
)

// 팝업 열릴때 호출하는 쪽에서 파라미터 받아 선택한 은행 초기화 하고 은행 목록 조회
const handleOnGetParams = async (evt: RouteParams) => {
  // 1. 선택한 은행 초기화
  selectBank.value = evt.value as unknown as ShowModalSelectBankParams

  // 2. 은행 목록 조회
  try {
    isLoading.value = true
    isFail.value = false
    await etcStore.fetchBanks()
  } catch (err) {
    isFail.value = true
  } finally {
    isLoading.value = false
  }
}

// 은행 선택
const handleOnSelectBank = (bank: Bank) => {
  useEvent('bank:select', bank)
  handleOnClose()
}

// 팝업 닫기
const handleOnClose = async () => {
  await modalHide(modalsName.MODAL_SELECT_BANK)
}

// 팝업 닫기 후 초기화
const handleOnClosed = () => {
  keyword.value = ''
}
</script>

<template>
  <VueFinalModal
    v-model="show"
    :name="modalsName.MODAL_SELECT_BANK"
    :content-class="'modal-content'"
    classes="modal-container"
    @before-open="(evt: any) => handleOnGetParams(evt.ref.params)"
    @closed="handleOnClosed"
    @click-outside="handleOnClose"
  >
    <ClientOnly>
      <NovaLoadingIndicator
        v-if="isLoading"
        :fill="true"
        :bg-bright="'dark'"
        class="async-status-message"
      />

      <NovaBoxBase v-if="!isLoading && show" class="modal-box">
        <div class="modal-header">
          <div class="on-mobile">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'arrow-left' }"
              :theme="'transparent'"
              :size="24"
              class="btn-close-on-mobile"
              @click="handleOnClose"
            />
          </div>

          <div class="on-desktop">
            <NovaButtonIcon
              :icon="{ type: 'outline', name: 'close-extend' }"
              :theme="'transparent'"
              :size="24"
              class="btn-close"
              @click="handleOnClose"
            />
          </div>
        </div>

        <div class="modal-body">
          <div class="search-bar">
            <NovaInput
              v-model="keyword"
              :placeholder="'Search'"
              theme="search"
            />
          </div>

          <NovaLayoutScrollContainer ref="scrollContainerRef" class="banks">
            <NovaBoxSelectBank
              v-if="show && !isFail && filteredBanks.length"
              :select-bank="selectBank"
              :banks="filteredBanks"
              @on-select-bank="handleOnSelectBank"
            />

            <NovaBoxEmptyMessage
              v-if="!isFail && !filteredBanks.length"
              :message="
                t(
                  'accountPanel.settings.bankAccount.messages.empty.fetchBanks',
                  { keyword }
                )
              "
              :fill="true"
              class="async-status-message"
            />

            <NovaBoxEmptyMessage
              v-if="isFail"
              :message="
                t('accountPanel.settings.bankAccount.messages.error.fetchBanks')
              "
              :fill="true"
              class="async-status-message"
            />

            <NovaScrollToTopButton :target="scrollContainerRef?.$el || null" />
          </NovaLayoutScrollContainer>
        </div>
      </NovaBoxBase>
    </ClientOnly>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.vfm--overlay) {
  background-color: hex-to-rgba($color-black, 0.65);
}

:deep(.modal-content) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(30px + 24px + 12px) 32px 32px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .modal-box {
    width: 100%;
    padding: 0;
    max-width: 420px;
    max-height: 100%;
    border-radius: 20px !important;
    pointer-events: auto;

    .modal-header {
      flex-shrink: 0;
      height: 0;
      padding: 0;
      background-color: $color-bg-3;

      .on-mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        height: 50px;
      }

      .on-desktop {
        .btn-close {
          position: absolute;
          top: 30px;
          right: 30px;
          color: $color-bg-3;
        }
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      border-radius: $radius-10;
      overflow: hidden;

      .search-bar {
        flex-shrink: 0;
        position: relative;
        padding: 20px 20px 0 20px;
        background: rgb(255, 255, 255);

        > :after {
          position: absolute;
          top: 100%;
          left: 0;
          display: block;
          content: '';
          width: 100%;
          height: 20px;
          background: linear-gradient(
            180deg,
            hex-to-rgba($color-bg-3, 1) 0%,
            hex-to-rgba($color-bg-3, 0) 100%
          );
        }
      }

      .banks {
        flex-grow: 1;
        height: 600px;
        max-height: 100%;
        padding: 20px;
      }

      .async-status-message {
        min-height: 200px;
      }
    }
  }
}

@include mobile {
  :deep(.modal-content) {
    width: 100%;
    height: 100%;
    padding: 0;

    .modal-box {
      width: 100%;
      max-width: 100%;
      height: 100%;
      border-radius: 0 !important;

      .modal-header {
        flex-shrink: 0;
        height: 56px;
        padding: 0 20px;

        .on-mobile {
          display: flex;
        }

        .on-desktop {
          .btn-close {
            display: none;
          }
        }
      }

      .modal-body {
        border-radius: 0;

        .search-bar {
          padding: 12px 12px 0 12px;

          :after {
            height: 12px;
          }
        }

        .banks {
          height: 100%;
          padding: 12px;
        }
      }
    }
  }
}
</style>
