<script setup lang="ts">
import type { NovaCreatorProfileCardTinyProps } from './NovaCreatorProfileCardTiny.types'
import { ROUTES } from '@configs'
const { t } = useI18n()
const props = withDefaults(defineProps<NovaCreatorProfileCardTinyProps>(), {
  forceMobileLayout: false,
  onGoCreatorAfter: () => {},
})

const memberStatus = computed(() => {
  switch (props.creator.creatorDaoSbscrbSttusCode) {
    case 'P':
      return t('dao.createCard.sttusCode.pCode')
    case 'S':
      return t('dao.createCard.sttusCode.sCode')
    case 'T':
    case 'R':
    case 'B':
    default:
      return ''
  }
})

const handleOnGoCreatorHome = () => {
  useNavigations({
    url: useRoutePathIdChange(ROUTES.CREATOR.path, {
      id: `@${props.creator.creatorLink}`,
    }),
  })
  props.onGoCreatorAfter()
}
</script>

<template>
  <a
    :class="[
      'nova-creator-profile-card',
      { 'force-mobile-layout': forceMobileLayout },
      { 'dao-member': creator.creatorDaoOpenedAt === 'Y' },
    ]"
    @click="handleOnGoCreatorHome"
  >
    <div class="portrait">
      <div class="dao-badge">FAM</div>
      <NovaImageContainer
        :ratio="'1:1'"
        :image-url="creator.creatorProfl || ''"
      />
      <div
        :class="[
          'dao-member-status',
          {
            'dao-join': ['P', 'S'].includes(creator.creatorDaoSbscrbSttusCode),
          },
        ]"
      >
        {{ memberStatus }}
      </div>
    </div>
    <div class="profile-info">
      <h4 class="creator-name">{{ creator.creatorNcnm || '&nbsp;' }}</h4>
      <p class="category">{{ creator.ctgryNm || '&nbsp;' }}</p>
    </div>
  </a>
</template>

<style scoped lang="scss">
@mixin mobile-layout {
  border-radius: 10px;
  .portrait {
    .dao-badge {
      @include text-style($text-body-12-medium);
    }
    .dao-member-status {
      @include text-style($text-body-12-medium);
    }
  }
  .profile-info {
    padding: 7px 10px 10px;

    .creator-name {
      color: #434343;
      font-size: 12px;
      letter-spacing: -0.3px;
    }

    .category {
      color: $color-neutral-dark-4;
      font-size: 10px;
      letter-spacing: -0.25px;
    }
  }
}

.nova-creator-profile-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: $color-white;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;

  .portrait {
    position: relative;
    background-color: $color-bg-custom-1;
    .dao-badge {
      position: absolute;
      top: 5px;
      right: 5px;
      display: none;
      color: $color-white;
      background-color: $color-blue-900;
      padding: 1px 6px;
      z-index: 10;
      border-radius: 10px;
      @include text-style($text-body-14-medium);
    }
    .dao-member-status {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: none;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      color: $color-white;
      padding: 4px 4px;
      z-index: 10;
      @include text-style($text-body-14-medium);
    }
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 15px;

    @include mobile {
      padding: 10px 10px 10px;
    }
    .creator-name {
      color: $color-text-11;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */
      letter-spacing: -0.5px;
      @include ellipsis(1);
    }

    .category {
      color: $color-neutral-dark-4;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.4px;
      @include ellipsis(1);
    }
  }

  &.force-mobile-layout {
    @include mobile-layout;
  }
  &.dao-member {
    background-color: $color-blue-900;
    .portrait {
      .dao-badge {
        display: block;
      }
      .dao-join {
        display: block;
      }
    }
    .profile-info {
      .creator-name {
        color: $color-white;
      }
      .category {
        color: $color-gray-400;
      }
    }
  }
  @include mobile {
    @include mobile-layout;
  }
}
</style>
